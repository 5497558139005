export const fetchStatuses = {
  idle: 'idle',
  pending: 'pending',
  succeeded: 'succeeded',
  failed: 'failed',
}

export const LOGINTYPES = {
  NLE: 'nle',
  GOOGLE: 'google',
  BASIC: 'basic',
}

export const notifStatus = {
  read: 'read',
  unread: 'unread',
}
import { APPROVED, REJECTED, getBadgeClass } from "Helpers/options";
import React from "react";
import { Link } from "react-router-dom";

export default function AgreementItem({
  page,
  index,
  id,
  code,
  trucking,
  updated_on,
  status,
  onDeleteClick,
  table,
}) {
  return (
    <tr>
      <td className="fw-medium">{index + page + 1}</td>
      <td>
        <div className="hstack gap-3 fs-15">
          <Link to={`/app/${table}/detail/${id}`} className="link-success">
            <i className="ri-eye-fill"></i>
          </Link>
          {status === APPROVED || status === REJECTED ? null : (
            <>
              <Link to={`/app/${table}/edit/${id}`} className="link-warning">
                <i className="ri-edit-2-line"></i>
              </Link>
              <Link
                to="#"
                className="link-danger"
                onClick={() => {
                  onDeleteClick(id);
                }}
              >
                <i className="ri-delete-bin-5-line"></i>
              </Link>
            </>
          )}
        </div>
      </td>
      <td>{code}</td>
      <td>{trucking}</td>
      <td>{status === APPROVED ? updated_on : "-"}</td>
      <td>{status === REJECTED ? updated_on : "-"}</td>
      <td>
        <span className={getBadgeClass(status)}>{status}</span>
      </td>
    </tr>
  );
}

import React from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import DeleteModal from "Components/Common/DeleteModal";
import { DriverRQ } from "Hooks/Queries";
import withTable from "Components/Common/Table/withTable";
import { useQueryClient } from "@tanstack/react-query";
import { DATA_LIMIT } from "configs";
import { DriverItem } from "Components/DriverItem";

export default function ListDriver() {
  const [deleteDriverId, setDeleteDriverId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DATA_LIMIT);
  const [keyword, setKeyword] = useState(null);
  const {
    status,
    data,
    error,
    isFetching,
    refetch,
  } = DriverRQ.useGetDataList()(false, keyword, offset, limit);

  const useQuery = useQueryClient();
  const navigate = useNavigate();

  const onDeleteSuccess = (key) => {
    setDeleteModal(false);
    setDeleteDriverId(null);
    useQuery.invalidateQueries({ queryKey: [key] });
    refetch();
  };
  const { mutate: deleteDriver } = DriverRQ.useDeleteData(onDeleteSuccess)();

  const onDeleteClick = (id) => {
    setDeleteModal(true);
    setDeleteDriverId(id);
  };

  const onDeleteConfirm = () => {
    deleteDriver(deleteDriverId);
  };

  const onAddButtonClicked = () => {
    navigate('/app/driver/create')
  }

  const DriverTable = withTable(
    DriverItem,
    "driver",
    ["NO", "Action", "nama", "nik", "foto sim", "masa aktif sim", 'foto ktp', 'foto driver', 'Status'],
    onDeleteClick,
    onAddButtonClicked
  )

  const onPageChange = (i) => {
    setOffset(i.selected);
  }

  const onPerPageChange = (i) => {
    setLimit(i);
    setOffset(0);
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Driver" pageTitle="Drivers" />

        <Row>
          <DriverTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            onPageChange={onPageChange}
            error={error}
            status={status}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal((v) => !v)}
        show={deleteModal}
        loading={false}
      />
    </div>
  );
}

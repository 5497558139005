import React from 'react'
import { Link } from 'react-router-dom';

export function TruckingCatItem({ page, index ,id, name, onDeleteClick, table}) {
  return (
    <tr >
      <td className="fw-medium">{index + page + 1}</td>
      <div className="hstack gap-3 fs-15">
          <Link to={`/app/${table}/detail/${id}`} className="link-success">
            <i className="ri-eye-fill"></i>
          </Link>
          <Link to={`/app/${table}/edit/${id}`} className="link-warning">
            <i className="ri-edit-2-line"></i>
          </Link>
          <Link
            to="#"
            className="link-danger"
            onClick={() => {
              onDeleteClick(id);
            }}
          >
            <i className="ri-delete-bin-5-line"></i>
          </Link>
        </div>
      <td>{name}</td>
    </tr>
  )
}
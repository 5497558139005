import { CREATE_TRUCK_BOOKING_URL, DELETE_TRUCK_BOOKING_URL, LIST_TRUCK_BOOKING_URL, UPDATE_TRUCK_BOOKING_URL, VIEW_TRUCK_BOOKING_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'truck-book';
const REDIRECT_URL = '/app/truck-book';

export const truckBookingRQ = new RQ(
  QUERY_KEY,
  LIST_TRUCK_BOOKING_URL,
  VIEW_TRUCK_BOOKING_URL,
  CREATE_TRUCK_BOOKING_URL,
  UPDATE_TRUCK_BOOKING_URL,
  DELETE_TRUCK_BOOKING_URL,
  REDIRECT_URL
);
import ErrorFieldWrapper from "Components/Common/ErrorFieldWrapper";
import ImageTextPreview from "Components/Common/ImageTextPreview";
import React from "react";
import { Col, Label, Row } from "reactstrap";

export default function FileFormCDO({
  errors,
  register,
  watch,
  awb_file,
  hawb_file,
  sppb_file,
  do_file,
  isEdit,
}) {
  const values = watch();
  const getFileName = (key) => {
    if (!values[key]) return "";
    return values[key].length > 0 ? values[key][0].name : "";
  };

  return (
    <Row className="gy-4 text-muted">
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.awb_file}
          message={errors.awb_file?.message}
        >
          <div className="d-flex justify-content-between">
            <Label htmlFor="awb_file" className="form-label">
              AWB
            </Label>
            {isEdit ? (
              <div className="ml-auto">
                <ImageTextPreview link={awb_file} />
              </div>
            ) : null}
          </div>
          <div className="btn-input">
            <input
              type="file"
              id="file-upload_01"
              accept="image/jpg,image/jpeg,image/png"
              {...register("awb_file")}
            />
            <span className="btn btn-outline-primary px-3 py-2 mb-0 w-100 btn-file d-flex align-items-center justify-content-center">
              <i className="ri-file-2-line me-2"></i>
              {getFileName("awb_file")
                ? getFileName("awb_file")
                : "Pilih Berkas AWB"}
            </span>
          </div>
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.hawb_file}
          message={errors.hawb_file?.message}
        >
          <div className="d-flex justify-content-between">
            <Label htmlFor="hawb_file" className="form-label">
              BUKTI PEMBAYARAN KWITANSI
            </Label>
            {isEdit ? (
              <div className="ml-auto">
                <ImageTextPreview link={hawb_file} />
              </div>
            ) : null}
          </div>
          <div className="btn-input">
            <input
              type="file"
              id="file-upload_01"
              accept="image/jpg,image/jpeg,image/png"
              {...register("hawb_file")}
            />
            <span className="btn btn-outline-primary px-3 py-2 mb-0 w-100 btn-file d-flex align-items-center justify-content-center">
              <i className="ri-file-2-line me-2"></i>
              {getFileName("hawb_file")
                ? getFileName("hawb_file")
                : "Pilih Berkas BUKTI PEMBAYARAN KWITANSI"}
            </span>
          </div>
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.sppb_file}
          message={errors.sppb_file?.message}
        >
          <div className="d-flex justify-content-between">
            <Label htmlFor="sppb_file" className="form-label">
              IZIN BEACUKAI
            </Label>
            {isEdit ? (
              <div className="ml-auto">
                <ImageTextPreview link={sppb_file} />
              </div>
            ) : null}
          </div>
          <div className="btn-input">
            <input
              type="file"
              id="file-upload_01"
              accept="image/jpg,image/jpeg,image/png"
              {...register("sppb_file")}
            />
            <span className="btn btn-outline-primary px-3 py-2 mb-0 w-100 btn-file d-flex align-items-center justify-content-center">
              <i className="ri-file-2-line me-2"></i>
              {getFileName("sppb_file")
                ? getFileName("sppb_file")
                : "Pilih Berkas Izin Beacukai"}
            </span>
          </div>
        </ErrorFieldWrapper>
      </Col>
      <Col md={6} sm={12}>
        <ErrorFieldWrapper
          error={errors.do_file}
          message={errors.do_file?.message}
        >
          <div className="d-flex justify-content-between">
            <Label htmlFor="do_file" className="form-label">
              DO
            </Label>
            {isEdit ? (
              <div className="ml-auto">
                <ImageTextPreview link={do_file} />
              </div>
            ) : null}
          </div>
          <div className="btn-input">
            <input
              type="file"
              id="file-upload_01"
              accept="image/jpg,image/jpeg,image/png"
              {...register("do_file")}
            />
            <span className="btn btn-outline-primary px-3 py-2 mb-0 w-100 btn-file d-flex align-items-center justify-content-center">
              <i className="ri-file-2-line me-2"></i>
              {getFileName("do_file")
                ? getFileName("do_file")
                : "Pilih Berkas DO"}
            </span>
          </div>
        </ErrorFieldWrapper>
      </Col>
    </Row>
  );
}

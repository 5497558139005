import { GOOGLE_API_KEY } from "configs";

window.gmapCB = () => {
  console.log("gmap sdk successfully loaded");
  console.log(window.google);
};
export default function initGMapSDK() {
  return new Promise((resolve) => {
    // Load the GMap SDK script.
    (function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&maps&callback=gmapCB&region=ID&language=id`;
      js.crossorigin = true;
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "gmap-sdk");
    resolve();
  });
}

const extractAddress = (address_components) => {
  let fullName = "";
  if(address_components){
    address_components.forEach((address_component, i)=>{
      fullName += address_component.short_name + (i !== address_components.length - 1 ? ', ':'')
    })
  }
  return fullName
};

export const initAutoComplete = (ref, onSelect) => {
  if (window.google) {
    const autocomplete = new window.google.maps.places.Autocomplete(
      ref.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if(place){
        onSelect({
          name: extractAddress(place.address_components),
          lat: place.geometry.location.lat(),
          long: place.geometry.location.lng(),
        });
      }
    });
  }
};

export async function initMapRoute({
  selectorRef,
  origin_longitude, 
  destination_longitude, 
  origin_latitude, 
  destination_latitude
}) {
  if(window.google){
    console.log(selectorRef.current)
    const directionsRenderer = new window.google.maps.DirectionsRenderer();
    const directionsService = new window.google.maps.DirectionsService();
    const map = new window.google.maps.Map(selectorRef.current, {
      zoom: 14,
      center: { lat: origin_latitude, lng: origin_longitude },
    });
    
    directionsRenderer.setMap(map);
    directionsService
      .route({
        origin: { lat: origin_latitude, lng: origin_longitude },
        destination:{ lat: destination_latitude, lng: destination_longitude },
        // Note that Javascript allows us to access the constant
        // using square brackets and a string value as its
        // "property."
        travelMode: window.google.maps.TravelMode['DRIVING'],
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
      })
      .catch((e) => console.log("Directions request failed due to " + e.message))
      .finally(()=>{
       new window.google.maps.Marker({
          position: { lat: origin_latitude, lng: origin_longitude },
          icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
          map
        });
      });
    }
  }
  

import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import { shipmentStatus } from './helpers';

export function ShippingManagementItem({ page, index ,id, cdo_code, date, customer_name, shipment_status, onDeleteClick, table}) {
  return (
    <tr >
      <td className="fw-medium">{index + page + 1}</td>
      <td>
        <div className="hstack gap-3 fs-15">
          <Link to={`/app/${table}/detail/${id}`} className="link-success">
            <i className="ri-eye-fill"></i>
          </Link>
        </div>
      </td>
      <td>{cdo_code}</td>
      <td>{moment(date).format('YYYY-MM-DD')}</td>
      <td>{customer_name}</td>
      <td>{shipmentStatus(shipment_status)}</td>
    </tr>
  )
}
import React, { useRef, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  TabPane,
  NavLink,
  NavItem,
  Nav,
  TabContent,
  Alert,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";

import avatar from "../../assets/images/users/avatar-1.jpg";
import { APP_NAME } from "../../configs";
import PersonalDetail from "../../Components/Profile/PersonalDetail";
import ChangePassword from "../../Components/Profile/ChangePassword";
import { useMutation, useQuery } from "@tanstack/react-query";
import { apiBearer } from "../../Helpers/api";
import { AVATAR_UPLOAD, MY_PROFILE } from "../../Helpers/url_helper";
import { CUSTOMER } from "Helpers/options";
import { showSuccess } from "Helpers/show_toast";
import { setProfile } from "Redux/reducers/auth";
import SettingKopSurat from "Components/Profile/SettingKopSurat";

const UserProfile = () => {
  const { profile } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const { is_approved, role } = profile;
  const inputRef = useRef();

  // const isAlertDisplay = !is_approved
  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { isLoading, mutate } = useMutation({
      mutationFn: (newData) => {
        return apiBearer.patch(AVATAR_UPLOAD, newData)
      },
      onSuccess: async (data) => {
        showSuccess('Avatar updated!')
        const dataForReduxt = Object.assign({},profile)
        dataForReduxt.avatar = data?.data?.data;
        dispatch(setProfile(dataForReduxt))
      },
    })

  const onChangeFile = (e) => {
    if(e.target.files){
      const file = e.target.files[0]
      if(file){
        const formData = new FormData()
        formData.append('avatar', file)
        mutate(formData)
      }
    }
  }
  const {
    data: dataProfile,
    isFetching,
    status,
    error,
    refetch,
  } = useQuery(
    ['profile'],
    async () => {
      const res = await apiBearer.get(MY_PROFILE)
      return res.data.data
    },
    {
      refetchOnMount: true,
    },
  )


  document.title = `${APP_NAME} | Profile`;
  return (
    <div className={is_approved || profile.is_approved === null ? "page-content" : "mt-2"}>
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                  <Row >
                    <Col md={6} sm={12}>
                      <div className="d-flex">
                        <div className="mx-3 position-relative">
                          <img
                            src={profile ? profile.avatar : avatar}
                            alt=""
                            className="avatar-md rounded-circle img-thumbnail"
                            style={{cursor:isLoading ? 'progress' : 'pointer', objectFit:'cover', objectPosition:'center'}}
                            onClick={()=> isLoading ? null : inputRef.current?.click()}

                          />
                          {isLoading ? (
                            <div style={{position:'absolute', top:'15%', left:'25%'}}>
                              <i className="mdi mdi-loading mdi-spin mdi-36px" style={{color:'green'}}></i>
                            </div>
                            ):null}
                          <input type="file" accept="image/*" ref={inputRef} hidden onChange={onChangeFile}/>
                        </div>
                        <div className="flex-grow-1 align-self-center">
                          <div className="text-muted">
                            <h5>{profile?.name}</h5>
                            <p className="mb-1">Email Id : {profile?.email}</p>
                            {/* <p className="mb-0">Id No : #{idx}</p> */}
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col md={6} sm={12} className="mt-2">
                      {!is_approved ? (
                        <Alert color="warning">
                          <strong className="d-block">
                            {" "}
                            Akun anda belum aktif{" "}
                          </strong>
                          <p className="mb-0">
                            {role === CUSTOMER
                              ? "Lengkapi profile anda untuk mengaktifkan akun"
                              : "Lengkapi profile anda dan tunggu approve dari admin"}
                          </p>
                        </Alert>
                      ) : null}
                    </Col>
                  </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col lg={12}>
            <Card className="">
              <CardHeader>
                <Nav
                  className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => {
                        tabChange("1");
                      }}
                      type="button"
                    >
                      <i className="fas fa-home"></i>
                      Personal Details
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="#"
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => {
                        tabChange("2");
                      }}
                      type="button"
                    >
                      <i className="far fa-user"></i>
                      Change Password
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="#"
                      className={activeTab === "3" ? "active" : ""}
                      onClick={() => {
                        tabChange("3");
                      }}
                      type="button"
                    >
                      <i className="far fa-user"></i>
                      Setting Kop Surat
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
              <CardBody className="p-4">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <PersonalDetail 
                      is_approved={is_approved}
                      dataProfile={dataProfile}
                      isFetching={isFetching}
                      status={status}
                      error={error}
                      refetch={refetch}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <ChangePassword />
                  </TabPane>
                  <TabPane tabId="3">
                    <SettingKopSurat 
                      dataProfile={dataProfile}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default UserProfile;

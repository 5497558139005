import {ADMIN, CUSTOMER, TRUCKING, WAREHOUSE} from 'Helpers/options'
import {useLocalStorage} from 'Hooks'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'

const Navdata = () => {
  const {profile} = useSelector((state) => state.auth)
  const {role} = profile
  const navigate = useNavigate()
  //state data
  const [isDashboard, setIsDashboard] = useState(false)

  const [isMaster, setIsMaster] = useState(false)
  const [isHakAskses, setIsHakAkses] = useState(false)
  const [isCDO, setIsCDO] = useState(false)
  const [isWarehouse, setIsWarehouse] = useState(false)
  const [isSaldo, setIsSaldo] = useState(false)
  const [isDriver, setIsDriver] = useState(false)
  const [isExternalDriver, setIsExternalDriver] = useState(false)
  const [isTruckingRate, setIsTruckingRate] = useState(false)
  const [isTruckingOrder, setIsTruckingOrder] = useState(false)
  const [isShippingManagement, setIsShippingManagement] = useState(false)
  const [isTruck, setIsTruck] = useState(false)
  const [isTruckBooking, setIsTruckBooking] = useState(false)
  const [isAgreement, setIsAgreement] = useState(false)
  const [isAgreementApproval, setIsAgreementApproval] = useState(false)
  const [isLocationPoint, setIsLocationPoint] = useState(false)

  const [isCommission, setIsCommission] = useState(false)
  const [isCommissionTrucking, setIsCommissionTrucking] = useState(false)
  const [isCommissionCdo, setIsCommissionCdo] = useState(false)
  const [isReport, setIsReport] = useState(false)
  const [isTransactionReport, setIsTransactionReport] = useState(false)

  const [iscurrentState, setIscurrentState] = useLocalStorage(
    'currentPage',
    null,
  )

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu')
      const iconItems = ul.querySelectorAll('.nav-icon.active')
      let activeIconItems = [...iconItems]
      activeIconItems.forEach((item) => {
        item.classList.remove('active')
        const id = item.getAttribute('subitems')
        if (document.getElementById(id))
          document.getElementById(id).classList.remove('show')
      })
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel')
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false)
    }
    if (iscurrentState !== 'Master') {
      setIsMaster(false)
    }
    if (iscurrentState !== 'hak-akses') {
      setIsHakAkses(false)
    }
    if (iscurrentState !== 'cdo') {
      setIsCDO(false)
    }
    if (iscurrentState !== 'saldo-monitor') {
      setIsSaldo(false)
    }
    if (iscurrentState !== 'warehouse') {
      setIsWarehouse(false)
    }
    if (iscurrentState !== 'driver') {
      setIsDriver(false)
    }
    if (iscurrentState !== 'external-driver') {
      setIsExternalDriver(false)
    }
    if (iscurrentState !== 'trucking-rate') {
      setIsTruckingRate(false)
    }
    if (iscurrentState !== 'truck') {
      setIsTruck(false)
    }
    if (iscurrentState !== 'truck-booking') {
      setIsTruckBooking(false)
    }
    if (iscurrentState !== 'agreement') {
      setIsAgreement(false)
    }
    if (iscurrentState !== 'agreement-approval') {
      setIsAgreementApproval(false)
    }

    if (iscurrentState !== 'report') {
      setIsReport(false)
    }
    if (iscurrentState !== 'transaction-report') {
      setIsTransactionReport(false)
    }
  }, [navigate, iscurrentState])

  const menuItemsAdmin = [
    {
      id: 'Dashboard',
      label: 'Dashboard',
      icon: 'ri-dashboard-2-line',
      link: '/app/Dashboard',
      click: function (e) {
        e.preventDefault()
        setIsDashboard(!isDashboard)
        setIscurrentState('dashboard')
        updateIconSidebar(e)
      },
      stateVariables: isDashboard,
    },
    {
      id: 'hak-akses',
      label: 'Hak Akses',
      icon: 'ri-user-3-fill',
      link: '/#',
      click: function (e) {
        e.preventDefault()
        setIsHakAkses(!isHakAskses)
        setIscurrentState('hak-akses')
        updateIconSidebar(e)
      },
      stateVariables: isHakAskses,
      subItems: [
        // {
        //   id: 'permission',
        //   label: 'Permission',
        //   link: '/app/permission',
        //   parentId: 'hak-akses',
        // },
        {
          id: 'user',
          label: 'User',
          link: '/app/users',
          parentId: 'hak-akses',
        },
      ],
    },
    {
      id: 'data-master',
      label: 'Data Master',
      icon: 'ri-database-2-fill',
      link: '/#',
      click: function (e) {
        e.preventDefault()
        setIsMaster(!isMaster)
        setIscurrentState('Master')
        updateIconSidebar(e)
      },
      stateVariables: isMaster,
      subItems: [
        {
          id: 'warehouses',
          label: 'Warehouses',
          link: '/app/warehouses',
          parentId: 'master',
        },
        {
          id: 'agents',
          label: 'Agents / Forwarders',
          link: '/app/agents',
          parentId: 'master',
        },
        {
          id: 'trucking',
          label: 'Trucking',
          link: '/app/trucking',
          parentId: 'master',
        },
        {
          id: 'consignee',
          label: 'Consignee',
          link: '/app/consignee',
          parentId: 'master',
        },
        {
          id: 'sppb-doc-type',
          label: 'Sppb Doc Type',
          link: '/app/sppb-doc-type',
          parentId: 'master',
        },
        {
          id: 'location-point',
          label: 'Location Point',
          link: '/app/location-point',
          parentId: 'master',
        },
        {
          id: 'static-page',
          label: 'Static Page',
          link: '/app/static-page',
          parentId: 'master',
        },
        {
          id: 'faq',
          label: 'Faq',
          link: '/app/faq',
          parentId: 'master',
        },
        {
          id: 'harga',
          label: 'Struktur Harga',
          link: '/#',
          parentId: 'master',
          isChildItem: true,
          click: function (e) {
            e.preventDefault()
            setIsCommission(!isCommission)
          },
          stateVariables: isCommission,
          childItems: [
            {
              id: 'ppn',
              label: 'PPN',
              link: '/app/struktur-harga/ppn',
              parentId: 'master',
            },
            {
              id: 'struct-harga-cdo',
              label: 'Cdo',
              link: '/#',
              parentId: 'harga',
              isChildItem: true,
              click: function (e) {
                e.preventDefault()
                setIsCommissionCdo(!isCommissionCdo)
              },
              stateVariables: isCommissionCdo,
              childItems: [
                {
                  id: 'biaya-truck',
                  label: 'Biaya',
                  link: '/app/struktur-harga/cdo/biaya',
                  parentId: 'struct-harga-truck',
                },
                {
                  id: 'rate-cdo',
                  label: 'Rate Cdo',
                  link: '/app/struktur-harga/cdo/rate',
                  parentId: 'struct-harga-cdo',
                },
              ],
            },
            {
              id: 'struct-harga-truck',
              label: 'Trucking',
              link: '/#',
              parentId: 'harga',
              isChildItem: true,
              click: function (e) {
                e.preventDefault()
                setIsCommissionTrucking(!isCommissionTrucking)
              },
              stateVariables: isCommissionTrucking,
              childItems: [
                {
                  id: 'biaya-truck',
                  label: 'Biaya',
                  link: '/app/struktur-harga/trucking/biaya',
                  parentId: 'struct-harga-truck',
                },
                {
                  id: 'commission-truck',
                  label: 'Komisi',
                  link: '/app/struktur-harga/trucking/komisi',
                  parentId: 'struct-harga-truck',
                },
              ],
            },
          ],
        },
      ],
    },
    // {
    //   id: "External Driver",
    //   label: "External Driver",
    //   icon: "ri-dashboard-2-line",
    //   link: "/app/external-driver",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsExternalDriver(!isExternalDriver);
    //     setIscurrentState("external-driver");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isExternalDriver,
    // },
    {
      id: 'Truck',
      label: 'Truck',
      icon: 'ri-truck-fill',
      link: '/#',
      click: function (e) {
        e.preventDefault()
        setIsTruck(!isTruck)
        setIscurrentState('truck')
        updateIconSidebar(e)
      },
      stateVariables: isTruck,
      subItems: [
        {
          id: 'category-truck',
          label: 'Category Truck',
          link: '/app/truck/category',
          parentId: 'truck',
        },
        {
          id: 'type-truck',
          label: 'Type Truck',
          link: '/app/truck/type',
          parentId: 'truck',
        },
        // {
        //   id: "truck-data",
        //   label: "Data",
        //   link: "/app/truck/data",
        // },
      ],
    },
    {
      id: 'Report',
      label: 'Laporan',
      icon: 'ri-file-text-fill',
      link: '/#',
      click: function (e) {
        e.preventDefault()
        setIsReport(!isReport)
        setIscurrentState('report')
        updateIconSidebar(e)
      },
      stateVariables: isReport,
      subItems: [
        {
          id: 'commission-cdo',
          label: 'Komisi Cdo',
          link: '/app/laporan/komisi-cdo',
          parentId: 'truck',
        },
        {
          id: 'commission-truck',
          label: 'Komisi Trucking',
          link: '/app/laporan/komisi-trucking',
          parentId: 'truck',
        },
        {
          id: 'saldo-truck',
          label: 'Saldo',
          link: '/app/laporan/saldo',
          parentId: 'truck',
        },
      ],
    },
    {
      id: 'laporan-transaksi',
      label: 'Laporan Transaksi',
      icon: 'ri-file-text-fill',
      link: '/#',
      click: function (e) {
        e.preventDefault()
        setIsTransactionReport(!isTransactionReport)
        setIscurrentState('transaction-report')
        updateIconSidebar(e)
      },
      stateVariables: isTransactionReport,
      subItems: [
        {
          id: 'booking-truck',
          label: 'Booking Truck',
          link: '/app/laporan-transaksi/booking-truck',
          parentId: 'truck',
        },
      ],
    },
  ]

  const menuItemsCustomer = [
    {
      id: 'Dashboard',
      label: 'Dashboard',
      icon: 'ri-dashboard-2-line',
      link: '/app/Dashboard',
      click: function (e) {
        e.preventDefault()
        setIsDashboard(!isDashboard)
        setIscurrentState('dashboard')
        updateIconSidebar(e)
      },
      stateVariables: isDashboard,
    },
    {
      id: 'hak-akses',
      label: 'Hak Akses',
      icon: 'ri-user-3-fill',
      link: '/#',
      click: function (e) {
        e.preventDefault()
        setIsHakAkses(!isHakAskses)
        setIscurrentState('hak-akses')
        updateIconSidebar(e)
      },
      stateVariables: isHakAskses,
      subItems: [
        {
          id: 'user',
          label: 'User',
          link: '/app/users',
          parentId: 'hak-akses',
        },
        // {
        //   id: 'permission',
        //   label: 'Permission',
        //   link: '/app/permission',
        //   parentId: 'hak-akses',
        // },
      ],
    },
    {
      id: 'CDO Processing',
      label: 'CDO Processing',
      icon: 'ri-train-fill',
      link: '/app/cdo',
      click: function (e) {
        e.preventDefault()
        setIsCDO(!isCDO)
        setIscurrentState('cdo')
        updateIconSidebar(e)
      },
      stateVariables: isDashboard,
    },
    {
      id: 'saldo-monitor',
      label: 'Saldo Monitoring',
      icon: 'ri-database-2-fill',
      link: '/#',
      click: function (e) {
        e.preventDefault()
        setIsSaldo(!isSaldo)
        setIscurrentState('saldo-monitor')
        updateIconSidebar(e)
      },
      stateVariables: isSaldo,
      subItems: [
        {
          id: 'topup',
          label: 'Topup',
          link: '/app/topups',
          parentId: 'saldo-monitor',
        },
        {
          id: 'history-balance',
          label: 'History',
          link: '/app/history-balance',
          parentId: 'saldo-monitor',
        },
        {
          id: 'mutation-balance',
          label: 'Mutasi Saldo',
          link: '/app/mutation-balance',
          parentId: 'saldo-monitor',
        },
      ],
    },
    {
      id: 'truck-booking',
      label: 'Booking Truck',
      icon: 'ri-truck-fill',
      link: '/#',
      click: function (e) {
        e.preventDefault()
        setIsTruckBooking(!isTruckBooking)
        setIscurrentState('truck-booking')
        updateIconSidebar(e)
      },
      stateVariables: isTruckBooking,
      subItems: [
        {
          id: 'self-pickup',
          label: 'Self Pickup',
          link: '/app/truck-book/self-pickup',
          parentId: 'truck-booking',
        },
        {
          id: 'truck-booking-book',
          label: 'Booking',
          link: '/app/truck-book/booking',
          parentId: 'truck-booking',
        },
      ],
    },
    {
      id: 'Agreement',
      label: 'Agreement',
      icon: 'ri-file-text-fill',
      link: '/app/agreement',
      click: function (e) {
        e.preventDefault()
        setIsAgreement(!isAgreement)
        setIscurrentState('agreement')
        updateIconSidebar(e)
      },
      stateVariables: isAgreement,
    },
  ]

  const menuItemsWarehouse = [
    {
      id: 'Dashboard',
      label: 'Dashboard',
      icon: 'ri-dashboard-2-line',
      link: '/app/Dashboard',
      click: function (e) {
        e.preventDefault()
        setIsDashboard(!isDashboard)
        setIscurrentState('dashboard')
        updateIconSidebar(e)
      },
      stateVariables: isDashboard,
    },
    {
      id: 'hak-akses',
      label: 'Hak Akses',
      icon: 'ri-user-3-fill',
      link: '/#',
      click: function (e) {
        e.preventDefault()
        setIsHakAkses(!isHakAskses)
        setIscurrentState('hak-akses')
        updateIconSidebar(e)
      },
      stateVariables: isHakAskses,
      subItems: [
        {
          id: 'user',
          label: 'User',
          link: '/app/users',
          parentId: 'hak-akses',
        },
        // {
        //   id: 'permission',
        //   label: 'Permission',
        //   link: '/app/permission',
        //   parentId: 'hak-akses',
        // },
      ],
    },
    {
      id: 'request-cdo',
      label: 'Request CDO',
      icon: 'ri-file-edit-fill',
      link: '/app/request-cdo',
      click: function (e) {
        e.preventDefault()
        setIsWarehouse(!isWarehouse)
        setIscurrentState('warehouse')
        updateIconSidebar(e)
      },
      stateVariables: isWarehouse,
    },
    {
      id: 'shipping-management',
      label: 'Shipping Management',
      icon: 'ri-dashboard-2-line',
      link: '/app/shipping-management',
      click: function (e) {
        e.preventDefault()
        setIsShippingManagement(!isShippingManagement)
        setIscurrentState('shipping-management')
        updateIconSidebar(e)
      },
      stateVariables: isShippingManagement,
    },
  ]

  const menuItemsTrucking = [
    {
      id: 'Dashboard',
      label: 'Dashboard',
      icon: 'ri-dashboard-2-line',
      link: '/app/Dashboard',
      click: function (e) {
        e.preventDefault()
        setIsDashboard(!isDashboard)
        setIscurrentState('dashboard')
        updateIconSidebar(e)
      },
      stateVariables: isDashboard,
    },
    {
      id: 'hak-akses',
      label: 'Hak Akses',
      icon: 'ri-user-3-fill',
      link: '/#',
      click: function (e) {
        e.preventDefault()
        setIsHakAkses(!isHakAskses)
        setIscurrentState('hak-akses')
        updateIconSidebar(e)
      },
      stateVariables: isHakAskses,
      subItems: [
        {
          id: 'user',
          label: 'User',
          link: '/app/users',
          parentId: 'hak-akses',
        },
        // {
        //   id: 'permission',
        //   label: 'Permission',
        //   link: '/app/permission',
        //   parentId: 'hak-akses',
        // },
      ],
    },
    {
      id: 'saldo-monitor',
      label: 'Saldo Monitoring',
      icon: 'ri-database-2-fill',
      link: '/#',
      click: function (e) {
        e.preventDefault()
        setIsSaldo(!isSaldo)
        setIscurrentState('saldo-monitor')
        updateIconSidebar(e)
      },
      stateVariables: isSaldo,
      subItems: [
        {
          id: 'topup',
          label: 'Topup',
          link: '/app/topups',
          parentId: 'saldo-monitor',
        },
        {
          id: 'history-balance',
          label: 'History',
          link: '/app/history-balance',
          parentId: 'saldo-monitor',
        },
        {
          id: 'mutation-balance',
          label: 'Mutasi Saldo',
          link: '/app/mutation-balance',
          parentId: 'saldo-monitor',
        },
      ],
    },
    {
      id: 'Truck',
      label: 'Truck',
      icon: 'ri-truck-fill',
      link: '/app/truck/data',
      click: function (e) {
        e.preventDefault()
        setIsTruck(!isTruck)
        setIscurrentState('truck')
        updateIconSidebar(e)
      },
      stateVariables: isTruck,
      // subItems: [
      //   {
      //     id: "category-truck",
      //     label: "Category Truck",
      //     link: "/app/truck/category",
      //     parentId: "truck",
      //   },
      //   {
      //     id: "type-truck",
      //     label: "Type Truck",
      //     link: "/app/truck/type",
      //     parentId: "truck",
      //   },
      //   {
      //     id: "truck-data",
      //     label: "Data",
      //     link: "/app/truck/data",
      //   },
      // ],
    },
    {
      id: 'Driver',
      label: 'Driver',
      icon: 'ri-dashboard-2-line',
      link: '/app/driver',
      click: function (e) {
        e.preventDefault()
        setIsDriver(!isDriver)
        setIscurrentState('driver')
        updateIconSidebar(e)
      },
      stateVariables: isDriver,
    },
    // {
    //   id: "External Driver",
    //   label: "External Driver",
    //   icon: "ri-dashboard-2-line",
    //   link: "/app/external-driver",
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsExternalDriver(!isExternalDriver);
    //     setIscurrentState("external-driver");
    //     updateIconSidebar(e);
    //   },
    //   stateVariables: isExternalDriver,
    // },
    {
      id: 'TruckingRate',
      label: 'Rate Trucking',
      icon: 'ri-question-answer-line',
      link: '/app/trucking-rate',
      click: function (e) {
        e.preventDefault()
        setIsTruckingRate(!isTruckingRate)
        setIscurrentState('trucking-rate')
        updateIconSidebar(e)
      },
      stateVariables: isTruckingRate,
    },
    {
      id: 'TruckingOrder',
      label: 'Order Truck',
      icon: 'ri-luggage-cart-fill',
      link: '/app/trucking-order',
      click: function (e) {
        e.preventDefault()
        setIsTruckingOrder(!isTruckingOrder)
        setIscurrentState('trucking-order')
        updateIconSidebar(e)
      },
    },
    {
      id: 'Agreement',
      label: 'Agreement',
      icon: 'ri-file-text-fill',
      link: '/#',
      click: function (e) {
        e.preventDefault()
        setIsAgreementApproval(!isAgreementApproval)
        setIscurrentState('agreement-approval')
        updateIconSidebar(e)
      },
      stateVariables: isAgreementApproval,
      subItems: [
        {
          id: 'agreement-approval',
          label: 'Agreement',
          link: '/app/agreement-approval',
          parentId: 'AgreementApproval',
        },
        {
          id: 'termint',
          label: 'Termint',
          link: '/app/termint',
          parentId: 'AgreementApproval',
        },
      ],
    },
    {
      id: 'location-point',
      label: 'Location Point',
      icon: 'ri-map-pin-fill',
      link: '/app/location-point',
      click: function (e) {
        e.preventDefault()
        setIsLocationPoint(!isLocationPoint)
        setIscurrentState('location-point')
        updateIconSidebar(e)
      },
      stateVariables: isLocationPoint,
    },
  ]

  const renderMenuItems = () => {
    switch (role) {
      case ADMIN:
        return menuItemsAdmin
      case CUSTOMER:
        return menuItemsCustomer
      case WAREHOUSE:
        return menuItemsWarehouse
      case TRUCKING:
        return menuItemsTrucking
      default:
        return menuItemsCustomer
    }
  }

  return <React.Fragment>{renderMenuItems()}</React.Fragment>
}
export default Navdata

import React,{ useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import * as Yup from "yup";
import { validEmail, validName, validPassword } from '../../Helpers/regex_validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import ErrorFieldWrapper from '../../Components/Common/ErrorFieldWrapper'
import { UserRQ } from '../../Hooks/Queries'
import ImageTextPreview from 'Components/Common/ImageTextPreview'
import { useQuery } from '@tanstack/react-query';
import { apiBearer } from 'Helpers/api';
import { LIST_ALL_ROLE_URL } from 'Helpers/url_helper';
import Select from 'react-select';
import { useSelector } from 'react-redux';

export default function EditCreateUser(props) {
  const { id } = useParams()
  const navigate = useNavigate();
  const { id:loggedinUserId } = useSelector((state) => state.auth.profile);
  const [selectedPermission, setSelectedPermission] = useState(null);
  const validation = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .test('test-valid-name', 'Full Name hanya boleh berisi huruf', (v)=> validName.test(v)),
    userName: Yup.string()
      .required("Username is required"),
    password: Yup.string()
      .matches(
        validPassword,
        "Must Contain One Number and One Special Case Character"
      )
      .when([], {
        is: () => !id,
        then: Yup.string().required("Password is required"),
        otherwise: Yup.string().notRequired(),
      })
      .min(8, "Password length must be at least 8 characters"),
    email: Yup.string()
      .required("Email is required")
      .test("test-email-format", "invalid email format", (value) => {
        return validEmail.test(value);
      })
      .strict(true),
    rule_id: Yup.string().required('Permission tidak boleh kosong')
  },
    []
  );
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      name: '',
      userName: '',
      password: '',
      email: ''
    },
  };
  const { handleSubmit, formState, control, reset, register, watch } = useForm(formOptions);
  const { errors } = formState;

  const values = watch();
  const getFileName = (key) => {
    if (!values[key]) return "";
    return values[key].length > 0 ? values[key][0].name : "";
  };

  const {
    mutate: updateUser,
    isLoading: updateLoading
  } = UserRQ.useUpdateFormData(navigate)()

  const _updateUser = (values) => {
    const data = JSON.stringify({
      email: values.email,
      name: values.name,
      user_name: values.userName,
      is_active: values.isActive,
      is_verified: true,
    })
    
    const body = new FormData()
    body.append('id', id)
    body.append('data', data)
    if(values.avatar) {
      body.append('avatar', values.avatar[0]??null)
    }
    updateUser(body)
  }

  const {
    mutate: addUser,
    isLoading: createLoading
  } = UserRQ.useAddData(navigate)()

  const _createUser = (values) => {
    const data = JSON.stringify({
      password: values.password,
      confirm_password: values.password,
      email: values.email,
      name: values.name,
      role: 'ADMIN',
      user_name: values.userName,
      is_active: values.isActive,
      is_verified: true,
    })

    const body = new FormData()
    body.append('data', data)
    if(values.avatar) {
      body.append('avatar', values.avatar[0]??null)
    }
    addUser(body)
  }

  const {
    data,
    error,
    isFetching,
    status
  } = UserRQ.useGetData(id)()

  const {data:allRoleList} = useQuery(
    ['role-list-all'],
    async()=>{
      const response = await apiBearer.get(LIST_ALL_ROLE_URL);
      const {data} = response
      return data?.data
    },
    {
      select:(data)=>  data?.map((v)=>({label:v.name, value:v.id}))
    }
  )

  useEffect(() => {
    if (!isFetching && status === "success") {
      reset({
        name: data.data?.name ?? '',
        email: data.data?.email ?? '',
        userName: data.data?.user_name ?? '',
        isActive: data.data?.is_active ?? true
      })
    }
    if (!isFetching && status === "error") {
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching])

  const isEditedUserLoggedin = loggedinUserId === id

  const isLoading = createLoading || updateLoading
  const submitBtnText = () => {
    if(isLoading) return 'Loading...'
    return id ? 'Update':'Create'
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="User" />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(id ? _updateUser : _createUser)}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">{id ? 'Edit' : 'Create'} User</h4>
                </CardHeader>
                <CardBody className="card-body">
                  <Row className='gy-4'>
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper error={errors.userName} message={errors.userName?.message}>
                        <Label htmlFor="userName">
                          Username <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register('userName')}/>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper error={errors.name} message={errors.name?.message}>
                        <Label htmlFor="name" className='form-label text-uppercase'>
                          Name <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register('name')}/>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper error={errors.email} message={errors.email?.message}>
                        <Label htmlFor="email" className='form-label text-uppercase'>
                          Email <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register('email')} type="email"/>
                      </ErrorFieldWrapper>
                    </Col>
                    {!id &&
                      <Col sm={12} md={6}>
                        <ErrorFieldWrapper error={errors.password} message={errors.password?.message}>
                          <Label htmlFor="password" className='form-label text-uppercase'>
                            Password <span className="mandatory">*</span>
                          </Label>
                          <input className="form-control" {...register('password')} type="password"/>
                        </ErrorFieldWrapper>
                      </Col>
                    }
                    {!isEditedUserLoggedin ? (
                      <Col sm={12} md={6}>
                      <ErrorFieldWrapper error={errors.rule_id} message={errors.rule_id?.message}>
                      <Label htmlFor="rule_id" className="form-label text-uppercase">
                            Permission
                          </Label>
                        <Controller
                          name='rule_id'
                          control={control}
                          render={({field:{onChange}})=>(
                            <Select
                              required
                              cacheOptions
                              defaultOptions
                              isClearable
                              isSearchable={false}
                              value={selectedPermission??{}}
                              onChange={(e)=>{
                                if (e?.value) onChange(e.value)
                                setSelectedPermission(e)
                              }}
                              options={allRoleList}
                              menuPortalTarget={document.body} 
                              styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                      </Col>
                    ):null}
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper error={errors.isActive} message={errors.isActive?.message}>
                        <label htmlFor="isActive" className="form-label text-uppercase">Is Active?</label>
                        <Controller
                          id="isActive"
                          name="isActive"
                          control={control}
                          render={({ field }) => (
                            <div className="form-check form-switch">
                              <Input
                                {...field}
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                value={field.value}
                                defaultChecked={field.value}
                              />
                            </div>
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12} md={6}>
                      <ErrorFieldWrapper error={errors.docAttachment} message={errors.docAttachment?.message}>
                      
                      {/* <FloatingInput register={register} label='Avatar' inputName='avatar' type='file'/> */}
                      <div className="d-flex justify-content-between">
                          <Label htmlFor="ktp" className="form-label text-uppercase">
                            Avatar{" "}
                            {id ? null : <span className="mandatory">*</span>}
                          </Label>
                          {data?.data?.avatar ? (
                            <ImageTextPreview link={data?.data?.avatar} />
                          ) : null}
                        </div>
                        <div className="btn-input">
                          <input
                            type="file"
                            id="file-upload_01"
                            accept="image/jpg,image/jpeg,image/png"
                            {...register("avatar")}
                          />
                          <span className="btn btn-outline-primary px-3 py-2 mb-0 w-100 btn-file d-flex align-items-center justify-content-center">
                            <i className="ri-file-2-line me-2"></i>
                            {getFileName("avatar")
                              ? getFileName("avatar")
                              : "Pilih Foto avatar"}
                          </span>
                        </div>
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to='/app/users' className='btn btn-danger'>Cancel</Link>
                  <Button color='success' type='submit' disabled={isLoading}>{submitBtnText()}</Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import { Controller, useForm } from 'react-hook-form'
import ErrorFieldWrapper from '../../../Components/Common/ErrorFieldWrapper'
import { TopupRQ } from 'Hooks/Queries'

export default function CreateTopup() {
  const navigate = useNavigate();

  const validation = Yup.object().shape({
    amount: Yup.string().required("Amount is required")
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      amount: '',
    },
  };
  const { handleSubmit, formState, control, reset, register } = useForm(formOptions);
  const { errors } = formState;

  const {
    mutate :addTopup,
    isLoading
  } = TopupRQ.useAddData(navigate)()
  
  // const mutationRQ = TopupRQ.useAddData()()
  // const detailRQ = TopupRQ.useGetData()()

  const _createTopup = async (values)=> {
    const data = {
      amount: values.amount
    };
    
    // mutationRQ.mutate(data)
    addTopup(data)
  }

  // useEffect(() => {
  //   if(mutationRQ.isSuccess) {
  //     detailRQ.
  //   }
  // }, [mutationRQ.isSuccess])

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Topup" pageTitle="Topup" />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(_createTopup)}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">{'Create'} Topup</h4>
                </CardHeader>
                <CardBody className="card-body">
                  <Row className='gy-4'>
                    <Col md={6}>
                      <ErrorFieldWrapper error={errors.amount} message={errors.amount?.message}>
                        <Label htmlFor="amount" className="form-label">Jumlah</Label>
                        <Controller
                          id="amount"
                          name="amount"
                          type='amount'
                          control={control}
                          render={({ field }) => <Input {...field} value={field.value} />}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to='/app/topups' className='btn btn-danger'>Cancel</Link>
                  <Button 
                    color='success' 
                    type='submit' 
                    disabled={isLoading}
                  >{isLoading ? 'Loading...' : 'Create'}</Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

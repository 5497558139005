import Select from 'react-select'
import { Label, Table } from 'reactstrap'

const TruckingCommission = ({
  label = 'Komisi',
  onAdd,
  onDelete,
  onChangeValue,
  lists,
  percent,
  isValueValid,
  invalidMessage = 'Value tidak boleh melebihi cost cdo',
  typeInput = true
}) => {
  return (
    <>
      <h4 className='card-title mb-0 flex-grow-1 mt-4'>Setting Komisi</h4>
      <hr />
      <Label>{label}</Label>
      <input
        type='text'
        disabled
        value={percent}
        className='form-control w-25 mb-2'
      />
      <div className='table-responsive'>
        <Table className='table-hover table-bordered align-middle table-nowrap mb-4'>
          <thead>
            <tr>
              <th>No</th>
              <th>Deskripsi</th>
              <th>Persentase (%)</th>
              <th>Aksi</th>
            </tr>
          </thead>
          <tbody>
            {lists.map(({id, name, type, value}, i) => {
              let placeHolder = ''
              const isPercent = type === 'PERCENT'
              const isAmount = type === 'AMOUNT'
              if (isPercent) placeHolder = 'Masukkan persentase'
              else if (isAmount) placeHolder = 'Masukkan jumlah'
              if(!typeInput) placeHolder = 'Masukkan persentase'

              return (
                <tr key={id}>
                  <td>{i + 1}</td>
                  <td style={{minWidth: '30%'}}>
                    <input
                      type='text'
                      value={name ?? ''}
                      className='form-control'
                      onChange={(e) =>
                        onChangeValue('name', id, e.target.value)
                      }
                    />
                  </td>
                  <td style={{minWidth: '60%'}}>
                    <div className='d-flex'>
                      {
                        typeInput ?
                        <Select
                          cacheOptions
                          isClearable
                          defaultOptions
                          value={type}
                          options={[
                            {label: 'Percent', value: 'PERCENT'},
                            {label: 'Amount', value: 'AMOUNT'},
                          ]}
                          onChange={(e) => {
                            onChangeValue('type', id, e)
                            onChangeValue('value_type', id, e?.value)
                          }}
                          placeholder='Tipe Value'
                          className='form-control me-2'
                        />
                      :null}

                      <input
                        type='number'
                        value={value ?? ''}
                        className='form-control'
                        onChange={(e) =>
                          onChangeValue('value', id, e.target.value)
                        }
                        disabled={!type && typeInput}
                        placeholder={
                          placeHolder
                            ? placeHolder
                            : 'Pilih tipe terlebih dahulu'
                        }
                      />
                    </div>
                    {i === lists?.length - 1 ? (
                      <div
                        style={{display: isValueValid ? 'none' : 'block'}}
                        className='text-danger mt-2 small fw-bold'
                      >{invalidMessage}</div>
                    ) : null}
                  </td>
                  <td className='d-flex align-items-center justify-content-center gap-1'>
                    {i >= 1 ? (
                      <button
                        className='btn btn-small small py-0 btn-danger'
                        onClick={() => onDelete(id)}
                        type='button'
                      >
                        <i className='ri-subtract-fill ri-2x'></i>
                      </button>
                    ) : null}
                    <button
                      className='btn btn-small small py-0 btn-success'
                      onClick={onAdd}
                      type='button'
                    >
                      <i className='ri-add-fill ri-2x'></i>
                    </button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default TruckingCommission

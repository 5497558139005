import {useQueryClient} from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import DeleteModal from 'Components/Common/DeleteModal'
import withTable from 'Components/Common/Table/withTable'
import SppbDocTypeItem from 'Components/Master/SppbDocTypeItem'
import {DATA_LIMIT} from 'configs'
import {SppbDocTypeRQ} from 'Hooks/Queries'
import {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {Container, Row} from 'reactstrap'

export default function ListSppbDocType() {
  const [deleteAgreementId, setDeleteAgreementId] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)
  const {status, data, error, isFetching, refetch} = SppbDocTypeRQ.useGetDataList()(
    false,
    keyword,
    offset,
    limit,
  )

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
  }

  const useQuery = useQueryClient()
  const navigate = useNavigate()

  const onDeleteSuccess = (key) => {
    setDeleteModal(false)
    setDeleteAgreementId(null)
    useQuery.invalidateQueries({queryKey: [key]})
    refetch()
  }

  const {mutate: deleteTruck, isLoading:deleteLoading} = SppbDocTypeRQ.useDeleteData(onDeleteSuccess)()

  const onDeleteConfirm = () => {
    deleteTruck(deleteAgreementId)
  }

  const onAddButtonClicked = () => {
    navigate('/app/sppb-doc-type/create')
  }
  const onDeleteClick=(id)=> {
    setDeleteAgreementId(id)
    setDeleteModal(true)
  }

  const SppbDocTypeTable = withTable(
    SppbDocTypeItem,
    'sppb-doc-type',
    ['NO', 'aksi', 'nama', 'kode'],
    onDeleteClick,
    onAddButtonClicked,
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Sppb Doc Type' />
        <Row>
          <SppbDocTypeTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            error={error}
            status={status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
        <DeleteModal
          onDeleteConfirm={() => onDeleteConfirm()}
          onCloseClick={() => setDeleteModal((v) => !v)}
          show={deleteModal}
          loading={deleteLoading}
        />
      </Container>
    </div>
  )
}

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import BreadCrumb from "Components/Common/BreadCrumb";
import { selfPickupRQ } from "Hooks/Queries";
import ErrorFieldWrapper from "Components/Common/ErrorFieldWrapper";
import Select from "react-select";
import { apiBearer } from "Helpers/api";
import { VIEW_CDO_URL } from "Helpers/url_helper";

export default function EditCreateSelfPickup(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedCdo, setSelectedCdo] = useState(null);
  const [cdoOptions, setCdoOptions] = useState([]);

  const validation = Yup.object().shape({
    cdo_id: Yup.string().required("CDO is required"),
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      cdo_id: "",
    },
  };
  const { handleSubmit, formState, reset, control, register, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const { mutate: updateTrucking, isLoading: loadingUpdate } = selfPickupRQ.useUpdateData(navigate)();

  const _updateSelfPickup = (values) => {
    const body = values;
    body.id = id;
    updateTrucking(body);
  };

  const { mutate: addSelfPickup, isLoading: loadingCreate } = selfPickupRQ.useAddData(navigate)();

  const _createSelfPickup = async (values) => {
    const payload = {
      "cdo_id": values.cdo_id,
      "receiver_address": values.receiver_address,
      "receiver_name": values.receiver_name,
      "receiver_phone": values.receiver_phone,
      "sender_address": values.sender_address,
      "sender_name": values.sender_name,
      "sender_phone": values.sender_phone
    }
    addSelfPickup(payload);
  };

  const { data, error, isFetching, status } = selfPickupRQ.useGetData(id)();

  useEffect(() => {
    if (!isFetching && status === "success") {
      reset({
        name: data.data?.name ?? "",
      });
    }
    if (!isFetching && status === "error") {
      console.log(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  const getCdoList = async () => {
    try {
      const response = apiBearer.get("self-pickup/cdo");
      const { data } = (await response).data || {};
      setCdoOptions(
        data.map((v) => ({ label: v.code, value: v.id, detail: v }))
      );
    } catch (err) {}
  };

  const onSelectCdo = async (e) => {
    setSelectedCdo(e);

    const response = await apiBearer.get(VIEW_CDO_URL(e.value));
    const {data: dataDetailCDO} = response.data;
    if (dataDetailCDO) {
      setValue("awb", dataDetailCDO.awb ?? "");
      setValue("hawb", dataDetailCDO.hawb ?? "");
      setValue("sppb", dataDetailCDO.sppb ?? "");
      setValue("do", dataDetailCDO.do_no ?? "");
      setValue("sender_name", dataDetailCDO.sender_name ?? "");
      setValue("sender_address", dataDetailCDO.sender_address ?? "");
      setValue("sender_phone", dataDetailCDO.sender_phone ?? "");
      setValue("receiver_name", dataDetailCDO.receiver_name ?? "");
      setValue("receiver_address", dataDetailCDO.receiver_address ?? "");
      setValue("receiver_phone", dataDetailCDO.receiver_phone ?? "");
    }
  };

  useEffect(() => {
    getCdoList();
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Self Pickup" pageTitle="Self Pickup" />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={handleSubmit(
                id ? _updateSelfPickup : _createSelfPickup
              )}
            >
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {id ? "Edit" : "Create"} Self Pickup
                  </h4>
                </CardHeader>
                <CardBody className="card-body">
                  <Row className="gy-4">
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.name}
                        message={errors.name?.message}
                      >
                        <Label>
                          CDO
                          <span className="mandatory">*</span>
                        </Label>
                        <Controller
                          name="cdo_id"
                          control={control}
                          render={({ field: { onChange, ...field } }) => (
                            <Select
                              {...field}
                              isClearable
                              value={selectedCdo ?? ""}
                              options={cdoOptions}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value);
                                onSelectCdo(e);
                              }}
                            />
                          )}
                        />
                        {/* <input className="form-control"/> */}
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12} className="mb-3">
                      <ErrorFieldWrapper
                        error={errors.awb}
                        message={errors.awb?.message}
                      >
                        <Label>
                          AWB <span className="mandatory">*</span>
                        </Label>
                        <input
                          className="form-control"
                          disabled
                          {...register("awb")}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12} className="mb-3">
                      <ErrorFieldWrapper
                        error={errors.hawb}
                        message={errors.hawb?.message}
                      >
                        <Label>HAWB</Label>
                        <input
                          className="form-control"
                          disabled
                          {...register("hawb")}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12} className="mb-3">
                      <ErrorFieldWrapper
                        error={errors.sppb}
                        message={errors.sppb?.message}
                      >
                        <Label>
                          Izin Beacukai <span className="mandatory"></span>
                        </Label>
                        <input
                          className="form-control"
                          disabled
                          {...register("sppb")}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12} className="mb-3">
                      <ErrorFieldWrapper
                        error={errors.do}
                        message={errors.do?.message}
                      >
                        <Label>
                          DO
                        </Label>
                        <input
                          className="form-control"
                          disabled
                          {...register("do")}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <div className="card-body pt-0 pb-0">
                  <h4 className="">Pengirim</h4>
                </div>
                <div className="card-footer">
                  <Row>
                    <Col lg={6} sm={12} className="mb-3">
                      <ErrorFieldWrapper
                        error={errors.sender_name}
                        message={errors.sender_name?.message}
                      >
                        <Label>
                          Nama Pengirim <span className="mandatory">*</span>
                        </Label>
                        <input
                          className="form-control"
                          disabled
                          {...register("sender_name")}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12} className="mb-3">
                      <ErrorFieldWrapper
                        error={errors.sender_address}
                        message={errors.sender_address?.message}
                      >
                        <Label>
                          Alamat Pengirim <span className="mandatory">*</span>
                        </Label>
                        <input
                          className="form-control"
                          disabled
                          {...register("sender_address")}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12} className="mb-3">
                      <ErrorFieldWrapper
                        error={errors.sender_phone}
                        message={errors.sender_phone?.message}
                      >
                        <Label>
                          No TELP/HP Pengirim{" "}
                          <span className="mandatory">*</span>
                        </Label>
                        <input
                          className="form-control"
                          disabled
                          {...register("sender_phone")}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </div>
                <div className="card-body pt-0 pb-0">
                  <h4 className="">Penerima</h4>
                </div>
                <div className="card-footer">
                  <Row>
                    <Col lg={6} sm={12} className="mb-3">
                      <ErrorFieldWrapper
                        error={errors.receiver_name}
                        message={errors.receiver_name?.message}
                      >
                        <Label>
                          Nama Penerima <span className="mandatory">*</span>
                        </Label>
                        <input
                          className="form-control"
                          disabled
                          {...register("receiver_name")}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12} className="mb-3">
                      <ErrorFieldWrapper
                        error={errors.receiver_address}
                        message={errors.receiver_address?.message}
                      >
                        <Label>
                          Alamat Penerima <span className="mandatory">*</span>
                        </Label>
                        <input
                          className="form-control"
                          disabled
                          {...register("receiver_address")}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12} className="mb-3">
                      <ErrorFieldWrapper
                        error={errors.receiver_phone}
                        message={errors.receiver_phone?.message}
                      >
                        <Label>
                          No TELP/HP Penerima{" "}
                          <span className="mandatory">*</span>
                        </Label>
                        <input
                          className="form-control"
                          disabled
                          {...register("receiver_phone")}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </div>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to="/app/truck-book/self-pickup" className="btn btn-danger">
                    Cancel
                  </Link>
                  <Button color="success" type="submit" disabled={loadingUpdate || loadingCreate}>
                    {loadingUpdate || loadingCreate ?  "Loading.." : (id ? "Update" : "Create")}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { TruckingOrderRQ } from "Hooks/Queries";
import { apiBearer } from "Helpers/api";
import debounce from "Helpers/debounce";
import FloatingInput from "Components/Common/FloatingInput";
import { ASSIGN_DRIVER_URL, AVAILABLE_TRUCK_BY_TYPE_URL, LIST_DRIVER_BY_TRUCK } from "Helpers/url_helper";
import AcyncSelect from "react-select/async";
import { showError, showSuccess } from "Helpers/show_toast";
import Select from "react-select";

export default function AssignTruckingOrder(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const [driverOptions, setDriverOptions] = useState([]);
  const [selectedTruck, setSelectedTruck] = useState();
  const [selectedDriver, setSelectedDriver] = useState();

  const { data, error, isFetching, status, refetch } =
    TruckingOrderRQ.useGetData(id)();

  const fetchAvailableTruck = async (input) => {
    if(data) {
      return new Promise(async (res, rej) => {
        try {
          
            const result = await apiBearer.get(
              `${AVAILABLE_TRUCK_BY_TYPE_URL}?truckTypeId=${data?.data?.truck_type.id}&search=${input}&size=${10}`
            );
            const resData = result.data;
            if (resData.success) {
              res(resData?.data?.data?.map((v) => ({ label: v.plate_number, value: v.id })));
            }
            rej(resData.message);
          
        } catch (error) {
          rej(error);
        }
      });
    } else {
      return null;
    }
  };

  const debounceSearchTrucks = debounce((input, cb) => {
    fetchAvailableTruck(input).then((op) => cb(op));
  }, 1500);


  const fetchDrivers = async (selectedTruckValue) => {
    try {
      if(selectedTruck) {
        const result = await apiBearer.get(
          `${LIST_DRIVER_BY_TRUCK}?truckId=${selectedTruckValue}&size=${10}`
        );
        const { data } = result;
        if (data.success) {
          setDriverOptions(data?.data?.data?.map((v) => ({ label: v.name, value: v.id })));
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const onTruckSelected = (id, e) => {
    setSelectedTruck(e);
    if(e.last_driver && e.last_driver?.is_available){
      const { id, name } = e.last_driver;
      setDriverOptions(prev => [...prev, {label:name, value:id}])
      setSelectedDriver({label:name, value:id})
    }  
  }

  const onDriverSelected = (id, e) => {
    setSelectedDriver(e);
  }

  const onSubmit = async (e) => {
    if(selectedTruck && selectedDriver) {
      const result = await apiBearer.patch(ASSIGN_DRIVER_URL(id), {
        truck_id: selectedTruck.value,
        driver_id: selectedDriver.value
      });
      if(result?.data?.data) {
        showSuccess("Assigned Successfully");
        navigate('/app/trucking-order')
      } else {
        showError('Error while saving');
      }
    }else{
      showError('Pastikan anda mengisi semua field.')
    }
  }

  useEffect(()=>{
    if(selectedTruck?.value){
      fetchDrivers(selectedTruck.value)
    }
  },[selectedTruck?.value])

  useEffect(() => {
    if (!isFetching && status === "success") {
    }
    if (!isFetching && status === "error") {
      console.log(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Trucking Order" pageTitle="Trucking Order" />
        <Row>
          <Col lg={12}>
            <form onSubmit={() => {}}>
              <Card>
                <CardHeader className="d-flex justify-content-between align-items-center">
                  <h4 className="">Assign Driver Trucking</h4>
                </CardHeader>
                <CardBody className="card-body">
                  <Row>
                    <Col md="6">
                      <label htmlFor='truck_category_name'>
                        Truck Category
                      </label>
                      <input
                        type={'text'}
                        className="form-control"
                        id={'truck_category_name'}
                        disabled={true}
                        defaultValue={data?.data?.truck_category.name}
                      />
                    </Col>
                    <Col md="6">
                      <label htmlFor='truck_type_name'>
                        Truck Type
                      </label>
                      <input
                        type={'text'}
                        className="form-control"
                        id={'truck_type_name'}
                        disabled={true}
                        defaultValue={data?.data?.truck_type.name}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-4">
                    <Col md="6">
                      <label>
                        Plat Number
                      </label>
                      <AcyncSelect
                        cacheOptions
                        isClearable
                        defaultOptions
                        value={selectedTruck ?? ""}
                        loadOptions={debounceSearchTrucks}
                        onChange={(e) => {
                          if (e?.value) onTruckSelected(id, e);
                        }}
                        placeholder="Search by name"
                      />
                    </Col>
                    <Col md="6">
                      <label>
                        Driver
                      </label>
                      <Select
                        cacheOptions
                        isClearable
                        defaultOptions
                        value={selectedDriver ?? ""}
                        options={driverOptions}
                        onChange={(e) => {
                          if (e?.value) onDriverSelected(id, e);
                        }}
                        placeholder="Search by name"
                      />
                    </Col>
                  </Row>
                </CardBody>

                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to={`/app/trucking-order/detail/${id}`} className="btn btn-outline-dark">
                    Tutup
                  </Link>
                  <Button type="button" onClick={onSubmit}>
                    Buat
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import React from 'react'
import MyPagination from './Pagination'
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown,
} from 'reactstrap'

/**
 * HOC Datalist
 * @returns
 */
export default function withTable(
  ItemComponent,
  table,
  headers,
  onDeleteClick,
  onAddButtonClicked,
  additionalActions,
  addButtonLabel,
) {
  return ({
    data,
    status,
    error,
    isFetching,
    onPageChange,
    grid = false,
    onPerPageChange,
    onKeywordChange,
    keyword = '',
  }) => {
    const _onPerPageChange = (v) => {
      if (typeof onPerPageChange === 'function') {
        onPerPageChange(v)
      }
    }

    const isNotEditable = !onAddButtonClicked && !onDeleteClick

    return (
      <Card>
        <CardHeader className='align-items-center justify-content-end d-flex'>
          {/* <h4 className="card-title mb-0 flex-grow-1">{titleFormat(table)}</h4> */}
          {onAddButtonClicked && (
            <div
              className='d-flex justify-content-between align-items-center btn btn-sm btn-primary'
              onClick={onAddButtonClicked}
            >
              <i className='ri-add-line label-icon align-middle fs-16 me-1'></i>
              <button className='btn btn-sm p-0 btn-primary waves-effect waves-light fw-bold'>
                {addButtonLabel ?? 'Buat'}
              </button>
            </div>
          )}
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={12} md={12} xs={12} className='mb-2'>
              <div className='d-flex'>
                <div className='flex-grow-1'>
                  Show&nbsp;
                  <ButtonGroup>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag='button'
                        className='btn btn-sm btn-primary'
                      >
                        {data?.perPage}
                        <i className='mdi mdi-chevron-down'></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem onClick={() => _onPerPageChange(10)}>
                          10
                        </DropdownItem>
                        <DropdownItem onClick={() => _onPerPageChange(25)}>
                          25
                        </DropdownItem>
                        <DropdownItem onClick={() => _onPerPageChange(50)}>
                          50
                        </DropdownItem>
                        <DropdownItem onClick={() => _onPerPageChange(100)}>
                          100
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </ButtonGroup>
                  &nbsp;entries
                </div>
                <div className='flex-shrink-0'>
                  <div className='search-box'>
                    <input
                      placeholder='Search...'
                      type='text'
                      className='form-control form-control'
                      defaultValue={keyword}
                      onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                          onKeywordChange(event.target.value)
                        }
                      }}
                    />
                    <i className='ri-search-line search-icon'></i>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className='table-responsive'>
            <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
              <thead>
                <tr>
                  {headers.map((v, i) => (
                    <th key={i} scope='col'>
                      {v}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {isFetching && status === 'loading' ? (
                  <tr>
                    <td colSpan={headers.length} className='text-center'>
                      <Spinner color='success' />
                    </td>
                  </tr>
                ) : status === 'error' ? (
                  <tr>
                    <td colSpan={headers.length} className='text-center'>
                      <span className='text-danger'>No Data</span>
                    </td>
                  </tr>
                ) : (
                  data?.data?.map((props, i) => (
                    <ItemComponent
                      {...props}
                      index={i}
                      page={data.offset}
                      limit={data.limit}
                      key={i}
                      onDeleteClick={onDeleteClick}
                      table={table}
                      additionalActions={additionalActions}
                      isNotEditable={isNotEditable}
                    />
                  ))
                )}
              </tbody>
            </Table>
          </div>
          <MyPagination
            total={data?.total || 0}
            page={data?.offset}
            perPage={data?.limit}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
          />
        </CardBody>
      </Card>
    )
  }
}

import { formatNumber } from '../../Helpers/number';
import moment from 'moment'
import React from 'react'
import { Link } from "react-router-dom";

export function MutationBalanceItem({ page, index, id, credit, date, debit, notes, balance, table}) {
  return (
    <tr >
      <td className="fw-medium">{index + page + 1}</td>
      {/* <td>
        <div className="hstack gap-3 fs-15">
        <Link to={`/app/${table}/detail/${id}`} className="link-success">
            <i className="ri-eye-fill"></i>
          </Link>
        </div>
      </td> */}
      <td>{moment(date).format('DD MMMM YYYY')}</td>
      <td>{notes}</td>
      <td>Rp.{formatNumber(debit)}</td>
      <td>Rp.{formatNumber(credit)}</td>
      <td>Rp.{formatNumber(balance)}</td>
    </tr>
  )
}
import { 
  CREATE_WAREHOUSE_URL, 
  DELETE_WAREHOUSE_URL, 
  LIST_WAREHOUSE_URL, 
  UPDATE_WAREHOUSE_URL, 
  VIEW_WAREHOUSE_URL 
} from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'warehouses';
const REDIRECT_URL = '/app/warehouses';

export const WarehouseRQ = new RQ(
  QUERY_KEY, 
  LIST_WAREHOUSE_URL, 
  VIEW_WAREHOUSE_URL, 
  CREATE_WAREHOUSE_URL, 
  UPDATE_WAREHOUSE_URL, 
  DELETE_WAREHOUSE_URL, 
  REDIRECT_URL
);
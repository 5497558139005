import { CUSTOMER } from "Helpers/options";
import React from "react";
import { Table } from "reactstrap";

const theadStyles = {
  textAlign: "center",
  verticalAlign: "middle",
};

function CdoPrint({ data, componentRef }) {
  console.log(data)
  const {warehouse_settings} = data || {}
  /**
   * WAREHOUSE_ADDRESS: "Depok - Jawa Barat"
​​
WAREHOUSE_IDENTITY: "not just a warehouse"
​​
WAREHOUSE_NAME: "YOLOO WH"
   */
  return (
    <div className="p-4 mb-2" ref={componentRef}>
      <div className="row">
        <div className="col-2">
        {
          data?.warehouse_logo ? (
            <div style={{width:90}}>
              <img src={data?.warehouse_logo} className="img-fluid" alt=""/>
            </div>

          ):(
              <p className="fs-4 mb-0 fw-bold">GK</p>
          )
        }
        </div>
        <div className="col-8 d-flex justify-content-center align-items-center flex-column">
          <p className="mb-0 fs-4 fw-bolder">
            {warehouse_settings?.WAREHOUSE_NAME || 'PT. UNEX RAJAWALI INDONESIA'}
          </p>
          <p className="mb-0 fs-4">
            {warehouse_settings?.WAREHOUSE_IDENTITY || 'AVIATION SERVICES'}
          </p>
          <p className="mb-0">
            {warehouse_settings?.WAREHOUSE_ADDRESS || 'PROOF OF DELIVERY'}
          </p>
        </div>
      </div>
      <div className="w-full border my-4" />
      <p className="mb-0 fs-4 fw-medium text-center">Cargo Delivery Order</p>
      <p className="mb-0 text-center">IZIN PENGELUARAN BARANG</p>
      <div className="row align-items-center">
        <div className="col-6 row justify-content-between">
          <div className="col-6 text-uppercase">
            <p className="mb-1" style={{ fontWeight: 600 }}>
              izin Beacukai
            </p>
            <p className="mb-1" style={{ fontWeight: 600 }}>
              company name
            </p>
            <p className="mb-1" style={{ fontWeight: 600 }}>
              shipper name
            </p>
            <p className="mb-1" style={{ fontWeight: 600 }}>
              date of out
            </p>
            <p className="mb-1" style={{ fontWeight: 600 }}>
              Awb
            </p>
          </div>
          <div className="col-6">
            <p className="mb-1">: {data?.sppb ?? "-"}</p>
            <p className="mb-1">: {data?.customer_name ?? "-"}</p>
            <p className="mb-1">: {data?.sender_name ?? "-"}</p>
            <p className="mb-1">: {data?.action_date ?? "-"}</p>
            <p className="mb-1">: {data?.awb ?? "-"}</p>
          </div>
        </div>
        <div className="col-6 d-flex justify-content-end">
          {data?.qr_url ? (
            <div className="d-flex flex-column align-items-center">
              <img
                src={data?.qr_url}
                height={100}
                width={100}
                alt=""
                className="ml-auto"
              />
              <p>{data?.code}</p>
            </div>
          ) : (
            <div
              style={{
                height: 100,
                width: 100,
                border: "1px gray solid",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                marginLeft: "auto",
              }}
            >
              <span className="fs-10">Image not found</span>
            </div>
          )}
        </div>
      </div>

      <div className="table-responsive mt-5">
        <Table className="table-bordered align-middle table-nowrap mb-0">
          <thead>
            <tr>
              <th rowSpan={2} style={theadStyles}>
                awb / hawb
              </th>
              <th rowSpan={2} style={theadStyles}>
                Pu / pos
              </th>
              <th rowSpan={2} style={theadStyles}>
                pcs
              </th>
              <th rowSpan={2} style={theadStyles}>
                caw
              </th>
              <th rowSpan={2} style={theadStyles}>
                nature of goods
              </th>
              <th colSpan={2} style={theadStyles}>
                flight
              </th>
              <th rowSpan={2} colSpan={2} style={theadStyles}>
                remarks
              </th>
            </tr>
            <tr>
              <th style={theadStyles}>number</th>
              <th style={theadStyles}>date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">
                {data?.awb ?? "-"} / {data?.hawb ?? "-"}
              </td>
              <td className="text-center">{data?.pu ?? "-"}</td>
              <td className="text-center">{data?.koli ?? "-"}</td>
              <td className="text-center">{data?.caw ?? "-"}</td>
              <td className="text-center">{data?.type_of_goods ?? "-"}</td>
              <td className="text-center">{data?.flight_code ?? "-"}</td>
              <td className="text-center">{data?.flight_date ?? "-"}</td>
              <td className="text-center">{data?.remarks ?? "-"}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <p className="mt-2">Barang diserahkan dalam keadaan baik dan lengkap</p>
      <div className="d-flex justify-content-around mt-5">
        <h6 className="fw-bold">{data?.customer_name}</h6>
        <h6 className="fw-bold">{data?.warehouse}</h6>
      </div>
    </div>
  );
}

export default CdoPrint;

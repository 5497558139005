import React from "react";

export default function ErrorFieldWrapper({
  children,
  error = false,
  message,
  className = "",
}) {

  return (
    <div className={error ? "error-form " + className : "" + className}>
      {children}
      <div className="error-form-info badge badge-soft-danger">
        <img
          alt=""
          src="/assets/icons/triangle-alert-fill.svg"
          className="me-1"
        />
        {message}
      </div>
    </div>
  );
}

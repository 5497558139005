import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorFieldWrapper from "../../../Components/Common/ErrorFieldWrapper";
import { faqRQ } from "Hooks/Queries";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { faqCategories } from "Helpers/options";
import Editor from "Components/Editor";

export default function EditCreateFaq(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(null);

  const validation = Yup.object().shape({
    category: Yup.string().required("Kategori tidak boleh kosong"),
    content: Yup.string().required("Konten tidak boleh kosong"),
    title: Yup.string().required("Judul tidak boleh kosong"),
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      category: "",
      content: "",
      title: "",
    },
  };
  const { handleSubmit, formState, register, reset, control, setValue, getValues } =
    useForm(formOptions);
  const { errors } = formState;

  const { mutate: updateFaq, isLoading: isLoadingUpdate } = faqRQ.useUpdateData(navigate)();

  const _updateFaq = (values) => {
    const body = values;
    body.id = id;
    updateFaq(body);
  };

  const { mutate: addFaq, isLoading: isLoadingCreate } = faqRQ.useAddData(navigate)();

  const _createFaq = async (values) => {
    addFaq(values);
  };

  const { data, error, isFetching, status } = faqRQ.useGetData(id)();

  useEffect(() => {
    if (!isFetching && status === "success") {
      reset({
        category: data.data?.category ?? "",
        title: data.data?.title ?? "",
        content: data.data?.content ?? "",
      });
      console.log(
        faqCategories.find((v) => v.label === data.data?.category),
        data.data?.category
      );
      setSelectedCategory(
        faqCategories.find((v) => v.label === data.data?.category)
      );
    }
    if (!isFetching && status === "error") {
      console.log(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Faq" pageTitle="Faq" />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(id ? _updateFaq : _createFaq)}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {id ? "Edit" : "Create"} Faq
                  </h4>
                </CardHeader>
                <CardBody className="card-body">
                  <Row className="gy-4">
                    <Col lg={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.title}
                        message={errors.title?.message}
                      >
                        <Label>
                          Judul <span className="mandatory">*</span>
                        </Label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("title")}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.category}
                        message={errors.category?.message}
                      >
                        <Label>
                          Kategori <span className="mandatory">*</span>
                        </Label>
                        <Controller
                          name="category"
                          control={control}
                          render={({ field: { onChange, ...field } }) => (
                            <Select
                              {...field}
                              cacheOptions
                              isClearable
                              defaultOptions
                              value={selectedCategory ?? ""}
                              options={faqCategories}
                              onChange={(e) => {
                                if (e) {
                                  onChange(e.value);
                                  setSelectedCategory(e);
                                }
                                return e;
                              }}
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12}>
                      <ErrorFieldWrapper
                        className="form-floating"
                        error={errors.category}
                        message={errors.category?.message}
                      >
                        <Editor
                          onEditorChange={(e) => setValue("content", e)}
                          initialValue={getValues().content}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to="/app/static-page" className="btn btn-danger">
                    Cancel
                  </Link>
                  <Button color="success" type="submit" disabled={isLoadingCreate || isLoadingUpdate}>
                    {isLoadingCreate || isLoadingUpdate ? 'Loading...' :(id ? "Update" : "Create")}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import { useQuery } from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { apiBearer } from 'Helpers/api'
import { formatNumber } from 'Helpers/number'
import { PAYMENT_METHODS, UNPAID } from 'Helpers/options'
import { ADMIN_REPORT_BOOKING_TRUCK_DETAIL } from 'Helpers/url_helper'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Card, CardBody, CardFooter, Container, Row } from 'reactstrap'

export default function BookingTruckReportDetail() {
  const {id} = useParams()
  const {data, status, isFetching} = useQuery(['detail-report-booking-admmin'],
    async()=>{
    const response = await apiBearer.get(ADMIN_REPORT_BOOKING_TRUCK_DETAIL(id))
    console.log(response.data)
    return response.data
    },
    {
        enabled: !!id
    }
  )
  const [total, setTotal] = useState(0)
  const {price, final_price, additional_costs, ppn} =
    data?.data?.price_detail || {}

  useEffect(() => {
    if (!isFetching && status === 'success') {
      let total = price
      additional_costs?.map((v) => {
        total += v.value || 0
      })
      setTotal(total)
    }
  }, [status, isFetching])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Truck Booking Detail' />
        <Card>
          <CardBody className='card-body'>
            <h2 className='mb-3'>INV #{data?.data?.code ?? '-'}</h2>
            <Row className='text-muted'>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th style={{background: 'rgb(243, 246, 249)'}} colSpan={2}>
                      General
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th style={{maxWidth: '20%'}}>CDO</th>
                    <td>{data?.data?.cdo?.code ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Nama Pengirim</th>
                    <td>{data?.data?.sender_name ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Nomor Pengirim</th>
                    <td>{data?.data?.sender_phone ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Alamat Pengambilan Barang</th>
                    <td>{data?.data?.sender_address ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Nama Penerima</th>
                    <td>{data?.data?.receiver_name ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Nomor Penerima</th>
                    <td>{data?.data?.receiver_phone ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Alamat Penerima</th>
                    <td>{data?.data?.receiver_address ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{background: 'rgb(243, 246, 249)'}} colSpan={2}>
                      Detail Order Trucking
                    </th>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Asal</th>
                    <td>{data?.data?.origin_name ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Tujuan</th>
                    <td>{data?.data?.destination_name ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Vendor</th>
                    <td>{data?.data?.trucking?.name ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Jenis Kendaraan</th>
                    <td>{data?.data?.truck_category?.name ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Tipe Kendaraan</th>
                    <td>{data?.data?.truck_type?.name ?? '-'}</td>
                  </tr>
                  {/* <tr>
                    <th style={{maxWidth: '20%'}}>Rate</th>
                    <td>Rp.{formatNumber(data?.data?.price) ?? '-'}</td>
                  </tr> */}
                  {/* <tr>
                    <th style={{background: 'rgb(243, 246, 249)'}} colSpan={2}>
                      Status Order Trucking
                    </th>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Pembayaran</th>
                    <td>{getPaymentStatus(data?.data?.payment_status)}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Order</th>
                    <td>
                      {getValidationStatus(
                        data?.data?.trucking_validation_status,
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Pengiriman</th>
                    <td>{logStatus(data?.data?.last_log_status)}</td>
                  </tr>
                  <tr>
                    <th style={{background: 'rgb(243, 246, 249)'}} colSpan={2}>
                      System
                    </th>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Waktu dibuat</th>
                    <td>{data?.data?.created_on ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Waktu diubah</th>
                    <td>{data?.data?.action_date ?? '-'}</td>
                  </tr> */}
                  <tr>
                    <th style={{background: 'rgb(243, 246, 249)'}} colSpan={2}>
                      Detail Pembayaran
                    </th>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Biaya Trucking</th>
                    <td>Rp. {formatNumber(price)}</td>
                  </tr>
                  {additional_costs?.map((p, i) => (
                    <tr key={i}>
                      <th style={{maxWidth: '20%'}}>{p.name}</th>
                      <td>Rp. {formatNumber(p.value)}</td>
                    </tr>
                  ))}
                  <tr>
                    <th style={{maxWidth: '20%'}}>Total</th>
                    <td className='fw-bold'>Rp. {formatNumber(total)}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>PPN</th>
                    <td>Rp. {formatNumber(ppn?.value)}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Total Pembayaran</th>
                    <td className='fw-bold'>Rp. {formatNumber(final_price)}</td>
                  </tr>
                </tbody>
              </table>
              <div className='table-responsive table-card '></div>
            </Row>
          </CardBody>
          <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
            <Link to='/app/laporan-transaksi/booking-truck' className='btn btn-outline-dark'>
              Kembali
            </Link>
          </CardFooter>
        </Card>
      </Container>
    </div>
  )
}

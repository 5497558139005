import React from 'react'
import { Link } from 'react-router-dom';
import ImageWithLink from './Common/ImageWithLink';

export function DriverItem({ page, index ,id, name, nik, sim_url, sim_expired, ktp_url, photo_url, is_available, onDeleteClick, table}) {
  return (
    <tr >
      <td className="fw-medium">{index + page + 1}</td>
      <td>
        <div className="hstack gap-3 fs-15">
          <Link to={`/app/${table}/detail/${id}`} className="link-success">
            <i className="ri-eye-fill"></i>
          </Link>
          <Link
            to={`/app/${table}/edit/${id}`}
            className='link-warning'
          >
            <i className='ri-edit-2-line'></i>
          </Link>
          <Link
            to="#"
            className='link-danger'
            onClick={() => {
              onDeleteClick(id);
            }}
          >
            <i className='ri-delete-bin-5-line'></i>
          </Link>
        </div>
      </td>
      <td>{name}</td>
      <td>{nik}</td>
      <td><ImageWithLink url={sim_url} width={200}/></td>
      <td>{sim_expired}</td>
      <td><ImageWithLink url={ktp_url} width={200}/></td>
      <td><ImageWithLink url={photo_url} width={200}/></td>
      <td>
        {is_available ? (
          <span className="badge badge-soft-success">Available</span>
        ) : (
          <span className="badge badge-soft-dark">Not Available</span>
        )}
      </td>
    </tr>
  )
}
const {
  REACT_APP_FE_URL,
  REACT_APP_API_URL,
  REACT_APP_API_KEY,
  REACT_APP_LOKAL_STORAGE_PREFIX,
  REACT_APP_NAME,
  REACT_APP_NAME_SHORT,
  REACT_APP_DATA_LIMIT,
  REACT_APP_GOOGLE_APP_ID,

  REACT_APP_FB_API_KEY,
  REACT_APP_FB_AUTH_DOMAIN,
  REACT_APP_FB_PROJECT_ID,
  REACT_APP_FB_STORAGE_BUCKET,
  REACT_APP_FB_MESSAGING_SENDER_ID,
  REACT_APP_FB_APP_ID,
  REACT_APP_FB_MEASUREMENT_ID,

  REACT_APP_FCM_KEY_PAIR,
  REACT_APP_GOOGLE_API_KEY,
  REACT_APP_NOTIF_PREFIX,

  REACT_APP_TINYMCE_API_KEY,
} = process.env

module.exports = {
  FE_URL: REACT_APP_FE_URL,
  BASE_API_URL: REACT_APP_API_URL,
  BASIC_API_KEY: REACT_APP_API_KEY,
  LOKAL_STORAGE_PREFIX: REACT_APP_LOKAL_STORAGE_PREFIX,
  APP_NAME: REACT_APP_NAME,
  APP_NAME_SHORT: REACT_APP_NAME_SHORT,
  DATA_LIMIT: REACT_APP_DATA_LIMIT,
  GOOGLE_APP_ID: REACT_APP_GOOGLE_APP_ID,
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  firebaseConfig: {
    apiKey: REACT_APP_FB_API_KEY,
    authDomain: REACT_APP_FB_AUTH_DOMAIN,
    projectId: REACT_APP_FB_PROJECT_ID,
    storageBucket: REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: REACT_APP_FB_APP_ID,
    measurementId: REACT_APP_FB_MEASUREMENT_ID,
  },
  fcmKeyPair: REACT_APP_FCM_KEY_PAIR,
  GOOGLE_API_KEY: REACT_APP_GOOGLE_API_KEY,
  NOTIF_PREFIX: REACT_APP_NOTIF_PREFIX,
  TINYMCE_API_KEY: REACT_APP_TINYMCE_API_KEY,
}

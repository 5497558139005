import moment from 'moment';
import React from 'react'
import { Link } from 'react-router-dom';
import { logStatus } from './helpers';

export function TruckingOrderItem({ 
  page, 
  index, 
  id, 
  code,
  date,
  payment_status,
  trucking_validation_status,
  shipment_status,
  last_log_status,
  onDeleteClick, 
  table
}) {

  const getPaymentStatus = (status) => {
    switch(status) {
      case 'FAILED':
        return <span className="badge badge-soft-dark">Gagal / Expired</span>;
      case 'PAID':
        return <span className="badge badge-soft-dark">Sudah Dibayar</span>;
      case 'UNPAID':
        return <span className="badge badge-soft-dark">Menunggu Pembayaran</span>;
    }
  }

  const getValidationStatus = (status) => {
    switch(status) {
      case 'WAITING_APPROVAL':
        return <span className="badge badge-soft-dark">Pending</span>;
      case 'APPROVED':
        return <span className="badge badge-soft-dark">Approved</span>;
      case 'REJECTED':
        return <span className="badge badge-soft-dark">Rejected</span>;
    }
  }

  // const getShipmentStatus = (status) => {
  //   switch(status) {
  //     case 'PENDING':
  //       return <span className="badge badge-soft-dark">Pending</span>;
  //     case 'PREPARE_GOODS':
  //       return <span className="badge badge-soft-dark">Prepare Goods</span>;
  //     case 'READY_PICKUP':
  //       return <span className="badge badge-soft-dark">Ready</span>;
  //     case 'PICKUP':
  //       return <span className="badge badge-soft-dark">Pickup</span>;
  //     case 'LOADED':
  //       return <span className="badge badge-soft-dark">Loaded</span>;
  //   }
  // }

  return (
    <tr >
      <td className="fw-medium">{index + page + 1}</td>
      <div className="hstack gap-3 fs-15">
        <Link to={`/app/${table}/detail/${id}`} className="link-success">
          <i className="ri-eye-fill"></i>
        </Link>
      </div>
      <td>{code}</td>
      <td>{date}</td>
      <td>{getPaymentStatus(payment_status)}</td>
      <td>{getValidationStatus(trucking_validation_status)}</td>
      <td>{logStatus(last_log_status)}</td>
    </tr>
  )
}
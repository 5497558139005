import ErrorFieldWrapper from "Components/Common/ErrorFieldWrapper";
import React, { useEffect, useRef, useState } from "react";
import {
  ButtonToggle,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { apiBearer } from "Helpers/api";
import {
  LIST_ALL_TRUCKING_CAT_URL,
  TRUCKING_TYPE_BASE_API,
} from "Helpers/url_helper";
import Select from "react-select";
import { locationPointRQ } from "Hooks/Queries";
import flatpickr from "flatpickr";
import moment from "moment";

export default function SearchTruckForm({
  setTruckList,
  setIsSerching,
  setTruckData,
  isWithCDO,
  setIsWithCDO,
  isSearching
}) {
  const validation = Yup.object().shape({
    available_date: Yup.string().required("Tanggal tidak boleh kosong"),
    destination_point_id: Yup.string().required("Tujuan tidak boleh kosong"),
    origin_point_id: Yup.string().required("Asal tidak boleh kosong"),
    qty: Yup.string().required("Kuantitas tidak boleh kosong"),
    truck_category_id: Yup.string().required(
      "Kategori truk tidak boleh kosong"
    ),
    truck_type_id: Yup.string().required("Tipe Truk tidak boleh kosong"),
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      available_date: moment().format("YYYY-MM-DD"),
      destination_point_id: "",
      origin_point_id: "",
      qty: 1,
      truck_category_id: "",
      truck_type_id: "",
    },
  };

  const { handleSubmit, formState, register, control, setValue, reset } =
    useForm(formOptions);
  const { errors } = formState;

  const onSubmit = async (values) => {
    setIsSerching(true);
    try {
      const response = await apiBearer.post(
        `/booking-truck/search-trucking`,
        values
      );
      const result = await response.data;
      setTruckList(result?.data?.data ?? []);
    } catch (error) {}
    setIsSerching(false);
  };

  const [tructCatOptions, setTruckCatOptions] = useState([]);
  const [tructTypeOptions, setTruckTypeOptions] = useState([]);
  const [selectedCat, setSelectedCat] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const fetchTruckCats = async () => {
    return new Promise(async (res, rej) => {
      try {
        const result = await apiBearer.get(LIST_ALL_TRUCKING_CAT_URL);
        const { data } = result;
        if (data.success) {
          setTruckCatOptions(
            data?.data?.map((v) => ({ label: v.name, value: v.id }))
          );
        }
        rej(data.message);
      } catch (error) {
        rej(error);
      }
    });
  };

  useEffect(() => {
    fetchTruckCats();
  }, []);

  const onTruckCatSelected = async (e) => {
    setSelectedCat(e);
    if (e?.value) {
      const truckTypeResponse = await apiBearer.get(
        `${TRUCKING_TYPE_BASE_API}/by-category/${e.value}`
      );
      const { data } = truckTypeResponse;
      if (data.success) {
        setTruckTypeOptions(
          data?.data?.map((v) => ({ label: v?.name, value: v?.id }))
        );
      }
    }
  };
  const [selectedOrigin, setSelectedOrigin] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState([]);
  const [originOptions, setOriginOptions] = useState([]);

  const {
    data: dataLocations,
    isFetching: isFetchingLocation,
    status: statusLocations,
  } = locationPointRQ.useGetDataList()(false, "", 0, 1000);

  useEffect(() => {
    if (!isFetchingLocation && statusLocations === "success") {
      if (dataLocations?.data) {
        setOriginOptions(
          dataLocations.data.map((v) => ({ label: v.name, value: v.id }))
        );
      }
    }
  }, [isFetchingLocation, statusLocations]);

  const simRef = useRef();
  const formRef = useRef();

  useEffect(() => {
    if (simRef.current) {
      flatpickr(simRef.current, {
        dateFormat: "Y-m-d",
        defaultDate: new Date(),
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setValue("available_date", sDate);
          setTruckData((v) => ({ ...v, date: sDate }));
        },
      });
      setValue("available_date", moment().format("YYYY-MM-DD"));
    }
  }, []);


  return (
    <Card>
      <CardHeader className="d-flex justify-content-between">
        <h4>Search Trucking Vendor</h4>
        {/* <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckChecked"
            defaultChecked
            onChange={(e) => {
              if (e.target.checked) {
                if(simRef.current){
                  simRef.current.value = moment().format("YYYY-MM-DD")
                  setValue("available_date", moment().format("YYYY-MM-DD"));
                  setTruckData((v) => ({ ...v, date:  moment().format("YYYY-MM-DD") }));
                  setIsWithCDO(true);
                }
              } else {
                setIsWithCDO(false);
              }
            }}
          />
          <label className="form-check-label" htmlFor="flexSwitchCheckChecked">
            {isWithCDO ? "Dengan CDO" : "Tanpa CDO"}
          </label>
        </div> */}
      </CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          <Row>
            <Col lg={6} sm={12}>
              <ErrorFieldWrapper
                className="mb-3"
                error={errors.origin_point_id}
                message={errors.origin_point_id?.message}
              >
                <Label>
                  Asal <span className="mandatory">*</span>
                </Label>
                <Controller
                  id="origin_point_id"
                  name="origin_point_id"
                  type="text"
                  control={control}
                  render={({ field: { onChange, ...field } }) => (
                    <Select
                      {...field}
                      isClearable
                      value={selectedOrigin}
                      options={originOptions}
                      onChange={(e) => {
                        if (e?.value) onChange(e.value);
                        setSelectedOrigin(e);
                        setTruckData((v) => ({ ...v, origin: e?.label ?? "" }));
                      }}
                      placeholder="Search by name"
                    />
                  )}
                />
              </ErrorFieldWrapper>
            </Col>
            <Col lg={6} sm={12}>
              <ErrorFieldWrapper
                className="mb-3"
                error={errors.destination_point_id}
                message={errors.destination_point_id?.message}
              >
                <Label>
                  Tujuan <span className="mandatory">*</span>
                </Label>
                <Controller
                  id="destination_point_id"
                  name="destination_point_id"
                  type="text"
                  control={control}
                  render={({ field: { onChange, ...field } }) => (
                    <Select
                      {...field}
                      isClearable
                      value={selectedDestination}
                      options={originOptions}
                      onChange={(e) => {
                        if (e?.value) onChange(e.value);
                        setSelectedDestination(e);
                        setTruckData((v) => ({
                          ...v,
                          destination: e?.label ?? "",
                        }));
                      }}
                      placeholder="Search by name"
                    />
                  )}
                />
              </ErrorFieldWrapper>
            </Col>

            <Col lg={6} sm={12}>
              <ErrorFieldWrapper
                error={errors.truck_category_id}
                message={errors.truck_category_id?.message}
                className="mb-3"
              >
                <Label htmlFor="truck_category_id" className="form-label">
                  Kategori Kendaraan
                  <span className="mandatory">*</span>
                </Label>
                <Controller
                  name="truck_category_id"
                  control={control}
                  render={({ field: { onChange, ...field } }) => (
                    <Select
                      {...field}
                      cacheOptions
                      isClearable
                      defaultOptions
                      value={selectedCat ?? ""}
                      options={tructCatOptions}
                      onChange={(e) => {
                        if (e?.value) onChange(e.value);
                        onTruckCatSelected(e);
                      }}
                      placeholder="Search by name"
                    />
                  )}
                />
              </ErrorFieldWrapper>
            </Col>

            <Col lg={6} sm={12}>
              <ErrorFieldWrapper
                error={errors.truck_type_id}
                message={errors.truck_type_id?.message}
                className="mb-3"
              >
                <Label htmlFor="truck_type_id" className="form-label">
                  Tipe Kendaraan
                  <span className="mandatory">*</span>
                </Label>
                <Controller
                  name="truck_type_id"
                  control={control}
                  render={({ field: { onChange, ...field } }) => (
                    <Select
                      {...field}
                      cacheOptions
                      isClearable
                      defaultOptions
                      value={selectedType ?? ""}
                      options={tructTypeOptions}
                      onChange={(e) => {
                        if (e?.value) onChange(e.value);
                        setSelectedType(e);
                      }}
                      placeholder="Pilih Kategori terlebih dahulu"
                    />
                  )}
                />
              </ErrorFieldWrapper>
            </Col>
              <Col lg={6} sm={12} hidden={isWithCDO}>
                <ErrorFieldWrapper
                  error={errors.available_date}
                  message={errors.available_date?.message}
                  className="mb-3"
                >
                  <Label htmlFor="available_date" className="form-label">
                    Tanggal
                    <span className="mandatory">*</span>
                  </Label>
                  <input className="form-control" ref={simRef} />
                </ErrorFieldWrapper>
              </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-light me-4"
              type="button"
              onClick={ () => {
                reset({
                  available_date:  moment().format("YYYY-MM-DD"),
                  destination_point_id: "",
                  origin_point_id: "",
                  truck_category_id: "",
                  truck_type_id: "",
                  qty:0
                });
                setSelectedCat(null)
                setSelectedDestination(null)
                setSelectedOrigin(null)
                setSelectedType(null)
              }}
            >
              <img src="/assets/icons/bx-reset.svg" alt="" />
            </button>
            <button className="btn btn-outline-primary" type="submit" disabled={isSearching}>
              Search
            </button>
          </div>
        </form>
      </CardBody>
    </Card>
  );
}

import { BOOK_STATE_ADD } from "Pages/TruckBooking/Create";
import React from "react";
import { Card, CardBody, CardHeader, Spinner, Table } from "reactstrap";
import TruckResultItem from "./TruckResultItem";

export default function SearchResult({
  truckList,
  isSearching,
  truckData,
  setTruckData,
  setBookState,
}) {
  const headers = ["NO", "Action", "vendor", "harga", "ranking"];
  const onOrderClick = (data) => {
    setTruckData((v) => ({ ...v, truck_rate_item_id: data?.truck_rate_id, rate:data?.rate }));
    setBookState(BOOK_STATE_ADD);
  };
  return (
    <Card>
      <CardHeader>
        Hasil Search
        <hr />
        <div className="d-flex justify-content-between">
          <div className="d-flex">
            <p className="mb-0 me-4">Asal : {truckData?.origin ?? "-"}</p>
            <p className="mb-0">Tujuan : {truckData?.destination ?? "-"}</p>
          </div>
          <p className="mb-0">Tanggal : {truckData?.date ?? "-"}</p>
        </div>
      </CardHeader>
      <CardBody>
        <div className="table-responsive">
          <Table className="table-hover table-bordered align-middle table-nowrap mb-0">
            <thead>
              <tr>
                {headers.map((v, i) => (
                  <th key={v} scope="col">
                    {v}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isSearching ? (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Spinner color="success" />
                  </td>
                </tr>
              ) : (
                truckList.map((truck, i) => (
                  <TruckResultItem
                    {...truck}
                    key={truck.truck_rate_id}
                    index={i}
                    onOrderClick={onOrderClick}
                  />
                ))
              )}
            </tbody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
}

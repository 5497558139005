import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { AgreementRQ } from "Hooks/Queries";
import { getBadgeClass } from "Helpers/options";
import { showError } from "Helpers/show_toast";
import { formatNumber } from "Helpers/number";
import { useQuery } from "@tanstack/react-query";
import { apiBearer } from "Helpers/api";
import { LIST_AGREEMENT_PAYMENT } from "Helpers/url_helper";
import AgreementPaymentItem from "Components/Agreement/AgreementPaymentItem";

export default function DetailAgreement(props) {
  const { id } = useParams();

  const { data, error, isFetching, status } = AgreementRQ.useGetData(id)();
  const dataAgreement = data?.data || {}

  const {data: dataPayment, isFetching:dataPaymentLoading, status: dataPaymentStatus} = useQuery(
      ['payment-histories', id], 
      async()=>{
        const response = await apiBearer.get(LIST_AGREEMENT_PAYMENT(id))
        const dataPayment = response.data
        return dataPayment?.data?.data

      },
      {
        enabled: !!id
      }
    )

    const renderData = () => {
      if (dataPaymentStatus === 'error')
        return (
          <tr>
            <td colSpan={7} className='text-center'>
              <span className='text-danger'>No Data</span>
            </td>
          </tr>
        )
      if (dataPaymentLoading)
        return (
          <tr>
            <td colSpan={7} className='text-center'>
              <Spinner color='success' />
            </td>
          </tr>
        )
      if (dataPaymentStatus === 'loading' && !dataPaymentLoading) {
        return (
          <tr>
            <td colSpan={7} className='text-center'>
              <span className='text-danger'>Search to show data</span>
            </td>
          </tr>
        )
      }
      if (!dataPayment || dataPayment.length < 1)
        return (
          <tr>
            <td colSpan={7} className='text-center'>
              <span className='text-danger'>No Data</span>
            </td>
          </tr>
        )
      return dataPayment?.map((props, i) => {
        return <AgreementPaymentItem {...props} key={props.id}  index={i} idAgreement={id} table='/app/agreement/detail/:id/payment/:paymentId'/>
      })
    }
  


  useEffect(() => {
    if (!isFetching && status === "error") {
      showError("Server Error, silahkan coba lagi nanti!");
      console.log(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Agreement" pageTitle="Agreement" />
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="d-flex justify-content-between align-items-center">
                <h4 className="mb-0">Detail #{dataAgreement.code ?? "-"}</h4>
              </CardHeader>
              <CardBody className="card-body">
                <div
                  className="row"
                  style={{
                    border: "1px solid #DDDDDD",
                    margin: "0px 0.1rem 2rem 0.1rem",
                    borderRadius:5,
                    overflow:'hidden'
                  }}
                >
                  <div
                    style={{
                      background: "rgb(243, 246, 249)",
                      color: "#677788",
                    }}
                    className="p-2"
                  >
                    <h5 className="mb-0 fw-medium">General</h5>
                  </div>
                  <div
                    className="row justify-content-between"
                    style={{ color: "#516377" }}
                  >
                    <div className="col-md-2 col-sm-12 py-2 px-2 px-0">
                      <p className="fw-bold mb-0 text-uppercase">
                        No Agreement
                      </p>
                      <p className="mb-0">{dataAgreement.code ?? "-"}</p>
                    </div>
                    <div className="col-md-2 col-sm-12 py-2 px-2 px-0">
                      <p className="fw-bold mb-0 text-uppercase">
                        Vendor Trucking
                      </p>
                      <p className="mb-0">{dataAgreement.trucking ?? "-"}</p>
                    </div>
                    <div className="col-md-2 col-sm-12 py-2 px-2 px-0">
                      <p className="fw-bold mb-0 text-uppercase">
                        Tanggal Mulai
                      </p>
                      <p className="mb-0">{dataAgreement.aprove_date ?? "-"}</p>
                    </div>
                    <div className="col-md-2 col-sm-12 py-2 px-2 px-0">
                      <p className="fw-bold mb-0 text-uppercase">Status</p>
                      <span className={getBadgeClass(dataAgreement.status)}>
                        {dataAgreement.status}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    border: "1px solid #DDDDDD",
                    margin: "0px 0.1rem 2rem 0.1rem",
                    borderRadius:5,
                    overflow:'hidden'
                  }}
                >
                  <div
                    style={{
                      background: "rgb(243, 246, 249)",
                      color: "#677788",
                    }}
                    className="p-2"
                  >
                    <h5 className="mb-0 fw-medium">Kuota Agreement</h5>
                  </div>
                  <div
                    className="row justify-content-between"
                    style={{ color: "#516377" }}
                  >
                    <div className="col col-md-4 col-sm-12 py-2 px-2 px-0">
                      <p className="fw-bold mb-0 text-uppercase">
                        total Kuota
                      </p>
                      <input
                        disabled
                        value={`Rp. ${formatNumber(dataAgreement.quota_total? dataAgreement.quota_total : 0)}`}
                        style={{
                          padding: 5,
                          background: "#F3F4F4",
                          border: "1px solid #D4D8DD",
                          borderRadius:5
                        }}
                        className="fw-medium"
                      />
                    </div>
                    <div className="col col-md-4 col-sm-12 py-2 px-2 px-0">
                      <p className="fw-bold mb-0 text-uppercase">
                        Kuota terpakai
                      </p>
                      <input
                        disabled
                        value={`Rp. ${formatNumber(dataAgreement.quota_used ? dataAgreement.quota_used : 0)}`}
                        style={{
                          padding: 5,
                          background: "#F3F4F4",
                          border: "1px solid #D4D8DD",
                          borderRadius:5
                        }}
                        className="fw-medium"
                      />
                    </div>
                    <div className="col col-md-4 col-sm-12 py-2 px-2 px-0">
                      <p className="fw-bold mb-0 text-uppercase">
                        kuota tersedia
                      </p>
                      <input
                        disabled
                        value={`Rp. ${formatNumber(dataAgreement.quota_available ? dataAgreement.quota_available : 0)}`}
                        style={{
                          padding: 5,
                          background: "#F3F4F4",
                          border: "1px solid #D4D8DD",
                          borderRadius:5
                        }}
                        className="fw-medium"
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <Link 
                    to={"/app/agreement/detail/" + id + '/tagihan'}
                    className="btn btn-primary"
                    style={{borderTopRightRadius:1, borderBottomRightRadius:1}}
                  >
                    Bayar
                  </Link>
                  <Link 
                    to={"/app/agreement/detail/" + id + '/mutasi'}
                    className="btn btn-outline-dark" 
                    style={{borderTopLeftRadius:1, borderBottomLeftRadius:1}}
                  >
                    Mutasi
                  </Link>
                </div>
              </CardBody>

                {/* <Link to="/app/agreement" className="btn btn-outline-dark">
                  Back
                </Link>
              </CardFooter> */}
              <div className="px-3 mb-2">
                <h4>History Pembayaran</h4>
              </div>
              <div className='table-responsive'>
                <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
                  <thead>
                    <tr>
                      <th scope='col'>
                        No
                      </th>
                      <th scope='col'>
                        Aksi
                      </th>
                      <th scope='col'>
                        date and time
                      </th>
                      <th scope='col'>
                        total payment
                      </th>
                      <th scope='col'>
                        status
                      </th>
                    </tr>
                  </thead>
                  <tbody>{renderData()}</tbody>
                </Table>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

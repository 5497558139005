import React from "react";
import StarIcon from "./StarIcon";

export default function TruckResultItem({
  page,
  index,
  truck_rate_id,
  trucking_name,
  rate,
  ranking,
  table,
  onOrderClick,
}) {
  const renderStars = () => {
    const activeStars = ranking ? (ranking > 5 ? 5 : ranking) : 0;
    const inactiveStars = 5 - activeStars;
    const stars = [];

    for (let i = 0; i < activeStars; i++) {
      stars.push(<StarIcon active={true} key={i+'active'}/>);
    }
    for (let j = 0; j < inactiveStars; j++) {
      stars.push(<StarIcon key={j+'inactive'}/>);
    }
    return stars;
  };
  return (
    <tr>
      <td className="fw-medium">{index + 1}</td>
      <td>
        <div className="hstack gap-3 fs-15">
          {/* <button className="btn btn-outline-dark">Chat</button> */}
          <button
            className="btn btn-outline-dark"
            onClick={() => {
              onOrderClick({truck_rate_id, rate});
            }}
          >
            Order
          </button>
        </div>
      </td>
      <td>{trucking_name ?? "-"}</td>
      <td>{rate ?? "-"}</td>
      <td>{renderStars()}</td>
    </tr>
  );
}

import React,{ useState } from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import withTable from "Components/Common/Table/withTable";
import { useQuery } from "@tanstack/react-query";
import { DATA_LIMIT } from "configs";
import { apiBearer } from "Helpers/api";
import { ExternalDriverItem } from "Components/Driver/ExternalDriverItem";

export default function ListExternalDriver() {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DATA_LIMIT);
  const [keyword, setKeyword] = useState(null);
  const { status, data, error, isFetching } = useQuery(
    ['external-driver', '', keyword, offset, limit],
    async () => {
      const params = {
        page: offset,
        size: limit,
        search: keyword,
      };
      const res = await apiBearer.get("/driver/lists-external", { params });
      return res.data?.data;
    }
  );

  const DriverTable = withTable(
    ExternalDriverItem,
    "external-driver",
    [
      "NO",
      "Action",
      "nama",
      "nik",
      "foto sim",
      "masa aktif sim",
      "foto ktp",
      "foto driver",
      "Status",
      "Status "
    ],
  );

  const onPageChange = (i) => {
    setOffset(i.selected);
  };

  const onPerPageChange = (i) => {
    setLimit(i);
    setOffset(0);
  };

  const onKeywordChange = (value) => {
    setKeyword(value);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb pageTitle="External Drivers" />

        <Row>
          <DriverTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            onPageChange={onPageChange}
            error={error}
            status={status}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
    </div>
  );
}

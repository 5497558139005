import { CREATE_ROLE_URL, DELETE_ROLE_URL, LIST_ROLE_URL, UPDATE_ROLE_URL, VIEW_ROLE_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'permission';
const REDIRECT_URL = '/app/permission';

export const rolesRQ = new RQ(
  QUERY_KEY,
  LIST_ROLE_URL,
  VIEW_ROLE_URL,
  CREATE_ROLE_URL,
  UPDATE_ROLE_URL,
  DELETE_ROLE_URL,
  REDIRECT_URL
);
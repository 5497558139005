import useQueryParams from "Hooks/useQueryParams";
import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { TRUCKING } from "Helpers/options";

const statuses = ["error", "unfinish", "success"];

const PaymentRedirect = () => {
  const { profile } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const query = useQueryParams();
  const { status } = useParams();
  const orderId = query.get("order_id");

  if (!statuses.includes(status)) navigate("/");
  if (!orderId) navigate("/");

  if (orderId.includes("TOP-")) return <Navigate to={"/app/topups"} />;
  if (orderId.includes("BKG-"))
    return (
      <Navigate
        to={`/app/${
          profile?.role === TRUCKING ? "trucking-order" : "truck-book/booking"
        }`}
      />
    );
  if (orderId.includes("CDO-")) return <Navigate to={"/app/cdo"} />;
  if (orderId.includes('AGR-')) return <Navigate to='/app/agreement' />
  return <Navigate to='/'/>;
};

export default PaymentRedirect;

import { CREATE_TRUCK_RATE_URL, DELETE_TRUCK_RATE_URL, LIST_TRUCK_RATE_URL, UPDATE_TRUCK_RATE_URL, VIEW_TRUCK_RATE_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'trucking-rate';
const REDIRECT_URL = '/app/trucking-rate';

export const truckingRateRQ = new RQ(
  QUERY_KEY,
  LIST_TRUCK_RATE_URL,
  VIEW_TRUCK_RATE_URL,
  CREATE_TRUCK_RATE_URL,
  UPDATE_TRUCK_RATE_URL,
  DELETE_TRUCK_RATE_URL,
  REDIRECT_URL
);
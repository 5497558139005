import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { DriverRQ, truckRQ } from "Hooks/Queries";
import ErrorFieldWrapper from "Components/Common/ErrorFieldWrapper";
import Select from "react-select";
import flatpickr from "flatpickr";
import ImageTextPreview from "Components/Common/ImageTextPreview";
import debounce from "Helpers/debounce";
import { validNumbers } from "Helpers/regex_validation";

export default function EditCreateDriver(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const simRef = useRef();
  const [truckOptions, setTruckOptions] = useState([]);
  const [selectedTruck, setSelctedTruck] = useState(null);
  const [searchTruck, setSearchTruck] = useState("");
  const [simEx, setSimEx] = useState("");

  const { data: truckListData } = truckRQ.useGetDataList()(
    false,
    searchTruck,
    0,
    5000
  );

  const validation = Yup.object().shape({
    name: Yup.string()
      .required("Name wajib diisi")
      .min(3, "Name minimal terdiri dari 3 karakter"),
    email: Yup.string().required("Email wajib diisi"),
    // bank_account: Yup.string().required(),
    // bank_name: Yup.string().required(),
    sim_expired: Yup.string().required(),
    user_name: Yup.string().required(),
    password: id ? Yup.string() : Yup.string().required(),
    nik: Yup.string().required()
    .test('format-nik','Nik hanya boleh berisi angka',(v)=> validNumbers.test(v)),
    truck_id: Yup.string(),
    ktp: !id
      ? Yup.mixed().test(
          "fileExist",
          "File is required",
          (value) => value && value.length > 0
        )
      : Yup.mixed()
          .test("fileType", "Format file harus png/img/jpeg", (value) => {
            return (
              !value ||
              (value && value.length === 0) ||
              (value &&
                value.length > 0 &&
                ["image/jpeg", "image/png", "image/jpg"].includes(
                  value[0].type
                ))
            );
          })
          .test("fileSize", "Ukuran file terlalu besar", (file) => {
            return (
              !file ||
              (file && file.length === 0) ||
              (file && file.length > 0 && file[0].size <= 2097152)
            );
          }),
    photo: !id
      ? Yup.mixed().test(
          "fileExist",
          "File is required",
          (value) => value && value.length > 0
        )
      : Yup.mixed()
          .test("fileType", "Format file harus png/img/jpeg", (value) => {
            return (
              !value ||
              (value && value.length === 0) ||
              (value &&
                value.length > 0 &&
                ["image/jpeg", "image/png", "image/jpg"].includes(
                  value[0].type
                ))
            );
          })
          .test("fileSize", "Ukuran file terlalu besar", (file) => {
            return (
              !file ||
              (file && file.length === 0) ||
              (file && file.length > 0 && file[0].size <= 2097152)
            );
          }),
    sim: !id
      ? Yup.mixed().test(
          "fileExist",
          "File is required",
          (value) => value && value.length > 0
        )
      : Yup.mixed()
          .test("fileType", "Format file harus png/img/jpeg", (value) => {
            return (
              !value ||
              (value && value.length === 0) ||
              (value &&
                value.length > 0 &&
                ["image/jpeg", "image/png", "image/jpg"].includes(
                  value[0].type
                ))
            );
          })
          .test("fileSize", "Ukuran file terlalu besar", (file) => {
            return (
              !file ||
              (file && file.length === 0) ||
              (file && file.length > 0 && file[0].size <= 2097152)
            );
          }),
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      name: "",
      email: "",
      sim_expired: "",
      // bank_account: "",
      // bank_name: "",
      username: "",
      password: "",
      nik: "",
      sim: [],
      photo: [],
      ktp: [],
    },
  };
  const {
    handleSubmit,
    formState,
    control,
    reset,
    register,
    getValues,
    setValue,
    watch,
  } = useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    // fetchBanks();
    if (simRef.current) {
      flatpickr(simRef.current, {
        dateFormat: "Y-m-d",
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setSimEx(sDate);
          setValue("sim_expired", sDate);
        },
      });
    }
  }, []);

  const { mutate: updateDriver, isLoading: updateLoading } = DriverRQ.useUpdateFormData(navigate)();

  const getPayload = (values) => {
    const formData = new FormData();
    const data = {
      name: values.name,
      user_name: values.user_name,
      email: values.email,
      password: values.password ?? "",
      nik: values.nik,
      truck_id: values.truck_id,
      sim_expired: values.sim_expired,
    };

    formData.append("data", JSON.stringify(data));
    formData.append("ktp", values.ktp[0]);
    formData.append("sim", values.sim[0]);
    formData.append("photo", values.photo[0]);
    return formData;
  };
  const _updateDriver = (values) => {
    const merge = getPayload(values);
    merge.append("id", id);
    updateDriver(merge);
  };

  const { mutate: addDriver, isLoading: createLoading } = DriverRQ.useAddData(navigate)();

  const _createDriver = (values) => {
    addDriver(getPayload(values));
  };

  const { data, error, isFetching, status } = DriverRQ.useGetData(id)();

  useEffect(() => {
    if (!isFetching && status === "success") {
      reset({
        name: data?.data?.name ?? "",
        user_name: data?.data?.user_name ?? "",
        email: data?.data?.email ?? "",
        password: data?.data?.password ?? "",
        // bank_account: data?.data?.bank_account ?? "",
        // bank_name: data?.data?.bank_name ?? "",
        nik: data?.data?.nik ?? "",
        sim_expired: data?.data?.sim_expired ?? "",
        photo: [],
        ktp: [],
        sim: [],
      });

      if (simRef.current) {
        simRef.current.value = data?.data?.sim_expired ?? new Date();
      }
    }
    if (!isFetching && status === "error") {
      console.log(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  const values = watch();
  const getFileName = (key) => {
    if (!values[key]) return "";
    return values[key].length > 0 ? values[key][0].name : "";
  };

  const debounceSearchTruck = debounce(setSearchTruck, 500);

  const isLoading = updateLoading || createLoading

  const submitBtnText = () => {
    if(isLoading) return 'Loading...'
    return id ? "Update" : "Create"
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Driver" />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(id ? _updateDriver : _createDriver)}>
              <Card>
                <CardBody className="card-body">
                  <h4 className="">{id ? "Edit" : "Create"} Driver</h4>
                  <Row className="gy-4 text-muted">
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.truck_id}
                        message={errors.truck_id?.message}
                      >
                        <Label>Armada</Label>
                        <Controller
                          control={control}
                          name="truck_id"
                          render={({ field: { onChange } }) => (
                            <Select
                              value={selectedTruck ?? ""}
                              isClearable
                              isSearchable
                              name="color"
                              onInputChange={(v) => {
                                debounceSearchTruck(v)
                              }}
                              options={(truckListData?.data ?? []).map((v) => ({
                                label: v.plate_number,
                                value: v.id,
                              }))}
                              onChange={(e) => {
                                if (e) {
                                  onChange(e.value);
                                  setSelctedTruck(e);
                                }
                              }}
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.name}
                        message={errors.name?.message}
                      >
                        <Label htmlFor="name">
                          Name <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register('name')}/>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.user_name}
                        message={errors.user_name?.message}
                      >
                        <Label htmlFor="user_name">
                          Username <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register('user_name')}/>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.password}
                        message={errors.password?.message}
                      >
                        <Label htmlFor="ktp" className="form-label">
                          Password
                        </Label>
                        <input
                          className="form-control"
                          {...register("password")}
                          placeholder="********"
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.email}
                        message={errors.email?.message}
                      >
                        <Label htmlFor="email">
                          Email <span className="mandatory">*</span>
                        </Label>
                        <input type="email" className="form-control" {...register('email')}/>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.nik}
                        message={errors.nik?.message}
                      >
                        <Label htmlFor="nik">
                          Nik <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register('nik')}/>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.sim_expired}
                        message={errors.sim_expired?.message}
                      >
                        <Label htmlFor="sim_expired">
                          Masa Berlaku SIM <span className="mandatory">*</span>
                        </Label>
                        <Controller
                          id="sim_expired"
                          name="sim_expired"
                          control={control}
                          render={({ field }) => (
                            <input
                              ref={simRef}
                              className="form-control"
                              type="text"
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                  <h4 className="mt-4">Dokumen</h4>
                  <Row className="gy-4 text-muted">
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.photo}
                        message={errors.photo?.message}
                      >
                        <div className="d-flex justify-content-between">
                          <Label htmlFor="photo" className="form-label">
                            Foto{" "}
                            {id ? null : <span className="mandatory">*</span>}
                          </Label>
                          {data?.data?.photo_url ? (
                            <ImageTextPreview link={data?.data?.photo_url} />
                          ) : null}
                        </div>
                        <div className="btn-input">
                          <input
                            type="file"
                            id="file-upload_01"
                            accept="image/jpg,image/jpeg,image/png"
                            {...register("photo")}
                          />
                          <span className="btn btn-outline-primary px-3 py-2 mb-0 w-100 btn-file d-flex align-items-center justify-content-center">
                            <i className="ri-file-2-line me-2"></i>
                            {getFileName("photo")
                              ? getFileName("photo")
                              : "Pilih Berkas Foto"}
                          </span>
                        </div>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.ktp}
                        message={errors.ktp?.message}
                      >
                        <div className="d-flex justify-content-between">
                          <Label htmlFor="ktp" className="form-label">
                            KTP{" "}
                            {id ? null : <span className="mandatory">*</span>}
                          </Label>
                          {data?.data?.ktp_url ? (
                            <ImageTextPreview link={data?.data?.ktp_url} />
                          ) : null}
                        </div>
                        <div className="btn-input">
                          <input
                            type="file"
                            id="file-upload_01"
                            accept="image/jpg,image/jpeg,image/png"
                            {...register("ktp")}
                          />
                          <span className="btn btn-outline-primary px-3 py-2 mb-0 w-100 btn-file d-flex align-items-center justify-content-center">
                            <i className="ri-file-2-line me-2"></i>
                            {getFileName("ktp")
                              ? getFileName("ktp")
                              : "Pilih Berkas KTP"}
                          </span>
                        </div>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.sim}
                        message={errors.sim?.message}
                      >
                        <div className="d-flex justify-content-between">
                          <Label htmlFor="sim" className="form-label">
                            Sim{" "}
                            {id ? null : <span className="mandatory">*</span>}
                          </Label>
                          {data?.data?.sim_url ? (
                            <ImageTextPreview link={data?.data?.sim_url} />
                          ) : null}
                        </div>
                        <div className="btn-input">
                          <input
                            type="file"
                            id="file-upload_01"
                            accept="image/jpg,image/jpeg,image/png"
                            {...register("sim")}
                          />
                          <span className="btn btn-outline-primary px-3 py-2 mb-0 w-100 btn-file d-flex align-items-center justify-content-center">
                            <i className="ri-file-2-line me-2"></i>
                            {getFileName("sim")
                              ? getFileName("sim")
                              : "Pilih Berkas sim"}
                          </span>
                        </div>
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to="/app/driver" className="btn btn-danger">
                    Cancel
                  </Link>
                  <Button color="success" type="submit" disabled={isLoading}>
                    {submitBtnText()}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import { 
  LIST_SHIPPING_MANAGEMENT_URL, 
  VIEW_SHIPPING_MANAGEMENT_URL 
} from "../../Helpers/url_helper";

import RQ from "./baseRQ";

const QUERY_KEY = 'shipping-management';
const REDIRECT_URL = '/app/shipping-management';

export const ShippingManagementRQ = new RQ(
  QUERY_KEY, 
  LIST_SHIPPING_MANAGEMENT_URL, 
  VIEW_SHIPPING_MANAGEMENT_URL, 
  null, //CREATE_URL, 
  null, //UPDATE_URL, 
  null, //DELETE_URL, 
  REDIRECT_URL
);
import { CREATE_CONSIGNEE_URL, DELETE_CONSIGNEE_URL, LIST_CONSIGNEE_URL, UPDATE_CONSIGNEE_URL, VIEW_CONSIGNEE_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'consignee';
const REDIRECT_URL = '/app/consignee';

export const ConsigneeRQ = new RQ(
  QUERY_KEY,
  LIST_CONSIGNEE_URL,
  VIEW_CONSIGNEE_URL,
  CREATE_CONSIGNEE_URL,
  UPDATE_CONSIGNEE_URL,
  DELETE_CONSIGNEE_URL,
  REDIRECT_URL
);
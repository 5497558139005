import { logStatus } from "Components/TruckingOrder/helpers";
import { formatNumber } from "Helpers/number";
import { UNPAID } from "Helpers/options";
import React from "react";
import { Link } from "react-router-dom";

export const getPaymentStatus = (status) => {
  switch(status) {
    case 'FAILED':
      return <span className="badge badge-soft-dark">Gagal / Expired</span>;
    case 'PAID':
      return <span className="badge badge-soft-dark">Sudah Dibayar</span>;
    case 'UNPAID':
      return <span className="badge badge-soft-dark">Menunggu Pembayaran</span>;
  }
}

export const getValidationStatus = (status) => {
  switch(status) {
    case 'WAITING_APPROVAL':
      return <span className="badge badge-soft-dark">Pending</span>;
    case 'APPROVED':
      return <span className="badge badge-soft-dark">Approved</span>;
    case 'REJECTED':
      return <span className="badge badge-soft-dark">Rejected</span>;
  }
}

export default function TruckBookingItem({
  page,
  index,
  id,
  code,
  origin_point_name,
  destination_point_name,
  price,
  trucking_validation_status,
  payment_status,
  last_log_status,
  date,
  table,
}) {
  return (
    <tr>
      <td className="fw-medium">{index + page + 1}</td>
      <td>
        <div className="hstack gap-3 fs-15">
          <Link to={`/app/${table}/detail/${id}`} className="link-success">
            <i className="ri-eye-fill"></i>
          </Link>
        </div>
      </td>
      <td>{code??'-'}</td>
      <td>{date ?? "-"}</td>
      <td>{origin_point_name ?? "-"}</td>
      <td>{destination_point_name ?? "-"}</td>
      <td>RP. {formatNumber(price)}</td>
      <td>{getPaymentStatus(payment_status)}</td>
      <td>{getValidationStatus(trucking_validation_status)}</td>
      <td>{logStatus(last_log_status)}</td>
    </tr>
  );
}

import {memo} from 'react'

const SinglePermission = ({
  headers,
  dataPermission,
  onChange,
  permissionValues,
  permission,
  disabled = false
}) => {
  const options = permission.base_option.sort()

  return (
    <>
      <tr>
        <td role='button'>
          <p className='mb-0'>{dataPermission.group}</p>
        </td>
        {headers.map((header, i) => {
          const indexPermission = headers.findIndex((v) => v === options[i])
          const isPermissionExist = permissionValues.find(
            (permissionValue) => permissionValue.id === permission.id,
          )
          return (
            <td key={header}>
              {indexPermission >= 0 ? (
                <input
                  className='form-check-input'
                  type='checkbox'
                  checked={
                    !!isPermissionExist &&
                    !!isPermissionExist?.value?.find(
                      (value) => value === options[i],
                    )
                  }
                  id='auth-remember-check'
                  onChange={(e) => {
                    onChange(permission.id, options[i])
                  }}
                  disabled={disabled}
                />
              ) : null}
            </td>
          )
        })}
      </tr>
    </>
  )
}
export default memo(SinglePermission)

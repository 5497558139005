import React, { useState } from 'react'
import { Container, Row } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import withTable from 'Components/Common/Table/withTable'
import { useNavigate } from 'react-router-dom'
import { DATA_LIMIT } from 'configs'
import { ShippingManagementItem } from 'Components/ShippingManagement/ShippingManagementItem'
import { ShippingManagementRQ } from 'Hooks/Queries'

export default function ShippingManagement() {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DATA_LIMIT);
  const [keyword, setKeyword] = useState(null);
  const {
    status,
    data,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = ShippingManagementRQ.useGetDataList()(false, keyword, offset, limit);

  const navigate = useNavigate();

  const onAddButtonClicked = () => {
    navigate('/app/warehouses/create')
  }

  const onPageChange = (i) => {
    setOffset(i.selected);
  }
  
  const onPerPageChange = (i) => {
    setLimit(i);
    setOffset(0);
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
  }

  const onDeleteClick = () => {
    
  }

  const ShippingManagementTable = withTable(
    ShippingManagementItem, 
    'shipping-management', 
    ['NO', 'Action', 'Nomor CDO', 'Tgl Request Pickup', 'Nama Consignee', 'Status'], 
    onDeleteClick, 
    // onAddButtonClicked
  );

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Shipping Management"/>
        
        <Row>
          <ShippingManagementTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            error={error}
            status={status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
    </div>
  )
}

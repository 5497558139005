import { DATA_LIMIT } from "../../configs";
import React from "react";
import { Link } from "react-router-dom";

export function SubscriberItem({
  page,
  limit,
  index,
  id,
  name,
  user_name,
  email,
  role,
  is_active,
  is_verified,
  onDeleteClick,
  table,
}) {
  return (
    <tr>
      <td className="fw-medium">{index + page + 1}</td>
      <td>
        <div className="hstack gap-3 fs-15">
          <Link to={`/app/${table}/edit/${id}`} className="link-warning">
            <i className="ri-edit-2-line"></i>
          </Link>
          <Link
            to="#"
            className="link-danger"
            onClick={() => {
              onDeleteClick(id);
            }}
          >
            <i className="ri-delete-bin-5-line"></i>
          </Link>
        </div>
      </td>
      <td>{user_name}</td>
      <td>{name}</td>
      <td>{email}</td>
      <td>{role}</td>
      <td>
        {is_active ? (
          <span className="badge badge-soft-success">Aktif</span>
        ) : (
          <span className="badge badge-soft-dark">Inactive</span>
        )}
      </td>

    </tr>
  );
}

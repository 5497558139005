import {
  CREATE_COMMISSION_URL,
  DELETE_COMMISSION_URL,
  LIST_COMMISSION_URL,
  UPDATE_COMMISSION_URL,
  VIEW_COMMISSION_URL
} from '../../Helpers/url_helper'
import RQ from './baseRQ'

const QUERY_KEY = 'commission'
const REDIRECT_URL = '/app/struktur-harga/trucking/komisi'

export const CommissionRQ = new RQ(
  QUERY_KEY,
  LIST_COMMISSION_URL,
  VIEW_COMMISSION_URL,
  CREATE_COMMISSION_URL,
  UPDATE_COMMISSION_URL,
  DELETE_COMMISSION_URL,
  REDIRECT_URL,
)

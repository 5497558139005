import { Container, Row } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { useState } from 'react'
import DeleteModal from 'Components/Common/DeleteModal'
import { TruckingCatRQ } from 'Hooks/Queries'
import withTable from 'Components/Common/Table/withTable'
import { TruckingCatItem } from 'Components/Master/TruckingCatItem'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { DATA_LIMIT } from 'configs'

export default function ListTruckingCat() {
  const [deleteTruckingId, setDeleteTruckingId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DATA_LIMIT);
  const [keyword, setKeyword] = useState(null);
  const {
    status,
    data,
    error,
    isFetching,
    refetch
  } = TruckingCatRQ.useGetDataList()(false, keyword, offset, limit);

  const useQuery = useQueryClient();
  const navigate = useNavigate();

  const onDeleteSuccess = (key) =>{
    setDeleteModal(false);
    setDeleteTruckingId(null)
    useQuery.invalidateQueries({queryKey:[key]})
    refetch()
  }
  const { mutate: deleteTruck } = TruckingCatRQ.useDeleteData(onDeleteSuccess)();

  const onDeleteClick = (id) => {
    setDeleteModal(true);
    setDeleteTruckingId(id)
  }

  const onDeleteConfirm = () => {
    deleteTruck(deleteTruckingId);
  }

  const onAddButtonClicked = () => {
    navigate('/app/truck/category/create')
  }

  const onPageChange = (i) => {
    setOffset(i.selected);
  }

  const onPerPageChange = (i) => {
    setLimit(i);
    setOffset(0);
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
  }

  const TruckingTable = withTable(
    TruckingCatItem,
    'truck/category',
    ['NO', 'Action', 'Nama'],
    onDeleteClick,
    onAddButtonClicked
  );

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Trucking Categories"/>

        <Row>
          <TruckingTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            error={error}
            status={status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal(v => !v)}
        show={deleteModal}
        loading={false}
      />
    </div>
  )
}

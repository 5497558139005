import AddQuotaModal from 'Components/Agreement/AddQuotaModal'
import AgreementApproveItem from 'Components/Agreement/AgreementApproveItem'
import AgreementMutationModal from 'Components/Agreement/AgreementMutationModal'
import BreadCrumb from 'Components/Common/BreadCrumb'
import withTable from 'Components/Common/Table/withTable'
import {DATA_LIMIT} from 'configs'
import {apiBearer} from 'Helpers/api'
import {VIEW_AGREEMENT_APPROVAL_URL} from 'Helpers/url_helper'
import {AgreementApprovalRQ} from 'Hooks/Queries'
import {useState} from 'react'
import {Container, Row} from 'reactstrap'

export default function ListAgreementApproval() {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [quotaModal, setQuotaModal] = useState(false)
  const [mutationModal, setMutationModal] = useState(false)
  const [dataDetail, setDataDetail] = useState(null)
  const [keyword, setKeyword] = useState(null)
  const {status, data, error, isFetching, refetch} =
    AgreementApprovalRQ.useGetDataList()(false, keyword, offset, limit)

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
  }

  const getAgreementAprrovalDetail = async (id) =>
    await apiBearer.get(VIEW_AGREEMENT_APPROVAL_URL(id))

  const onAddQuotaClick = async (id) => {
    try {
      const response = await getAgreementAprrovalDetail(id)
      const data = response.data
      if (data?.data) {
        setDataDetail(data.data)
        setQuotaModal(true)
      }
    } catch (error) {}
  }

  const showMutaions = async (id) => {
    try {
      const response = await getAgreementAprrovalDetail(id)
      const data = response.data
      if (data?.data) {
        setDataDetail(data.data)
        setMutationModal(true)
      }
    } catch (error) {}
  }

  const AgreementApprovalTable = withTable(
    AgreementApproveItem,
    'agreement-approval',
    [
      'NO',
      'Action',
      'ID Agreement',
      'consignee',
      'kuota',
      'kuota terpakai',
      'kuota sisa',
      'status',
    ],
    null,
    null,
    {onAddQuotaClick, showMutaions},
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Agreement Approval' />
        <Row>
          <AgreementApprovalTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            error={error}
            status={status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
        <AddQuotaModal
          show={quotaModal}
          onCloseClick={() => {
            setQuotaModal(false)
            setDataDetail(null)
          }}
          agreementData={dataDetail ?? {}}
          onSuccess={() => {
            refetch()
            setDataDetail(null)
            setQuotaModal(false)
          }}
        />

        <AgreementMutationModal
          show={mutationModal}
          onCloseClick={() => {
            setMutationModal(false)
            setDataDetail(null)
          }}
          agreementData={dataDetail ?? {}}
        />
      </Container>
    </div>
  )
}

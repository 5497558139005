import BreadCrumb from 'Components/Common/BreadCrumb'
import ConfirmModal from 'Components/Common/ConfirmModal'
import { apiBearer } from 'Helpers/api'
import { APPROVED, WAITING_APPROVAL, getBadgeClass } from 'Helpers/options'
import { showError, showSuccess } from 'Helpers/show_toast'
import {
  APPROVE_AGREEMENT_APPROVAL_URL,
  REJECT_AGREEMENT_APPROVAL_URL,
} from 'Helpers/url_helper'
import { AgreementApprovalRQ } from 'Hooks/Queries'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from 'reactstrap'

export default function DetailAgreementApproval(props) {
  const {id} = useParams()
  // const [quotaModal, setQuotaModal] = useState(false)
  const [modalState, setModalState] = useState({
    show: false,
    onConfirm: () => {},
    description: '',
    isReject: false,
  })

  const navigate = useNavigate()

  const approveAgreement = async () => {
    const result = await apiBearer.patch(APPROVE_AGREEMENT_APPROVAL_URL(id))
    if (result?.data?.success) {
      showSuccess('Approved Successfully')
      navigate('/app/agreement-approval')
    } else {
      showError('Error while saving')
    }
  }

  const [reason, setReason] = useState('-')
  const rejectAgreement = async () => {
    const result = await apiBearer.patch(REJECT_AGREEMENT_APPROVAL_URL(id), {
      reject_reason: reason,
    })
    if (result?.data?.success) {
      showSuccess('Rejected Successfully')
      navigate('/app/agreement-approval')
    } else {
      showError('Error while saving')
    }
  }

  const {data, error, isFetching, status, refetch} =
    AgreementApprovalRQ.useGetData(id)()

    const {consignee_partner} = data?.data || {}

  useEffect(() => {
    if (!isFetching && status === 'success') {
    }
    if (!isFetching && status === 'error') {
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Agreement Approval' pageTitle='Agreement Approval' />
        <Row>
          <Col lg={12}>
            <form onSubmit={() => {}}>
              <Card>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                  <h4 className='mb-0'>Detail #{data?.data?.code ?? '-'}</h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='text-muted'>
                    <table className='table table-bordered'>
                      <thead>
                        <tr>
                          <th
                            style={{background: 'rgb(243, 246, 249)'}}
                            colSpan={2}
                          >
                            General
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th style={{maxWidth: '20%'}}>No Agreement</th>
                          <td>{data?.data?.code ?? '-'}</td>
                        </tr>
                        <tr>
                          <th style={{maxWidth: '20%'}}>Nama Consignee</th>
                          <td>{consignee_partner?.name ?? '-'}</td>
                        </tr>
                        <tr>
                          <th style={{maxWidth: '20%'}}>Email</th>
                          <td>{consignee_partner?.pic_email ?? '-'}</td>
                        </tr>
                        <tr>
                          <th style={{maxWidth: '20%'}}>Telepon</th>
                          <td>{consignee_partner?.pic_phone ?? '-'}</td>
                        </tr>
                        <tr>
                          <th style={{maxWidth: '20%'}}>Alamat</th>
                          <td>{consignee_partner?.address ?? '-'}</td>
                        </tr>
                        <tr>
                          <th style={{maxWidth: '20%'}}>Status</th>
                          <td>
                            <span className={getBadgeClass(data?.data?.status)}>
                              {data?.data?.status}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th
                            style={{maxWidth: '20%'}}
                            rowSpan={(data?.data?.docs.length || 1) + 1}
                          >
                            Dokumen Pendukung
                          </th>
                        </tr>

                        {(data?.data?.docs ? data?.data?.docs : []).map(
                          ({id, doc}) => (
                            <tr key={id}>
                              <td>
                                <a
                                  className='text-decoration-underline'
                                  target={doc ? '_blank' : ''}
                                  rel='noreferrer'
                                  href={doc ? doc : '#'}
                                >
                                  Preview
                                </a>
                              </td>
                            </tr>
                          ),
                        )}
                        <tr>
                          <th
                            style={{background: 'rgb(243, 246, 249)'}}
                            colSpan={2}
                          >
                            System
                          </th>
                        </tr>
                        <tr>
                          <th style={{maxWidth: '20%'}}>Waktu dibuat</th>
                          <td>{data?.data?.created_on ?? '-'}</td>
                        </tr>
                        <tr>
                          <th style={{maxWidth: '20%'}}>Waktu diubah</th>
                          <td>{data?.data?.updated_on ?? '-'}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className='table-responsive table-card '></div>
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link
                    to='/app/agreement-approval'
                    className='btn btn-soft-dark'
                  >
                    Back
                  </Link>
                  {data?.data?.status === WAITING_APPROVAL ? (
                    <>
                      <Button
                        color='danger'
                        type='button'
                        onClick={() => {
                          setModalState((v) => ({
                            description: 'Yakin ingin tolak agreement ini?',
                            show: true,
                            isReject: true,
                            onConfirm: rejectAgreement,
                          }))
                        }}
                      >
                        REJECT
                      </Button>
                      <Button
                        color='success'
                        type='button'
                        onClick={() => {
                          setModalState((v) => ({
                            description: 'Yakin ingin approve agreement ini?',
                            show: true,
                            isReject: false,
                            onConfirm: approveAgreement,
                          }))
                        }}
                      >
                        APPROVE
                      </Button>
                    </>
                  ) : null}
                  {
                    data?.data?.status === APPROVED ? (
                      <Link 
                        className='btn btn-warning' 
                        to={`/app/agreement-approval/detail/${id}/credit-limit`}
                        state={{consigneeName: consignee_partner?.name}}
                      >Receivables</Link>
                    ):null                  }
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
      <ConfirmModal
        key={modalState.isReject}
        id={id}
        description={modalState.description}
        onConfirm={() => modalState.onConfirm()}
        onCloseClick={() => setModalState((v) => ({...v, show: !v.show}))}
        show={modalState.show}
        loading={false}
        isReject={modalState.isReject}
        refetch={refetch}
      >
        {modalState.isReject ? (
          <>
            <Label>
              Alasan<span className='mandatory'>*</span>
            </Label>
            <Input value={reason} onChange={(e) => setReason(e.target.value)} />
          </>
        ) : null}
      </ConfirmModal>
    </div>
  )
}

import {createAsyncThunk} from '@reduxjs/toolkit'
import {apiBasic, apiBearer} from 'Helpers/api'
import {
  LOGIN,
  LOGIN_GOOGLE,
  LOGIN_NLE,
  REFRESH_TOKEN,
  REFRESH_TOKEN_NLE,
} from 'Helpers/url_helper'
import {LOGINTYPES} from 'Redux/constants'
import {DRIVER} from '../../Helpers/options'

export const loginAction = createAsyncThunk(
  'auth/login',
  async (payload, _thunkApi) => {
    try {
      const loginPayload = {...payload}
      let URL = LOGIN
      switch (payload.loginType) {
        case LOGINTYPES.GOOGLE:
          URL = LOGIN_GOOGLE
          break
        case LOGINTYPES.NLE:
          URL = LOGIN_NLE
          break
        default:
          break
      }
      delete loginPayload.redirectTo
      delete loginPayload.loginType
      const response = await apiBasic.post(URL, {...loginPayload})
      if (response.data.data.role == DRIVER) {
        return _thunkApi.rejectWithValue({ message: 'Please log in from your mobile app'})
      }
      return {...response.data.data, redirectTo: payload.redirectTo}
    } catch (error) {
      return _thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const refreshTokenAction = createAsyncThunk(
  'auth/refresh-token',
  async (payload, _thunkApi) => {
    try {
      const refreshPayload = {...payload}
      let URL = REFRESH_TOKEN
      if (payload.refreshType === REFRESH_TOKEN_NLE) URL = REFRESH_TOKEN_NLE
      delete refreshPayload.refreshType
      const response = await apiBearer.post(URL, {...refreshPayload})
      return {...response.data.data, refreshType: payload.refreshType}
    } catch (error) {
      return _thunkApi.rejectWithValue(error.response.data)
    }
  },
)

export const logoutAction = createAsyncThunk(
  'auth/logout',
  async (_, {fulfillWithValue}) => {
    return fulfillWithValue({message: 'success'})
  },
)

import { APP_NAME } from "configs";
import React from "react";
import { Card, CardBody, Col, Container,Row } from "reactstrap";
import FormRegister from "Components/Register/Form";

export default function Register() {
  document.title = `${APP_NAME} | Register`;
  return (
    <div className="auth-page-content">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} sm={12}>
            <Card className="mt-4">
              <CardBody className="p-4">
                <div className="text-center mt-2 mb-0">
                  <p className="text-muted mb-4 mb-0 auth-label">
                    REGISTRATION
                  </p>
                </div>
                <div className="p-2 mt-2">
                 <FormRegister/>
                </div>
              </CardBody>
            </Card>

            <div className="mt-4 text-center">
              {/* <p className="mb-0">Don't have an account ? <Link to="/auth-signup-basic" className="fw-semibold text-primary text-decoration-underline"> Signup </Link> </p> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import {Link} from 'react-router-dom'
import { shipmentStatus } from './ShippingManagement/helpers'

export default function SelfPickupItem({
  page,
  limit,
  index,
  id,
  sender_name,
  receiver_name,
  cdo_code,
  status,
  onDeleteClick,
  table,
}) {
  return (
    <tr>
      <td className='fw-medium'>{index + page + 1}</td>
      <td>
        <div className='hstack gap-3 fs-15'>
          <Link to={`/app/${table}/detail/${id}`} className='link-success'>
            <i className='ri-eye-fill'></i>
          </Link>
          {/* <Link to={`/app/${table}/edit/${id}`} className="link-warning">
            <i className="ri-edit-2-line"></i>
          </Link>
          <Link
            to="#"
            className="link-danger"
            onClick={() => {
              onDeleteClick(id);
            }}
          >
            <i className="ri-delete-bin-5-line"></i>
          </Link> */}
        </div>
      </td>
      <td>{sender_name ?? '-'}</td>
      <td>{receiver_name ?? '-'}</td>
      <td>{cdo_code ?? '-'}</td>
      <td>
        {shipmentStatus(status)}
      </td>
    </tr>
  )
}

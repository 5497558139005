import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { truckRQ } from "Hooks/Queries";
import ErrorFieldWrapper from "Components/Common/ErrorFieldWrapper";
import Select from "react-select";
import { apiBearer } from "Helpers/api";
import {
  LIST_ALL_TRUCKING_CAT_URL,
  LIST_TRUCKING_TYPE_URL,
  TRUCKING_TYPE_BASE_API,
} from "Helpers/url_helper";
import flatpickr from "flatpickr";
import ImageTextPreview from "Components/Common/ImageTextPreview";

export default function EditCreateTruck(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const kirRef = useRef();
  const stnkRef = useRef();
  const [truckType, setTruckType] = useState(null);
  const [truckTypeOptions, setTruckTypeOptions] = useState([]);

  const [tructCatOptions, setTruckCatOptions] = useState([]);
  const [selectedCat, setSelectedCat] = useState(null);

  const fetchTruckCats = async () => {
    return new Promise(async (res, rej) => {
      try {
        const result = await apiBearer.get(LIST_ALL_TRUCKING_CAT_URL);
        const { data } = result;
        if (data.success) {
          setTruckCatOptions(
            data?.data?.map((v) => ({ label: v.name, value: v.id }))
          );
        }
        rej(data.message);
      } catch (error) {
        rej(error);
      }
    });
  };

  useEffect(() => {
    fetchTruckCats();
  }, []);

  // use id when api is accesable by trucking
  const fetchTruckTypes = async (catID) => {
    try {
      const response = await apiBearer.get(
        `${TRUCKING_TYPE_BASE_API}/by-category/${catID}`
      );
      const { data } = response;
      if (data.success) {
        setTruckTypeOptions(
          data?.data?.map((v) => ({
            label: v.name,
            value: v.id,
            cat: v.truck_category?.id ?? "",
          }))
        );
      }
    } catch (error) {}
  };

  const onTruckSelect = (e) => {
    setSelectedCat(e);
    setValue("truck_category_id", e.value);
    fetchTruckTypes(e.value);
  };

  const validation = Yup.object().shape({
    brand: Yup.string()
      .required("Brand wajib diisi")
      .min(2, "Brand minimal terdiri dari 2 karakter"),
    plate_number: Yup.string().required(),
    capacity: Yup.string().required(),
    truck_category_id: Yup.string().required(
      "Kategori Truk tidak boleh kosong"
    ),
    truck_type_id: Yup.string().required("Tipe Truk tidak boleh kosong"),
    kir_expired: Yup.string().required(),
    stnk_expired: Yup.string().required(),
    length: Yup.string(),
    width: Yup.string(),
    height: Yup.string(),
    stnk: !id
      ? Yup.mixed().test(
          "fileExist",
          "File is required",
          (value) => value && value.length > 0
        )
      : Yup.mixed()
          .test("fileType", "Format file harus png/img/jpeg", (value) => {
            return (
              !value ||
              (value && value.length === 0) ||
              (value &&
                value.length > 0 &&
                ["image/jpeg", "image/png", "image/jpg"].includes(
                  value[0].type
                ))
            );
          })
          .test("fileSize", "Ukuran file terlalu besar", (file) => {
            return (
              !file ||
              (file && file.length === 0) ||
              (file && file.length > 0 && file[0].size <= 2097152)
            );
          }),
    photo: !id
      ? Yup.mixed().test(
          "fileExist",
          "File is required",
          (value) => value && value.length > 0
        )
      : Yup.mixed()
          .test("fileType", "Format file harus png/img/jpeg", (value) => {
            return (
              !value ||
              (value && value.length === 0) ||
              (value &&
                value.length > 0 &&
                ["image/jpeg", "image/png", "image/jpg"].includes(
                  value[0].type
                ))
            );
          })
          .test("fileSize", "Ukuran file terlalu besar", (file) => {
            return (
              !file ||
              (file && file.length === 0) ||
              (file && file.length > 0 && file[0].size <= 2097152)
            );
          }),
    kir: !id
      ? Yup.mixed().test(
          "fileExist",
          "File is required",
          (value) => value && value.length > 0
        )
      : Yup.mixed()
          .test("fileType", "Format file harus png/img/jpeg", (value) => {
            return (
              !value ||
              (value && value.length === 0) ||
              (value &&
                value.length > 0 &&
                ["image/jpeg", "image/png", "image/jpg"].includes(
                  value[0].type
                ))
            );
          })
          .test("fileSize", "Ukuran file terlalu besar", (file) => {
            return (
              !file ||
              (file && file.length === 0) ||
              (file && file.length > 0 && file[0].size <= 2097152)
            );
          }),
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      brand: "",
      plate_number: "",
      capacity: "",
      kir_expired: "",
      stnk_expired: "",
      length: "",
      width: "",
      height: "",
      kir: [],
      photo: [],
      stnk: [],
    },
  };
  const {
    handleSubmit,
    formState,
    control,
    reset,
    register,
    getValues,
    setValue,
    watch,
  } = useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if (kirRef.current) {
      flatpickr(kirRef.current, {
        dateFormat: "Y-m-d",
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setValue("kir_expired", sDate);
        },
      });
    }
    if (stnkRef.current) {
      flatpickr(stnkRef.current, {
        dateFormat: "Y-m-d",
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setValue("stnk_expired", sDate);
        },
      });
    }
  }, []);

  const { mutate: updateTruck, isLoading: updateLoading } = truckRQ.useUpdateFormData(navigate)();

  const getPayload = (values) => {
    const formData = new FormData();
    const data = {
      brand: values.brand,
      plate_number: values.plate_number,
      stnk_expired: values.stnk_expired,
      capacity: values.capacity,
      kir_expired: values.kir_expired,
      truck_category_id: values.truck_category_id,
      truck_type_id: values.truck_type_id,
      length: values.length,
      width: values.width,
      height: values.height,
    };

    formData.append("data", JSON.stringify(data));
    if (values.stnk && values.stnk.length > 0)
      formData.append("stnk", values.stnk[0]);
    if (values.kir && values.kir.length > 0)
      formData.append("kir", values.kir[0]);
    if (values.photo && values.photo.length > 0)
      formData.append("photo", values.photo[0]);
    return formData;
  };
  const _updateTruck = (values) => {
    const merge = getPayload(values);
    merge.append("id", id);
    updateTruck(merge);
  };

  const { mutate: addTruck, isLoading: createLoading } = truckRQ.useAddData(navigate)();

  const _createTruck = (values) => {
    addTruck(getPayload(values));
  };

  const { data, error, isFetching, status } = truckRQ.useGetData(id)();

  useEffect(() => {
    if (!isFetching && status === "success") {
      reset({
        brand: data?.data?.brand ?? "",
        plate_number: data?.data?.plate_number ?? "",
        capacity: data?.data?.capacity ?? "",
        length: data?.data?.length ?? "",
        width: data?.data?.width ?? "",
        height: data?.data?.height ?? "",
        photo: [],
        stnk: [],
        kir: [],
      });
      if (data?.data?.truck_category) {
        const { id, name } = data.data.truck_category;
        onTruckSelect({ label: name, value: id });
        if (data.data.truck_type) {
          const { id, name } = data.data.truck_type;
          setTruckType({ value: id, label: name });
          setValue("truck_type_id", id);
        }
      }

      if (data?.data?.kir_expired) {
        setValue("kir_expired", data?.data?.kir_expired ?? new Date());
        kirRef.current.value = data?.data?.kir_expired ?? new Date();
      }
      if (data?.data?.stnk_expired) {
        setValue("stnk_expired", data?.data?.stnk_expired ?? new Date());
        stnkRef.current.value = data?.data?.stnk_expired ?? new Date();
      }
    }
    if (!isFetching && status === "error") {
      console.log(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  const values = watch();
  const getFileName = (key) => {
    if (!values[key]) return "";
    return values[key].length > 0 ? values[key][0].name : "";
  };

  const isLoading = createLoading || updateLoading

  const submitBtnText = ()=> {
    if(isLoading) return 'Loading...'
    return id ? "Update" : "Create"
  }


  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Truck" />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(id ? _updateTruck : _createTruck)}>
              <Card>
                <CardBody className="card-body">
                  <h4 className="">{id ? "Edit" : "Create"} Truck</h4>
                  <Row className="gy-4 text-muted">
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.brand}
                        message={errors.brand?.message}
                      >
                        <Label htmlFor="kir_expired">
                          Brand <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register('brand')}/>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.plate_number}
                        message={errors.plate_number?.message}
                      >
                        <Label htmlFor="plate_number">
                          Plat Nomor <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register('plate_number')}/>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.capacity}
                        message={errors.capacity?.message}
                      >
                        <Label htmlFor="capacity">
                          Kapasitas (TON) <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register('capacity')}/>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.stnk_expired}
                        message={errors.stnk_expired?.message}
                      >
                        <Label htmlFor="stnk_expired">
                          Masa Berlaku STNK <span className="mandatory">*</span>
                        </Label>
                        <Controller
                          id="stnk_expired"
                          name="stnk_expired"
                          control={control}
                          render={({ field }) => (
                            <input
                              ref={stnkRef}
                              className="form-control"
                              type="text"
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.kir_expired}
                        message={errors.kir_expired?.message}
                      >
                        <Label htmlFor="kir_expired">
                          Masa Berlaku Kir <span className="mandatory">*</span>
                        </Label>
                        <Controller
                          id="kir_expired"
                          name="kir_expired"
                          control={control}
                          render={({ field }) => (
                            <input
                              ref={kirRef}
                              className="form-control"
                              type="text"
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.length}
                        message={errors.length?.message}
                      >
                        <Label htmlFor="length">
                          Length <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register('length')}/>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.width}
                        message={errors.width?.message}
                      >
                        <Label htmlFor="width">
                          Width <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register('width')}/>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.height}
                        message={errors.height?.message}
                      >
                        <Label htmlFor="height">
                          Height <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register('height')}/>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.truck_category_id}
                        message={errors.truck_category_id?.message}
                      >
                        <Label
                          htmlFor="truck_category_id"
                          className="form-label"
                        >
                          Kategori Truk <span className="mandatory">*</span>
                        </Label>
                        <Controller
                          id="truck_category_id"
                          name="truck_category_id"
                          type="text"
                          control={control}
                          render={({ field: { onChange, ...field } }) => (
                            <Select
                              {...field}
                              cacheOptions
                              isClearable
                              defaultOptions
                              value={selectedCat ?? ""}
                              options={tructCatOptions}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value);
                                onTruckSelect(e);
                              }}
                              placeholder="Search by name"
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.truck_type_id}
                        message={errors.truck_type_id?.message}
                      >
                        <Label htmlFor="truck_type_id" className="form-label">
                          Tipe Truk <span className="mandatory">*</span>
                        </Label>
                        <Controller
                          id="truck_type_id"
                          name="truck_type_id"
                          type="text"
                          control={control}
                          render={({ field: { onChange, ...field } }) => (
                            <Select
                              {...field}
                              cacheOptions
                              isClearable
                              defaultOptions
                              value={truckType ?? ""}
                              options={truckTypeOptions}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value);
                                setTruckType(e);
                              }}
                              placeholder={
                                tructCatOptions.length > 0
                                  ? "Cari"
                                  : "Pilih tipe truk terlebih dahulu"
                              }
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                  <h4 className="mt-4">Dokumen</h4>
                  <Row className="gy-4 text-muted">
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.photo}
                        message={errors.photo?.message}
                      >
                        <div className="d-flex justify-content-between">
                          <Label htmlFor="photo" className="form-label">
                            Foto{" "}
                            {id ? null : <span className="mandatory">*</span>}
                          </Label>
                          {data?.data?.truck_url ? (
                            <ImageTextPreview link={data?.data?.truck_url} />
                          ) : null}
                        </div>
                        <div className="btn-input">
                          <input
                            type="file"
                            id="file-upload_01"
                            accept="image/jpg,image/jpeg,image/png"
                            {...register("photo")}
                          />
                          <span className="btn btn-outline-primary px-3 py-2 mb-0 w-100 btn-file d-flex align-items-center justify-content-center">
                            <i className="ri-file-2-line me-2"></i>
                            {getFileName("photo")
                              ? getFileName("photo")
                              : "Pilih Berkas Foto"}
                          </span>
                        </div>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.stnk}
                        message={errors.stnk?.message}
                      >
                        <div className="d-flex justify-content-between">
                          <Label htmlFor="stnk" className="form-label">
                            stnk{" "}
                            {id ? null : <span className="mandatory">*</span>}
                          </Label>
                          {data?.data?.stnk_url ? (
                            <ImageTextPreview link={data?.data?.stnk_url} />
                          ) : null}
                        </div>
                        <div className="btn-input">
                          <input
                            type="file"
                            id="file-upload_01"
                            accept="image/jpg,image/jpeg,image/png"
                            {...register("stnk")}
                          />
                          <span className="btn btn-outline-primary px-3 py-2 mb-0 w-100 btn-file d-flex align-items-center justify-content-center">
                            <i className="ri-file-2-line me-2"></i>
                            {getFileName("stnk")
                              ? getFileName("stnk")
                              : "Pilih Berkas stnk"}
                          </span>
                        </div>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.kir}
                        message={errors.kir?.message}
                      >
                        <div className="d-flex justify-content-between">
                          <Label htmlFor="kir" className="form-label">
                            Kir{" "}
                            {id ? null : <span className="mandatory">*</span>}
                          </Label>
                          {data?.data?.kir_url ? (
                            <ImageTextPreview link={data?.data?.kir_url} />
                          ) : null}
                        </div>
                        <div className="btn-input">
                          <input
                            type="file"
                            id="file-upload_01"
                            accept="image/jpg,image/jpeg,image/png"
                            {...register("kir")}
                          />
                          <span className="btn btn-outline-primary px-3 py-2 mb-0 w-100 btn-file d-flex align-items-center justify-content-center">
                            <i className="ri-file-2-line me-2"></i>
                            {getFileName("kir")
                              ? getFileName("kir")
                              : "Pilih Berkas kir"}
                          </span>
                        </div>
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to="/app/truck/data" className="btn btn-danger">
                    Cancel
                  </Link>
                  <Button color="success" type="submit" disabled={isLoading}>
                    {submitBtnText()}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

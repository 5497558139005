import { CREATE_STATIC_PAGE_URL, DELETE_STATIC_PAGE_URL, LIST_STATIC_PAGE_URL, UPDATE_STATIC_PAGE_URL, VIEW_STATIC_PAGE_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'static-page';
const REDIRECT_URL = '/app/static-page';

export const staticPageRQ = new RQ(
  QUERY_KEY,
  LIST_STATIC_PAGE_URL,
  VIEW_STATIC_PAGE_URL,
  CREATE_STATIC_PAGE_URL,
  UPDATE_STATIC_PAGE_URL,
  DELETE_STATIC_PAGE_URL,
  REDIRECT_URL
);
import { CREATE_DRIVER_URL, DELETE_DRIVER_URL, LIST_DRIVER_URL, UPDATE_DRIVER_URL, VIEW_DRIVER_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'driver';
const REDIRECT_URL = '/app/driver';

export const DriverRQ = new RQ(
  QUERY_KEY,
  LIST_DRIVER_URL,
  VIEW_DRIVER_URL,
  CREATE_DRIVER_URL,
  UPDATE_DRIVER_URL,
  DELETE_DRIVER_URL,
  REDIRECT_URL
);
import {useQuery} from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import MyPagination from 'Components/Common/Table/Pagination'
import flatpickr from 'flatpickr'
import {apiBearer} from 'Helpers/api'
import {formatNumber} from 'Helpers/number'
import {AGREEMENT_APPROVAL_RECEIVABLES, LIST_AGREEMENT_UNPAID_URL} from 'Helpers/url_helper'
import moment from 'moment'
import {useEffect, useRef, useState} from 'react'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from 'reactstrap'

import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import 'flatpickr/dist/plugins/monthSelect/style.css'

const AgreementApprovalReceivables = () => {
  const {state} = useLocation()
  const {id} = useParams()
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [page, setPage] = useState(0)
  const size =10
  const [stateIndicator, setStateIndicator] = useState(false)

  const startDateRef = useRef()
  const formRef = useRef()

  const monthOptions = {
    plugins: [
      new monthSelectPlugin({
        dateFormat: "F Y",
        altFormat: "F Y"
      }),
    ],
  }
  useEffect(() => {
    if (startDateRef.current) {
      flatpickr(startDateRef.current, {
        ...monthOptions,
        dateFormat: 'm',
        closeOnSelect: true,
        onChange: (v) => {
          const startDate = moment(v[0]).startOf('month').format('YYYY-MM-DD')
          const endDate = moment(v[0]).endOf('month').format('YYYY-MM-DD')
          setStartDate(startDate)
          setEndDate(endDate)
        },
      })
    }
    // eslint-disable-next-line
  }, [])


  const {data, isFetching, status, refetch} = useQuery(
    ['agreement-receivable-list', page, size],
    async () => {
      const params = {
        page,
        size,
        start_date: startDate,
        end_date: endDate,
      }
      const res = await apiBearer.get(AGREEMENT_APPROVAL_RECEIVABLES(id), {params})
      return res.data?.data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      enabled: false,
      retryOnMount: false,
      retry: false,
    },
  )

  const onSubmitSearch = (e) => {
    e.preventDefault()
    if (page === 0) refetch()
    else setPage(0)
  }

  //handle refetch when form change and reset button clicked
  useEffect(() => {
    if (id) refetch()
  }, [refetch, stateIndicator, id])

  const colCounts =
    10 +
    (data?.sum_commissions?.length || 0) +
    (data?.sum_additional_costs?.length || 0)

  const renderData = () => {
    if (status === 'error')
      return (
        <tr>
          <td colSpan={colCounts} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    if (isFetching)
      return (
        <tr>
          <td colSpan={colCounts} className='text-center'>
            <Spinner color='success' />
          </td>
        </tr>
      )
    if (status === 'loading' && !isFetching) {
      return (
        <tr>
          <td colSpan={colCounts} className='text-center'>
            <span className='text-danger'>Search to show data</span>
          </td>
        </tr>
      )
    }
    if (!data?.data || data?.data.length < 1)
      return (
        <tr>
          <td colSpan={colCounts} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    return data?.data?.map((props, i) => {
      return (
        <tr
          key={i}
        >
          <td>{i + data?.offset + 1}</td>
          <td>{moment(props.transaction_time).format('DD MMMM YYYY HH:mm')}</td>
          <td>Rp. {formatNumber(props.amount)}</td>
          <td>
          <Link 
            to={`/app/agreement-approval/detail/${id}/credit-limit/${props.transaction_time}`} 
            state={{consigneeName:state?.consigneeName}}
            className='link-dark'
            >
            <i className='ri-eye-fill'></i>
          </Link>
          </td>
        </tr>
      )
    })
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Tagihan' />
        <Card>
          <CardBody>
            <h4>Tagihan</h4>
            <form onSubmit={onSubmitSearch} ref={formRef}>
              <Row className='mt-3 mb-2 align-items-end'>
                <Col lg={3} md={6} sm={12}>
                  <label className='text-uppercase' htmlFor='start'>
                    Bulan dan tahun
                  </label>
                  <input
                    type='text'
                    id='start'
                    ref={startDateRef}
                    className='form-control'
                    placeholder='Pilih bulan dan tahun'
                  />
                </Col>
                <Col lg={3} md={6} sm={12} className='mt-2 d-flex '>
                  <div className='flex align-items-end'>
                    <button
                      className='btn btn-primary bg-white text-primary'
                      type='button'
                      onClick={() => {
                        formRef.current?.reset()
                        setStartDate('')
                        setEndDate('')
                        setPage(0)
                        setStateIndicator((v) => !v)
                      }}
                    >
                      <i className='mdi mdi-restore' style={{fontSize: 15}}></i>
                    </button>
                    <button
                      className='ms-3 btn btn-primary'
                      style={{border: '1px solid #3F5F29'}}
                      type='submit'
                    >
                      Terapkan
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
          <div className='table-responsive'>
            <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
              <thead>
                <tr>
                  <th scope='col'>No</th>
                  <th scope='col'>Tanggal</th>
                  <th scope='col'>Total</th>
                  <th scope='col'>aksi</th>
                </tr>
              </thead>
              <tbody>{renderData()}</tbody>
            </Table>
          </div>
          <div className='p-2'>
            <MyPagination
              total={data?.total || 0}
              page={data?.offset || 0}
              perPage={data?.size || 10}
              onPageChange={(e) => {
                setPage(e.selected)
              }}
            />
          </div>
          <CardFooter className='d-flex justify-content-end'>
            <Link
              to={'/app/agreement-approval/detail/' + id}
              className='btn btn-outline-dark'
            >
              Kembali
            </Link>
          </CardFooter>
        </Card>
      </Container>
    </div>
  )
}

export default AgreementApprovalReceivables

import "./assets/scss/themes.scss";
import Routes from "./Routes";
import moment from "moment";
import { useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { onMessageListener } from "Service/Firebase";
import flatpickr from "flatpickr"
const Id = require("flatpickr/dist/l10n/id.js").default.id;
flatpickr.localize(Id); // default locale is now Id
var idLocale = require("moment/locale/id");

moment.locale("id", idLocale);

function App() {

  const routes = useRoutes(Routes);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retryOnMount: false,
      },
    },
  });

  onMessageListener()
  .then((payload) => {
    // setShow(true);
    // setNotification({
    //   title: payload.notification.title,
    //   body: payload.notification.body,
    // });
    console.log(payload);
  })
  .catch((err) => console.log("failed: ", err));


  return (
    <QueryClientProvider client={queryClient}>
      {routes}
      <ToastContainer />
    </QueryClientProvider>
  );
}

export default App;

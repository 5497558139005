import React from 'react'

const ImageTextPreview = ({link, text = 'Preview', className = ''}) => {
  return (
    <a
      className={'text-decoration-underline '+className}
      target={link ? '_blank' : ''}
      rel='noreferrer'
      href={link ? link : null}
    >
      {text}
    </a>
  )
}

export default ImageTextPreview

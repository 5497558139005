import BreadCrumb from 'Components/Common/BreadCrumb'
import {formatNumber} from 'Helpers/number'
import moment from 'moment'
import {Link, useParams} from 'react-router-dom'
import {
  Card,
  CardBody,
  Container,
  FormGroup,
  Input,
  Label,
  Table,
} from 'reactstrap'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import {useCallback, useEffect, useState} from 'react'
import {PAYMENT_METHODS} from 'Helpers/options'
import {useMutation, useQuery} from '@tanstack/react-query'
import {apiBearer} from 'Helpers/api'
import {DETAIL_AGREEMENT_UNPAID_URL, PAY_AGREEMENT} from 'Helpers/url_helper'
import {showError} from 'Helpers/show_toast'
import {toast} from 'react-toastify'

const PayAgreement = () => {
  const {id, date} = useParams()
  const [checkAll, setCheckAll] = useState(true)
  const [totalPayment, setTotalPayment] = useState(0)
  const [payload, setPayload] = useState({
    payment_method: null,
    invoices: [],
  })

  const {data:dataUnpaid, status, isFetching} = useQuery(['detail-unpaid', id], async()=>{
    const response = await apiBearer.get(DETAIL_AGREEMENT_UNPAID_URL(id, date))
    const {data} = response
    return data?.data
  },{
    enabled: !!id && !!date
  })

  useEffect(()=>{
    if(!isFetching && status === 'success'){
      setPayload((v)=>({...v, invoices : dataUnpaid?.invoices?.map((v)=>({...v, checked:true}))}))
    }
  },[status, isFetching, dataUnpaid?.invoices])

  const {invoices, payment_method} = payload

  const onCheckAll = useCallback(() => {
    setCheckAll(!checkAll)
    setPayload((prevValues) => ({
      ...prevValues,
      invoices: prevValues.invoices.map((invoice) => ({
        ...invoice,
        checked: !checkAll,
      })),
    }))
  }, [checkAll])

  const checkItem = useCallback((invoice_number) => {
    setPayload((prevValues) => ({
      ...prevValues,
      invoices: prevValues.invoices.map((invoice) =>
        invoice.invoice_number === invoice_number
          ? {...invoice, checked: !invoice.checked}
          : invoice,
      ),
    }))
  }, [])

  useEffect(() => {
    const checkedInvoices = invoices.filter((v) => v.checked)
    if (checkedInvoices.length === invoices.length) setCheckAll(true)
    if (checkedInvoices.length < invoices.length) setCheckAll(false)

    setTotalPayment(
      checkedInvoices.reduce((prev, curr) => prev + curr.amount, 0),
    )
  }, [invoices])

  const {isLoading, mutate} = useMutation({
    mutationFn: (newData) => {
      return apiBearer.post(PAY_AGREEMENT(id), newData)
    },
    onSuccess: async (response) => {
      const {data} = response
      window.open(data?.data?.url, '_self')
    },
    onError: async (error) => {
      toast.dismiss()
      showError('Transaksi gagal, silahkan coba lagi nanti.')
    },
  })

  const handlePay = () => {
    mutate({
      ...payload,
      invoices: invoices.map((invoice) => invoice.id),
    })
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Tagihan' />
        <Card>
          <CardBody>
            <h4 className='mb-4'>Bayar Tagihan</h4>
            <div className='table-responsive'>
              <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
                <thead style={{color: '#516377'}}>
                  <tr>
                    <th
                      className='w-50'
                      style={{background: '#F3F4F4'}}
                      scope='col'
                    >
                      <h4
                        className='p-3 mb-0 fw-medium'
                        style={{textTransform: 'none'}}
                      >
                        Detail Installment
                      </h4>
                    </th>
                    <th
                      className='w-50'
                      style={{background: '#F3F4F4'}}
                      scope='col'
                    >
                      <h4
                        className='p-3 mb-0 fw-medium'
                        style={{textTransform: 'none'}}
                      >
                        Metode Pembayaran
                      </h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className='w-50'>
                      <div className='p-4 d-flex' style={{color: '#677788'}}>
                        <div className='me-5'>
                          <h5>Total Pembayaran</h5>
                          <p className='lead'>
                            Rp. {formatNumber(totalPayment)}
                          </p>
                        </div>
                        <div className=''>
                          <h5>Tgl Jatuh Tempo</h5>
                          <p className='lead'>
                            {moment(dataUnpaid?.transaction_time).format('DD MMMM YYYY')}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className='w-50'>
                      <div className='p-4' style={{color: '#677788'}}>
                        <h5>Tgl Jatuh Tempo</h5>
                        <FormGroup check>
                          <Input
                            name='payment_method'
                            onChange={() =>
                              setPayload((prev) => ({
                                ...prev,
                                payment_method: PAYMENT_METHODS.TRANSFER,
                              }))
                            }
                            type='radio'
                            id='1'
                            className='me-3'
                          />
                          <Label check htmlFor='1' className='text-uppercase'>
                            Bank Transfer
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Input
                            name='payment_method'
                            onChange={() =>
                              setPayload((prev) => ({
                                ...prev,
                                payment_method: PAYMENT_METHODS.BALANCE,
                              }))
                            }
                            type='radio'
                            id='2'
                            className='me-3'
                          />
                          <Label check htmlFor='2' className='text-uppercase'>
                            Via Saldo
                          </Label>
                        </FormGroup>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
          <div className='px-3'>
            <h4 className='mb-2 mt-4'>List Transaksi</h4>
          </div>
          <div className='table-responsive'>
            <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
              <thead>
                <tr>
                  <th scope='col'>
                    <Input
                      type='checkbox'
                      checked={checkAll}
                      onChange={() => onCheckAll()}
                    />
                  </th>
                  <th scope='col'>No Invoice</th>
                  <th scope='col'>Jangka Waktu</th>
                  <th scope='col'>total Pembayaran</th>
                  <th scope='col'>cicilan</th>
                </tr>
              </thead>
              <tbody>
                {invoices.map((invoice) => {
                  const {
                    invoice_number,
                    termin,
                    amount,
                    termin_phase,
                    checked,
                  } = invoice || {}
                  return (
                    <tr key={invoice_number}>
                      <td>
                        <Input
                          type='checkbox'
                          checked={checked}
                          onChange={() => checkItem(invoice_number)}
                        />
                      </td>
                      <td>{invoice_number}</td>
                      <td>{termin} Bulan</td>
                      <td>Rp. {formatNumber(amount)}</td>
                      <td>
                        {termin_phase} dari {termin} bulan
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
          <div className='d-flex p-3 justify-content-between align-items-center'>
            <div className='' style={{color: '#677788'}}>
              <div className='d-flex align-items-center'>
                <h5 style={{minWidth: '12rem'}}>Total Pembayaran</h5>{' '}
                <span style={{minWidth: 10, fontWeight: 800}}>:</span>
                <p className='lead mb-0'>Rp. {formatNumber(totalPayment)}</p>
              </div>
              <div className='d-flex align-items-center'>
                <h5 style={{minWidth: '12rem'}}>Tgl Jatuh Tempo</h5>{' '}
                <span style={{minWidth: 10, fontWeight: 800}}>:</span>
                <p className='lead mb-0'>
                  {moment(dataUnpaid?.transaction_time).format('DD MMMM YYYY')}
                </p>
              </div>
            </div>
            <div className='d-flex'>
              <Link
                to={'/app/agreement/detail/' + id +'/tagihan'}
                className='btn btn-outline-dark me-2'
              >
                Kembali
              </Link>
              <button
                className='btn btn-primary'
                disabled={totalPayment < 1 || isLoading || !payment_method}
                onClick={() => handlePay()}
              >
                {isLoading ? 'Loading' : 'Bayar'}
              </button>
            </div>
          </div>
        </Card>
      </Container>
    </div>
  )
}

export default PayAgreement

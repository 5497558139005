import {yupResolver} from '@hookform/resolvers/yup'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {apiBearer} from 'Helpers/api'
import {formatNumber} from 'Helpers/number'
import {ADD_QUOTA_AGREEMENTE_APPROVAL_URL} from 'Helpers/url_helper'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import * as Yup from 'yup'

const AddQuotaModal = ({show, agreementData, onCloseClick, onSuccess}) => {
  const [loading, setLoading] = useState(false)
  const validation = Yup.object().shape({
    quota: Yup.string(),
  })

  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      quota: '',
    },
  }

  const {handleSubmit, formState, register, setError, watch, clearErrors} = useForm(formOptions)

  const {errors} = formState

  const onSubmitHandler = async (values) => {
    setLoading(true)
    try {
      await apiBearer.patch(
        ADD_QUOTA_AGREEMENTE_APPROVAL_URL(agreementData.id),
        values,
      )
      onCloseClick()
      onSuccess()
    } catch (error) {}
    setLoading(false)
  }

  const quota = watch('quota'); 

    useEffect(()=>{
      let showed = true
      if(showed) {
        const available = agreementData?.quota_total + Number(quota);
       clearErrors()
        if(agreementData?.quota_used > 0 && available < agreementData?.quota_used){
          setError('quota', {
            message:'Pengurangan kuota tidak boleh lebih dari tagihan/kuota terpakai'
          }, {shouldFocus:true})
        }
        if(!agreementData?.quota_used > 0 && available < 0){
          setError('quota', {
            message:'Pengurangan kuota tidak boleh lebih dari total kuota'
          }, {shouldFocus:true})
        }
      }
      return ()=>{
        showed = false
      }
    },[agreementData?.quota_total, agreementData?.quota_used, clearErrors, quota, setError])

  const getQuotaDetail = () => {
    if (!agreementData?.quota_total) return
    else
      return (
        <div className='mb-3' style={{color: '#677788'}}>
          <div className='d-flex'>
            <p className='fw-bold w-50 mb-0'>Total Kuota</p>
            <p className='mb-0'>
              : Rp. {formatNumber(agreementData?.quota_total)}
            </p>
          </div>
          <div className='d-flex'>
            <p className='fw-bold w-50 mb-0'>Kuota Terpakai</p>
            <p className='mb-0'>
              : Rp. {formatNumber(agreementData?.quota_used)}
            </p>
          </div>
          <div className='d-flex'>
            <p className='fw-bold w-50 mb-0'>Total Tersedia</p>
            <p className='mb-0'>
              : Rp. {formatNumber(agreementData?.quota_available)}
            </p>
          </div>
        </div>
      )
  }

  const renderTitle = () => {
    if(quota === '') return 'Tambah Kuota'
    if(Number(quota) < 0) return 'Kurangi Kuota'
    else return 'Tambah Kuota'
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader style={{color: '#516377'}}>{renderTitle()}</ModalHeader>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <ModalBody>
          {getQuotaDetail()}
          <ErrorFieldWrapper
            error={errors.quota}
            message={errors.quota?.message}
            className={'mb-3'}
          >
            <Label htmlFor='quota' className='form-label'>
              KUOTA
            </Label>
            <input
              {...register('quota')}
              className='form-control w-50'
              placeholder='Masukkan kuota'
            />
          </ErrorFieldWrapper>
          <hr className='mb-0' />
        </ModalBody>
        <ModalFooter>
          <Button color='light' onClick={onCloseClick}>
            Kembali
          </Button>
          <button className='btn btn-primary' type='submit' disabled={loading}>
            {loading ? 'Loading...' : 'Simpan'}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default AddQuotaModal

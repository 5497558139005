import React, { useState } from "react";
import { Container, Row } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import { useNavigate } from "react-router-dom";
import DeleteModal from "Components/Common/DeleteModal";
import { truckRQ } from "Hooks/Queries";
import withTable from "Components/Common/Table/withTable";
import { useQueryClient } from "@tanstack/react-query";
import { DATA_LIMIT } from "configs";
import { TruckItem } from "Components/TruckItem";

export default function ListTruck() {
  const [deleteTruckId, setDeleteTruckId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DATA_LIMIT);
  const [keyword, setKeyword] = useState(null);
  const { status, data, error, isFetching, refetch } =
    truckRQ.useGetDataList()(false, keyword, offset, limit);

  const useQuery = useQueryClient();
  const navigate = useNavigate();

  const onDeleteSuccess = (key) => {
    setDeleteModal(false);
    setDeleteTruckId(null);
    useQuery.invalidateQueries({ queryKey: [key] });
    refetch();
  };
  const { mutate: deleteTruck } = truckRQ.useDeleteData(onDeleteSuccess)();

  const onDeleteClick = (id) => {
    setDeleteModal(true);
    setDeleteTruckId(id);
  };

  const onDeleteConfirm = () => {
    deleteTruck(deleteTruckId);
  };

  const onAddButtonClicked = () => {
    navigate("/app/truck/data/create");
  };

  const TruckTable = withTable(
    TruckItem,
    "truck/data",
    [
      "NO",
      "Action",
      "kategori",
      "tipe",
      "kapasitas",
      "plat nomor",
      "Status",
    ],
    onDeleteClick,
    onAddButtonClicked
  );

  const onPageChange = (i) => {
    setOffset(i.selected);
  };

  const onPerPageChange = (i) => {
    setLimit(i);
    setOffset(0);
  };

  const onKeywordChange = (value) => {
    setKeyword(value);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Truck" pageTitle="Trucks" />

        <Row>
          <TruckTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            onPageChange={onPageChange}
            error={error}
            status={status}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal((v) => !v)}
        show={deleteModal}
        loading={false}
      />
    </div>
  );
}

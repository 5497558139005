import React,{ useState } from "react";
import {  Container, Row } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import {  useNavigate } from "react-router-dom";
import DeleteModal from "Components/Common/DeleteModal";
import { CdoRQ } from "Hooks/Queries";
import CdoItem from "Components/Cdo/Item";
import withTable from "Components/Common/Table/withTable";
import { useQueryClient } from "@tanstack/react-query";
import { DATA_LIMIT } from "configs";

export default function Cdo() {
  const [deleteCdoId, setDeleteCdoId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DATA_LIMIT);
  const [keyword, setKeyword] = useState(null);
  const {
    status,
    data,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = CdoRQ.useGetDataList()(false, keyword, offset, limit);

  const useQuery = useQueryClient();
  const navigate = useNavigate();

  const onDeleteSuccess = (key) => {
    setDeleteModal(false);
    setDeleteCdoId(null);
    useQuery.invalidateQueries({ queryKey: [key] });
    refetch();
  };
  const { mutate: deleteCdo } = CdoRQ.useDeleteData(onDeleteSuccess)();

  const onDeleteClick = (id) => {
    setDeleteModal(true);
    setDeleteCdoId(id);
  };

  const onDeleteConfirm = () => {
    deleteCdo(deleteCdoId);
  };

  const onAddButtonClicked = () => {
    navigate('/app/cdo/create')
  }

  const CdoTable = withTable(
    CdoItem,
    "cdo",
    ["NO", "Action", "AWB", "HAWB", "izin beacukai", "DO", "WAREHOUSE", "KODE PENERBANGAN", "TANGGAL DIBUAT", "STATUS PAYMENT", "STATUS VALIDASI"],
    onDeleteClick,
    onAddButtonClicked
  )

  const onPageChange = (i) => {
    setOffset(i.selected);
  }

  const onPerPageChange = (i) => {
    setLimit(i);
    setOffset(0);
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Cdo" pageTitle="Cdos" />

        <Row>
          <CdoTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            onPageChange={onPageChange}
            error={error}
            status={status}
            onPerPageChange={onPerPageChange}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal((v) => !v)}
        show={deleteModal}
        loading={false}
      />
    </div>
  );
}

import axios from "axios";
import { BASE_API_URL, BASIC_API_KEY } from "../configs";
import { load, removeLocalSDatas, save } from "./localStorage";
import { showError } from "./show_toast";


const apiwithtoken = axios.create({
  headers : {
    'api-key': `Basic ${BASIC_API_KEY}`,
    Authorization: `Bearer ${load('token')}`,
  },
  baseURL : BASE_API_URL
})

apiwithtoken.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const status = error.response?.status || 500;
  if(error.message === 'canceled') return Promise.reject(error)
  if(status === 401 || status === 403) {
    showError('Session expired, please sign in again');
    removeLocalSDatas()
    window.location.reload()
  }else if(status !== 404){
    let message = "Internal Server Error";
    if(error.message) message = error.message;
    if(error.response && error.response.data?.message) message = error.response.data?.message;
    showError(message)
  }
  
  return Promise.reject(error);
});

export default apiwithtoken
import {configureStore} from '@reduxjs/toolkit'
import authReducer from './reducers/auth'
import notificationReducer from './reducers/notification'

export default configureStore({
  reducer: {
    auth: authReducer,
    notification: notificationReducer
  },
})

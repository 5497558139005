import React, {useState, useEffect} from "react";
import { ShippingManagementRQ } from "Hooks/Queries";
import {  useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import { warehouseShipmentStatuses } from "Helpers/options";
import { getShipmentStatus, shipmentStatus } from "Components/ShippingManagement/helpers";
import moment from "moment";
import Select from "react-select";
import ConfirmModal from "Components/Common/ConfirmModal";
import { apiBearer } from "Helpers/api";
import { CHANGE_SHIPPING_MANAGEMENT_STATUS_API } from "Helpers/url_helper";
import { showError, showSuccess } from "Helpers/show_toast";


export default function DetailShippingManagement() {
  const { id } = useParams()
  const { data, status, isFetching, refetch } = ShippingManagementRQ.useGetData(id)()

  const [confirmModal, setConfirmModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState()

  useEffect(() => {
    if(!isFetching && status === "success"){
      setSelectedStatus(getShipmentStatus(data?.data?.shipment_status))
    }
  },[status, isFetching])

  const onStatusChanged = (e) => {
    setConfirmModal(true)
    setSelectedStatus(e)
  }

  const triggerChangeStatus = async() => {
    setLoading(true)
    const result = await apiBearer.patch(CHANGE_SHIPPING_MANAGEMENT_STATUS_API(data?.data?.id, selectedStatus.value))
    if(result?.data?.success) {
      refetch()
      showSuccess("Status Changed Successfully");
    } else {
      showError("Error while change status")
    }
    setLoading(false)
    setConfirmModal(false)
  }

  const triggerCancel = () => {
    setSelectedStatus(getShipmentStatus(data?.data?.shipment_status))
    setConfirmModal(false)
  }

  const renderLogs = () => {
    return data?.data?.log.map((l,i) => 
      <div className="accordion-item border-0" key={i}>
        <div className="accordion-header">
          <button className="accordion-button p-2 shadow-none" type="button" id="headingOne" >
            <div className="d-flex">
              <div className="flex-shrink-0">
                <i className="icon ri-user-smile-line avatar-xs rounded-circle ms-2"></i>
              </div>
              <div
                className="flex-grow-1 ms-3">
                <h6 className="fs-14 mb-1">
                    {l.status_name}
                </h6>
                <small className="text-muted">{l.notes}</small><br/>
                <small className="text-muted">{moment(l.date).format('YYYY-MM-DD HH:mm')}</small>
              </div>
            </div>
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Detail: Request Pickup" />
        <Card>
          <CardBody className="card-body">
            <h2 className="mb-3">Detail: Request Pickup</h2>
            <Row className="text-muted">
              <Col md={12}>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th style={{ width: "30%" }}>CDO</th>
                      <td>{data?.data?.cdo?.code ?? "-"}</td>
                    </tr>
                    <tr>
                      <th>Tanggal Request Pickup</th>
                      <td>{moment(data?.data?.date).format('YYYY-MM-DD') ?? "-"}</td>
                    </tr>
                    <tr>
                      <th>Nama Consignee</th>
                      <td>{data?.data?.customer?.name ?? "-"}</td>
                    </tr>
                    <tr>
                      <th>Alamat Consignee</th>
                      <td>{data?.data?.customer?.address ?? "-"}</td>
                    </tr>
                    <tr>
                      <th>Nomor Telp Consignee</th>
                      <td>{data?.data?.customer?.pic_phone ?? "-"}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>{data && shipmentStatus(data?.data?.shipment_status)}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>  
            <Row>
              <Col md={6}>
                <h2 className="mb-3">Detail Product</h2>
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <th style={{ maxWidth: "100%" }}>Jumlah</th>
                      <td>{data?.data?.cdo?.koli ?? "-"} Koli</td>
                    </tr>
                    <tr>
                      <th>Jenis / Nature of Goods</th>
                      <td>{data?.data?.cdo?.type_of_goods ?? "-"}</td>
                    </tr>
                    <tr>
                      <th>Berat</th>
                      <td>{data?.data?.cdo?.weight ?? "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </Col>
              <Col md={6}>
                <h2 className="mb-3">Status</h2>
                  <Select
                    options={warehouseShipmentStatuses(selectedStatus ? selectedStatus.value : null)}
                    isClearable={false}
                    isSearchable={false}
                    isOptionDisabled={(option) => option.disabled}
                    value={selectedStatus && selectedStatus}
                    onChange={(v) => {
                      onStatusChanged(v)
                    }}
                  />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <h2 className="mb-3">Trucking Status</h2>
                <div className="profile-timeline">
                  <div className="accordion accordion-flush" id="todayExample">
                    { renderLogs() }
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
      <ConfirmModal
        description="Yakin ingin mengubah status pickup ini?"
        loading={loading}
        onConfirm={triggerChangeStatus}
        onCloseClick={(v) => triggerCancel()}
        show={confirmModal}
      />
    </div>
  );
}

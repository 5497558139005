import {createSlice} from '@reduxjs/toolkit'
import {showError} from '../../Helpers/show_toast'
import {fetchStatuses, notifStatus} from '../constants'
import {
  deleteNotificationAction,
  getNotificationAction,
  readNotificationAction,
} from 'Redux/actions/notification'
import moment from 'moment'

const initialState = {
  status: fetchStatuses.idle,
  loading: false,
  unread: [],
  read: [],
  all: [],
}

const clientDateSort = (arr)=> arr.sort((a,b) => moment(b.date).format('YYYYMMDD') - moment(a.date).format('YYYYMMDD'))

export const notification = createSlice({
  name: 'notification',
  initialState,
  reducers:{
    setNotifStatusIddle : (state)=>{
        state.status = fetchStatuses.idle
    }
  },
  extraReducers(builder) {
    builder.addCase(getNotificationAction.pending, (state) => {
      state.status = fetchStatuses.pending
      state.loading = true
    })
    builder.addCase(getNotificationAction.fulfilled, (state, action) => {
      state.status = fetchStatuses.succeeded
      state.loading = false
      switch (action.payload.isRead) {
        case notifStatus.read:
          state.read = action.payload.data
          break
        case notifStatus.unread:
          state.unread = action.payload.data
          break
        default:
          state.all = action.payload.data
          break
      }
    })
    builder.addCase(getNotificationAction.rejected, (state, action) => {
      state.status = fetchStatuses.failed
      state.loading = false
    })
    builder.addCase(readNotificationAction.pending, (state) => {
      state.status = fetchStatuses.pending
      state.loading = true
    })
    builder.addCase(readNotificationAction.fulfilled, (state, action) => {
      state.status = fetchStatuses.succeeded
      state.loading = false
      if (action.payload.id) {
        const selectedUnred = state.unread.find(
          (unread) => unread?.id === action.payload.id,
        )
        state.read = clientDateSort([...state.read, {...selectedUnred, is_read:true}])
        state.unread = state.unread.filter(
          (unread) => unread?.id !== action.payload.id,
        )

        state.all = state.all.map((all) =>
          all?.id === action.payload.id ? {...all, is_read: true} : all,
        )
      } else {
        state.read = clientDateSort([
            ...state.read, 
            ...state.unread.map((v)=>({...v, is_read:true}))
        ])
        state.unread = []
        state.all = state.all.map((all) => ({...all, is_read: true}))
      }
    })
    builder.addCase(readNotificationAction.rejected, (state, action) => {
      state.loading = false
      state.status = fetchStatuses.failed
      showError(action.payload.message)
    })
    builder.addCase(deleteNotificationAction.pending, (state) => {
      state.status = fetchStatuses.pending
      state.loading = true
    })
    builder.addCase(deleteNotificationAction.fulfilled, (state, action) => {
      state.status = fetchStatuses.succeeded
      state.loading = false
      if (action.payload.id) {
        state.unread = state.unread.filter(
          (unread) => unread?.id !== action.payload.id,
        )
        state.read = state.read.filter((read) => read?.id !== action.payload.id)
        state.all = state.all.filter((all) => all?.id !== action.payload.id)
      } else {
        state.read = []
        state.unread = []
        state.all = []
      }
    })
    builder.addCase(deleteNotificationAction.rejected, (state, action) => {
      state.status = fetchStatuses.failed
      state.loading = false
    })
  },
})

export const {setNotifStatusIddle} = notification.actions

export default notification.reducer

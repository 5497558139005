import { APPROVED, FAILED, getBadgeClass, PAID, UNPAID, WAITING_APPROVAL } from "Helpers/options";
import React from "react";
import { Link } from "react-router-dom";


// const getBadgeClass()
export default function CdoItem({
  index,
  page,
  limit,
  table,
  id,
  onDeleteClick,
  awb,
  hawb,
  do_no,
  sppb,
  warehouse,
  flight_code,
  created_on,
  payment_status,
  validation_status,
}) {

  return (
    <tr>
      <td className="fw-medium">{index + page + 1}</td>
      <td>
        <div className="hstack gap-3 fs-15">
          <Link to={`/app/${table}/detail/${id}`} className="link-success">
            <i className="ri-eye-fill"></i>
          </Link>
          {payment_status === FAILED || (payment_status === PAID || validation_status === APPROVED) ? null : (
            <>
              <Link to={`/app/${table}/edit/${id}`} className="link-warning">
                <i className="ri-edit-2-line"></i>
              </Link>
              <Link
                to="#"
                className="link-danger"
                onClick={() => {
                  onDeleteClick(id);
                }}
              >
                <i className="ri-delete-bin-5-line"></i>
              </Link>
            </>
          )}
        </div>
      </td>
      <td>
        {awb ? (
          awb
        ) : (
          "-"
        )}
      </td>
      <td>
        {hawb ? (
          hawb
        ) : (
          "-"
        )}
      </td>
      <td>
        {sppb ? (
          sppb
        ) : (
          "-"
        )}
      </td>
      <td>
        {do_no ? (
          do_no
        ) : (
          "-"
        )}
      </td>
      <td>{warehouse}</td>
      <td>{flight_code}</td>
      <td>{created_on}</td>
      <td>
          <span className={getBadgeClass(payment_status)}>{payment_status}</span>
      </td>

      <td>
        <span className={getBadgeClass(validation_status)}>
          {validation_status}
        </span>
      </td>
    </tr>
  );
}

import {yupResolver} from '@hookform/resolvers/yup'
import {useQuery} from '@tanstack/react-query'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import ImageTextPreview from 'Components/Common/ImageTextPreview'
import {BASE_API_URL} from 'configs'
import flatpickr from 'flatpickr'
import {apiBearer} from 'Helpers/api'
import {CUSTOMER, WAREHOUSE} from 'Helpers/options'
import { validaName, validName, validNumbers } from 'Helpers/regex_validation'
import {showSuccess} from 'Helpers/show_toast'
import {
  LIST_BANKS_URL,
  LIST_CITIES_URL,
  LIST_DISTRICTS_URL,
  LIST_PROVINCES_URL,
  LIST_VILLAGES_URL,
  MY_PROFILE,
} from 'Helpers/url_helper'
import {useEffect, useRef, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import Select from 'react-select'
import {Col, Input, Label, Row} from 'reactstrap'
import {logoutAction} from 'Redux/actions/auth'
import {setProfile} from 'Redux/reducers/auth'
import * as Yup from 'yup'

export default function PersonalDetail({
  is_approved,
  dataProfile,
  isFetching,
  status,
  error,
  refetch
}) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [province, setProvince] = useState(null)
  const [provinceOptions, setProvinceOptions] = useState([])
  const [city, setCity] = useState(null)
  const [cityOptions, setCityOptions] = useState([])
  const [district, setDistrict] = useState(null)
  const [districtOptions, setDistrictOptions] = useState([])
  const [village, setVillage] = useState(null)
  const [villageOptions, setVillageOptions] = useState([])
  const [bank, setBank] = useState(null)
  const [bankOptions, setBankOptions] = useState([])
  const [gender, setGender] = useState(null)
  const [genderOptions, setGenderOptions] = useState([
    {label: 'Male', value: 'MALE'},
    {label: 'Female', value: 'FEMALE'},
  ])
  const dobRef = useRef()

  const validation = Yup.object().shape({
    code: Yup.string().test('test-required', 'Code harus diisi', (value) =>
      dataProfile?.role !== WAREHOUSE ? true : value,
    )
    .test('test-valid-code', 'Code hanya boleh berisi hurung dan angka', (v)=> dataProfile?.role !== WAREHOUSE ? true : /^[a-zA-Z0-9]+$/.test(v)),
    name: Yup.string()
      .required('Full Name harus diisi')
      .min(3, 'Full Name minimal terdiri dari 3 karakter')
      .test('test-valid-name', 'Full Name hanya boleh berisi huruf', (v)=> validName.test(v)),
    email: Yup.string().required('Email harus diisi').strict(true),
    bank_account: Yup.string().required(),
    bank_name: Yup.string().required(),
    phone: Yup.string().required('Phone harus diisi').
      matches(validNumbers, {message:'Phone hanya boleh berisi angka'}),
    gender: Yup.string().required('Gender harus diisi').strict(true),
    dob: Yup.mixed(),
    address: Yup.string().required('Address harus diisi'),
    province: Yup.string().required('Province harus diisi'),
    city: Yup.string().required('City harus diisi'),
    district: Yup.string().required('District harus diisi'),
    village: Yup.string().required('Village harus diisi'),
    zip_code: Yup.string().required('Zip code harus diisi').matches(validNumbers, {message:'Zip code hanya boleh berisi angka'}),
    akta_pendirian: Yup.mixed(),
    npwp: Yup.mixed(),
    ktp: Yup.mixed(),
    logo: Yup.mixed()
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      code: '',
      name: '',
      email: '',
      picName: '',
      phone: '',
      address: '',
      bank_account: '',
      bank_name: '',
      province: '',
      city: '',
      district: '',
      village: '',
      zip_code: '',
      dob: '',
      akta_pendirian: '',
      gender: '',
      logo: ''
    },
  }
  const {handleSubmit, formState, control, reset, setValue} =
    useForm(formOptions)
  const {errors} = formState

  useEffect(() => {
    if (dobRef.current) {
      flatpickr(dobRef.current, {
        dateFormat: 'Y-m-d',
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setValue('dob', sDate)
        },
      })
    }
  }, [])

  const fetchProvinces = async (input) => {
    const result = await apiBearer.get(LIST_PROVINCES_URL + '?keyword=' + input)
    const {data} = result
    if (data.success) {
      setProvinceOptions(data.data.map((v) => ({label: v.name, value: v.id})))
    }
  }

  const onProvinceSelected = async (e) => {
    setProvince(e)
    if (e && e.value) {
      const result = await apiBearer.get(LIST_CITIES_URL(e.value))
      const {data} = result
      if (data.success) {
        setCityOptions(data.data.map((v) => ({label: v.name, value: v.id})))
      }
    } else {
      setCityOptions([])
      setDistrictOptions([])
      setVillageOptions([])
      setCity(null)
      setDistrict(null)
      setVillage(null)
    }
  }

  const onCitySelected = async (e) => {
    setCity(e)
    if (e) {
      const result = await apiBearer.get(LIST_DISTRICTS_URL(e.value))
      const {data} = result
      if (data.success) {
        setDistrictOptions(data.data.map((v) => ({label: v.name, value: v.id})))
      }
    } else {
      setDistrictOptions([])
      setDistrict(null)
    }
  }

  const onDistrictSelected = async (e) => {
    setDistrict(e)
    if (e) {
      const result = await apiBearer.get(LIST_VILLAGES_URL(e.value))
      const {data} = result
      if (data.success) {
        setVillageOptions(data.data.map((v) => ({label: v.name, value: v.id})))
      }
    } else {
      setVillageOptions([])
      setVillage(null)
    }
  }

  const onSubmit = async (values) => {
    const formData = new FormData()
    const data = JSON.stringify({
      code: values.code,
      name: values.name,
      pic_email: values.email,
      bank_account: values.bank_account,
      bank_name: values.bank_name,
      pic_phone: values.phone,
      gender: values.gender,
      date_of_birth: values.dob,
      // date_of_birth: values.dob,
      address: values.address,
      province_id: values.province,
      city_id: values.city,
      district_id: values.district,
      village_id: values.village,
      zip_code: values.zip_code,
    })
    formData.append('data', data)
    formData.append('aktaPendirian', values.akta_pendirian)
    formData.append('npwp', values.npwp)
    formData.append('ktp', values.ktp)
    formData.append('logo', values.logo)
    setLoading(true)
    try {
      await apiBearer.patch(BASE_API_URL + '/profile/update', formData)
      showSuccess('Profile is successfully updated')
      refetch()
    } catch (error) {
      // showError(
      //   error.response?.data?.message ??
      //     "Something wrong, please check the input"
      // );
      console.log(error.response)
    }
    setLoading(false)
  }


  useEffect(() => {
    if (!isFetching && status === 'success') {
      if (dataProfile.partner?.gender) {
        setGender(
          genderOptions.find(
            (opt) => opt.value === dataProfile.partner?.gender,
          ),
        )
      }

      if (dataProfile.partner?.province) {
        onProvinceSelected({
          label: dataProfile.partner?.province?.name,
          value: dataProfile.partner?.province?.id,
        })
      }
      if (dataProfile.partner?.city) {
        onCitySelected({
          label: dataProfile.partner?.city?.name,
          value: dataProfile.partner?.city?.id,
        })
      }
      if (dataProfile.partner?.district) {
        onDistrictSelected({
          label: dataProfile.partner?.district?.name,
          value: dataProfile.partner?.district?.id,
        })
      }
      if (dataProfile.partner?.village) {
        setVillage({
          label: dataProfile.partner.village.name,
          value: dataProfile.partner.village.id,
        })
      }

      reset({
        code: dataProfile.partner?.code ?? '',
        name: dataProfile?.name ?? '',
        email: dataProfile?.email ?? '',
        picName: dataProfile.partner?.pic_name ?? '',
        phone: dataProfile.partner?.pic_phone ?? '',
        address: dataProfile.partner?.address ?? '',
        bank_account: dataProfile.partner?.bank_account ?? '',
        bank_name: dataProfile.partner?.bank_name ?? '',
        province: dataProfile.partner?.province?.id ?? '',
        city: dataProfile.partner?.city?.id ?? '',
        district: dataProfile.partner?.district?.id ?? '',
        village: dataProfile.partner?.village?.id ?? '',
        zip_code: dataProfile.partner?.zip_code ?? '',
        dob: dataProfile.partner?.date_of_birth ?? '',
        gender: dataProfile.partner?.gender,
      })
      if (dobRef.current) {
        dobRef.current.value = dataProfile.partner?.date_of_birth ?? new Date()
      }
      const dataForRedux = Object.assign({}, dataProfile)
      delete dataForRedux.partner
      dispatch(setProfile(dataForRedux))
    }
    if (!isFetching && status === 'error') {
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching])

  const fetchBanks = async () => {
    const result = await apiBearer.get(LIST_BANKS_URL)
    const {data} = result
    if (data.success) {
      setBankOptions(data.data.map((v) => ({label: v.name, value: v.code})))
      if (dataProfile?.partner?.bank_name) {
        const selectedBank = data.data.find(
          (opt) => opt.code === dataProfile.partner.bank_name,
        )
        if (selectedBank) {
          setBank({label: selectedBank.name, value: selectedBank.code})
        }
      }
    }
  }

  useEffect(() => {
    fetchProvinces()
    fetchBanks()
  }, [])

  useEffect(() => {
    if (dataProfile?.partner?.bank_name && bankOptions.length > 0) {
      const selectedBank = bankOptions.find(
        (v) => v.value === dataProfile.dpartnerbank_name,
      )
      if (selectedBank) setBank(selectedBank)
    }
  }, [dataProfile?.partner?.bank_name, bankOptions])


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        {dataProfile?.role === WAREHOUSE && (
          <>
            <Col lg={3} md={6} sm={12}>
              <div className={`mb-3 ${errors.code ? 'error-form' : ''}`}>
                <label htmlFor='code' className='form-label'>
                  Code
                </label>
                <div className='position-relative auth-pass-inputgroup '>
                  <Controller
                    id='code'
                    name='code'
                    type='text'
                    control={control}
                    render={({field}) => <Input {...field} value={field.value} />}
                  />
                </div>
                <div className='error-form-info badge badge-soft-danger'>
                  <img
                    alt=''
                    src='/assets/icons/triangle-alert-fill.svg'
                    className='me-1'
                  />{' '}
                  {errors.code?.message}
                </div>
              </div>
            </Col>
            <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper
            error={errors.logo}
            message={errors.logo?.message}
          >
            <div className='d-flex justify-content-between align-items-center'>
              <Label htmlFor='logo' className='form-label'>
                Logo
              </Label>
              {dataProfile?.partner?.logo ? (
                <ImageTextPreview link={dataProfile.partner.logo} />
              ) : null}
            </div>
            <Controller
              id='logo'
              name='logo'
              control={control}
              render={({field}) => (
                <Input
                  className='form-control'
                  type='file'
                  onChange={(e) =>
                    field.onChange({
                      target: {value: e.target.files[0], name: field.name},
                    })
                  }
                />
              )}
            />
          </ErrorFieldWrapper>
        </Col>
          </>
        )}
        <Col lg={3} md={6} sm={12}>
          <div className={`mb-3 ${errors.name ? 'error-form' : ''}`}>
            <label htmlFor='name' className='form-label'>
              {dataProfile?.role === CUSTOMER || dataProfile?.role === WAREHOUSE
                ? 'Company Name'
                : 'Full Name'}
            </label>
            <div className='position-relative auth-pass-inputgroup '>
              <Controller
                id='name'
                name='name'
                type='text'
                control={control}
                render={({field}) => <Input {...field} value={field.value} />}
              />
            </div>
            <div className='error-form-info badge badge-soft-danger'>
              <img
                alt=''
                src='/assets/icons/triangle-alert-fill.svg'
                className='me-1'
              />{' '}
              {errors.name?.message}
            </div>
          </div>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <div className={`mb-3 ${errors.email ? 'error-form' : ''}`}>
            <label htmlFor='email' className='form-label'>
              Email
            </label>
            <div className='position-relative auth-pass-inputgroup'>
              <Controller
                id='email'
                name='email'
                control={control}
                render={({field}) => <Input {...field} value={field.value} />}
              />
              <div
                className='btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted'
                type='button'
              >
                <i className='ri-mail-line align-middle '></i>
              </div>
            </div>
            <div className='error-form-info badge badge-soft-danger'>
              <img
                alt=''
                src='/assets/icons/triangle-alert-fill.svg'
                className='me-1'
              />{' '}
              {errors.email?.message}
            </div>
          </div>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper
            error={errors.bank_account}
            message={errors.bank_account?.message}
          >
            <Label htmlFor='bank_account' className='form-label'>
              Bank Account
            </Label>
            <Controller
              id='bank_account'
              name='bank_account'
              type='bank_account'
              control={control}
              render={({field}) => <Input {...field} value={field.value} />}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper
            error={errors.bank_name}
            message={errors.bank_name?.message}
          >
            <Label htmlFor='bank' className='form-label'>
              Bank
            </Label>
            <Controller
              id='bank_name'
              name='bank_name'
              control={control}
              render={({field: {onChange, ...field}}) => (
                <Select
                  {...field}
                  cacheOptions
                  isClearable
                  defaultOptions
                  value={bank ?? ''}
                  options={bankOptions}
                  onChange={(e) => {
                    if (e?.value) onChange(e.value)
                    setBank(e)
                  }}
                  placeholder='Search by name'
                />
              )}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper
            error={errors.phone}
            message={errors.phone?.message}
          >
            <Label htmlFor='phone' className='form-label'>
              Phone
            </Label>
            <Controller
              id='phone'
              name='phone'
              type='phone'
              control={control}
              render={({field}) => <Input {...field} value={field.value} />}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper
            className='mb-3'
            error={errors.gender}
            message={errors.gender?.message}
          >
            <Label htmlFor='gender' className='form-label'>
              Gender
            </Label>
            <Controller
              id='gender'
              name='gender'
              type='text'
              control={control}
              render={({field: {onChange, ...field}}) => (
                <Select
                  {...field}
                  cacheOptions
                  isClearable
                  defaultOptions
                  value={gender ?? ''}
                  options={genderOptions}
                  onChange={(e) => {
                    if (e?.value) onChange(e.value)
                    setGender(e)
                  }}
                />
              )}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper error={errors.dob} message={errors.dob?.message}>
            <Label htmlFor='dob' className='form-label'>
              DOB
            </Label>
            <Controller
              id='dob'
              name='dob'
              control={control}
              render={({field}) => (
                <input ref={dobRef} className='form-control' type='text' />
              )}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper
            error={errors.address}
            message={errors.address?.message}
          >
            <Label htmlFor='address' className='form-label'>
              Address
            </Label>
            <Controller
              id='address'
              name='address'
              type='address'
              control={control}
              render={({field}) => <Input {...field} value={field.value} />}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper
            className='mb-3'
            error={errors.province}
            message={errors.province?.message}
          >
            <Label htmlFor='province' className='form-label'>
              Province
            </Label>
            <Controller
              id='province'
              name='province'
              type='text'
              control={control}
              render={({field: {onChange, ...field}}) => (
                <Select
                  {...field}
                  cacheOptions
                  isClearable
                  defaultOptions
                  value={province ?? ''}
                  options={provinceOptions}
                  onChange={(e) => {
                    if (e?.value) onChange(e.value)
                    onProvinceSelected(e)
                  }}
                  placeholder='Search by name'
                />
              )}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper error={errors.city} message={errors.city?.message}>
            <Label htmlFor='city' className='form-label'>
              City
            </Label>
            <Controller
              id='city'
              name='city'
              type='text'
              control={control}
              render={({field: {onChange, ...field}}) => (
                <Select
                  {...field}
                  cacheOptions
                  isClearable
                  defaultOptions
                  value={city ?? ''}
                  options={cityOptions}
                  onChange={(e) => {
                    if (e?.value) onChange(e.value)
                    onCitySelected(e)
                  }}
                  placeholder='Search by name'
                />
              )}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper
            className='mb-3'
            error={errors.district}
            message={errors.district?.message}
          >
            <Label htmlFor='district' className='form-label'>
              District
            </Label>
            <Controller
              id='district'
              name='district'
              type='text'
              control={control}
              render={({field: {onChange, ...field}}) => (
                <Select
                  {...field}
                  cacheOptions
                  isClearable
                  defaultOptions
                  value={district ?? ''}
                  options={districtOptions}
                  onChange={(e) => {
                    if (e?.value) onChange(e.value)
                    onDistrictSelected(e)
                  }}
                  placeholder='Search by name'
                />
              )}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper
            className='mb-3'
            error={errors.village}
            message={errors.village?.message}
          >
            <Label htmlFor='village' className='form-label'>
              Village
            </Label>
            <Controller
              id='village'
              name='village'
              type='text'
              control={control}
              render={({field: {onChange, ...field}}) => (
                <Select
                  {...field}
                  cacheOptions
                  isClearable
                  defaultOptions
                  value={village ?? ''}
                  options={villageOptions}
                  onChange={(e) => {
                    if (e?.value) onChange(e.value)
                    setVillage(e)
                  }}
                  placeholder='Search by name'
                />
              )}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper
            className='mb-3'
            error={errors.zip_code}
            message={errors.zip_code?.message}
          >
            <Label htmlFor='zip_code' className='form-label'>
              Zip Code
            </Label>
            <Controller
              id='zip_code'
              name='zip_code'
              type='zip_code'
              control={control}
              render={({field}) => <Input {...field} value={field.value} />}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper
            error={errors.akta_pendirian}
            message={errors.akta_pendirian?.message}
          >
            <div className='d-flex justify-content-between align-items-center'>
              <Label htmlFor='akta_pendirian' className='form-label'>
                Akta Pendirian
              </Label>
              {dataProfile?.partner?.akta_pendirian ? (
                <ImageTextPreview link={dataProfile.partner.akta_pendirian} />
              ) : null}
            </div>
            <Controller
              id='akta_pendirian'
              name='akta_pendirian'
              control={control}
              render={({field}) => (
                <Input
                  className='form-control'
                  type='file'
                  onChange={(e) =>
                    field.onChange({
                      target: {value: e.target.files[0], name: field.name},
                    })
                  }
                />
              )}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper error={errors.npwp} message={errors.npwp?.message}>
            <div className='d-flex justify-content-between align-items-center'>
              <Label htmlFor='npwp' className='form-label'>
                Npwp
              </Label>
              {dataProfile?.partner?.npwp ? (
                <ImageTextPreview link={dataProfile.partner.npwp} />
              ) : null}
            </div>
            <Controller
              id='npwp'
              name='npwp'
              control={control}
              render={({field}) => (
                <Input
                  className='form-control'
                  type='file'
                  onChange={(e) =>
                    field.onChange({
                      target: {value: e.target.files[0], name: field.name},
                    })
                  }
                />
              )}
            />
          </ErrorFieldWrapper>
        </Col>
        <Col lg={3} md={6} sm={12}>
          <ErrorFieldWrapper error={errors.ktp} message={errors.ktp?.message}>
            <div className='d-flex justify-content-between align-items-center'>
              <Label htmlFor='ktp' className='form-label'>
                KTP
              </Label>
              {dataProfile?.partner?.ktp ? (
                <ImageTextPreview link={dataProfile.partner.ktp} />
              ) : null}
            </div>
            <Controller
              id='ktp'
              name='ktp'
              control={control}
              render={({field}) => (
                <Input
                  className='form-control'
                  type='file'
                  onChange={(e) =>
                    field.onChange({
                      target: {value: e.target.files[0], name: field.name},
                    })
                  }
                />
              )}
            />
          </ErrorFieldWrapper>
        </Col>
      </Row>
      <div className='mt-4 align-items-center d-flex justify-content-end gap-3'>
        <button color='success' className='btn btn-success' type='submit' disabled={loading}>
          {loading ? 'Loading...' : 'Update'}
        </button>
        {!is_approved ? (
          <button
            className='btn btn-outline-dark'
            onClick={() => dispatch(logoutAction())}
          >
            Logout
          </button>
        ) : null}
      </div>
    </form>
  )
}

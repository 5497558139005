export default function ImageWithLink({url, width = 100, height = 100}) {
  return (
    <img
      className='d-block mb-2 border img-fluid'
      style={{cursor: 'pointer'}}
      src={url}
      alt='file'
      width={width}
      height={height}
      onClick={(e) => (url ? window.open(url, '_blank') : '')}
    />
  )
}

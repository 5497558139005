import { CREATE_CDO_URL, DELETE_CDO_URL, LIST_CDO_URL, UPDATE_CDO_URL, VIEW_CDO_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'cdo';
const REDIRECT_URL = '/app/cdo';

export const CdoRQ = new RQ(
  QUERY_KEY, 
  LIST_CDO_URL, 
  VIEW_CDO_URL, 
  CREATE_CDO_URL, 
  UPDATE_CDO_URL, 
  DELETE_CDO_URL, 
  REDIRECT_URL
);
import BreadCrumb from 'Components/Common/BreadCrumb'
import ImageTextPreview from 'Components/Common/ImageTextPreview'
import { formatNumber } from 'Helpers/number'
import { APPROVED, PAYMENT_METHODS, UNPAID } from 'Helpers/options'
import { truckBookingRQ } from 'Hooks/Queries'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Card, CardBody, CardFooter, Container, Row } from 'reactstrap'

export default function DetailTruckBooking() {
  const {id} = useParams()
  const {data, status, isFetching} = truckBookingRQ.useGetData(id)()
  const [total, setTotal] = useState(0)
  const {price, final_price, additional_costs, ppn} =
    data?.data?.price_detail || {}

  useEffect(() => {
    if (!isFetching && status === 'success') {
      let total = price
      additional_costs?.map((v) => {
        total += v.value || 0
      })
      setTotal(total)
    }
  }, [status, isFetching])

  const isPaymentActive =
    data?.data?.payment_status === UNPAID &&
    data?.data?.payment_method === PAYMENT_METHODS.TRANSFER

  const isManual = data?.data?.booking_truck_type  === 'BOOKING_MANUAL'

  const cdoNum = isManual ? data?.data?.cdo_num : data?.data?.cdo?.code 

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Truck Booking Detail' />
        <Card>
          <CardBody className='card-body'>
            <h2 className='mb-3'>INV #{data?.data?.code ?? '-'}</h2>
            <Row className='text-muted'>
              <table className='table table-bordered'>
                <thead>
                  <tr>
                    <th style={{background: 'rgb(243, 246, 249)'}} colSpan={2}>
                      General
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th style={{maxWidth: '20%'}}>CDO</th>
                    <td>{cdoNum ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Nama Pengirim</th>
                    <td>{data?.data?.sender_name ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Nomor Pengirim</th>
                    <td>{data?.data?.sender_phone ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Alamat Pengambilan Barang</th>
                    <td>{data?.data?.sender_address ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Nama Penerima</th>
                    <td>{data?.data?.receiver_name ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Nomor Penerima</th>
                    <td>{data?.data?.receiver_phone ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Alamat Penerima</th>
                    <td>{data?.data?.receiver_address ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{background: 'rgb(243, 246, 249)'}} colSpan={2}>
                      Detail Order Trucking
                    </th>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Asal</th>
                    <td>{data?.data?.origin_name ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Tujuan</th>
                    <td>{data?.data?.destination_name ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Vendor</th>
                    <td>{data?.data?.trucking?.name ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Jenis Kendaraan</th>
                    <td>{data?.data?.truck_category?.name ?? '-'}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Tipe Kendaraan</th>
                    <td>{data?.data?.truck_type?.name ?? '-'}</td>
                  </tr>
                  {isManual ? (
                    <>
                      <tr>
                        <th style={{background: 'rgb(243, 246, 249)'}} colSpan={2}>
                          Detail Barang dan Intruksi
                        </th>
                      </tr>
                      <tr>
                        <th style={{maxWidth: '20%'}}>Barang</th>
                        <td>{data?.data?.goods_detail ?? '-'}</td>
                      </tr>
                      <tr>
                        <th style={{maxWidth: '20%'}}>Instruksi</th>
                        <td>{data?.data?.instruction ?? '-'}</td>
                      </tr>
                    </>
                    ):null}
                  <tr>
                    <th style={{background: 'rgb(243, 246, 249)'}} colSpan={2}>
                      Detail Pembayaran
                    </th>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Biaya Trucking</th>
                    <td>Rp. {formatNumber(price)}</td>
                  </tr>
                  {additional_costs?.map((p, i) => (
                    <tr key={i}>
                      <th style={{maxWidth: '20%'}}>{p.name}</th>
                      <td>Rp. {formatNumber(p.value)}</td>
                    </tr>
                  ))}
                  <tr>
                    <th style={{maxWidth: '20%'}}>Total</th>
                    <td className='fw-bold'>Rp. {formatNumber(total)}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>PPN</th>
                    <td>Rp. {formatNumber(ppn?.value)}</td>
                  </tr>
                  <tr>
                    <th style={{maxWidth: '20%'}}>Total Pembayaran</th>
                    <td className='fw-bold'>Rp. {formatNumber(final_price)}</td>
                  </tr>
                  {isManual ? (
                    <>
                      <tr>
                        <th style={{background: 'rgb(243, 246, 249)'}} colSpan={2}>
                          Dokumen
                        </th>
                      </tr>
                      {
                        data?.data?.files.map(({name, file}, i)=>(
                          <tr key={i}>
                            <th style={{maxWidth: '20%'}}>{name}</th>
                            <td><ImageTextPreview link={file}/></td>
                          </tr> 
                        ))
                      }
                    </>
                    ):null}
                </tbody>
              </table>
              <div className='table-responsive table-card '></div>
            </Row>
          </CardBody>
          <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
            <Link to='/app/truck-book/booking' className='btn btn-outline-dark'>
              Kembali
            </Link>
            {
              data?.data?.driver_validation_status === APPROVED && data?.data?.last_log_status !== 'DONE' ? (
                <Link
                  to={`/app/truck-book/booking/detail/${id}/map`}
                  className='btn btn-warning'
                >
                  Maps
                </Link>
              ):null
            }
            {isPaymentActive ? (
              <button
                className='btn btn-primary'
                type='button'
                onClick={() => window.open(data?.data?.payment_url, '_blank')}
              >
                Bayar
              </button>
            ) : (
              <button
                className='btn btn-outline-dark'
                type='button'
                disabled={true}
              >
                {data?.data?.payment_status}
              </button>
            )}
          </CardFooter>
        </Card>
      </Container>
    </div>
  )
}

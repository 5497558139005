import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import { CdoRQ } from "Hooks/Queries";
import { APPROVED, UNPAID } from "Helpers/options";
import SimpleBar from "simplebar-react";

export default function DetailCDO(props) {
  const { id } = useParams();
  const { data } = CdoRQ.useGetData(id)();

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Cdo" />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={function (e) {
                e.preventDefault();
              }}
            >
              <Card>
                <CardHeader>
                  <div className="d-flex justify-content-between align-items-center">
                    <h4 className="">CDO Request</h4>
                    {data?.data?.qr_url ? (
                      <img
                        src={data?.data?.qr_url}
                        height={100}
                        width={100}
                        alt=""
                      />
                    ) : (
                      <div
                        style={{
                          height: 100,
                          width: 135,
                          border: "1px gray solid",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 10,
                          marginLeft: 15,
                        }}
                      >
                        <span>Image not found</span>
                      </div>
                    )}
                  </div>
                </CardHeader>
                <CardBody className="card-body">
                  <Row className="text-muted">
                    <div className="table-responsive table-card ">
                      <SimpleBar
                        style={{
                          maxHeight: "365px",
                        }}
                      >
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">Company Name :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.customer_name ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6 text-uppercase">Awb :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.awb ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6 text-uppercase">Hawb :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.hawb ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">Izin Beacukai :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.sppb ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6 text-uppercase">Do :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.do_no ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">Nama Pengirim:</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.sender_name ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">Nama Penerima :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.receiver_name ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">
                                Alamat Penerima :
                              </div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.receiver_address ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">Nomor Penerima :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.receiver_phone ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">
                                Agent / Forwader :
                              </div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.forwarder ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">Koli :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.koli ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">Berat :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.weight ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">Jenis Barang :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.type_of_goods ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">
                                Kode Penerbangan :
                              </div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.flight_code ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">
                                Tanggal Penerbangan :
                              </div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.flight_date ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">
                                Tanggal Kuitansi :
                              </div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.invoice_date ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">Nomor Kuitansi :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.invoice_no ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">
                                Jumlah Dana Kuitansi :
                              </div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.invoice_amount ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">Metode Pembayaran :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.payment_method ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6 text-uppercase">Pu/POS :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.pu ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">CAW :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.data?.caw ?? "-"}</h6>
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">AWB File :</div>
                              <div className="ms-3 col-3">
                                { data?.data?.awb_file ?
                                  <a
                                    className="text-decoration-underline"
                                    target={data?.data?.awb_file ? "_blank" : ""}
                                    rel="noreferrer"
                                    href={
                                      data?.data?.awb_file
                                        ? data?.data?.awb_file
                                        : "#"
                                    }
                                  >
                                    Preview
                                  </a>
                                  : '-'
                                }
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">HAWB File :</div>
                              <div className="ms-3 col-3">
                                { data?.data?.hawb_file ?
                                  <a
                                    className="text-decoration-underline"
                                    target={data?.data?.hawb_file ? "_blank" : ""}
                                    rel="noreferrer"
                                    href={
                                      data?.data?.hawb_file
                                        ? data?.data?.hawb_file
                                        : "#"
                                    }
                                  >
                                    Preview
                                  </a>
                                  : '-'
                                }
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">Izin Beacukai :</div>
                              <div className="ms-3 col-3">
                                { data?.data?.sppb_file ?
                                  <a
                                    className="text-decoration-underline"
                                    target={data?.data?.sppb_file ? "_blank" : ""}
                                    rel="noreferrer"
                                    href={
                                      data?.data?.sppb_file
                                        ? data?.data?.sppb_file
                                        : "#"
                                    }
                                  >
                                    Preview
                                  </a>
                                  : '-'
                                }
                              </div>
                            </div>
                          </li>
                          <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">DO File :</div>
                              <div className="ms-3 col-3">
                                { data?.data?.do_file ?
                                  <a
                                    className="text-decoration-underline"
                                    target={data?.data?.do_file ? "_blank" : ""}
                                    rel="noreferrer"
                                    href={
                                      data?.data?.do_file
                                        ? data?.data?.do_file
                                        : "#"
                                    }
                                  >
                                    Preview
                                  </a>
                                  : '-'
                                }
                              </div>
                            </div>
                          </li>
                        </ul>
                      </SimpleBar>
                    </div>
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to="/app/cdo" className="btn btn-danger">
                    Cancel
                  </Link>
                  <Button
                    color="success"
                    type="submit"
                    onClick={() =>
                      window.open(data?.data?.url_payment, "_blank")
                    }
                    disabled={data?.data?.payment_status !== UNPAID}
                  >
                    {data?.data?.payment_status === UNPAID
                      ? "Pay"
                      : data?.data?.payment_status}
                  </Button>
                  {data?.data?.validation_status === APPROVED ? (
                    <Link
                      to={`/app/cdo/print/${id}`}
                      className="btn btn-success"
                    >
                      Document
                    </Link>
                  ) : null}
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import { useQuery } from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import MyPagination from 'Components/Common/Table/Pagination'
import { logStatus } from 'Components/TruckingOrder/helpers'
import { apiBearer } from 'Helpers/api'
import debounce from 'Helpers/debounce'
import { formatNumber } from 'Helpers/number'
import {
  ADMIN_REPORT_BOOKING_TRUCK, ADMIN_REPORT_BOOKING_TRUCK_EXPORT, LIST_TRUCKING_URL, PARTNER_LIST
} from 'Helpers/url_helper'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import {
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown
} from 'reactstrap'

export const getPaymentStatus = (status) => {
  switch(status) {
    case 'FAILED':
      return <span className="badge badge-soft-danger">Gagal / Expired</span>;
    case 'PAID':
      return <span className="badge badge-soft-success">Sudah Dibayar</span>;
    case 'UNPAID':
      return <span className="badge badge-soft-dark">Menunggu Pembayaran</span>;
  }
}

export const getOrderStatus = (status) => {
  switch(status) {
    case 'WAITING_APPROVAL':
      return <span className="badge badge-soft-dark">Pending</span>;
    case 'APPROVED':
      return <span className="badge badge-soft-success">Approved</span>;
    case 'REJECTED':
      return <span className="badge badge-soft-danger">Rejected</span>;
    case 'DONE':
      return <span className="badge badge-soft-success">Done</span>;
    default:
      return <span className="badge badge-soft-dark">{status}</span>;
  }
}

const BookingTruckReport = () => {
  const [code, setCode] = useState('')
  const [customerId, setCustomerId] = useState('')
  const [selectedCustomerId, setselectedCustomerId] = useState('')
  const [isFetched, setIsFetched] = useState(false)
  const [stateIndicator, setStateIndicator] = useState(false)
  const [truckingId, setTruckingId] = useState(null)
  const [selectedTrucking, setSelectedTrucking] = useState(null)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const formRef = useRef()

  const {data, isFetching, status, refetch} = useQuery(
    ['report-booking-truck', page, size],
    async () => {
      const params = {
        truckingId,
        customerId,
        code
      }
      setIsFetched(true)
      const res = await apiBearer.get(ADMIN_REPORT_BOOKING_TRUCK, {params})
      return res.data?.data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      enabled: isFetched,
      retryOnMount: false,
      retry: false,
    },
  )

  //handle refetch when form change and reset button clicked
  useEffect(() => {
    if (isFetched) refetch()
  }, [isFetched, refetch, stateIndicator])

  const {
    isFetching: isFetchingExport,
    refetch: refetchExport,
  } = useQuery(
    ['report-booking-truck-export', page, size],
    async () => {
      const params = {
        truckingId,
        customerId,
        code
      }
      const res = await apiBearer.get(ADMIN_REPORT_BOOKING_TRUCK_EXPORT, {
        params,
        responseType: 'blob',
      })
      const href = URL.createObjectURL(res.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `Report Booking Truck.xlsx`) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      enabled: false,
    },
  )

  const onSubmitSearch = (e) => {
    e.preventDefault()
    if(page === 0) refetch()
    else setPage(0)
  }

  const theadStyles = {
    textAlign: 'center',
    verticalAlign: 'middle',
  }


  const renderData = () => {
    if (status === 'error')
      return (
        <tr>
          <td colSpan={7} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    if (isFetching)
      return (
        <tr>
          <td colSpan={7} className='text-center'>
            <Spinner color='success' />
          </td>
        </tr>
      )
    if (!data?.data || data?.data.length < 1)
      return (
        <tr>
          <td colSpan={7} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    return data?.data?.map((props, i) => {
      return (
        <tr key={i}>
          <td>{i + data?.offset + 1}</td>
          <td>
            <div className='hstack gap-3 fs-15'>
              <Link 
                to={`/app/laporan-transaksi/booking-truck/detail/${props.id}`} 
                className='link-success'
              >
                <i className='ri-eye-fill'></i>
              </Link>
            </div>
          </td>
          <td>{props.code}</td>
          <td>{props.consignee_name}</td>
          <td>{props.trucking_name}</td>
          <td>{props.origin_name ? props.origin_name : '-'}</td>
          <td>{props.destination_name ? props.destination_name : '-' }</td>
          <td>Rp. {formatNumber(props.price)}</td>
          <td>{getPaymentStatus(props.payment_status)}</td>
          <td>{getOrderStatus(props.order_status)}</td>
          <td>{logStatus(props.shipment_status)}</td>

        </tr>
      )
    })
  }

  const searchUser = async (input, isEmail = false) => {
    return new Promise(async (res, rej) => {
      try {
        const result = await apiBearer.get(
          `${PARTNER_LIST}?search=${input}&size=10&partnerType=CUSTOMER`,
        )
        const {data} = result
        if (data.success) {
          res( 
            isEmail ? 
            data?.data?.data?.map((v) => ({label: v?.email, value: v?.email}))
            :
            data?.data?.data?.map((v) => ({label: v?.name, value: v?.id})))
        }
        rej(data.message)
      } catch (error) {
        rej(error)
      }
    })
  }

  const debounceSearchUser = debounce((input, cb) => {
    searchUser(input).then((op) => cb(op))
  }, 1500)

  const searchTrucking = async (input) => {
    return new Promise(async (res, rej) => {
      try {
        const result = await apiBearer.get(
          `${LIST_TRUCKING_URL}?page=0&size=10&search=${input}`,
        )
        const {data} = result
        if (data.success) {
          res(data?.data?.data?.map((v) => ({label: v.name, value: v.id})))
        }
        rej(data.message)
      } catch (error) {
        rej(error)
      }
    })
  }

  const debounceSearchTrucking = debounce((input, cb) => {
    searchTrucking(input).then((op) => cb(op))
  }, 1500)

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Laporan Booking Truck' />
        <Card>
          <CardBody>
            <h4>Laporan Booking Truck</h4>
            <div style={{border: '1px solid #3F5F29'}} className='rounded mt-2'>
              <div className='bg-primary px-3 py-3'>
                <h6 className='text-white '>Search</h6>
              </div>
              <form onSubmit={(onSubmitSearch)} ref={formRef}>
                <Row className='px-3 mt-3 mb-2 align-items-end'>
                  <Col lg={4} md={6} sm={12}>
                    <label className='text-uppercase' htmlFor='method'>Kode Booking</label>
                    <input className='form-control' onChange={e=> setCode(e.target.value)} value={code}/>
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                    <label className='text-uppercase' htmlFor='consignee'>Consignee</label>
                      <AsyncSelect
                        defaultOptions
                        isClearable
                        value={selectedCustomerId ?? ''}
                        loadOptions={debounceSearchUser}
                        onChange={(e) => {
                          setCustomerId(e?.value ?? '')
                          setselectedCustomerId(e)
                        }}
                        placeholder={'Search Consignee'}
                      />
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                    <label className='text-uppercase' htmlFor='vendor'>
                      Vendor
                    </label>
                    <AsyncSelect
                      id='vendor'
                      cacheOptions
                      isClearable
                      defaultOptions
                      value={selectedTrucking ?? ''}
                      loadOptions={debounceSearchTrucking}
                      onChange={(e) => {
                        setTruckingId(e?.value ?? '')
                        setSelectedTrucking(e)
                      }}
                      placeholder='Search Vendor'
                    />
                  </Col>
                  <Col sm={12} className='mt-2 d-flex justify-content-end'>
                    <div className='flex align-items-end'>
                      <button
                        className='btn btn-primary'
                        type='button'
                        onClick={() => {
                          formRef.current?.reset()
                          setselectedCustomerId(null)
                          setCustomerId('')
                          setSelectedTrucking(null)
                          setTruckingId('')
                          setCode('')
                          setPage(0)
                          setStateIndicator((v) => !v)
                        }}
                      >
                        <i
                          className='mdi mdi-restore'
                          style={{fontSize: 15}}
                        ></i>
                      </button>
                      <button
                        className='ms-3 btn btn-primary bg-white text-primary'
                        style={{border: '1px solid #3F5F29'}}
                        type='submit'
                      >
                        Search
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
            <Row className='mt-3'>
              <Col lg={12} md={12} xs={12} className='mb-2'>
                <div className='d-flex'>
                  <div className='flex-grow-1'>
                    Show&nbsp;
                    <ButtonGroup>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag='button'
                          className='btn btn-sm btn-primary'
                        >
                          {data?.perPage}
                          <i className='mdi mdi-chevron-down'></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => setSize(10)}>
                            10
                          </DropdownItem>
                          <DropdownItem onClick={() => setSize(25)}>
                            25
                          </DropdownItem>
                          <DropdownItem onClick={() => setSize(50)}>
                            50
                          </DropdownItem>
                          <DropdownItem onClick={() => setSize(100)}>
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </ButtonGroup>
                    &nbsp;entries
                  </div>
                  <div className='flex-shrink-0'>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary waves-effect waves-light fw-bold d-flex align-items-center'
                      onClick={refetchExport}
                    >
                      <i
                        className={
                          isFetchingExport
                            ? 'mdi mdi-loading mdi-spin mdi-18px me-2'
                            : 'mdi mdi-printer mdi-18px me-2'
                        }
                      ></i>{' '}
                      Export
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <div className='table-responsive'>
              <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
                <thead>
                  <tr>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      No
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Aksi
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Kode Booking
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Consignee
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Vendor
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Asal
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Tujuan
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Total <br/> Pembayaran
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Status <br/> Pembayaran
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Status <br/> Order
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Status <br/> Pengiriman
                    </th>
                  </tr>
                </thead>
                <tbody>{renderData()}</tbody>
              </Table>
            </div>
            <MyPagination
              total={data?.total || 0}
              page={data?.offset || 0}
              perPage={data?.size || 10}
              onPageChange={(e) => {
                setPage(e.selected)
              }}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default BookingTruckReport

import { CREATE_AGREEMENT_URL, DELETE_AGREEMENT_URL, LIST_AGREEMENT_URL, UPDATE_AGREEMENT_URL, VIEW_AGREEMENT_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'agreements';
const REDIRECT_URL = '/app/agreement';

export const AgreementRQ = new RQ(
  QUERY_KEY, 
  LIST_AGREEMENT_URL, 
  VIEW_AGREEMENT_URL, 
  CREATE_AGREEMENT_URL, 
  UPDATE_AGREEMENT_URL, 
  DELETE_AGREEMENT_URL, 
  REDIRECT_URL
);
import {useQuery} from '@tanstack/react-query'
import CdoRequestItem from 'Components/CdoRequest/Item'
import BreadCrumb from 'Components/Common/BreadCrumb'
import withTable from 'Components/Common/Table/withTable'
import {DATA_LIMIT} from 'configs'
import {apiBearer} from 'Helpers/api'
import {LIST_CDO_REQUEST_URL} from 'Helpers/url_helper'
import {useState} from 'react'
import {Container, Row} from 'reactstrap'

export default function CdoRequest() {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(DATA_LIMIT)
  const [keyword, setKeyword] = useState(null)

  const {status, data, error, isFetching} = useQuery(
    ['cdo-request', keyword, offset, limit],
    async () => {
      const params = {
        page: offset,
        size: limit,
        search: keyword,
      }
      const res = await apiBearer.get(LIST_CDO_REQUEST_URL, {params})
      return res.data?.data
    },
  )

  const CdoRequestTable = withTable(CdoRequestItem, 'request-cdo', [
    'NO',
    'Aksi',
    'AWB',
    'HAWB',
    'DO',
    'SPPB',
    'TANGGAL DIBUAT',
    'STATUS PAYMENT',
    'STATUS VALIDASI',
  ])

  const onPageChange = (i) => {
    setOffset(i.selected)
  }

  const onPerPageChange = (i) => {
    setLimit(i)
    setOffset(0)
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='List Request CDO' pageTitle='CdoRequests' />

        <Row>
          <CdoRequestTable
            data={data}
            isFetching={isFetching}
            loading={isFetching}
            onPageChange={onPageChange}
            error={error}
            status={status}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
    </div>
  )
}

import { useQuery } from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import MyPagination from 'Components/Common/Table/Pagination'
import flatpickr from 'flatpickr'
import { apiBearer } from 'Helpers/api'
import debounce from 'Helpers/debounce'
import { formatNumber } from 'Helpers/number'
import {
  ADMIN_REPORT_TRUCK_COMMISSION,
  ADMIN_REPORT_TRUCK_COMMISSION_EXPORT,
  LIST_TRUCKING_URL
} from 'Helpers/url_helper'
import { useEffect, useRef, useState } from 'react'
import AsyncSelect from 'react-select/async'
import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown
} from 'reactstrap'

const CommissionTruckReport = () => {
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [isFetched, setIsFetched] = useState(false)
  const [stateIndicator, setStateIndicator] = useState(false)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [truckingId, setTruckingId] = useState(null)
  const [truckingId1, setTruckingId1] = useState(null)
  const [selectedTrucking, setSelectedTrucking] = useState(null)

  const startDateRef = useRef()
  const endDateRef = useRef()
  const invRef = useRef()
  const formRef = useRef()

  useEffect(() => {
    if (startDateRef.current) {
      flatpickr(startDateRef.current, {
        dateFormat: 'Y-m-d',
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setStartDate(sDate)
        },
      })
    }
  }, [])

  useEffect(() => {
    if (endDateRef.current) {
      flatpickr(endDateRef.current, {
        dateFormat: 'Y-m-d',
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setEndDate(sDate)
        },
      })
    }
  }, [])

  const {data, isFetching, status, refetch} = useQuery(
    ['report-truck-commission', page, size],
    async () => {
      const params = {
        page,
        size,
        startDate,
        endDate,
        invoice: invRef.current?.value,
        truckingId,
      }
      setIsFetched(true)
      const res = await apiBearer.get(ADMIN_REPORT_TRUCK_COMMISSION, {params})
      return res.data?.data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      enabled: isFetched,
      retryOnMount: false,
      retry: false,
    },
  )

  //handle refetch when form change and reset button clicked
  useEffect(() => {
    if (isFetched) refetch()
  }, [isFetched, refetch, stateIndicator])

  const {isFetching: isFetchingExport, refetch: refetchExport} = useQuery(
    ['report-truck-commission-export', page, size],
    async () => {
      const params = {
        startDate,
        endDate,
        invoice: invRef.current?.value,
        truckingId,
      }
      const res = await apiBearer.get(ADMIN_REPORT_TRUCK_COMMISSION_EXPORT, {
        params,
        responseType: 'blob',
      })
      const href = URL.createObjectURL(res.data)

      // create "a" HTML element with href to file & click
      const range =
        startDate && endDate ? ` ${startDate ?? ''} -  ${endDate ?? ''}` : ''
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `Report Komisi Trucking${range}.xlsx`) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      enabled: false,
    },
  )

  const searchTrucking = async (input) => {
    return new Promise(async (res, rej) => {
      try {
        const result = await apiBearer.get(
          `${LIST_TRUCKING_URL}?page=0&size=10&search=${input}`,
        )
        const {data} = result
        if (data.success) {
          res(data?.data?.data?.map((v) => ({label: v.name, value: v.id})))
        }
        rej(data.message)
      } catch (error) {
        rej(error)
      }
    })
  }

  const debounceSearchTrucking = debounce((input, cb) => {
    searchTrucking(input).then((op) => cb(op))
  }, 1500)
  const {report_data} = data || {}

  const onSubmitSearch = (e) => {
    setTruckingId1(truckingId)
    e.preventDefault()
    if (page === 0) refetch()
    else setPage(0)
  }

  const theadStyles = {
    textAlign: 'center',
    verticalAlign: 'middle',
  }

  const colCounts =
    10 +
    (data?.sum_commissions.length || 0) +
    (data?.sum_additional_costs.length || 0)

  const renderData = () => {
    if (status === 'error')
      return (
        <tr>
          <td colSpan={colCounts} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    if (isFetching)
      return (
        <tr>
          <td colSpan={colCounts} className='text-center'>
            <Spinner color='success' />
          </td>
        </tr>
      )
    if (status === 'loading' && !isFetching) {
      return (
        <tr>
          <td colSpan={colCounts} className='text-center'>
            <span className='text-danger'>Search to show data</span>
          </td>
        </tr>
      )
    }
    if (!report_data?.data || report_data?.data.length < 1)
      return (
        <tr>
          <td colSpan={colCounts} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    return report_data?.data?.map((props, i) => {
      const {cost_items, commission_items} = props

      const additionalCostValues = data?.sum_additional_costs.map((v) => {
        const findAddCost = cost_items?.find(
          (cost_item) => cost_item.additional_cost_id === v.additionalCostId,
        )
        if (findAddCost) return `Rp. ${formatNumber(findAddCost.final_cost)}`
        else return '-'
      })

      const commissionValues = data?.sum_commissions.map((v) => {
        const findComCost = commission_items?.find(
          (com_item) =>
            com_item.commission_distribute_id === v.commissionDistributeId,
        )
        if (findComCost) return `Rp. ${formatNumber(findComCost.commission)}`
        else return '-'
      })

      return (
        <tr key={i}>
          <td>{i + report_data?.offset + 1}</td>
          <td>{props.created_on}</td>
          <td>{props.invoice_no}</td>
          <td>{props.consignee_name}</td>
          <td>{props.trucking_name}</td>
          {truckingId1 ? (
            <>
              {commissionValues.map((v, i) => (
                <td key={i}>{v}</td>
              ))}
              {additionalCostValues.map((v, i) => (
                <td key={i}>{v}</td>
              ))}
            </>
          )
          :null}
          <td>Rp. {formatNumber(props.ppn_price)}</td>
          <td>Rp. {formatNumber(props.price)}</td>
          <td>{props.payment_method}</td>
          <td>{props.payment_status}</td>
        </tr>
      )
    })
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Komisi Trucking' />
        <Row>
          <Col md={6} sm={12}>
            <Card>
              <CardHeader>
                <h5 className='fw-semibold mb-0'>Total Komisi</h5>
              </CardHeader>
              <CardBody style={{height:150, overflowY: data?.sum_commissions?.length > 6 ? 'auto' : 'hidden'}}>
                <Row className='mt-4'>
                  {data?.sum_commissions?.map((v, i) => (
                    <Col key={i} md={4} sm={12}>
                      <h6 className='fw-semibold mb-2'>
                        {v.commissionDistributeName}
                      </h6>
                      <p style={{color: '#677788'}}>
                        Rp. {formatNumber(v.sumCommission)}
                      </p>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col md={6} sm={12}>
            <Card>
              <CardHeader>
                <h5 className='fw-semibold mb-0'>Total Biaya</h5>
              </CardHeader>
              <CardBody style={{height:150, overflowY: data?.sum_commissions?.length > 5 ? 'auto' : 'hidden'}}>
                <Row className='mt-4'>
                  <Col md={4} sm={12}>
                    <h6 className='fw-semibold mb-2'>PPN</h6>
                    <p style={{color: '#677788'}}>
                      Rp. {formatNumber(data?.sum_ppn_price)}
                    </p>
                  </Col>
                  {data?.sum_additional_costs?.map((v, i) => (
                    <Col key={i} md={4} sm={12}>
                      <h6 className='fw-semibold mb-2'>
                        {v.additionalCostName}
                      </h6>
                      <p style={{color: '#677788'}}>
                        Rp. {formatNumber(parseInt(v.sumAdditionalCost))}
                      </p>
                    </Col>
                  ))}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card>
          <CardBody>
            <h4>Laporan Komisi Truking</h4>
            <div style={{border: '1px solid #3F5F29'}} className='rounded mt-2'>
              <div className='bg-primary px-3 py-3'>
                <h6 className='text-white '>Search</h6>
              </div>
              <form onSubmit={onSubmitSearch} ref={formRef}>
                <Row className='px-3 mt-3 mb-2 align-items-end'>
                  <Col lg={3} md={6} sm={12}>
                    <label htmlFor='start'>Start Date</label>
                    <input
                      type='text'
                      id='start'
                      ref={startDateRef}
                      className='form-control'
                      placeholder='YYYY-mm-dd'
                    />
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <label htmlFor='end'>End Date</label>
                    <input
                      type='text'
                      id='end'
                      ref={endDateRef}
                      className='form-control'
                      placeholder='YYYY-mm-dd'
                    />
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <label htmlFor='end'>No Invoice</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='type here'
                      ref={invRef}
                    />
                  </Col>
                  <Col lg={3} md={6} sm={12}>
                    <label className='text-uppercase' htmlFor='vendor'>
                      vendor
                    </label>
                    <AsyncSelect
                      id='vendor'
                      cacheOptions
                      isClearable
                      defaultOptions
                      value={selectedTrucking ?? ''}
                      loadOptions={debounceSearchTrucking}
                      onChange={(e) => {
                        setTruckingId(e?.value ?? '')
                        setSelectedTrucking(e)
                      }}
                    />
                  </Col>
                  <Col sm={12} className='mt-2 d-flex justify-content-end'>
                    <div className='flex align-items-end'>
                      <button
                        className='btn btn-primary'
                        type='button'
                        onClick={() => {
                          formRef.current?.reset()
                          setStartDate('')
                          setEndDate('')
                          setSelectedTrucking(null)
                          setTruckingId('')
                          setTruckingId1('')
                          setPage(0)
                          setStateIndicator((v) => !v)
                        }}
                      >
                        <i
                          className='mdi mdi-restore'
                          style={{fontSize: 15}}
                        ></i>
                      </button>
                      <button
                        className='ms-3 btn btn-primary bg-white text-primary'
                        style={{border: '1px solid #3F5F29'}}
                        type='submit'
                      >
                        Search
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
            <Row className='mt-3'>
              <Col lg={12} md={12} xs={12} className='mb-2'>
                <div className='d-flex'>
                  <div className='flex-grow-1'>
                    Show&nbsp;
                    <ButtonGroup>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag='button'
                          className='btn btn-sm btn-primary'
                        >
                          {data?.perPage}
                          <i className='mdi mdi-chevron-down'></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => setSize(10)}>
                            10
                          </DropdownItem>
                          <DropdownItem onClick={() => setSize(25)}>
                            25
                          </DropdownItem>
                          <DropdownItem onClick={() => setSize(50)}>
                            50
                          </DropdownItem>
                          <DropdownItem onClick={() => setSize(100)}>
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </ButtonGroup>
                    &nbsp;entries
                  </div>
                  <div className='flex-shrink-0'>
                    <button
                      className='btn btn-sm btn-primary waves-effect waves-light fw-bold d-flex align-items-center'
                      onClick={refetchExport}
                    >
                      <i
                        className={
                          isFetchingExport
                            ? 'mdi mdi-loading mdi-spin mdi-18px me-2'
                            : 'mdi mdi-printer mdi-18px me-2'
                        }
                      ></i>{' '}
                      Export
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <div className='table-responsive'>
              <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
                <thead>
                  <tr>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      No
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Tanggal
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      No Inv
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Consignee
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Vendor
                    </th>
                    {truckingId1 ? (
                      <>
                        <th
                          scope='col'
                          colSpan={data?.sum_commissions.length}
                          style={theadStyles}
                        >
                          Komisi
                        </th>
                        
                      </>
                    ) : null}
                    <th 
                        scope='col'
                        colSpan={truckingId1 ? data?.sum_additional_costs.length + 1 : 1}
                        style={theadStyles}
                      >
                        Biaya
                      </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Total Biaya
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Payment method
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Status
                    </th>
                  </tr>
                  <tr>
                    {truckingId1 ? (
                      data?.sum_commissions?.map((v, i) => (
                        <th scope='col' key={i} style={theadStyles}>
                          {v.commissionDistributeName}
                        </th>
                      ))
                    ):null}
                    {truckingId1 ? ( 
                        data?.sum_additional_costs?.map((v, i) => (
                        <th scope='col' key={i} style={theadStyles}>
                          {v.additionalCostName}
                        </th>))
                    ):null}
                    <th scope='col' style={theadStyles}>
                      PPN
                    </th>
                  </tr>
                </thead>
                <tbody>{renderData()}</tbody>
              </Table>
            </div>
            <MyPagination
              total={report_data?.total || 0}
              page={report_data?.offset || 0}
              perPage={report_data?.size || 10}
              onPageChange={(e) => {
                setPage(e.selected)
              }}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default CommissionTruckReport

import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorFieldWrapper from "Components/Common/ErrorFieldWrapper";
import { AgreementRQ } from "Hooks/Queries";
import { Controller, useForm } from "react-hook-form";
import { apiBearer } from "Helpers/api";
import { ALL_TRUCKING_URL } from "Helpers/url_helper";
import Select from "react-select";
import { toBase64 } from "Helpers/fileToBase64";
import { useSelector } from "react-redux";
import ImageTextPreview from "Components/Common/ImageTextPreview";

let idd = 0;
const initialDocs = [
  {
    id: idd++,
    doc: "",
  },
];
export default function EditCreateAgreement(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.auth);

  const [vendorOptions, setVendorOptions] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const agreementNoRef = useRef();
  const [docs, setDocs] = useState(initialDocs);

  const fetchVendor = async () => {
    try {
      const result = await apiBearer.get(ALL_TRUCKING_URL);
      const { data } = result;
      if (data.success) {
        setVendorOptions(
          data?.data?.map((v) => ({ label: v?.name, value: v?.id }))
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchVendor();
  }, []);

  const validation = Yup.object().shape({
    docs: Yup.mixed(),
    trucking_id: Yup.string().required("Vendor tidak boleh kosong"),
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      docs: [],
      start_date: "",
      end_date: "",
      trucking_id: "",
    },
  };

  const onChangeDocs = async (id, file) => {
    try {
      const base64File = await toBase64(file);
      setDocs((prevDocs) =>
        prevDocs.map((prevDoc) => {
          if (prevDoc.id === id) {
            return { ...prevDoc, doc: base64File };
          }
          return prevDoc;
        })
      );
    } catch (error) {
      const fileEl = document.getElementById(id);
      if (fileEl) {
        fileEl.value = [];
      }
    }
  };

  const onAddDocInputs = () => {
    setDocs([
      ...docs,
      {
        id: idd++,
        doc: "",
      },
    ]);
  };

  const onRemoveDocInputs = (id) => {
    setDocs((prevs) => prevs.filter((prev) => prev.id !== id));
  };

  const { handleSubmit, formState, reset, control } = useForm(formOptions);
  const { errors } = formState;

  const getPayload = (values, isCreate = false) => {
    const formData = values;
    formData.customer_id = profile.id;
    formData.docs = docs;
    delete formData.start_date;
    delete formData.end_date;
    if (isCreate) {
      formData.docs = docs.map((v) => {
        v.id = null;
        return v;
      });
    } else {
      formData.docs = docs.map((v) => (
        typeof v.id === "number" 
          ? 
          ({...v, id:null}) 
          : 
          ({
            ...v, 
            doc: v.doc.includes('http') ? null : v.doc 
          })
        ));
    }
    return formData;
  };

  const { mutate: updateAgreement, isLoading: updateLoading } = AgreementRQ.useUpdateData(navigate)();

  const _updateAgreement = (values) => {
    const body = getPayload(values);
    body.id = id;
    updateAgreement(body);
  };

  const { mutate: addAgreement, isLoading: createLoading } = AgreementRQ.useAddData(navigate)();

  const _createAgreement = async (values) => {
    addAgreement(getPayload(values, true));
  };

  const { data, error, isFetching, status } = AgreementRQ.useGetData(id)();

  useEffect(() => {
    if (!isFetching && status === "success") {
      if (agreementNoRef.current)
        agreementNoRef.current.value = data?.data?.code;
      reset({
        start_date: data.data?.start_date ?? "",
        end_date: data.data?.end_date ?? "",
        trucking_id: data?.data?.trucking_id ?? "",
      });
      if (data?.data?.docs) setDocs(data?.data?.docs);
      if (data?.data?.trucking_partner) {
        setSelectedVendor({
          label: data?.data?.trucking_partner.name,
          value: data?.data?.trucking_partner.id,
        });
      }
    }
    if (!isFetching && status === "error") {
      console.error(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Agreement" pageTitle="Agreement" />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={handleSubmit(id ? _updateAgreement : _createAgreement)}
            >
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {id ? "Edit" : "Create"} Agreement
                  </h4>
                </CardHeader>
                <CardBody className="card-body">
                  <Row className="gy-4">
                    <Col lg={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.trucking_id}
                        message={errors.trucking_id?.message}
                      >
                        <Label>
                          Vendor Trucking <span className="mandatory">*</span>
                        </Label>
                        <Controller
                          id="trucking_id"
                          name="trucking_id"
                          type="text"
                          control={control}
                          render={({ field: { onChange, ...field } }) => (
                            <Select
                              {...field}
                              isClearable
                              value={selectedVendor ?? ""}
                              options={vendorOptions}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value);
                                setSelectedVendor(e);
                              }}
                              placeholder="Search by name"
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                  <h2 className="mt-4">Attachment</h2>
                  <hr />
                  <Row>
                    {docs.map((doc, i) => (
                      <Col lg={6} sm={12} key={doc.id}>
                        <div>
                          <div className="d-flex justify-content-between">
                            <Label>
                              Dokumen Pendukung{" "}
                              <span className="mandatory">*</span>
                            </Label>
                            {doc?.doc && typeof doc?.id !== "number" ? (
                              <ImageTextPreview link={doc.doc} />
                            ) : null}
                          </div>
                          <div className="mb-3 d-flex align-items-end">
                            <input
                              type="file"
                              className="form-control"
                              onChange={(e) =>
                                onChangeDocs(doc.id, e.target.files[0])
                              }
                            />
                            {i >= 1 ? (
                              <button
                                className="btn btn-sm mx-1 py-0 btn-danger"
                                onClick={() => onRemoveDocInputs(doc.id)}
                                type="button"
                              >
                                <i className="ri-subtract-fill "></i>
                              </button>
                            ) : null}
                            <button
                              className="btn btn-sm mx-1 py-0 btn-success"
                              onClick={onAddDocInputs}
                              type="button"
                            >
                              <i className="ri-add-fill "></i>
                            </button>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to="/app/agreement" className="btn btn-soft-dark">
                    Cancel
                  </Link>
                  <button className="btn btn-primary" type="submit" disabled={updateLoading || createLoading}>
                    {updateLoading || createLoading ? 'Loading...' : (id ? "Update" : "Create")}
                  </button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

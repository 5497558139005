import { formatNumber } from "Helpers/number";
import React from "react";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";

const headers = ["Kategori", "tipe", "harga"];

export const RateListModal = ({ show, onCloseClick, items = [] }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader>
        <h4>Detail Rate Trucking</h4>
      </ModalHeader>
      <ModalBody style={{ minHeight: "50vh" }}>
        <div className="table-responsive">
          <Table className="table-hover table-bordered align-middle table-nowrap mb-0">
            <thead>
              <tr>
                {headers.map((v, i) => (
                  <th key={v} scope="col">
                    {v}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {items.map((v) => (
                <tr key={v}>
                  <td>{v.truck_category?.name ?? "-"}</td>
                  <td>{v.truck_type?.name ?? "-"}</td>
                  <td>RP {formatNumber(v.price ?? 0)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </ModalBody>
    </Modal>
  );
};

import { useQuery } from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import MyPagination from 'Components/Common/Table/Pagination'
import { apiBearer } from 'Helpers/api'
import { formatNumber } from 'Helpers/number'
import { ADMIN_REPORT_SALDO_DETAIL } from 'Helpers/url_helper'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown
} from 'reactstrap'

const theadStyles = {
  textAlign: 'center',
  verticalAlign: 'middle',
}
const DetailSaldoReport = () => {
  const {id} = useParams()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const {data, error, isFetching, status, refetch} = useQuery(
    ['report-saldo', id, page, size],
    async () => {
      const params = {
        page,
        size,
      }
      const res = await apiBearer.get(ADMIN_REPORT_SALDO_DETAIL(id), {params})
      return res.data?.data
    },
    {
      enabled: !!id,
      retryOnMount: false,
      retry: false,
    },
  )

  const {partner_address, partner_email, partner_name, report_data} = data || {}

  // TODO
  const renderData = () => {
    if (status === 'error')
      return (
        <tr>
          <td colSpan={6} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    if (isFetching)
      return (
        <tr>
          <td colSpan={6} className='text-center'>
            <Spinner color='success' />
          </td>
        </tr>
      )
    if (status === 'loading' && !isFetching) {
      return (
        <tr>
          <td colSpan={6} className='text-center'>
            <span className='text-danger'>Search to show data</span>
          </td>
        </tr>
      )
    }
    if (!report_data?.data || report_data?.data.length < 1)
      return (
        <tr>
          <td colSpan={6} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    return report_data?.data?.map((props, i) => {
      return (
        <tr key={i}>
          <td>{i + report_data?.offset + 1}</td>
          <td>{props.date}</td>
          <td>{props.notes}</td>
          <td>Rp. {formatNumber(props.debit)}</td>
          <td>Rp. {formatNumber(props.credit)}</td>
          <td>Rp. {formatNumber(props.saldo)}</td>
        </tr>
      )
    })
  }
  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Detail Laporan Saldo' />
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className='mt-4'>
                  <Col md={4} sm={6}>
                    <h6 className='fw-semibold mb-2'>Nama Mitra</h6>
                    <p style={{color: '#677788'}}>
                      {partner_name ? partner_name : '-'}
                    </p>
                  </Col>
                  <Col md={4} sm={6}>
                    <h6 className='fw-semibold mb-2'>Email</h6>
                    <p style={{color: '#677788'}}>
                      {partner_email ? partner_email : '-'}
                    </p>
                  </Col>
                  <Col md={4} sm={6}>
                    <h6 className='fw-semibold mb-2'>Alamat</h6>
                    <p style={{color: '#677788'}}>
                      {partner_address ? partner_address : '-'}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={12}>
            <Card>
              <CardBody>
              <Row className='mt-3'>
                  <Col lg={12} md={12} xs={12} className='mb-2'>
                    <div className='d-flex'>
                      <div className='flex-grow-1'>
                        Show&nbsp;
                        <ButtonGroup>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag='button'
                              className='btn btn-sm btn-primary'
                            >
                              {data?.perPage}
                              <i className='mdi mdi-chevron-down'></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem onClikc={() => setSize(10)}>
                                10
                              </DropdownItem>
                              <DropdownItem onClick={() => setSize(25)}>
                                25
                              </DropdownItem>
                              <DropdownItem onClick={() => setSize(50)}>
                                50
                              </DropdownItem>
                              <DropdownItem onClick={() => setSize(100)}>
                                100
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </ButtonGroup>
                        &nbsp;entries
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className='table-responsive'>
                  <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
                    <thead>
                      <tr>
                        <th scope='col' style={theadStyles}>
                          No
                        </th>
                        <th scope='col' style={theadStyles}>
                          Tanggal
                        </th>
                        <th scope='col' style={theadStyles}>
                          Keterangan
                        </th>
                        <th scope='col' style={theadStyles}>
                          Debit
                        </th>
                        <th scope='col' style={theadStyles}>
                          Saldo
                        </th>
                        <th scope='col' style={theadStyles}>
                          Saldo
                        </th>
                      </tr>
                    </thead>
                    <tbody>{renderData()}</tbody>
                  </Table>
                </div>
                <MyPagination
                  total={report_data?.total || 0}
                  page={report_data?.offset || 0}
                  perPage={report_data?.size || 10}
                  onPageChange={(e) => {
                    setPage(e.selected)
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default DetailSaldoReport

import {Link} from 'react-router-dom'

const TerminItem = ({
  page,
  index,
  id,
  termin,
  percent,
  onDeleteClick,
  table,
}) => {
  return (
    <tr>
      <td className='fw-medium'>{index + page + 1}</td>
      <td>
        <div className='hstack gap-3 fs-15'>
          {/* <Link to={`/app/${table}/detail/${id}`} className='link-success'>
            <i className='ri-eye-fill'></i>
          </Link> */}
          <Link to={`/app/${table}/edit/${id}`} className='link-warning'>
            <i className='ri-edit-2-line'></i>
          </Link>
          <Link
            to='#'
            className='link-danger'
            onClick={() => {
              onDeleteClick(id)
            }}
          >
            <i className='ri-delete-bin-5-line'></i>
          </Link>
        </div>
      </td>
      <td>{termin} Bulan</td>
      <td>{percent} %</td>
    </tr>
  )
}

export default TerminItem

import { initAutoComplete } from "Helpers/initGMapScript";
import React, { createRef, useEffect } from "react";

function GmapAutoComplete({ onChange, name }) {
  const ref = createRef();
  useEffect(() => {
    initAutoComplete(ref, onChange);
  }, [name, onChange, ref]);
  return (
    <input
      ref={ref}
      className="form-control"
      name={name}
      onKeyDown={(e) => {
        if (e.key === 13) e.preventDefault();
      }}
    />
  );
}

export default GmapAutoComplete;

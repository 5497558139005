import {useQuery} from '@tanstack/react-query'
import {apiBearer} from 'Helpers/api'
import {AGREEMENT_APPROVAL_MUTATION} from 'Helpers/url_helper'
import {useEffect, useRef, useState} from 'react'
import {Button, Col, Modal, ModalBody, ModalFooter, Row, Spinner, Table} from 'reactstrap'
import AgreementMutationItem from './AgreementMutationItem'
import MyPagination from 'Components/Common/Table/Pagination'
import flatpickr from 'flatpickr'

const AgreementMutationModal = ({show, agreementData, onCloseClick}) => {
  const [page, setPage] = useState(0)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [stateIndicator, setStateIndicator] = useState(false)
  const startDateRef = useRef()
  const endDateRef = useRef()
  const formRef = useRef()

  const {data, isFetching, status, refetch} = useQuery(
    ['agreement-mutation-list', page, agreementData.id],
    async () => {
      const params = {page, limit: 10, start_date:startDate, end_date:endDate}
      const data = await apiBearer.get(
        AGREEMENT_APPROVAL_MUTATION(agreementData.id),
        {params},
      )

      if (startDateRef.current) {
        flatpickr(startDateRef.current, {
          dateFormat: 'Y-m-d',
          closeOnSelect: true,
          onChange: (v, sDate) => {
            setStartDate(sDate)
          },
        })
      }
      if (endDateRef.current) {
        flatpickr(endDateRef.current, {
          dateFormat: 'Y-m-d',
          closeOnSelect: true,
          onChange: (v, sDate) => {
            setEndDate(sDate)
          },
        })
      }
      
      return data?.data?.data
    },
    {
      enabled: !!agreementData?.id,
    },
  )

  const onSubmitSearch = (e) => {
    e.preventDefault()
    if (page === 0) refetch()
    else setPage(0)
  }

  const headers = [
    'NO',
    'tanggal and jam',
    'informasi',
    'debet',
    'kredit',
    'balance',
  ]

  useEffect(()=>{
    if(agreementData.id) refetch();
  },[stateIndicator])


  const renderData = () => {
    if (status === 'error')
      return (
        <tr>
          <td colSpan={7} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    if (isFetching)
      return (
        <tr>
          <td colSpan={7} className='text-center'>
            <Spinner color='success' />
          </td>
        </tr>
      )
    if (status === 'loading' && !isFetching) {
      return (
        <tr>
          <td colSpan={7} className='text-center'>
            <span className='text-danger'>Search to show data</span>
          </td>
        </tr>
      )
    }
    if (!data?.data || data?.data.length < 1)
      return (
        <tr>
          <td colSpan={7} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    return data?.data?.map((props, i) => {
      return (
        <AgreementMutationItem
          {...props}
          index={i}
          page={data.offset}
          limit={data.limit}
          key={i}
          table={''}
        />
      )
    })
  }

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} size='lg'>
      <div
        style={{color: '#516377'}}
        className='d-flex justify-content-between pt-4 px-3 fw-bold'
      >
        <h4>Mutasi</h4>
        <h4>{agreementData?.customer}</h4>
      </div>
      <div>
        <form onSubmit={onSubmitSearch} ref={formRef}>
          <Row className='px-3 mt-3 mb-2 align-items-end'>
            <Col lg={4} md={6} sm={12}>
              <label className='text-uppercase' htmlFor='start'>
                Start Date
              </label>
              <input
                type='text'
                id='start'
                ref={startDateRef}
                className='form-control'
                placeholder='YYYY-mm-dd'
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <label className='text-uppercase' htmlFor='end'>
                End Date
              </label>
              <input
                type='text'
                id='end'
                ref={endDateRef}
                className='form-control'
                placeholder='YYYY-mm-dd'
              />
            </Col>
            <Col lg={4} md={6} sm={12} className='mt-2 d-flex justify-content-end'>
              <div className='flex align-items-end'>
                <button
                  className='btn btn-primary'
                  type='button'
                  onClick={() => {
                    formRef.current?.reset()
                    setStartDate('')
                    setEndDate('')
                    setPage(0)
                    setStateIndicator((v) => !v)
                  }}
                >
                  <i className='mdi mdi-restore' style={{fontSize: 15}}></i>
                </button>
                <button
                  className='ms-3 btn btn-primary bg-white text-primary'
                  style={{border: '1px solid #3F5F29'}}
                  type='submit'
                >
                  Search
                </button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
      <ModalBody>
        <div className='table-responsive'>
          <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
            <thead>
              <tr>
                {headers.map((v, i) => (
                  <th key={i} scope='col'>
                    {v}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>{renderData()}</tbody>
          </Table>
        </div>

        <MyPagination
          total={data?.total || 0}
          page={data?.offset || 0}
          perPage={data?.size || 10}
          onPageChange={(e) => {
            setPage(e.selected)
          }}
        />
      </ModalBody>
      <ModalFooter>
        <Button color='light' onClick={onCloseClick}>
          Kembali
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default AgreementMutationModal

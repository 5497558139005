import { warehouseShipmentStatuses } from "Helpers/options";

const shipmentStatus = (value) => {
    const selectedShipmentStatus = getShipmentStatus(value)
    return <span className="badge badge-soft-success">{selectedShipmentStatus?.label}</span>
}

const getShipmentStatus = (value) => {
  return warehouseShipmentStatuses().find(opt => opt.value === value)
}

export {
  shipmentStatus,
  getShipmentStatus
};
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorFieldWrapper from "Components/Common/ErrorFieldWrapper";
import { apiBearer } from "Helpers/api";
import {
  APPROVE_CDO_REQUEST_URL,
  REJECT_CDO_REQUEST_URL,
} from "Helpers/url_helper";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Input, Label, Modal, ModalBody } from "reactstrap";
import * as Yup from "yup";

const ConfirmModal = ({
  show,
  onCloseClick,
  loading,
  description = "Are you sure you want to remove this record ?",
  isReject,
  id,
  refetch,
}) => {
  const validation = Yup.object().shape({
    cdo: Yup.mixed(),
    input: Yup.string(),
  });

  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      input: "",
    },
  };

  const { handleSubmit, formState, control } = useForm(formOptions);

  const { errors } = formState;

  const reject = async (values) => {
    apiBearer
      .patch(REJECT_CDO_REQUEST_URL(id), { reject_reason: values.input })
      .then(() => {
        refetch();
        onCloseClick();
      });
  };

  const approve = async (values) => {
    apiBearer
      .patch(APPROVE_CDO_REQUEST_URL(id), { remarks: values.input })
      .then(() => {
        refetch();
        onCloseClick();
      });
  };

  const approval = (values) => {
    isReject ? reject(values) : approve(values);
  };

  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      id="exampleModal"
    >
      <div className="mt-2 text-center">
        <lord-icon
          src="https://cdn.lordicon.com/gsqxdxog.json"
          trigger="loop"
          colors="primary:#f7b84b,secondary:#f06548"
          style={{ width: "100px", height: "100px" }}
        ></lord-icon>
        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
          <h4>Change Data Status</h4>
          <p className="text-muted mx-4 mb-0">{description}</p>
        </div>
      </div>
      <ModalBody>
        <form onSubmit={handleSubmit(approval)}>
          <ErrorFieldWrapper
            error={errors.input}
            message={errors.input?.message}
            className={"mb-3"}
          >
            <Label htmlFor="input" className="form-label">
              {isReject ? "Reason" : "Catatan"}
            </Label>
            <Controller
              id="input"
              name="input"
              type="input"
              control={control}
              render={({ field }) => <Input {...field} value={field.value} />}
            />
          </ErrorFieldWrapper>

          <div className="modal-footer d-flex justify-content-center mt-4">
            <button className="btn btn-primary" type="submit">
              Yes
            </button>
            <Button color="light" onClick={onCloseClick}>
              Cancel
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmModal;

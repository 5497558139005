import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { selfPickupRQ } from "Hooks/Queries";
import BreadCrumb from "Components/Common/BreadCrumb";
import { shipmentStatus } from "Components/ShippingManagement/helpers";

export default function DetailSelfPickup(props) {
  const { id } = useParams();

  const { data, error, isFetching, status } = selfPickupRQ.useGetData(id)();

  const {cdo:dataCdo} = data?.data || {};
  
  useEffect(() => {
    if (!isFetching && status === "success") {
    }
    if (!isFetching && status === "error") {
      console.log(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Self Pickup" pageTitle="Self Pickup" />
        <Row>
          <Col lg={12}>
            <form onSubmit={() => {}}>
              <Card>
                <CardHeader className="d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">Detail {data?.data?.cdo_code ?? "-"}</h4>
                </CardHeader>
                <CardBody className="card-body">
                  <Row className="text-muted">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th
                            style={{ background: "rgb(243, 246, 249)" }}
                            colSpan={2}
                          >
                            General
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th style={{ maxWidth: "20%" }}>CDO</th>
                          <td>{data?.data?.cdo_code ?? "-"}</td>
                        </tr>
                        <tr>
                          <th style={{ maxWidth: "20%" }}>AWB</th>
                          <td>{dataCdo?.awb ?? "-"}</td>
                        </tr>
                        <tr>
                          <th style={{ maxWidth: "20%" }}>HAWB</th>
                          <td>{dataCdo?.hawb ?? "-"}</td>
                        </tr>
                        <tr>
                          <th style={{ maxWidth: "20%" }}>Ijin Beacukai</th>
                          <td>{dataCdo?.sppb ?? "-"}</td>
                        </tr>
                        <tr>
                          <th style={{ maxWidth: "20%" }}>DO</th>
                          <td>{dataCdo?.do_no ?? "-"}</td>
                        </tr>
                        <tr>
                          <th style={{ maxWidth: "20%" }}>Status</th>
                          <td>
                            {shipmentStatus(data?.data?.status)}
                          </td>
                        </tr>
                        <tr>
                          <th style={{ maxWidth: "20%" }}>Nama Pengirim</th>
                          <td>{data?.data?.sender_name ?? "-"}</td>
                        </tr>
                        <tr>
                          <th style={{ maxWidth: "20%" }}>Alamat Pengirim</th>
                          <td>{data?.data?.sender_address ?? "-"}</td>
                        </tr>
                        <tr>
                          <th style={{ maxWidth: "20%" }}>
                            No Telp/HP Pengirim
                          </th>
                          <td>{data?.data?.sender_phone ?? "-"}</td>
                        </tr>
                        <tr>
                          <th style={{ maxWidth: "20%" }}>Nama Penerima</th>
                          <td>{data?.data?.receiver_name ?? "-"}</td>
                        </tr>
                        <tr>
                          <th style={{ maxWidth: "20%" }}>Alamat Penerima</th>
                          <td>{data?.data?.receiver_address ?? "-"}</td>
                        </tr>
                        <tr>
                          <th style={{ maxWidth: "20%" }}>
                            No Telp/HP Penerima
                          </th>
                          <td>{data?.data?.receiver_phone ?? "-"}</td>
                        </tr>
                        <tr>
                          <th
                            style={{ background: "rgb(243, 246, 249)" }}
                            colSpan={2}
                          >
                            System
                          </th>
                        </tr>
                        <tr>
                          <th style={{ maxWidth: "20%" }}>Waktu dibuat</th>
                          <td>{data?.data?.created_on ?? "-"}</td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="table-responsive table-card "></div>
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to="/app/truck-book/self-pickup" className="btn btn-outline-dark">
                    Back
                  </Link>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

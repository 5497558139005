import React from 'react'
import { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import ErrorFieldWrapper from '../../../Components/Common/ErrorFieldWrapper'
import { TruckingCatRQ } from 'Hooks/Queries'
import { useForm } from 'react-hook-form'
import { validName } from 'Helpers/regex_validation'

export default function EditCreateTruckingCat(props) {
  const { id } = useParams()
  const navigate = useNavigate();

  const validation = Yup.object().shape({
    name: Yup.string().required("Name harus diisi")
    .test('test-valid-name', 'Name hanya boleh berisi huruf', (v)=> validName.test(v)),
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      name: '',
    },
  };
  const { handleSubmit, formState, register, reset } = useForm(formOptions);
  const { errors } = formState;


  const {
    mutate :updateTrucking,
    isLoading: updateLoading
  } = TruckingCatRQ.useUpdateData(navigate)()

  const _updateTruckingCat = (values) => {
    const body = values;
    body.id = id;
    updateTrucking(body)
  }

  const {
    mutate :addTrucking,
    isLoading: createLoading
  } = TruckingCatRQ.useAddData(navigate)()

  const _createTruckingCat = async (values)=> {
    addTrucking(values)
  }

  const {
    data,
    error,
    isFetching,
    status
  } = TruckingCatRQ.useGetData(id)()

  useEffect(()=>{
    if(!isFetching && status === "success"){
      reset({
        name: data.data?.name ?? '',
      })
    }
    if(!isFetching && status === "error"){
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  },[status, isFetching])

  const isLoading = createLoading || updateLoading

  const submitBtnText = () => {
    if(isLoading) return 'Loading...'
    return id ? 'Update' : 'Create'
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Trucking Category" pageTitle="Trucking Category" />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(id?_updateTruckingCat:_createTruckingCat)}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">{id ? 'Edit' : 'Create'} Trucking Category</h4>
                </CardHeader>
                <CardBody className="card-body">
                  <Row className='gy-4'>
                    <Col md={6}>
                      <ErrorFieldWrapper error={errors.name} message={errors.name?.message}>
                        <Label htmlFor="name">
                          Name <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register('name')}/>
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to='/app/truck/category' className='btn btn-danger'>Cancel</Link>
                  <Button color='success' type='submit' disabled={isLoading}>{submitBtnText()}</Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

/* eslint-disable react-hooks/exhaustive-deps */
import {yupResolver} from '@hookform/resolvers/yup'
import {useQuery} from '@tanstack/react-query'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import GmapAutoComplete from 'Components/Common/GmapAutoComplete'
import flatpickr from 'flatpickr'
import {apiBearer} from 'Helpers/api'
import { formatNumber } from 'Helpers/number'
import {paymentMethods, PAYMENT_METHODS} from 'Helpers/options'
import {showSuccess} from 'Helpers/show_toast'
import {
  LIST_TRUCK_BOOKING_PAYMENT_METHOD_URL,
  MY_PROFILE,
  SELF_PICK_CDO,
  VIEW_CDO_URL,
} from 'Helpers/url_helper'
import moment from 'moment'
import {BOOK_STATE_SEARCH} from 'Pages/TruckBooking/Create'
import {memo, useCallback, useEffect, useRef, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import Select from 'react-select'
import {Card, CardBody, CardHeader, Col, Input, Label, Row} from 'reactstrap'
import * as Yup from 'yup'
import BookFormFiles from './BookFormFiles'

const BOOK_TYPE = {
  BOOKING_MANUAL:'BOOKING_MANUAL',
  BOOKING_AUTO:''
}
const  BookForm = ({truckData, setBookState, hidden, isWithCDO, bookingType, setBookingType}) => {
  const {
    data: dataProfile,
  } = useQuery(
    ['profile'],
    async () => {
      const res = await apiBearer.get(MY_PROFILE)
      return res.data.data
    },
    {
      refetchOnMount: true,
    },
  )
  const {partner: userProfile} = dataProfile ?? {}
  const {truck_rate_item_id, date, rate} = truckData
  const [cdoOptions, setCdoOptions] = useState([])
  const [selectedCdo, setselectedCdo] = useState([])
  const [selectedPayment, setselectedPayment] = useState(null)
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([])
  const [saldo, setsaldo] = useState(0)
  const [termintOptions, setTermintOptions] = useState(0)
  const [selectedTermint, setSelectedTermint] = useState(0)
  const [fillWithProfile, setFillWithProfile] = useState({
    sender:false,
    receiver:false
  })

  const onChangeFillSender = useCallback((checked) => {
    setFillWithProfile(prev=>({...prev, sender:checked}))
  },[])

  const onChangeFillReceiver = useCallback((checked) => {
    setFillWithProfile(prev=>({...prev, receiver:checked}))
  },[])

  const getCdos = useCallback(async () => {
    try {
      const response = await apiBearer.get(SELF_PICK_CDO)
      const data = response.data
      setCdoOptions(
        (data?.data ? data.data : []).map((v) => ({
          ...v,
          label: `CDO ${v?.awb ?? '-'} - ${v?.warehouse ?? ''}`,
          value: v.id,
        })),
      )
    } catch (error) {}
  },[])
  
  useEffect(() => {
    getCdos()
    setValue('date', date)
  }, [])

  const validation = Yup.object().shape({
    payment_method: Yup.string().required(
      'Metode pembayaran tidak boleh kosong',
    ),
    sender_name: Yup.string().required('Nama pengirim tidak boleh kosong'),
    sender_address: Yup.string().required('Alamat pengirim tidak boleh kosong'),
    sender_phone: Yup.string().required('Nomor pengirim tidak boleh kosong'),
    receiver_name: Yup.string().required('Nama penerima tidak boleh kosong'),
    receiver_address: Yup.string().required(
      'Alamat penerima tidak boleh kosong',
    ),
    receiver_phone: Yup.string().required('Nomor penerima tidak boleh kosong'),
    origin_name: Yup.string().required('Asal tidak boleh kosong'),
    origin_latitude: Yup.string().required(),
    origin_longitude: Yup.string().required(),
    destination_name: Yup.string().required('Tujuan tidak boleh kosong'),
    destination_latitude: Yup.string().required(),
    destination_longitude: Yup.string().required(),
    truck_rate_item_id: Yup.string().required(),
    cdo_id: Yup.string(),
    date: Yup.string(),
    goods_detail: Yup.string(),
    instruction: Yup.string(),
    agreement_term_id: selectedPayment?.value === PAYMENT_METHODS.AGREEMENT
      ? Yup.string().required('Termint tidak boleh kosong')
      : Yup.string(),
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      payment_method: '',
      sender_name: '',
      sender_address: '',
      sender_phone: '',
      receiver_name: '',
      receiver_address: '',
      receiver_phone: '',
      origin_name: '',
      origin_latitude: '',
      origin_longitude: '',
      destination_name: '',
      destination_latitude: '',
      destination_longitude: '',
      truck_rate_item_id: truck_rate_item_id,
      cdo_id: '',
      date: date ?? moment().format('YYYY-MM-DD'),
    },
  }

  const {handleSubmit, formState, register, control, setValue, trigger, watch} =
    useForm(formOptions)
  const {errors} = formState

  const navigate = useNavigate()
  const isManual = bookingType === BOOK_TYPE.BOOKING_MANUAL
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])

  const onSubmit = useCallback(async (values) => {
    try {
      setLoading(true)
      const formData = new FormData()
      const newData = Object.assign({},values)
      if (!newData.date) {
        newData.date = date ?? moment().format('YYYY-MM-DD')
      }
      if(isManual){
        newData.cdo_num = values.cdo_id
        newData.cdo_id = null
        newData.booking_truck_type = BOOK_TYPE.BOOKING_MANUAL
        newData.files = files;
      }
      formData.append('data', JSON.stringify(newData))
      const response = await apiBearer.post(`/booking-truck/save`, formData)
      const result = await response.data
      showSuccess('Booking success')
      navigate('/app/truck-book/booking')
    } catch (error) {
    } finally{
      setLoading(false)
    }
  },[isManual, files])

  const onCdoSelect = useCallback((e) => {
    setselectedCdo(e)
  },[])

  const {
    data: dataCDO,
    error: errorCDO,
    isFetching: isFetchingCDO,
    status: statusCDO,
  } = useQuery(
    ['cdo-detail', selectedCdo?.id ?? ''],
    async () => {
      const res = await apiBearer.get(VIEW_CDO_URL(selectedCdo?.id ?? ''))
      return res.data?.data
    },
    {
      enabled: !!selectedCdo?.id ?? '',
    },
  )
  const onAddressesSelect = useCallback((inputNAme, {name, long, lat}) => {
    setValue(inputNAme + '_address', name)
    if (inputNAme === 'sender') {
      setValue('origin_name', name)
      setValue('origin_latitude', lat)
      setValue('origin_longitude', long)
    } else {
      setValue('destination_name', name)
      setValue('destination_latitude', lat)
      setValue('destination_longitude', long)
    }
    trigger(inputNAme + '_address')
  },[])

  const simRef = useRef()

  useEffect(() => {
    if (simRef.current) {
      flatpickr(simRef.current, {
        dateFormat: 'Y-m-d',
        defaultDate: new Date(),
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setValue('date', sDate)
        },
      })
      setValue('date', date)
    }
    if (truck_rate_item_id) {
      setValue('truck_rate_item_id', truck_rate_item_id)
      apiBearer
        .get(LIST_TRUCK_BOOKING_PAYMENT_METHOD_URL(truck_rate_item_id))
        .then((data) => {
          if (data?.data?.data) {
            setPaymentMethodOptions(
              data.data.data.map((paymentMethod) => {
                const {name, balance, options} = paymentMethod || {}
                const paymentMet = paymentMethods.find(
                  ({value}) => value === name,
                )
                const label = paymentMet ? paymentMet.label : name
                let isDisabled = false

                if (
                  name === PAYMENT_METHODS.AGREEMENT &&
                  options &&
                  options.length > 0
                ){
                  setTermintOptions(
                    options?.map((option) => ({
                      label: Number(option?.name) + ' bulan',
                      value: option.id,
                    })),
                  )
                setsaldo('Rp. '+formatNumber(balance))}

                if (
                  name === PAYMENT_METHODS.AGREEMENT ||
                  name === PAYMENT_METHODS.BALANCE
                )
                  if (balance < rate) isDisabled = true
                return {label, value: name, isDisabled}
              }),
            )
          }
        })
    }
  }, [setValue, truck_rate_item_id, rate])

  useEffect(() => {
    if (statusCDO === 'success' && !isFetchingCDO) {
      setValue('sender_name', dataCDO?.sender_name)
      setValue('sender_phone', dataCDO?.sender_phone)
      setValue('receiver_name', dataCDO?.receiver_name)
      setValue('receiver_phone', dataCDO?.receiver_phone)
    }
  }, [statusCDO, isFetchingCDO])
  

  useEffect(()=>{
    if(isManual){
      setValue('sender_name', '')
      setValue('sender_phone', '')
      setValue('receiver_name', '')
      setValue('receiver_phone', '')
      setselectedCdo(null)
      setValue('cdo_id', '')
    }
  },[isManual])
  
  useEffect(()=>{
    if(fillWithProfile.receiver){
      setValue('receiver_name', userProfile.pic_name)
      setValue('receiver_phone', userProfile.pic_phone)
    }else{
      setValue('receiver_name', '')
      setValue('receiver_phone', '')
    }
  },[fillWithProfile.receiver])

  useEffect(()=>{
    if(fillWithProfile.sender){
      setValue('sender_name', userProfile.pic_name)
      setValue('sender_phone', userProfile.pic_phone)
    }else{
      setValue('sender_name', '')
      setValue('sender_phone', '')
    }
  },[fillWithProfile.sender])


  console.log(files)
  return (
    <Card hidden={hidden}>
      <CardHeader>Order Booking</CardHeader>
      <CardBody>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className='d-flex mb-3'>
          <button 
            className={'fw-medium btn ' + (isManual ? 'btn-outline-primary':'btn-primary')} 
            type='button'
            onClick={()=>setBookingType('')}
          >
            Booking Dengan CDO Online
          </button>
          <button 
            className={'ms-3 fw-medium btn ' + (isManual ? 'btn-primary':'btn-outline-primary')} 
            type='button'
            onClick={()=>setBookingType(BOOK_TYPE.BOOKING_MANUAL)}
          >
            Booking Tanpa CDO Online
          </button>
        </div>
          <Row>
            <Col lg={isManual? 12:6} sm={12} className='mb-3' hidden={!isWithCDO}>
              <ErrorFieldWrapper
                error={errors.cdo_id}
                message={errors.cdo_id?.message}
              >
                <Label className='text-uppercase'>
                  CDO <span className='mandatory'>*</span>
                </Label>
                <Controller
                  name='cdo_id'
                  control={control}
                  render={({field: {onChange, ...field}}) => isManual ? (
                    <input {...field} className='form-control' onChange={(e)=>onChange(e.target.value)}/>
                  ):(
                    <Select
                      {...field}
                      cacheOptions
                      isClearable
                      defaultOptions
                      value={selectedCdo ?? ''}
                      options={cdoOptions}
                      onChange={(e) => {
                        if (e?.value) onChange(e.value)
                        onCdoSelect(e)
                      }}
                      placeholder='Search by name'
                    />
                  )}
                />
              </ErrorFieldWrapper>
            </Col>
            {isManual ? (
              <>
                <Col sm={12} className='mb-1 mt-3'>
                  <h4>Pengirim</h4>
                  <hr color='black'/>
                </Col>
                <Col lg={6} sm={12} className='mb-3 d-flex'>
                  <Label className='text-uppercase'>Isi dengan data anda</Label>
                  <div className='ms-5 me-3'>
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="auto-sender"
                      id='auto-sender-ok'
                      onChange={()=> onChangeFillSender(true)}
                    />
                    <label htmlFor='auto-sender-ok' className='ms-1'>Ya</label>
                  </div>
                  <div>
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="auto-sender"
                      id='auto-sender-no'
                      defaultChecked
                      onChange={()=> onChangeFillSender(false)}
                    />
                    <label htmlFor='auto-sender-no' className='ms-1'>Tidak</label>
                  </div>
                </Col>
              </>
              ):null}
            <Col lg={6} sm={12} className='mb-3'>
              <ErrorFieldWrapper
                error={errors.sender_name}
                message={errors.sender_name?.message}
              >
                <Label className='text-uppercase'>
                  Nama Pengirim <span className='mandatory'>*</span>
                </Label>
                <input className='form-control' {...register('sender_name')} />
              </ErrorFieldWrapper>
            </Col>
            <Col lg={6} sm={12} className='mb-3'>
              <ErrorFieldWrapper
                error={errors.sender_phone}
                message={errors.sender_phone?.message}
              >
                <Label className='text-uppercase'>
                  Nomor Pengirim <span className='mandatory'>*</span>
                </Label>
                <input
                  className='form-control'
                  {...register('sender_phone', {
                    value: dataCDO?.sender_phone ?? '',
                  })}
                />
              </ErrorFieldWrapper>
            </Col>
            <Col lg={6} sm={12} className='mb-3'>
              <ErrorFieldWrapper
                error={errors.sender_address}
                message={errors.sender_address?.message}
              >
                <Label className='text-uppercase'>
                  Alamat Pengambilan Barang <span className='mandatory'>*</span>
                </Label>
                <GmapAutoComplete
                  name='sender_address'
                  onChange={(e) => {
                    onAddressesSelect('sender', e)
                  }}
                />
              </ErrorFieldWrapper>
            </Col>

            {isManual ? (
              <>
                <Col sm={12} className='mb-1 mt-3'>
                  <h4>Penerima</h4>
                  <hr color='black'/>
                </Col>
                <Col lg={6} sm={12} className='mb-3 d-flex'>
                  <Label className='text-uppercase'>Isi dengan data anda</Label>
                  <div className='ms-5 me-3'>
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="auto-receiver"
                      id='auto-receiver-ok'
                      onChange={()=> onChangeFillReceiver(true)}
                    />
                    <label htmlFor='auto-receiver-ok' className='ms-1'>Ya</label>
                  </div>
                  <div>
                    <Input
                      className="form-check-input"
                      type="radio"
                      name="auto-receiver"
                      id='auto-receiver-no'
                      defaultChecked
                      onChange={()=> onChangeFillReceiver(false)}
                    />
                    <label htmlFor='auto-receiver-no' className='ms-1'>Tidak</label>
                  </div>
                </Col>
              </>
              ):null}
            <Col lg={6} sm={12} className='mb-3'>
              <ErrorFieldWrapper
                error={errors.receiver_name}
                message={errors.receiver_name?.message}
              >
                <Label className='text-uppercase'>
                  Nama Penerima <span className='mandatory'>*</span>
                </Label>
                <input
                  className='form-control'
                  {...register('receiver_name')}
                />
              </ErrorFieldWrapper>
            </Col>
            <Col lg={6} sm={12} className='mb-3'>
              <ErrorFieldWrapper
                error={errors.receiver_phone}
                message={errors.receiver_phone?.message}
              >
                <Label className='text-uppercase'>
                  Nomor Penerima <span className='mandatory'>*</span>
                </Label>
                <input
                  className='form-control'
                  {...register('receiver_phone')}
                />
              </ErrorFieldWrapper>
            </Col>

            <Col lg={6} sm={12} className='mb-3'>
              <ErrorFieldWrapper
                error={errors.receiver_address}
                message={errors.receiver_address?.message}
              >
                <Label className='text-uppercase'>
                  Alamat Penerima <span className='mandatory'>*</span>
                </Label>
                <GmapAutoComplete
                  name='receiver_address'
                  onChange={(e) => {
                    onAddressesSelect('receiver', e)
                  }}
                />
              </ErrorFieldWrapper>
            </Col>
            {isManual?(
              <>
                <Col lg={6} sm={12}  className='mb-3'>
                  <Label className='text-uppercase'>
                    Detail Barang (optional)
                  </Label>
                  <textarea 
                    className='form-control'
                    {...register('goods_detail')}
                  />
                </Col>
                <Col lg={6} sm={12}  className='mb-3'>
                  <Label className='text-uppercase'>
                    Instruksi (optional)
                  </Label>
                  <textarea 
                    className='form-control'
                    {...register('instruction')}
                  />
                </Col>
              </>
            ):null}
            <Col lg={6} sm={12} className='mb-3'>
              <ErrorFieldWrapper
                error={errors.payment_method}
                message={errors.payment_method?.message}
              >
                <Label className='text-uppercase'>
                  Metode Pembayaran <span className='mandatory'>*</span>
                </Label>
                <Controller
                  name='payment_method'
                  control={control}
                  render={({field: {onChange, ...field}}) => (
                    <Select
                      {...field}
                      cacheOptions
                      isClearable
                      defaultOptions
                      value={selectedPayment ?? ''}
                      options={paymentMethodOptions}
                      isOptionDisabled={(option) => option.isDisabled}
                      onChange={(e) => {
                        if (e?.value) onChange(e.value)
                        setselectedPayment(e)
                      }}
                      placeholder='Metode Pembayaran'
                    />
                  )}
                />
              </ErrorFieldWrapper>
            </Col>
            {selectedPayment?.value === PAYMENT_METHODS.AGREEMENT ? (
              <>
                <Col lg={6} sm={12} className='mb-3'>
                  <Label className='text-uppercase'>Sisa Kuota</Label>
                  <input
                    type='text'
                    name=''
                    id=''
                    disabled
                    value={saldo}
                    className='form-control'
                  />
                </Col>
                <Col lg={6} sm={12} className='mb-3'>
                  <ErrorFieldWrapper
                    error={errors.agreement_term_id}
                    message={errors.agreement_term_id?.message}
                  >
                    <Label className='text-uppercase'>
                      Termint <span className='mandatory'>*</span>
                    </Label>
                    <Controller
                      name='agreement_term_id'
                      control={control}
                      render={({field: {onChange, ...field}}) => (
                        <Select
                          {...field}
                          cacheOptions
                          isClearable
                          defaultOptions
                          value={selectedTermint ?? ''}
                          options={termintOptions}
                          onChange={(e) => {
                            if (e?.value) onChange(e.value)
                            setSelectedTermint(e)
                          }}
                          placeholder='Termint'
                        />
                      )}
                    />
                  </ErrorFieldWrapper>
                </Col>
              </>
            ) : null}
          </Row>
          {isManual ? <BookFormFiles setFiles={setFiles}/>:null}
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-light me-4'
              type='button'
              onClick={() => setBookState(BOOK_STATE_SEARCH)}
            >
              Back
            </button>
            <button
              className='btn btn-outline-primary' 
              type='submit' 
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Simpan'}
            </button>
          </div>
        </form>
      </CardBody>
    </Card>
  )
}
export default memo(BookForm)
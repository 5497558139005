export const WAREHOUSE = "WAREHOUSE";
export const CUSTOMER = "CUSTOMER";
export const ADMIN = "ADMIN";
export const TRUCKING = "TRUCKING";
export const DRIVER = "DRIVERS";

export const accountTypes = [WAREHOUSE, CUSTOMER, TRUCKING];

export const PAYMENT_METHODS = {
  BALANCE: "BALANCE",
  TRANSFER: "TRANSFER",
  AGREEMENT: "AGREEMENT",
};
export const paymentMethods = [
  { label: "Via Saldo", value: PAYMENT_METHODS.BALANCE },
  { label: "Direct Payment", value: PAYMENT_METHODS.TRANSFER },
  { label: "Agreement", value: PAYMENT_METHODS.AGREEMENT },
];

export const UNPAID = "UNPAID";
export const PAID = "PAID";
export const FAILED = "FAILED";

export const warehouseShipmentStatuses = (currentStatus) => {
  const statuses = [
    {label: "Proses", value: "ON_PROGRESS", disabled: false},
    { label: "Baru", value: "PENDING", disabled: false },
    { label: "Sedang Disiapkan", value: "PREPARE_GOODS", disabled: false },
    { label: "Barang Siap Dipickup", value: "READY_PICKUP", disabled: false },
    { label: "Pickup Selesai", value: "PICKUP", disabled: false },
    // { label: "Barang Telah Dimuat", value: "LOADED", disabled: false },
  ];

  if (currentStatus) {
    const selectedIndex = statuses.findIndex((s) => s.value === currentStatus);
    for (let i = 0; i < statuses.length && i <= selectedIndex; i++) {
      statuses[i].disabled = true;
    }
  }

  return statuses;
};

export const logStatuses = [
  { label: "Proses", value: "PENDING" },
  { label: "Baru", value: "TRUCKING_APPROVE_BOOKING" },
  { label: "Ditolak", value: "TRUCKING_REJECT_BOOKING" },
  { label: "Vendor assign", value: "TRUCKING_ASSIGN_DRIVER" },
  { label: "Driver terima", value: "DRIVER_APPROVE_BOOKING" },
  { label: "Driver tolak", value: "DRIVER_REJECT_BOOKING" },
  { label: "Barang disiapkan", value: "PREPARE_GOODS" },
  { label: "Barang siap", value: "READY_PICKUP" },
  { label: "Penjemputan", value: "PICKING_UP" },
  { label: "Penaikan Barang", value: "LOADED" },
  { label: "Pengiriman", value: "ON_DELIVERY" },
  { label: "Penurunan Barang", value: "UNLOADING" },
  { label: "Barang Sampai", value: "DELIVERED" },
  { label: "Barang Sampai", value: "RECEIVED_BY_CUSTOMER" },
  { label: "Security check", value: "SECURITY_CHECK" },
  { label: "Selesai", value: "DONE" },
];

export const TOPUP_STATUS_CONSTANTS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  ON_PROGRESS: "ON_PROGRESS",
  FAILED: "FAILED",
};
export const topupStatuses = [
  { label: "Pending", value: TOPUP_STATUS_CONSTANTS.PENDING },
  { label: "Success", value: TOPUP_STATUS_CONSTANTS.SUCCESS },
  { label: "On Progress", value: TOPUP_STATUS_CONSTANTS.ON_PROGRESS },
  { label: "Failed", value: TOPUP_STATUS_CONSTANTS.FAILED },
];

//validation status
export const WAITING_APPROVAL = "WAITING_APPROVAL";
export const APPROVED = "APPROVED";
export const REJECTED = "REJECTED";

export const getBadgeClass = (status) => {
  switch (status) {
    case WAITING_APPROVAL:
    case TOPUP_STATUS_CONSTANTS.PENDING:
    case UNPAID:
      return "badge badge-soft-dark";
    case APPROVED:
    case PAID:
      return "badge badge-soft-success";
    case REJECTED:
      return "badge badge-soft-danger";
    default:
      return "badge badge-soft-danger";
  }
};

export const faqCategories = [
  { label: CUSTOMER, value: CUSTOMER },
  { label: WAREHOUSE, value: WAREHOUSE },
  { label: TRUCKING, value: TRUCKING },
  { label: "DRIVERS", value: "DRIVERS" },
];

export const PERMISSION_CONSTANTS = {
  CREATE :'ADD',
  READ :'VIEW',
  UPDATE :'EDIT',
  DELETE :'DELETE',
  // APPROVE :'APPROVE',
  // EXPORT :'EXPORT',
}

//ADD, EDIT, DELETE, VIEW
import { APP_NAME, BASE_API_URL } from "configs";
import { apiBasic } from "Helpers/api";
import { showError, showSuccess } from "Helpers/show_toast";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

export default function VerifEmail() {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const verif = async () => {
    try {
      await apiBasic.post(BASE_API_URL + "/auth/verified-user", { token });
      showSuccess("Email is successfully verified!");
    } catch (error) {
      showError(
        error.response?.data?.message ??
          "Something wrong, please check the input"
      );
    }
    setLoading(false);
  };
  useEffect(() => {
    verif();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  document.title = `${APP_NAME} | Email Verification`;

  return (
    <div className="auth-page-content">
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <Card className="mt-4">
              <CardBody className="p-4">
                <div className="text-center mt-2 mb-0">
                  <Link to="/" className="d-inline-block auth-logo">
                    <img
                      src="/logo.svg"
                      alt="gkargo logo"
                      height={80}
                      width={80}
                    />
                  </Link>
                  <h4>Email Verification</h4>
                </div>
                <div className="mt-4 text-center">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => (loading ? null : navigate("/login"))}
                  >
                    {loading ? "Verifying . . ." : "Login"}
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import { CREATE_AGENT_URL, DELETE_AGENT_URL, LIST_AGENT_URL, UPDATE_AGENT_URL, VIEW_AGENT_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'agents';
const REDIRECT_URL = '/app/agents';

export const AgentRQ = new RQ(
  QUERY_KEY, 
  LIST_AGENT_URL, 
  VIEW_AGENT_URL, 
  CREATE_AGENT_URL, 
  UPDATE_AGENT_URL, 
  DELETE_AGENT_URL, 
  REDIRECT_URL
);
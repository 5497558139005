import { ASSIGN_TRUCKING_ORDER_DRIVER_URL, LIST_TRUCKING_ORDER_URL, VIEW_TRUCKING_ORDER_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'trucking-order';
const REDIRECT_URL = '/app/trucking/order';

export const TruckingOrderRQ = new RQ(
  QUERY_KEY,
  LIST_TRUCKING_ORDER_URL,
  VIEW_TRUCKING_ORDER_URL,
  ASSIGN_TRUCKING_ORDER_DRIVER_URL,
  REDIRECT_URL
);
import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { locationPointRQ } from "Hooks/Queries";

export default function DetailLocationPoint(props) {
  const { id } = useParams();

  const { data } =
    locationPointRQ.useGetData(id)();

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Location Point" pageTitle="Location Point" />
        <Row>
          <Col lg={12}>
            <form onSubmit={() => {}}>
              <Card>
              <CardHeader className="d-flex justify-content-between align-items-center">
                  <h4 className="">Detail Location Point</h4>
                </CardHeader>
                <CardBody className="card-body">
                  <Row className="gy-4">
                    <Col md={6}>
                      <Label htmlFor="name" className="form-label">
                        Name
                      </Label>
                      <Input
                        disabled
                        id="name"
                        name="name"
                        type="name"
                        value={data?.data?.name ?? ""}
                      />
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to="/app/location-point" className="btn btn-outline-dark">
                    Back
                  </Link>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

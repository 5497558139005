import React, { useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { showSuccess } from "../../Helpers/show_toast";
import { validPassword } from "../../Helpers/regex_validation";
import { CHANGE_PASSWORD } from "../../Helpers/url_helper";
import { apiBearer } from "../../Helpers/api";
import { Col, Row } from "reactstrap";
import ErrorFieldWrapper from "../../Components/Common/ErrorFieldWrapper";
import { Controller, useForm } from "react-hook-form";

export default function ChangePassword() {
  const validation = Yup.object().shape({
    currentPassword: Yup.string()
      .required("Password is required")
      .matches(
        validPassword,
        "Must Contain One Number and One Special Case Character"
      )
      .min(8, "Password length must be at least 8 characters"),
    newPassword: Yup.string()
      .required("Confirm Password is required")
      .min(8, "Password length must be at least 8 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("newPassword")], "Confirm password do not match new password"),
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
  };

  const { handleSubmit, formState, control, reset } = useForm(formOptions);
  const { errors } = formState;
  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    setLoading(true)
    apiBearer
      .patch(CHANGE_PASSWORD, {
        old_password: values.currentPassword,
        new_password: values.newPassword,
        confirm_new_password: values.confirmPassword,
      })
      .then(() => {
        showSuccess("Successfully change password");
        reset({
          currentPassword: "",
          newPassword: "",
          confirmPassword: ""
        })
      })
      .finally(()=>{
        setLoading(false)
      });
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-2">
          <ErrorFieldWrapper
            error={errors.currentPassword}
            message={errors.currentPassword?.message}
          >
            <div>
              <label htmlFor="oldpasswordInput" className="form-label">
                Old Password*
              </label>
              <Controller
                id="title"
                name="currentPassword"
                type="password"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    autoComplete="off"
                    className="form-control"
                  />
                )}
              />
            </div>
          </ErrorFieldWrapper>

          <ErrorFieldWrapper
            error={errors.newPassword}
            message={errors.newPassword?.message}
          >
            <div>
              <label htmlFor="newPassword" className="form-label">
                New Password*
              </label>
              <Controller
                id="title"
                name="newPassword"
                type="password"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    autoComplete="off"
                    className="form-control"
                  />
                )}
              />
            </div>
          </ErrorFieldWrapper>
          <ErrorFieldWrapper
            error={errors.confirmPassword}
            message={errors.confirmPassword?.message}
          >
            <div>
              <label htmlFor="confirmPassword" className="form-label">
                Confirm New Password*
              </label>
              <Controller
                id="title"
                name="confirmPassword"
                type="password"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    autoComplete="off"
                    className="form-control"
                  />
                )}
              />
            </div>
          </ErrorFieldWrapper>
          <Col lg={12}>
            <div className="text-end">
              <button type="submit" className="btn btn-success" disabled={loading}>
               {loading ? 'Loading..' : 'Change Password'}
              </button>
            </div>
          </Col>
        </Row>
      </form>
      {/* <div className="mt-4 mb-3 border-bottom pb-2">
                <div className="float-end">
                    <Link to="#" className="link-primary">All Logout</Link>
                </div>
                <h5 className="card-title">Login History</h5>
            </div>
            <div className="d-flex align-items-center mb-3">
                <div className="flex-shrink-0 avatar-sm">
                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                        <i className="ri-smartphone-line"></i>
                    </div>
                </div>
                <div className="flex-grow-1 ms-3">
                    <h6>iPhone 12 Pro</h6>
                    <p className="text-muted mb-0">Los Angeles, United States - March 16 at
                        2:47PM</p>
                </div>
                <div>
                    <Link to="#">Logout</Link>
                </div>
            </div>
            <div className="d-flex align-items-center mb-3">
                <div className="flex-shrink-0 avatar-sm">
                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                        <i className="ri-tablet-line"></i>
                    </div>
                </div>
                <div className="flex-grow-1 ms-3">
                    <h6>Apple iPad Pro</h6>
                    <p className="text-muted mb-0">Washington, United States - November 06
                        at 10:43AM</p>
                </div>
                <div>
                    <Link to="#">Logout</Link>
                </div>
            </div>
            <div className="d-flex align-items-center mb-3">
                <div className="flex-shrink-0 avatar-sm">
                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                        <i className="ri-smartphone-line"></i>
                    </div>
                </div>
                <div className="flex-grow-1 ms-3">
                    <h6>Galaxy S21 Ultra 5G</h6>
                    <p className="text-muted mb-0">Conneticut, United States - June 12 at
                        3:24PM</p>
                </div>
                <div>
                    <Link to="#">Logout</Link>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <div className="flex-shrink-0 avatar-sm">
                    <div className="avatar-title bg-light text-primary rounded-3 fs-18">
                        <i className="ri-macbook-line"></i>
                    </div>
                </div>
                <div className="flex-grow-1 ms-3">
                    <h6>Dell Inspiron 14</h6>
                    <p className="text-muted mb-0">Phoenix, United States - July 26 at
                        8:10AM</p>
                </div>
                <div>
                    <Link to="#">Logout</Link>
                </div>
            </div> */}
    </>
  );
}

import { fcmKeyPair, firebaseConfig } from "configs";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {
  getMessaging,
  getToken,
  isSupported,
  Messaging,
  onMessage,
} from "firebase/messaging";
import { showError } from "Helpers/show_toast";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
  prompt: "select_account",
});

export const signInWithGoogle = () => signInWithPopup(auth, provider);

// messaging
const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(app);
    }
    console.log("Firebase messaging not supported this browser");
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
})();

// get fcm token
export const requestForToken = async (dispatch) => {
  try {
    const messagingResolve = await messaging;
    const currentToken = await getToken(messagingResolve, {
      vapidKey: fcmKeyPair,
    });
    if (currentToken) {
      return currentToken;
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    Notification.requestPermission().then((d) => {
      if (d === "granted") {
        requestForToken()
          .then((firebaseToken) => {
            resolve(firebaseToken);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        reject("Notification blocked by user");
        console.log("Some feature will not work as espected!");
      }
    });
  });

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage((payload) => {
      resolve(payload);
    });
  });
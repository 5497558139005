import {Label, Table} from 'reactstrap'

const TruckingCommissionDetail = ({
  label = 'Komisi',
  onChangeValue,
  lists,
  percent,
}) => {
  return (
    <>
      <h4 className='card-title mb-0 flex-grow-1 mt-4'>Setting Komisi</h4>
      <hr />
      <Label>{label}</Label>
      <input
        type='text'
        disabled
        value={percent}
        className='form-control w-25 mb-2'
      />
      <div className='table-responsive'>
        <Table className='table-hover table-bordered align-middle table-nowrap mb-4'>
          <thead>
            <tr>
              <th>No</th>
              <th>Deskripsi</th>
              <th>Persentase (%)</th>
            </tr>
          </thead>
          <tbody>
            {lists.map(({id, name, value, value_type}, i) => {
              return (
                <tr key={id}>
                  <td>{i + 1}</td>
                  <td style={{minWidth: '30%'}}>
                    <input value={name} disabled className='form-control' />
                  </td>
                  <td style={{minWidth: '60%'}}>
                    <div className='d-flex'>
                      <input
                        type='text'
                        disabled
                        value={value_type}
                        className='form-control me-2'
                      />
                      <input
                        type='text'
                        disabled
                        value={value}
                        className='form-control me-2'
                      />
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  )
}

export default TruckingCommissionDetail

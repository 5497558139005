import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorFieldWrapper from "../../../Components/Common/ErrorFieldWrapper";
import { locationPointRQ } from "Hooks/Queries";
import FloatingInput from "Components/Common/FloatingInput";
import { useForm } from "react-hook-form";

export default function EditCreateLocationPoint(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const validation = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      name: "",
    },
  };
  const { handleSubmit, formState, register, reset } = useForm(formOptions);
  const { errors } = formState;

  const { mutate: updateLocationPoint, isLoading: updateLoading } =
    locationPointRQ.useUpdateData(navigate)();

  const _updateLocationPoint = (values) => {
    const body = values;
    body.id = id;
    updateLocationPoint(body);
  };

  const { mutate: addLocationPoint, isLoading: createLoading } = locationPointRQ.useAddData(navigate)();

  const _createLocationPoint = async (values) => {
    addLocationPoint(values);
  };

  const { data, error, isFetching, status } = locationPointRQ.useGetData(id)();

  useEffect(() => {
    if (!isFetching && status === "success") {
      reset({
        name: data.data?.name ?? "",
      });
    }
    if (!isFetching && status === "error") {
      console.log(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  const isLoading = createLoading || updateLoading

  const submitBtnText = ()=> {
    if(isLoading) return 'Loading...'
    return id ? "Update" : "Create"
  }


  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Location Point" pageTitle="LocationPoint" />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={handleSubmit(
                id ? _updateLocationPoint : _createLocationPoint
              )}
            >
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {id ? "Edit" : "Create"} Location Point
                  </h4>
                </CardHeader>
                <CardBody className="card-body">
                  <Row className="gy-4">
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.name}
                        message={errors.name?.message}
                      >
                        <Label>
                          Name <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register("name")} />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to="/app/location-point" className="btn btn-danger">
                    Cancel
                  </Link>
                  <Button color="success" type="submit" disabled={isLoading}>
                    {submitBtnText()}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";

const ConfirmModal = ({
  show,
  onCloseClick,
  onConfirm,
  loading,
  description,
  children,
}) => {
  return (
    <Modal
      isOpen={show}
      toggle={onCloseClick}
      centered={true}
      id="exampleModal"
    >
      <div className="mt-2 text-center">
        <lord-icon
          src="https://cdn.lordicon.com/gsqxdxog.json"
          trigger="loop"
          colors="primary:#f7b84b,secondary:#f06548"
          style={{ width: "100px", height: "100px" }}
        ></lord-icon>
        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
          <h4>Change Data Status</h4>
          <p className="text-muted mx-4 mb-0">{description}</p>
        </div>
      </div>
      <ModalBody>
        {children ? children : null}
        <div className="modal-footer d-flex justify-content-center">
          <button className="btn btn-primary" type="button" onClick={onConfirm}>
            {loading ? "Loading..." : "Yes"}
          </button>
          <Button color="light" onClick={onCloseClick}>
            Cancel
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmModal;

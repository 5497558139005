import {
  CREATE_ADDITIONAL_COST_URL,
  DELETE_ADDITIONAL_COST_URL,
  LIST_ADDITIONAL_COST_URL,
  UPDATE_ADDITIONAL_COST_URL,
  VIEW_ADDITIONAL_COST_URL,
} from '../../Helpers/url_helper'
import RQ from './baseRQ'

const QUERY_KEY = 'additional-cost-trucking'
const REDIRECT_URL = '/app/struktur-harga/trucking/biaya'

export const AdditionalCostTruckingRQ = new RQ(
  QUERY_KEY,
  LIST_ADDITIONAL_COST_URL + '?type=BOOKING_TRUCK',
  VIEW_ADDITIONAL_COST_URL,
  CREATE_ADDITIONAL_COST_URL,
  UPDATE_ADDITIONAL_COST_URL,
  DELETE_ADDITIONAL_COST_URL,
  REDIRECT_URL,
)

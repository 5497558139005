import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { showError, showSuccess } from "Helpers/show_toast";
import { apiBasic } from "Helpers/api";
import { APP_NAME, BASE_API_URL } from "configs";

export default function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const validation = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
    confirm_password: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Confirm password not match"),
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  };
  const { handleSubmit, formState, control } = useForm(formOptions);
  const { errors } = formState;
  const onSubmit = async (values) => {
    const body = values;
    body.token = token;
    setLoading(true);
    try {
      await apiBasic.post(BASE_API_URL + "/auth/set-password", body);
      showSuccess("Password is successfully changed");
    } catch (error) {
      showError(
        error.response?.data?.message ??
          "Something wrong, please check the input"
      );
      console.log(error.response);
    }
    setLoading(false);
  };
  document.title = `${APP_NAME} | Reset Password`;

  return (
    <div className="auth-page-content">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} sm={12}>
            <Card className="mt-4">
              <CardBody className="p-4">
                <div className=" mt-2 mb-0">
                  <p className="text-muted mb-4 mb-0 auth-label text-center">
                    Change Password
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div
                      className={`mb-3 ${errors.password ? "error-form" : ""}`}
                    >
                      <label className="form-label" htmlFor="password-input">
                        Password
                      </label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <Controller
                          id="password"
                          name="password"
                          type="password"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              value={field.value}
                              type={"password"}
                            />
                          )}
                        />
                        <div className="error-form-info badge badge-soft-danger">
                          <img
                            alt=""
                            src="/assets/icons/triangle-alert-fill.svg"
                            className="me-1"
                          />{" "}
                          {errors.password?.message}
                        </div>
                      </div>
                    </div>
                    <div
                      className={`mb-3 ${
                        errors.confirm_password ? "error-form" : ""
                      }`}
                    >
                      <label className="form-label" htmlFor="password-input">
                        Confirm Password
                      </label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <Controller
                          id="confirm_password"
                          name="confirm_password"
                          type="confirm_password"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              value={field.value}
                              type={"password"}
                            />
                          )}
                        />
                        <div className="error-form-info badge badge-soft-danger">
                          <img
                            alt=""
                            src="/assets/icons/triangle-alert-fill.svg"
                            className="me-1"
                          />{" "}
                          {errors.confirm_password?.message}
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <button
                        color="success"
                        className="btn btn-success w-100"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? 'Loading...' : 'Ganti Password'}
                      </button>
                      <button
                        className="btn btn-outline-dark w-100 mt-2"
                        type="button"
                        onClick={() => {
                          navigate("/login");
                        }}
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

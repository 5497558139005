import {Editor as TinyEditor} from '@tinymce/tinymce-react'
import {TINYMCE_API_KEY} from 'configs'

const Editor = ({onEditorChange, label = 'Konten', initialValue = ''}) => {
  return (
    <>
      <label className={'z-0'} htmlFor='description'>
        {label}
      </label>
      <TinyEditor
        id='description'
        apiKey={TINYMCE_API_KEY}
        initialValue={initialValue}
        init={{
          height: 500,
          menubar: 'edit view format tools table tc help',
          body_class: 'yolo',
          plugins: [
            'print preview powerpaste casechange importcss tinydrive searchreplace',
            'autolink autosave save directionality advcode visualblocks visualchars',
            'fullscreen image link media mediaembed template codesample table charmap hr',
            'pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist wordcount',
            'tinymcespellchecker a11ychecker imagetools textpattern noneditable help',
            'formatpainter permanentpen pageembed charmap tinycomments mentions quickbars',
            'linkchecker emoticons advtable export',
          ],
          toolbar: [
            'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect |',
            ' alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat |',
            ' pagebreak | charmap emoticons | fullscreen  preview save print | link anchor codesample',
          ],
        }}
        onChange={(ev) => onEditorChange(ev.target.getContent())}
      />
    </>
  )
}

export default Editor

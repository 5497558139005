import {
  CREATE_TERMINT_URL,
  DELETE_TERMINT_URL,
  LIST_TERMINT_URL,
  UPDATE_TERMINT_URL,
  VIEW_TERMINT_URL,
} from 'Helpers/url_helper'
import RQ from './baseRQ'

const QUERY_KEY = 'termints'
const REDIRECT_URL = '/app/termint'

export const TermintRQ = new RQ(
  QUERY_KEY,
  LIST_TERMINT_URL,
  VIEW_TERMINT_URL,
  CREATE_TERMINT_URL,
  UPDATE_TERMINT_URL,
  DELETE_TERMINT_URL,
  REDIRECT_URL,
)

import {yupResolver} from '@hookform/resolvers/yup'
import BreadCrumb from 'Components/Common/BreadCrumb'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {SppbDocTypeRQ} from 'Hooks/Queries'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from 'reactstrap'
import * as Yup from 'yup'

export default function EditCreateSppbDocType(props) {
  const {id} = useParams()
  const navigate = useNavigate()
  const validation = Yup.object().shape(
    {
      code: Yup.string().required('Kode tidak boleh kosong'),
      name: Yup.string().required('Nama tidak boleh kosong'),
      is_date_required: Yup.boolean(),
    },
    [],
  )
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      name: '',
      code: '',
      is_date_required: false,
    },
  }
  const {handleSubmit, formState, reset, register, setValue, watch} = useForm(formOptions)
  const {errors} = formState
  const isDateRequiredValue = watch('is_date_required', false)
  const {mutate: updateSppbDocType, isLoading: loadingUpdate} =
    SppbDocTypeRQ.useUpdateData(navigate)()
  const _updateSppbDocType = (values) => {
    const data = {
      code: values.code,
      name: values.name,
      is_date_required: values.is_date_required,
      id,
    }
    updateSppbDocType(data)
  }

  const {mutate: addSppbDocType, isLoading: loadingCreate} =
    SppbDocTypeRQ.useAddData(navigate)()

  const _createSppbDocType = (values) => {
    const data = {
      code: values.code,
      name: values.name,
      is_date_required: values.is_date_required,
    }
    addSppbDocType(data)
  }

  console.log(isDateRequiredValue)
  const {data, error, isFetching, status} = SppbDocTypeRQ.useGetData(id)()

  useEffect(() => {
    if (!isFetching && status === 'success') {
      reset({
        code: data.data?.code ?? '',
        name: data.data?.name ?? '',
        is_date_required: data.data?.date_required || false
      })
    }
    if (!isFetching && status === 'error') {
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Sppb Doc Type' />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={handleSubmit(
                id ? _updateSppbDocType : _createSppbDocType,
              )}
            >
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {id ? 'Edit' : 'Create'} Sppb Doc Type
                  </h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='gy-4'>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.name}
                        message={errors.name?.message}
                      >
                        <Label className='text-uppercase' htmlFor='name'>
                          Nama <span className='mandatory'>*</span>
                        </Label>
                        <input
                          className='form-control'
                          placeholder='Masukkan Nama'
                          {...register('name')}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.code}
                        message={errors.code?.message}
                      >
                        <Label className='text-uppercase' htmlFor='code'>
                          Kode <span className='mandatory'>*</span>
                        </Label>
                        <input
                          className='form-control'
                          {...register('code')}
                          placeholder='Masukkan Kode'
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6} className='d-flex flex-wrap'>
                      <Label className='text-uppercase' htmlFor='is_date_required'>
                        Perlu field tanggal <span className='mandatory'>*</span>
                      </Label>
                      <div className='ms-4 d-flex'>
                        <div className='form-check mx-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='flexRadioDefault'
                            checked={isDateRequiredValue}
                            id='flexRadioDefault1'
                            onChange={(e)=> {if(e.target.checked)setValue('is_date_required',true)}}
                          />
                          <label
                            className='form-check-label'
                            htmFor='flexRadioDefault1'
                            onClick={(e)=> {setValue('is_date_required',true)}}
                          >
                            Ya
                          </label>
                        </div>
                        <div className='form-check mx-2'>
                          <input
                            className='form-check-input'
                            type='radio'
                            name='flexRadioDefault'
                            checked={!isDateRequiredValue}
                            id='flexRadioDefault2'
                            onChange={(e)=> {if(e.target.checked)setValue('is_date_required',false)}}
                          />
                          <label
                            onClick={(e)=> {setValue('is_date_required',false)}}
                            className='form-check-label'
                            htmFor='flexRadioDefault2'
                          >
                            Tidak
                          </label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link to='/app/sppb-doc-type' className='btn btn-danger'>
                    Cancel
                  </Link>
                  <Button
                    color='success'
                    type='submit'
                    disabled={loadingUpdate || loadingCreate}
                  >
                    {loadingUpdate || loadingCreate
                      ? 'Loading...'
                      : id
                      ? 'Update'
                      : 'Create'}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

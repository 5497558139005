import { logStatuses } from "Helpers/options";

const logStatus = (value) => {
    const selectedLogStatus = getLogStatus(value)
    if (selectedLogStatus) {
      return <span className="badge badge-soft-success">{selectedLogStatus.label}</span>
    } else {
      return null;
    }
}

const getLogStatus = (value) => {
  return logStatuses.find(opt => opt.value === value)
}

export {
  logStatus,
  getLogStatus
};
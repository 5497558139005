import React, {useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {APPROVED, WAITING_APPROVAL} from 'Helpers/options'
import {useQuery} from '@tanstack/react-query'
import {VIEW_CDO_REQUEST_URL} from 'Helpers/url_helper'
import {apiBearer} from 'Helpers/api'
import ConfirmModal from 'Components/CdoRequest/ConformModal'
import SimpleBar from 'simplebar-react'

export default function DetailCdoRequest(props) {
  const {id} = useParams()
  const [modalState, setModalState] = useState({
    show: false,
    onConfirm: () => {},
    description: '',
    isReject: false,
  })

  const {data, refetch} = useQuery(
    ['cdo-request-detail', id],
    async () => {
      const res = await apiBearer.get(VIEW_CDO_REQUEST_URL(id))
      return res.data?.data
    },
    {
      enabled: !!id,
    },
  )

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Cdo' />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={function (e) {
                e.preventDefault()
              }}
            >
              <Card>
                <CardHeader>
                  <div className='d-flex justify-content-between align-items-center'>
                    <h4 className=''>CDO Request</h4>
                    {data?.qr_url ? (
                      <img src={data?.qr_url} height={100} width={100} alt='' />
                    ) : (
                      <div
                        style={{
                          height: 100,
                          width: 135,
                          border: '1px gray solid',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: 10,
                          marginLeft: 15,
                        }}
                      >
                        <span>Image not found</span>
                      </div>
                    )}
                  </div>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='text-muted'>
                    <div className='table-responsive table-card '>
                      <SimpleBar
                        style={{
                          maxHeight: '365px',
                        }}
                      >
                        <ul className='list-group list-group-flush'>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>Company Name :</div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.customer_name ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>AWB :</div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.awb ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>HAWB :</div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.hawb ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>Izin Beacukai :</div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.sppb ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>DO :</div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.do_no ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>Nama Pengirim:</div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.sender_name ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>Nama Penerima :</div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.receiver_name ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>
                                Alamat Penerima :
                              </div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.receiver_address ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>Nomor Penerima :</div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.receiver_phone ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>
                                Agent / Forwader :
                              </div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.forwarder ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>Koli :</div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.koli ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>Berat :</div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.weight ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>Jenis Barang :</div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.type_of_goods ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>
                                Kode Penerbangan :
                              </div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.flight_code ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>
                                Tanggal Penerbangan :
                              </div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.flight_date ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>
                                Tanggal Kuitansi :
                              </div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.invoice_date ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>Nomor Kuitansi :</div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.invoice_no ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>
                                Jumlah Dana Kuitansi :
                              </div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.invoice_amount ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          {/* <li className="list-group-item list-group-item-action">
                            <div className="d-flex align-items-center">
                              <div className="ms-3 col-6">Metode Pembayaran :</div>
                              <div className="ms-3 col-3">
                                <h6>{data?.payment_method ?? "-"}</h6>
                              </div>
                            </div>
                          </li> */}
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>PU/POS :</div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.pu ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>CAW :</div>
                              <div className='ms-3 col-3'>
                                <h6>{data?.caw ?? '-'}</h6>
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>AWB File :</div>
                              <div className='ms-3 col-3'>
                                {data?.awb_file ? (
                                  <a
                                    className='text-decoration-underline'
                                    target={data?.awb_file ? '_blank' : ''}
                                    rel='noreferrer'
                                    href={data?.awb_file ? data?.awb_file : '#'}
                                  >
                                    Preview
                                  </a>
                                ) : (
                                  '-'
                                )}
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>HAWB File :</div>
                              <div className='ms-3 col-3'>
                                {data?.hawb_file ? (
                                  <a
                                    className='text-decoration-underline'
                                    target={data?.hawb_file ? '_blank' : ''}
                                    rel='noreferrer'
                                    href={
                                      data?.hawb_file ? data?.hawb_file : '#'
                                    }
                                  >
                                    Preview
                                  </a>
                                ) : (
                                  '-'
                                )}
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>Izin Beacukai :</div>
                              <div className='ms-3 col-3'>
                                {data?.sppb_file ? (
                                  <a
                                    className='text-decoration-underline'
                                    target={data?.sppb_file ? '_blank' : ''}
                                    rel='noreferrer'
                                    href={
                                      data?.sppb_file ? data?.sppb_file : '#'
                                    }
                                  >
                                    Preview
                                  </a>
                                ) : (
                                  '-'
                                )}
                              </div>
                            </div>
                          </li>
                          <li className='list-group-item list-group-item-action'>
                            <div className='d-flex align-items-center'>
                              <div className='ms-3 col-6'>DO File :</div>
                              <div className='ms-3 col-3'>
                                {data?.do_file ? (
                                  <a
                                    className='text-decoration-underline'
                                    target={data?.do_file ? '_blank' : ''}
                                    rel='noreferrer'
                                    href={data?.do_file ? data?.do_file : '#'}
                                  >
                                    Preview
                                  </a>
                                ) : (
                                  '-'
                                )}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </SimpleBar>
                    </div>
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link to='/app/request-cdo' className='btn btn-soft-dark'>
                    Back
                  </Link>
                  {data?.validation_status === WAITING_APPROVAL ? (
                    <>
                      <Button
                        color='danger'
                        type='button'
                        onClick={() => {
                          setModalState((v) => ({
                            description: 'Yakin ingin reject data ini?',
                            show: true,
                            isReject: true,
                          }))
                        }}
                      >
                        REJECT
                      </Button>
                      <Button
                        color='success'
                        type='button'
                        onClick={() => {
                          setModalState((v) => ({
                            description: 'Yakin ingin approve data ini?',
                            show: true,
                            isReject: false,
                          }))
                        }}
                      >
                        APPROVE
                      </Button>
                    </>
                  ) : null}
                  {data?.validation_status === APPROVED ? (
                    <Link
                      to={`/app/request-cdo/print/${id}`}
                      className='btn btn-success'
                    >
                      Document
                    </Link>
                  ) : null}
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
      <ConfirmModal
        key={modalState.isReject}
        id={id}
        description={modalState.description}
        onConfirm={() => modalState.onConfirm()}
        onCloseClick={() => setModalState((v) => ({...v, show: !v.show}))}
        show={modalState.show}
        loading={false}
        isReject={modalState.isReject}
        refetch={refetch}
      />
    </div>
  )
}

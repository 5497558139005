import React from "react";

export default function StarIcon({active = false}) {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill={active?"#FDC638":"none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5643 10.5918L7.32493 10.4609L7.08534 10.5914L3.53309 12.5257L3.53222 12.5261C3.38168 12.6085 3.21775 12.5889 3.10102 12.5122C2.98705 12.4372 2.95809 12.343 2.98104 12.2538L3.92254 8.6021L4.00421 8.28533L3.74668 8.08363L0.605286 5.62323L0.605107 5.62309C0.494164 5.53628 0.485046 5.44104 0.513696 5.36168C0.544916 5.2752 0.63768 5.17475 0.820742 5.16159L0.820742 5.16159L0.823135 5.16141L4.95744 4.84419L5.24786 4.82191L5.37173 4.55829L6.9895 1.11528L6.98951 1.11528L6.99045 1.11324C7.03695 1.0131 7.15405 0.932617 7.32445 0.932617C7.49486 0.932617 7.61195 1.0131 7.65845 1.11324L7.65922 1.11489L9.277 4.56563L9.40076 4.82962L9.69146 4.85193L13.8258 5.16915L13.8282 5.16933C14.0112 5.18249 14.104 5.28294 14.1352 5.36942C14.1639 5.44878 14.1547 5.54402 14.0438 5.63083L14.0436 5.63097L10.9022 8.09137L10.6447 8.29307L10.7264 8.60983L11.6679 12.2615C11.6908 12.3507 11.6619 12.445 11.5479 12.5199C11.4312 12.5966 11.2672 12.6162 11.1167 12.5339L11.1166 12.5338L7.5643 10.5918Z"
        stroke="#FDC638"
      />
    </svg>
  );
}

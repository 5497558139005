import React from 'react'
import {MapContainer, TileLayer} from 'react-leaflet'

const OpenMap = ({center, zoom, height, children}) => {
  return (
    <MapContainer
      center={center}
      zoom={zoom}
      style={{height}}
      attributionControl={false}
    >
      <TileLayer
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {children}
    </MapContainer>
  )
}

export default OpenMap

import {LOKAL_STORAGE_PREFIX} from '../configs'

/**
 * Remove auth localstorage data
 */
export const removeLocalSDatas = () => {
  save('isLoggedin', false)
  save('token', null)
  save('profile', null)
  save('googleToken', null)
  save('nleToken', null)
  save('nleRefreshToken', null)
  save('regAutofill', {email: '', name: '', user_name: ''})
}

/**
 * get value from localstorage
 * @param {String} key
 * @param {any|null} defaultValue
 * @return {any|null} result
 */
export const load = (key, defaultValue = null) => {
  try {
    return JSON.parse(localStorage.getItem(LOKAL_STORAGE_PREFIX + key))
  } catch (error) {
    return defaultValue ?? null
  }
}

/**
 * store value to localstorage
 * @param {String} key
 * @param {any} key
 * @return {void}
 */
export const save = (key, value) =>
  localStorage.setItem(LOKAL_STORAGE_PREFIX + key, JSON.stringify(value))

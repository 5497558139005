import BreadCrumb from "Components/Common/BreadCrumb";
import BookForm from "Components/TruckBooking/BookForm";
import SearchResult from "Components/TruckBooking/SearchResult";
import SearchTruckForm from "Components/TruckBooking/SearchTruckForm";
import React, { useState } from "react";
import { Container } from "reactstrap";

export const BOOK_STATE_SEARCH = "BOOK_STATE_SEARCH";
export const BOOK_STATE_ADD = "BOOK_STATE_ADD";

export default function CreateTruckBooking() {
  const [truckList, setTruckList] = useState([]);
  const [truckData, setTruckData] = useState({});
  const [isSearching, setIsSerching] = useState(false);
  const [bookState, setBookState] = useState(BOOK_STATE_SEARCH);
  const [isWithCDO, setIsWithCDO] = useState(true);
  const [bookingType, setBookingType] = useState('')


  const searchResProps = {
    truckList,
    isSearching,
    truckData,
    isWithCDO,
    setBookState,
    setTruckData,
    bookingType, 
    setBookingType
  };


  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Truck Booking" />
          <div hidden={bookState !== BOOK_STATE_SEARCH}>
            <SearchTruckForm
              setTruckList={setTruckList}
              setIsSerching={setIsSerching}
              setTruckData={setTruckData}
              isWithCDO={isWithCDO}
              setIsWithCDO={setIsWithCDO}
              isSearching={isSearching}
            />
            <SearchResult {...searchResProps} />
          </div>
          <BookForm {...searchResProps}  hidden={bookState !== BOOK_STATE_ADD}/>
      </Container>
    </div>
  );
}

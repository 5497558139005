import { ADMIN } from "Helpers/options";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import BreadCrumb from "../Components/Common/BreadCrumb";
import { APP_NAME } from "../configs";
import Section from "./Section";

const Dashboard = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate()
  const { loading, token, profile } = auth;

  
  useEffect(()=>{
    if(!profile.is_approved && profile.role !== ADMIN){
      navigate('/app/profile')
    }

  },[navigate, profile.is_approved])
  document.title = `${APP_NAME} | Dashboard`;
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Dashboard" pageTitle="Dashboards" />
        <Row>
          <Col>
            <div className="h-100">
              <Section />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;

import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import { WarehouseRQ } from "Hooks/Queries";
import { apiBearer } from "Helpers/api";
import { APPROVE_WAREHOUSE_URL, LIST_BANKS_URL } from "Helpers/url_helper";
import { showSuccess } from "Helpers/show_toast";
import ConfirmModal from "Components/Common/ConfirmModal";
import ImageWithLink from "Components/Common/ImageWithLink";
import TruckingCommissionDetail from "Components/Master/TruckingCommissionDetail";

export default function DetailWarehouse(props) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [commissionDistribute, setCommissionDistribute] = useState([])
  const [bank, setBank] = useState(null)

  const { data, error, isFetching, status, refetch } =
    WarehouseRQ.useGetData(id)();

  const approve = () => {
    setLoading(true);
    apiBearer
      .patch(APPROVE_WAREHOUSE_URL(id))
      .then(() => {
        showSuccess("Approved successfully");
        refetch();
      })
      .finally(() => {
        setLoading(false);
        setConfirmModal(false)
      });
  };

  const fetchBanks = async () => {
    const result = await apiBearer.get(LIST_BANKS_URL)
    const {data:dataBank} = result
    if (dataBank.success) {
      const selectedBank = dataBank?.data.find(
        (v) => v.code === data?.data?.bank_name,
      )
      setBank(selectedBank.name)
    }
  }

  useEffect(() => {
    if (!isFetching && status === "success") {
      fetchBanks()
      setCommissionDistribute(data?.data?.commission_distributes)
    }
    if (!isFetching && status === "error") {
      console.log(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Warehouse' />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={function (e) {
                e.preventDefault()
              }}
            >
              <Card>
                <CardHeader className='d-flex justify-content-between align-items-center'>
                  <h4 className=''>Detail Warehouse</h4>
                  {data?.data?.is_approved ? (
                    <Badge color='success'>APPROVED </Badge>
                  ) : (
                    <Badge color='warning'>PENDING </Badge>
                  )}
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='gy-4 text-muted'>
                    <Col md={6} sm={12}>
                      <Label htmlFor='warehouse_id' className='form-label'>
                        Name
                      </Label>
                      <Input disabled value={data?.data?.name ?? ''} />
                    </Col>
                    <Col md={6} sm={12}>
                      <Label htmlFor='warehouse_id' className='form-label'>
                        Province
                      </Label>
                      <Input
                        disabled
                        value={data?.data?.province?.name ?? ''}
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <Label htmlFor='warehouse_id' className='form-label'>
                        City
                      </Label>
                      <Input disabled value={data?.data?.city?.name ?? ''} />
                    </Col>
                    <Col md={6} sm={12}>
                      <Label htmlFor='warehouse_id' className='form-label'>
                        District
                      </Label>
                      <Input
                        disabled
                        value={data?.data?.district?.name ?? ''}
                      />
                    </Col>
                    <Col md={6} sm={12}>
                      <Label htmlFor='warehouse_id' className='form-label'>
                        Village
                      </Label>
                      <Input disabled value={data?.data?.village?.name ?? ''} />
                    </Col>
                    <Col md={6} sm={12}>
                      <Label htmlFor='warehouse_id' className='form-label'>
                        Zip Code
                      </Label>
                      <Input disabled value={data?.data?.zip_code ?? ''} />
                    </Col>
                    <Col md={6} sm={12}>
                      <Label htmlFor='warehouse_id' className='form-label'>
                        PIC Name
                      </Label>
                      <Input disabled value={data?.data?.pic_name} />
                    </Col>
                    <Col md={6} sm={12}>
                      <Label htmlFor='warehouse_id' className='form-label'>
                        PIC Email
                      </Label>
                      <Input disabled value={data?.data?.pic_email} />
                    </Col>
                    <Col md={6} sm={12}>
                      <Label htmlFor='warehouse_id' className='form-label'>
                        PIC Phone
                      </Label>
                      <Input disabled value={data?.data?.pic_phone} />
                    </Col>
                    <Col md={6} sm={12}>
                      <Label htmlFor='warehouse_id' className='form-label'>
                        Address
                      </Label>
                      <Input disabled value={data?.data?.address ?? ''} />
                    </Col>
                    <Col md={6} sm={12}>
                      <Label htmlFor='warehouse_id' className='form-label'>
                        Bank Account
                      </Label>
                      <Input disabled value={data?.data?.bank_account ?? ''} />
                    </Col>
                    <Col md={6} sm={12}>
                      <Label htmlFor='warehouse_id' className='form-label'>
                        Bank
                      </Label>
                      <Input disabled value={bank ?? ''} />
                    </Col>
                    <Col md={6} sm={12}>
                      <Label htmlFor='logo' className='form-label'>
                        Logo
                      </Label>
                      {data?.data?.logo ? (
                        <ImageWithLink url={data?.data?.logo} />
                      ) : (
                        <input
                          type='text'
                          className='form-control'
                          disabled
                          value='Belum ada dokumen'
                        />
                      )}
                    </Col>
                    <Col md={6} sm={12}>
                      <Label htmlFor='docAttachment' className='form-label'>
                        DOKUMEN
                      </Label>
                      {data?.data?.doc ? (
                        <ImageWithLink url={data?.data?.doc} />
                      ) : (
                        <input
                          type='text'
                          className='form-control'
                          disabled
                          value='Belum ada dokumen'
                        />
                      )}
                    </Col>
                  </Row>
                  {data?.data?.commission ? (
                  <TruckingCommissionDetail
                    label='COST CDO AFTER PPN'
                    lists={commissionDistribute}
                    percent={data?.data?.commission}
                  />
                  ) : null}
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link to='/app/warehouses' className='btn btn-outline-dark'>
                    Back
                  </Link>
                  {data?.data?.is_approved ? null : (
                    <button
                      className='btn btn-primary'
                      type='button'
                      onClick={() => setConfirmModal((v) => !v)}
                    >
                      {loading ? 'Approving...' : 'Approve'}
                    </button>
                  )}
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
      <ConfirmModal
        description='Yakin ingin approve data warehouse ini?'
        loading={loading}
        onConfirm={approve}
        onCloseClick={() => setConfirmModal((v) => !v)}
        show={confirmModal}
      />
    </div>
  )
}

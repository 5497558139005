import {yupResolver} from '@hookform/resolvers/yup'
import BreadCrumb from 'Components/Common/BreadCrumb'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {validNumbers} from 'Helpers/regex_validation'
import {TermintRQ} from 'Hooks/Queries'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from 'reactstrap'
import * as Yup from 'yup'

export default function EditCreateTermint(props) {
  const {id} = useParams()
  const navigate = useNavigate()
  const validation = Yup.object().shape(
    {
      percent: Yup.string()
        .test(
          'type',
          'Pesentase harus berisi angka',
          (v) => !v || /^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/.test(v),
        )
        .required('Persentase tidak boleh kosong'),
      termin: Yup.string()
        .test(
          'type',
          'Termin harus berisi angka',
          (v) => !v || validNumbers.test(v),
        )
        .required('Termin tidak boleh kosong'),
    },
    [],
  )
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      termin: '',
      percent: '',
    },
  }
  const {handleSubmit, formState, reset, register} = useForm(formOptions)
  const {errors} = formState

  const {mutate: updateTermint, isLoading: loadingUpdate} = TermintRQ.useUpdateData(navigate)()
  const _updateTermint = (values) => {
    const data = {
      percent: values.percent,
      termin: values.termin,
      id,
    }
    updateTermint(data)
  }

  const {mutate: addTermint, isLoading: loadingCreate} = TermintRQ.useAddData(navigate)()

  const _createTermint = (values) => {
    const data = {
      percent: values.percent,
      termin: values.termin,
    }
    addTermint(data)
  }

  const {data, error, isFetching, status} = TermintRQ.useGetData(id)()

  useEffect(() => {
    if (!isFetching && status === 'success') {
      reset({
        percent: data.data?.percent ?? '',
        termin: data.data?.termin ?? '',
      })
    }
    if (!isFetching && status === 'error') {
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Termin' />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(id ? _updateTermint : _createTermint)}>
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {id ? 'Edit' : 'Create'} Termin
                  </h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='gy-4'>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.termin}
                        message={errors.termin?.message}
                      >
                        <Label htmlFor='termin'>
                          TERMIN (Bulan) <span className='mandatory'>*</span>
                        </Label>
                        <input
                          className='form-control'
                          placeholder='Masukkan Termin'
                          {...register('termin')}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.percent}
                        message={errors.percent?.message}
                      >
                        <Label htmlFor='percent'>
                          PERSENTASE <span className='mandatory'>*</span>
                        </Label>
                        <input
                          className='form-control'
                          {...register('percent')}
                          placeholder='Masukkan persentase'
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link to='/app/termint' className='btn btn-danger'>
                    Cancel
                  </Link>
                  <Button color='success' type='submit'disabled={loadingUpdate || loadingCreate} >
                    {loadingUpdate || loadingCreate ? 'Loading...' :(id ? 'Update' : 'Create')}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const { useMemo } = require("react");
const { useLocation } = require("react-router-dom");

const useQueryParams = () => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export default useQueryParams;

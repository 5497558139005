import {apiBearer} from 'Helpers/api'
import {showError, showSuccess} from 'Helpers/show_toast'
import React, {useState} from 'react'
import {Col, Row} from 'reactstrap'

const SettingKopSurat = ({dataProfile}) => {
  const {partner} = dataProfile || {}
  const [payloadState, setPayloadState] = useState(
    partner?.setting || {
      WAREHOUSE_NAME: '',
      WAREHOUSE_IDENTITY: '',
      WAREHOUSE_ADDRESS: '',
    },
  )

  const [loading, setLoading] = useState(false)

  const handleChange = (name, value) => {
    setPayloadState((v) => ({...v, [name]: value}))
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    const data = []

    for (const key in payloadState) {
      if (Object.hasOwnProperty.call(payloadState, key)) {
        const value = payloadState[key]
        data.push({
          setting_key: key,
          setting_value: value,
        })
      }
    }

    apiBearer
      .patch('/profile/update-partner-setting', data)
      .then(() => {
        showSuccess('Berhasil update kop surat!')
      })
      .catch(() => {
        showError('Gagal update kop surat, silahkan coba lagi nanti!')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <div className='w-full overflow-auto'>
        <div
          className='position-relative mx-auto'
          style={{border: '1px solid #D9D9D9', width: 500}}
        >
          <div
            style={{
              background: '#EEEEEE',
              top: 0,
              left: 0,
              display: 'inline',
              color: '#69737B',
              fontSize: 14,
            }}
            className='p-2'
          >
            Preview Kop Surat
          </div>
          <div className='d-flex justify-content-center flex-column align-items-center py-4'>
            <p className='mb-0 fs-4 fw-bolder'>
              {payloadState.WAREHOUSE_NAME ? payloadState.WAREHOUSE_NAME : '-'}
            </p>
            <p className='mb-0 fs-4'>
              {payloadState.WAREHOUSE_IDENTITY
                ? payloadState.WAREHOUSE_IDENTITY
                : '-'}
            </p>
            <p className='mb-0'>
              {payloadState.WAREHOUSE_ADDRESS
                ? payloadState.WAREHOUSE_ADDRESS
                : '-'}
            </p>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <Row className='my-3'>
          <Col className='my-2' lg={4} md={6} sm={12}>
            <label className='text-uppercase'>Nama Perusahaan</label>
            <input
              className='form-control'
              onChange={(e) => handleChange('WAREHOUSE_NAME', e.target.value)}
            />
          </Col>
          <Col className='my-2' lg={4} md={6} sm={12}>
            <label className='text-uppercase'>
              Identitas lembaga / perusahaan
            </label>
            <input
              className='form-control'
              onChange={(e) =>
                handleChange('WAREHOUSE_IDENTITY', e.target.value)
              }
            />
          </Col>
          <Col className='my-2' lg={4} md={6} sm={12}>
            <label className='text-uppercase'>Alamat, no. telp, Website</label>
            <input
              className='form-control'
              onChange={(e) =>
                handleChange('WAREHOUSE_ADDRESS', e.target.value)
              }
            />
          </Col>
          <Col lg={12}>
            <div className='text-end'>
              <button
                type='submit'
                className='btn btn-success'
                disabled={loading}
              >
                {loading ? 'Loading...' : 'Simpan'}
              </button>
            </div>
          </Col>
        </Row>
      </form>
    </>
  )
}

export default SettingKopSurat

import {useSelector} from 'react-redux'

export default function ConnectAccount({isCustomer, openModalNle}) {
  const {nleToken, autofill} = useSelector((state) => state.auth)

  const onNLELogin = () => {
    openModalNle()
  }

  return (
    <>
      <div className='mb-3'>
        <div className='mb-3'>
          <label htmlFor=''>
            Nle {isCustomer ? <span className='mandatory'>*</span> : null}{' '}
          </label>
          <button
            type='button'
            onClick={onNLELogin}
            className='btn rounded-3 d-flex align-items-center justify-content-start btn-outline-dark w-100 mb-3'
            style={{borderColor: 'hsl(0, 0%, 80%)', height: '60px'}}
          >
            <img
              src='assets/icons/nle-logo.svg'
              className='me-2'
              alt='google-logo'
            />{' '}
            <span className='text-small'>
              {nleToken && autofill?.username
                ? `Connected as ${autofill?.username}`
                : 'Connect to NLE'}
            </span>
          </button>
        </div>
      </div>
    </>
  )
}

import React, { useState } from 'react'
import { Container, Row } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import DeleteModal from 'Components/Common/DeleteModal'
import { AgentRQ } from 'Hooks/Queries'
import withTable from 'Components/Common/Table/withTable'
import { AgentItem } from 'Components/Agent/AgentItem'
import { useQueryClient } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { DATA_LIMIT } from 'configs'

export default function ListAgent() {
  const [deleteAgentId, setDeleteAgentId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DATA_LIMIT);
  const [keyword, setKeyword] = useState(null);
  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch
  } = AgentRQ.useGetDataList()(false, keyword, offset, limit);

  const useQuery = useQueryClient(); 
  const navigate = useNavigate();

  const onDeleteSuccess = (key) =>{
    setDeleteModal(false);
    setDeleteAgentId(null)
    useQuery.invalidateQueries({queryKey:[key]})
    refetch()
  }
  const { mutate: deleteAgent } = AgentRQ.useDeleteData(onDeleteSuccess)();

  const onDeleteClick = (id) => {
    setDeleteModal(true);
    setDeleteAgentId(id)
  }

  const onDeleteConfirm = () => {
    deleteAgent(deleteAgentId);
  }

  const onAddButtonClicked = () => {
    navigate('/app/agents/create')
  }

  const onPageChange = (i) => {
    setOffset(i.selected);
  }
  
  const onPerPageChange = (i) => {
    setLimit(i);
    setOffset(0);
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
  }

  const AgentTable = withTable(
    AgentItem, 
    'agents', 
    ['NO','Action', 'Nama', 'PIC Name', 'PIC Email', 'Address'], 
    onDeleteClick,
    onAddButtonClicked
  );

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Agent / Forwaders"/>
        
        <Row>
          <AgentTable
            data={data}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            loading={isFetching}
            error={error}
            status={status}
            fetchNextPage={fetchNextPage}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
      <DeleteModal
        onDeleteConfirm={() => onDeleteConfirm()}
        onCloseClick={() => setDeleteModal(v => !v)}
        show={deleteModal}
        loading={false}
      />
    </div>
  )
}

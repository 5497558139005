import { CREATE_TRUCKING_TYPE_URL, DELETE_TRUCKING_TYPE_URL, LIST_TRUCKING_TYPE_URL, UPDATE_TRUCKING_TYPE_URL, VIEW_TRUCKING_TYPE_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'trucking-category';
const REDIRECT_URL = '/app/truck/type';

export const TruckingTypeRQ = new RQ(
  QUERY_KEY,
  LIST_TRUCKING_TYPE_URL,
  VIEW_TRUCKING_TYPE_URL,
  CREATE_TRUCKING_TYPE_URL,
  UPDATE_TRUCKING_TYPE_URL,
  DELETE_TRUCKING_TYPE_URL,
  REDIRECT_URL
);
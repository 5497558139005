import {
  CREATE_TRUCK_URL,
  DELETE_TRUCK_URL,
  LIST_TRUCK_URL,
  UPDATE_TRUCK_URL,
  VIEW_TRUCK_URL,
} from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = "truck";
const REDIRECT_URL = "/app/truck/data";

export const truckRQ = new RQ(
  QUERY_KEY,
  LIST_TRUCK_URL,
  VIEW_TRUCK_URL,
  CREATE_TRUCK_URL,
  UPDATE_TRUCK_URL,
  DELETE_TRUCK_URL,
  REDIRECT_URL
);

import CdoPrint from "Components/Cdo/CdoPrint";
import BreadCrumb from "Components/Common/BreadCrumb";
import { CdoRQ } from "Hooks/Queries";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Card, CardBody, Container } from "reactstrap";

export default function PrintCdo() {
  const { id } = useParams();

  const { data } = CdoRQ.useGetData(id)();

  const [loadingPrint, setLoadingPrint] = useState(false);
  const componentRef = React.useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `cdo-doc-${data?.code??''}-${data?.created_on??''}`,
    onBeforePrint: () => {
      setLoadingPrint(true);
    },
    onAfterPrint: () => {
      setLoadingPrint(false);
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Cdo Request" />
        <Card>
          <CardBody>
            <CdoPrint data={data?.data} componentRef={componentRef} />
            <div className="modal-footer d-flex justify-content-end gap-1">
              <button
                className="btn btn-primary"
                type="button"
                onClick={handlePrint}
              >
                {loadingPrint ? "Loading..." : "Print"}
              </button>
              <Link
                className="btn btn-outline-dark"
                to={"/app/cdo/detail/" + id}
              >
                Cancel
              </Link>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

import {formatNumber} from 'Helpers/number'
import {Link} from 'react-router-dom'

export default function GeneralSettingItem({
  name,
  value,
  updateUrl,
  type = 'PPN',
}) {
  console.log(name, value)
  return (
    <tr>
      <div className='hstack gap-3 fs-15'>
        <Link to={updateUrl} className='link-warning'>
          <i className='ri-edit-2-line'></i>
        </Link>
      </div>
      <td>{name ?? type}</td>
      <td>
        {type === 'PPN'
          ? (value ?? 0) + '%'
          : `Rp. ${formatNumber(parseInt(value))}`}
      </td>
    </tr>
  )
}

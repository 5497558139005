import React, { useState } from 'react'
import { Card, CardBody, CardHeader, Container, Row } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import withTable from 'Components/Common/Table/withTable'
import { TopupItem } from 'Components/Topup/TopupItem'
import { useNavigate } from 'react-router-dom'
import { TopupRQ } from 'Hooks/Queries/topupRQ'
import { DATA_LIMIT } from 'configs'
import { formatNumber } from 'Helpers/number';

export default function ListTopup() {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DATA_LIMIT);
  const [keyword, setKeyword] = useState(null);

  const listRQ = TopupRQ.useGetDataList()(false, keyword, offset, limit);
  const getSaldoRQ = TopupRQ.useGetSaldo()();

  const navigate = useNavigate();

  const onAddButtonClicked = () => {
    navigate('/app/topups/create')
  }

  const onPageChange = (i) => {
    setOffset(i.selected);
  }

  const onPerPageChange = (i) => {
    setLimit(i);
    setOffset(0);
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
  }

  const TopupTable = withTable(
    TopupItem,
    'topups',
    ['NO', 'Aksi', 'Jumlah', 'Tanggal', 'Status'],
    null,
    onAddButtonClicked
  )

  const titleFormat = (v) =>
      v.replace(/[^a-zA-Z]/g, " ").replace(/^[a-zA-Z]/, (v) => v.toUpperCase());

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Topup"/>
        <Row>
          <Card>
            <CardHeader className="align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">{titleFormat('Total Saldo')}</h4>
            </CardHeader>
            <CardBody>
              {getSaldoRQ.data &&
                "Rp."+formatNumber(getSaldoRQ.data.data)
              }
            </CardBody>
          </Card>
        </Row>
        <Row>

          <TopupTable
            data={listRQ.data}
            isFetching={listRQ.isFetching}
            loading={listRQ.isFetching}
            error={listRQ.error}
            status={listRQ.status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            fetchNextPage={listRQ.fetchNextPage}
            hasNextPage={listRQ.hasNextPage}
            isFetchingNextPage={listRQ.isFetchingNextPage}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
    </div>
  )
}

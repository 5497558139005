import {formatNumber} from 'Helpers/number'
import {APPROVED, getBadgeClass} from 'Helpers/options'
import {Link} from 'react-router-dom'

export default function AgreementApproveItem({
  page,
  index,
  id,
  code,
  customer,
  quota_total,
  quota_used,
  quota_available,
  status,
  table,
  additionalActions,
}) {
  const {onAddQuotaClick, showMutaions} = additionalActions
  return (
    <tr>
      <td className='fw-medium'>{index + page + 1}</td>
      <td>
        <div className='text-center d-flex align-items-center'>
          <Link to={`/app/${table}/detail/${id}`} className='link-dark'>
            <i className='ri-eye-fill'></i>
          </Link>
          {status === APPROVED ? (
            <>
            <div
              role='button'
              onClick={() => onAddQuotaClick(id)}
              className='ms-2 link-dark'
            >
              <i className='bx bx-money'></i>
            </div>
            <div
              role='button'
              onClick={() => showMutaions(id)}
              className='ms-2 link-dark'
            >
              <i className='bx bx-list-ol'></i>
            </div>
            </>
          ) : null}
        </div>
      </td>
      <td>{code}</td>
      <td>{customer}</td>
      <td>Rp. {formatNumber(quota_total)}</td>
      <td>Rp. {formatNumber(quota_used)}</td>
      <td>Rp. {formatNumber(quota_available)}</td>
      <td>
        <span className={getBadgeClass(status)}>{status}</span>
      </td>
    </tr>
  )
}

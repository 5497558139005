import { CREATE_SELF_PICKUP_URL, DELETE_SELF_PICKUP_URL, LIST_SELF_PICKUP_URL, UPDATE_SELF_PICKUP_URL, VIEW_SELF_PICKUP_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'self-pickup';
const REDIRECT_URL = '/app/truck-book/self-pickup';

export const selfPickupRQ = new RQ(
  QUERY_KEY,
  LIST_SELF_PICKUP_URL,
  VIEW_SELF_PICKUP_URL,
  CREATE_SELF_PICKUP_URL,
  UPDATE_SELF_PICKUP_URL,
  DELETE_SELF_PICKUP_URL,
  REDIRECT_URL
);
import {memo, useState} from 'react'

const NestedPermissions = ({
  headers,
  dataPermission,
  onChange,
  permissionValues,
  disabled = false,
}) => {
  const [show, setShow] = useState(disabled)
  return (
    <>
      <tr onClick={() => setShow((v) => !v)}>
        <td colSpan={7} role='button'>
          <div className='d-flex align-items-center'>
            <span
              className='mdi mdi-chevron-right mdi-24px'
              style={{
                transform: show ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: 'all 0.3s ease-in-out 0s',
              }}
            ></span>
            <p className='mb-0'>{dataPermission.group}</p>
          </div>
        </td>
      </tr>
      {show
        ? dataPermission?.permissions?.map((permission) => {
            const options = permission.base_option.sort()
            return (
              <tr key={permission.id}>
                <td>
                  <span className='ms-3'>{permission.name}</span>
                </td>
                {headers.map((header, i) => {
                  const indexPermission = headers.findIndex(
                    (v) => v === options[i],
                  )
                  console.log(indexPermission, options[i], header)
                  const isPermissionExist = permissionValues.find(
                    (permissionValue) => permissionValue.id === permission.id,
                  )
                  return (
                    <td key={header}>
                      {indexPermission >= 0 ? (
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={
                            !!isPermissionExist &&
                            !!isPermissionExist?.value?.find(
                              (value) => value === options[i],
                            )
                          }
                          id='auth-remember-check'
                          onChange={(e) => {
                            onChange(permission.id, options[i])
                          }}
                          disabled={disabled}
                        />
                      ) : null}
                    </td>
                  )
                })}
              </tr>
            )
          })
        : null}
    </>
  )
}

export default memo(NestedPermissions)

import { formatNumber } from 'Helpers/number'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

const paymentStatuses = {
    PENDING : 'Menunggu Pembayaran',
    SUCCESS : 'Berhasil',
    FAILED : 'Gagal'
}

const getBadgeClass = (status) => {
    switch (status) {
      case 'PENDING':
        return "badge badge-soft-dark";
      case 'SUCCESS':
        return "badge badge-soft-success";
      default:
        return "badge badge-soft-danger";
    }
  };

const AgreementPaymentItem = ({
    page,
    index,
    idAgreement,
    id,
    transaction_time,
    status,
    amount,
    table,
}) => {
  return (
  <tr>
    <td className="fw-medium">{index + 1}</td>
    <td>
      <div className="hstack gap-3 fs-15">
        <Link to={table.replace(':id', idAgreement).replace(':paymentId', id)} className="link-success">
          <i className="ri-eye-fill"></i>
        </Link>
      </div>
    </td>
    <td>{moment(transaction_time).format('DD MMMM YYYY, HH:mm')}</td>
    <td>Rp. {formatNumber(amount)}</td>
    <td>
      <span className={getBadgeClass(status)}>{paymentStatuses[status]}</span>
    </td>
  </tr>
  )
}

export default AgreementPaymentItem
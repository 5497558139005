import { DATA_LIMIT } from "configs";
import React from "react";
import { Link } from "react-router-dom";

const FaqItem = ({
  page,
  index,
  id,
  title,
  category,
  onDeleteClick,
  table,
}) => {
  return (
    <tr>
      <td className="fw-medium">{index + page * DATA_LIMIT + 1}</td>
      <td>
        <div className="hstack gap-3 fs-15">
          <Link to={`/app/${table}/edit/${id}`} className="link-warning">
            <i className="ri-edit-2-line"></i>
          </Link>
          <Link
            to="#"
            className="link-danger"
            onClick={() => {
              onDeleteClick(id);
            }}
          >
            <i className="ri-delete-bin-5-line"></i>
          </Link>
        </div>
      </td>
      <td>{title}</td>
      <td>{category}</td>
    </tr>
  );
};

export default FaqItem;

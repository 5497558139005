import { useEffect, useState } from 'react'
import { LOKAL_STORAGE_PREFIX } from '../configs'
export function useLocalStorage(key, initialValue) {
  const prefixedKey = LOKAL_STORAGE_PREFIX + key

  const [value, setValue] = useState(() => {
    const jsonValue = localStorage.getItem(prefixedKey)
    try {
      if (jsonValue != null) return JSON.parse(jsonValue)
      
    } catch (error) {
      return initialValue
    }

    if (typeof initialValue === 'function') {
      return initialValue()
    } else {
      return initialValue
    }
  })

  useEffect(() => {
    localStorage.setItem(prefixedKey, JSON.stringify(value))
  }, [prefixedKey, value])

  return [value, setValue]
}

import {
  CREATE_SPPB_DOC_TYPE_URL,
  DELETE_SPPB_DOC_TYPE_URL,
  LIST_SPPB_DOC_TYPE_URL,
  UPDATE_SPPB_DOC_TYPE_URL,
  VIEW_SPPB_DOC_TYPE_URL,
} from 'Helpers/url_helper'
import RQ from './baseRQ'

const QUERY_KEY = 'sppb_docs'
const REDIRECT_URL = '/app/sppb-doc-type'

export const SppbDocTypeRQ = new RQ(
  QUERY_KEY,
  LIST_SPPB_DOC_TYPE_URL,
  VIEW_SPPB_DOC_TYPE_URL,
  CREATE_SPPB_DOC_TYPE_URL,
  UPDATE_SPPB_DOC_TYPE_URL,
  DELETE_SPPB_DOC_TYPE_URL,
  REDIRECT_URL,
)

import {createSlice} from '@reduxjs/toolkit'
import {loginAction, logoutAction, refreshTokenAction} from 'Redux/actions/auth'
// import { apiBearer } from '../../Helpers/api'
import {load, removeLocalSDatas, save} from '../../Helpers/localStorage'
import {showError} from '../../Helpers/show_toast'
import {fetchStatuses, LOGINTYPES} from '../constants'

const initialState = {
  status: fetchStatuses.idle,
  loading: false,
  error: null,
  isLoggedin: load('isLoggedin', false),
  token: load('token', null),
  profile: load('profile', null),
  googleToken: load('googleToken', null),
  nleToken: load('nleToken', null),
  nleRefreshToken: load('nleRefreshToken', null),
  autofill: load('regAutofill', {email: '', name: '', user_name: ''}),
}
export const auth = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setGoogleTokens(state, action) {
      save('googleToken', action.payload)
      state.googleToken = action.payload
    },
    setNleToken(state, action) {
      save('nleToken', action.payload)
      state.nleToken = action.payload
    },
    setNleRefreshToken(state, action) {
      save('nleRefreshToken', action.payload)
      state.nleRefreshToken = action.payload
    },
    setProfile(state, action) {
      const a = {...action.payload}
      save('profile', {...load('profile'), ...a})
      state.profile = {
        ...state.profile,
        ...a,
      }
    },
    setAutofillValues(state, {payload}) {
      console.log(payload)
      const prev = state.autofill ? state.autofill : load('regAutofill', null)
      save('regAutofill', {...prev, ...payload})
      state.autofill = prev
        ? {
            ...state.autofill,
            ...payload,
          }
        : {
            ...payload,
          }
    },
  },
  extraReducers(builder) {
    builder.addCase(loginAction.pending, (state) => {
      state.status = fetchStatuses.pending
      state.loading = true
    })
    builder.addCase(loginAction.fulfilled, (state, action) => {
      save('token', action.payload.token)
      save('email', action.payload.email)
      save('isLoggedin', true)
      state.token = action.payload.token
      delete action.payload.token
      save('profile', action.payload)
      state = {
        ...state,
        loading: false,
        isLoggedin: true,
        error: null,
        status: fetchStatuses.succeeded,
        profile: action.payload,
      }
      if (!action.payload.is_approved) {
        window.location.href = '/app/profile'
      } else {
        window.location.href = action.payload.redirectTo
      }
    })
    builder.addCase(loginAction.rejected, (state, action) => {
      state.loading = false
      state.status = fetchStatuses.failed
      showError(action.payload.message)
      if (action.payload.message?.toLowerCase().includes('not found')) {
        window.location.href = '/register'
      }
    })
    builder.addCase(logoutAction.pending, (state) => {
      state.status = fetchStatuses.pending
      state.loading = true
    })
    builder.addCase(logoutAction.fulfilled, (state, action) => {
      state.token = null
      removeLocalSDatas()
      state = {
        ...state,
        isLoggedin: false,
        token: null,
        error: null,
        status: 'idle',
        loading: false,
        profile: null,
      }
      window.location.href = '/login'
    })
    builder.addCase(logoutAction.rejected, (state, action) => {
      state.status = fetchStatuses.failed
      state.loading = false
    })
    builder.addCase(refreshTokenAction.fulfilled, (state, action) => {
      const {refreshType, token, access_token, refresh_token} =
        action.payload || {}
      state.loading = true
      if (refreshType === LOGINTYPES.NLE) {
        state.nleRefreshToken = access_token
        state.nleToken = refresh_token
        save('nleRefreshToken', action.payload.token)
        save('nleToken', action.payload.token)
      } else {
        save('token', action.payload.token)

        state.token = token
      }
    })
    builder.addCase(refreshTokenAction.pending, (state, action) => {
      state.loading = false
    })
    builder.addCase(refreshTokenAction.rejected, (state, action) => {
      state.loading = false
    })
  },
})

export const {
  setGoogleTokens,
  setNleToken,
  setProfile,
  setAutofillValues,
  setNleRefreshToken,
} = auth.actions

export default auth.reducer

import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import SimpleBar from "simplebar-react";
import { DriverRQ } from "Hooks/Queries";
import { apiBearer } from "Helpers/api";
import { APPROVE_DRIVER_URL } from "Helpers/url_helper";
import { showSuccess } from "Helpers/show_toast";
import ConfirmModal from "Components/Common/ConfirmModal";

export default function DetailExternalDriver(props) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false)
  const [confirmModal, setConfirmModal] = useState(false);

  const { data, error, isFetching, status, refetch } = DriverRQ.useGetData(id)();

  const approve = () => {
    setLoading(true);
    apiBearer
      .patch(APPROVE_DRIVER_URL(id))
      .then(() => {
        showSuccess("Approved successfully");
        refetch();
      })
      .finally(() => {
        setLoading(false);
        setConfirmModal(false)
      });
  };
  useEffect(() => {
    if (!isFetching && status === "success") {
    }
    if (!isFetching && status === "error") {
      console.log(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Driver" />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={function (e) {
                e.preventDefault();
              }}
            >
              <Card>
                <CardHeader className="d-flex align-items-center">
                  <h6 className="card-title mb-0 flex-grow-1">Detail External Driver</h6>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive table-card">
                    <SimpleBar style={{ maxHeight: "365px" }}>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex items-center">
                            <div className="ms-3 col-6">Email :</div>
                            <div className="ms-3 col-3">
                              <h6>{data?.data?.email ?? "-"}</h6>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Username :</div>
                            <div className="ms-3 col-3">
                              <h6>{data?.data?.user_name ?? "-"}</h6>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Password :</div>
                            <div className="ms-3 col-3">
                              <h6>
                                {Array.from(new Array(8)).map((v) => "*").join("")
                                  }
                              </h6>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Name :</div>
                            <div className="ms-3 col-3">
                              <h6>{data?.data?.name ?? "-"}</h6>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">NIK :</div>
                            <div className="ms-3 col-3">
                              <h6>{data?.data?.nik ?? "-"}</h6>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Masa Aktif SIM :</div>
                            <div className="ms-3 col-3">
                              <h6>{data?.data?.sim_expired ?? "-"}</h6>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Masa Aktif KIR :</div>
                            <div className="ms-3 col-3">
                              <h6>{data?.data?.kir_expired ?? "-"}</h6>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Foto SIM :</div>
                            <div className="ms-3 col-3">
                              <a
                                className="text-decoration-underline"
                                target={data?.data?.sim_url ? "_blank" : ""}
                                rel="noreferrer"
                                href={data?.data?.sim_url ? data?.data?.sim_url : "#"}
                              >
                                Preview
                              </a>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Foto KTP :</div>
                            <div className="ms-3 col-3">
                              <a
                                className="text-decoration-underline"
                                target={data?.data?.ktp_url ? "_blank" : ""}
                                rel="noreferrer"
                                href={data?.data?.ktp_url ? data?.data?.ktp_url : "#"}
                              >
                                Preview
                              </a>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Foto Driver :</div>
                            <div className="ms-3 col-3">
                              <a
                                className="text-decoration-underline"
                                target={data?.data?.photo_url ? "_blank" : ""}
                                rel="noreferrer"
                                href={data?.data?.photo_url ? data?.data?.photo_url : "#"}
                              >
                                Preview
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </SimpleBar>
                  </div>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link className="btn btn-light" to="/app/external-driver">
                    Kembali
                  </Link>
                  {data?.data?.is_approved ? null : (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={()=>setConfirmModal(v=>!v)}
                    >
                      {loading ? "Approving..." : "Approve"}
                    </button>
                  )}
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
        <ConfirmModal
          description="Yakin ingin approve driver?"
          loading={loading}
          onConfirm={approve}
          onCloseClick={() => setConfirmModal((v) => !v)}
          show={confirmModal}
        />
      </Container>
    </div>
  );
}

import { CREATE_FAQ_URL, DELETE_FAQ_URL, LIST_FAQ_URL, UPDATE_FAQ_URL, VIEW_FAQ_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'faq';
const REDIRECT_URL = '/app/faq';

export const faqRQ = new RQ(
  QUERY_KEY,
  LIST_FAQ_URL,
  VIEW_FAQ_URL,
  CREATE_FAQ_URL,
  UPDATE_FAQ_URL,
  DELETE_FAQ_URL,
  REDIRECT_URL
);
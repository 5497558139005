import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorFieldWrapper from "../../../Components/Common/ErrorFieldWrapper";
import { staticPageRQ } from "Hooks/Queries";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { faqCategories } from "Helpers/options";
import Editor from "Components/Editor";

export default function EditCreateStaticPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const validation = Yup.object().shape({
    content: Yup.string().required("Konten tidak boleh kosong"),
    title: Yup.string().required("Judul tidak boleh kosong"),
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      content: "",
      title: "",
    },
  };
  const { handleSubmit, formState, register, reset, getValues, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const { mutate: updateTrucking, isLoading: updateLoading } = staticPageRQ.useUpdateData(navigate)();

  const _updateStaticPage = (values) => {
    const body = values;
    body.id = id;
    updateTrucking(body);
  };

  const { mutate: addTrucking, isLoading: createLoading } = staticPageRQ.useAddData(navigate)();

  const _createStaticPage = async (values) => {
    addTrucking(values);
  };

  const { data, error, isFetching, status } = staticPageRQ.useGetData(id)();

  useEffect(() => {
    if (!isFetching && status === "success") {
      reset({
        title: data.data?.title ?? "",
        content: data.data?.content ?? "",
      });
    }
    if (!isFetching && status === "error") {
      console.log(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  const isLoading = createLoading || updateLoading

  const submitBtnText = ()=> {
    if(isLoading) return 'Loading...'
    return id ? "Update" : "Create"
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Static Page" pageTitle="Static Page" />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={handleSubmit(
                id ? _updateStaticPage : _createStaticPage
              )}
            >
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {id ? "Edit" : "Create"} Static Page
                  </h4>
                </CardHeader>
                <CardBody className="card-body">
                  <Row className="gy-4">
                    <Col sm={12}>
                      <ErrorFieldWrapper
                        error={errors.title}
                        message={errors.title?.message}
                      >
                        <Label>
                          Judul <span className="mandatory">*</span>
                        </Label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("title")}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12}>
                      <ErrorFieldWrapper
                        error={errors.category}
                        message={errors.category?.message}
                      >
                        <Editor onEditorChange={(e)=>setValue('content',e)} initialValue={getValues().content}/>
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to="/app/static-page" className="btn btn-danger">
                    Cancel
                  </Link>
                  <Button color="success" type="submit" disabled={isLoading}>
                    {submitBtnText()}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

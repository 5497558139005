import {getBadgeClass, UNPAID} from 'Helpers/options'
import React from 'react'
import {Link} from 'react-router-dom'

export default function CdoRequestItem({
  index,
  page,
  limit,
  table,
  id,
  awb,
  do_no,
  hawb,
  payment_status,
  sppb,
  validation_status,
  created_on,
}) {
  return (
    <tr>
      <td className='fw-medium'>{index + page + 1}</td>
      <td>
        <div className='hstack gap-3 fs-15'>
          <Link to={`/app/${table}/detail/${id}`} className='link-success'>
            <i className='ri-eye-fill'></i>
          </Link>
        </div>
      </td>
      <td>{awb ?? '-'}</td>
      <td>{hawb ?? '-'}</td>
      <td>{do_no ?? '-'}</td>
      <td>{sppb ?? '-'}</td>
      <td>{created_on ?? '-'}</td>
      <td>
        {payment_status === UNPAID ? (
          <span className='badge badge-soft-dark'>{payment_status}</span>
        ) : (
          <span className='badge badge-soft-success'>{payment_status}</span>
        )}
      </td>
      <td>
        <span className={getBadgeClass(validation_status)}>
          {validation_status}
        </span>
      </td>
    </tr>
  )
}

import React from 'react'
import { Container, Row } from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import { useState } from 'react'

import withTable from 'Components/Common/Table/withTable'
import { MutationBalanceItem } from 'Components/MutationBalance/MutationBalanceItem'
import { BalanceRQ } from 'Hooks/Queries/balanceRQ'
import { DATA_LIMIT } from 'configs'

export default function ListMutationBalance() {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(DATA_LIMIT);
  const [keyword, setKeyword] = useState(null);

  const listRQ = BalanceRQ.useGetDataListMutation()(false, keyword, offset, limit);


  const onPageChange = (i) => {
    setOffset(i.selected);
  }

  const onPerPageChange = (i) => {
    setLimit(i);
    setOffset(0);
  }

  const onKeywordChange = (value) => {
    setKeyword(value)
  }

  const MutationBalanceTable = withTable(
    MutationBalanceItem,
    'mutation',
    ["NO", "TANGGAL", "KETERANGAN","DEBIT", "CREDIT", "SALDO"],
    null,
    null
  )

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Mutasi Saldo"/>
        <Row>

          <MutationBalanceTable
            data={listRQ.data}
            isFetching={listRQ.isFetching}
            loading={listRQ.isFetching}
            error={listRQ.error}
            status={listRQ.status}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
            fetchNextPage={listRQ.fetchNextPage}
            hasNextPage={listRQ.hasNextPage}
            isFetchingNextPage={listRQ.isFetchingNextPage}
            onKeywordChange={onKeywordChange}
            keyword={keyword}
          />
        </Row>
      </Container>
    </div>
  )
}

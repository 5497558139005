import {yupResolver} from '@hookform/resolvers/yup'
import BreadCrumb from 'Components/Common/BreadCrumb'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {apiBearer} from 'Helpers/api'
import debounce from 'Helpers/debounce'
import {LIST_TRUCKING_URL} from 'Helpers/url_helper'
import {CommissionRQ} from 'Hooks/Queries'
import {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {Link, useNavigate, useParams} from 'react-router-dom'
import Select from 'react-select/async'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from 'reactstrap'
import * as Yup from 'yup'

export default function EditCreateCommission(props) {
  const {id} = useParams()
  const navigate = useNavigate()
  const [truck, setTrucking] = useState(null)
  const [trucks, setTrucks] = useState()

  const fetchTrucks = async (input) => {
    return new Promise(async (res, rej) => {
      try {
        const result = await apiBearer.get(
          `${LIST_TRUCKING_URL}?search=${input}&size=${10}`,
        )
        const {data} = result
        if (data.success) {
          setTrucks(
            data?.data?.data?.map((v) => ({label: v.name, value: v.id})),
          )
          res(data?.data?.data?.map((v) => ({label: v.name, value: v.id})))
        }
        rej(data.message)
      } catch (error) {
        rej(error)
      }
    })
  }

  const debounceSearchTrucks = debounce((input, cb) => {
    fetchTrucks(input).then((op) => cb(op))
  }, 1500)

  const validation = Yup.object().shape({
    percent_value: Yup.string().required('Persentase tidak boleh kosong'),
    partner_id: Yup.string().required('Vendor trucking tidak boleh kosong'),
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      percent_value: '',
      partner_id: '',
    },
  }
  const {handleSubmit, formState, register, reset, control} =
    useForm(formOptions)
  const {errors} = formState

  const onTruckCatSelected = async (e) => {
    setTrucking(e)
  }

  const {mutate: updateTrucking, isLoading: updateLoading} = CommissionRQ.useUpdateData(navigate)()

  const _updateCommission = (values) => {
    const body = values
    body.id = id
    updateTrucking(body)
  }

  const {mutate: addTrucking, isLoading: createLoading} = CommissionRQ.useAddData(navigate)()

  const _createCommission = async (values) => {
    addTrucking(values)
  }

  const {data, error, isFetching, status} = CommissionRQ.useGetData(id)()

  useEffect(() => {
    if (!isFetching && status === 'success') {
      reset({
        percent_value: data?.data?.percent_value ?? '',
        partner_id: data?.data?.partner?.id ?? '',
      })
      if (data?.data?.partner) {
        setTrucking({
          label: data.data.partner.name,
          value: data.data.partner.id,
        })
      }
    }
    if (!isFetching && status === 'error') {
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching])

  const isLoading = createLoading || updateLoading

  const submitBtnText = () => {
    if(isLoading) return 'Loading...'
    return id ? 'Update' : 'Create'
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Komisi' pageTitle='Komisi' />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={handleSubmit(
                id ? _updateCommission : _createCommission,
              )}
            >
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {id ? 'Edit' : 'Create'} Komisi
                  </h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='gy-4'>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.partner_id}
                        message={errors.partner_id?.message}
                      >
                        <Label htmlFor='partner_id' className='form-label'>
                          Vendor Trucking <span className='mandatory'>*</span>
                        </Label>
                        <Controller
                          id='partner_id'
                          name='partner_id'
                          type='text'
                          control={control}
                          render={({field: {onChange, ...field}}) => (
                            <Select
                              {...field}
                              cacheOptions
                              isClearable
                              defaultOptions
                              value={truck ?? ''}
                              loadOptions={debounceSearchTrucks}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value)
                                onTruckCatSelected(e)
                              }}
                              placeholder='Search by name'
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.percent_value}
                        message={errors.percent_value?.message}
                      >
                        <Label htmlFor='percent_value'>
                          Persentase Komisi <span className='mandatory'>*</span>
                        </Label>
                        <input
                          className='form-control'
                          {...register('percent_value')}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link
                    to='/app/struktur-harga/trucking/komisi'
                    className='btn btn-danger'
                  >
                    Cancel
                  </Link>
                  <Button color='success' type='submit' disabled={isLoading}>
                    {submitBtnText()}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

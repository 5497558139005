import ImageTextPreview from 'Components/Common/ImageTextPreview'
import {toBase64} from 'Helpers/fileToBase64'
import React, {useEffect, useState} from 'react'
import {Col, Label, Row, Table} from 'reactstrap'

let idd = 0
const initialDocs = [
  {
    name: '',
    file: '',
  },
]

const BookFormFiles = ({setFiles, initialFiles}) => {
  const [docs, setDocs] = useState(initialFiles ?? initialDocs)

  const onChangeDocs = async (id, key, value) => {
    const newValue = key === 'file' ? await toBase64(value) : value
    setDocs((prevDocs) =>
      prevDocs.map((prevDoc) => {
        if (prevDoc.id === id) {
          return {...prevDoc, [key]: newValue}
        }
        return prevDoc
      }),
    )
  }

  const onAddDocInputs = () => {
    setDocs([
      ...docs,
      {
        id: idd++,
        doc: '',
      },
    ])
  }

  const onRemoveDocInputs = (id) => {
    setDocs((prevs) => prevs.filter((prev) => prev.id !== id))
  }

  useEffect(() => {
    setFiles(
      docs.filter((v) => v.file).map((v) => ({name: v.name, file: v.file})),
    )
  }, [docs, setFiles])

  return (
    <>
      <h2 className='mt-4'>Attachment</h2>
      <hr />
      <div className='table-responsive'>
        <table className='table table-bordered align-middle table-nowrap mb-0'>
          <thead>
            <tr>
              <th>NAMA DOKUMEN</th>
              <th>FILE</th>
              <th >
                <i className='mdi mdi-plus mdi-24px' role='button' onClick={onAddDocInputs}/>
              </th>
            </tr>
          </thead>
          <tbody>
            {docs.map((doc, i) => (
              <tr key={i}>
                <td>
                  <input
                    type='text'
                    className='form-control'
                    onChange={(e) =>
                      onChangeDocs(doc.id, 'name', e.target.value)
                    }
                  />
                </td>
                <td>
                  <input
                    type='file'
                    className='form-control'
                    onChange={(e) =>
                      onChangeDocs(doc.id, 'file', e.target.files[0])
                    }
                  />
                </td>
                {i >= 1 ? (
                  <td>
                    <i
                      className='mdi mdi-trash-can-outline mdi-24px'
                      onClick={() => onRemoveDocInputs(doc.id)}
                      role='button'
                    />
                  </td>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default React.memo(BookFormFiles)

import { CREATE_TRUCKING_CAT_URL, DELETE_TRUCKING_CAT_URL, LIST_TRUCKING_CAT_URL, UPDATE_TRUCKING_CAT_URL, VIEW_TRUCKING_CAT_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'trucking-category';
const REDIRECT_URL = '/app/truck/category';

export const TruckingCatRQ = new RQ(
  QUERY_KEY,
  LIST_TRUCKING_CAT_URL,
  VIEW_TRUCKING_CAT_URL,
  CREATE_TRUCKING_CAT_URL,
  UPDATE_TRUCKING_CAT_URL,
  DELETE_TRUCKING_CAT_URL,
  REDIRECT_URL
);
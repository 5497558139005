import React from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { TruckingTypeRQ } from "Hooks/Queries";

export default function DetailTruckingCat(props) {
  const { id } = useParams();

  const { data, error, isFetching, status } =
    TruckingTypeRQ.useGetData(id)();

  useEffect(() => {
    if (!isFetching && status === "success") {
    }
    if (!isFetching && status === "error") {
      console.log(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Trucking Type" pageTitle="Trucking Type" />
        <Row>
          <Col lg={12}>
            <form onSubmit={() => {}}>
              <Card>
              <CardHeader className="d-flex justify-content-between align-items-center">
                  <h4 className="">Detail Trucking Type</h4>
                  {/* {data?.data?.is_approved ? (
                    <Badge color="success">APPROVED </Badge>
                  ) : (
                    <Badge color="warning">PENDING </Badge>
                  )} */}
                </CardHeader>
                <CardBody className="card-body">
                  <Row className="gy-4">
                    <Col md={6}>
                      <Label htmlFor="name" className="form-label">
                        Name
                      </Label>
                      <Input
                        disabled
                        id="name"
                        name="name"
                        type="name"
                        value={data?.data?.name ?? ""}
                      />
                    </Col>
                    <Col md={6}>
                      <Label htmlFor="category" className="form-label">
                        Category
                      </Label>
                      <Input
                        disabled
                        id="category"
                        name="category"
                        type="category"
                        value={data?.data?.truck_category?.name ?? ""}
                      />
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to="/app/trucking/type" className="btn btn-outline-dark">
                    Back
                  </Link>
                  {/* {data?.data?.is_approved ? null : (
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={()=>setConfirmModal(v=>!v)}
                    >
                      {loading ? "Approving..." : "Approve"}
                    </button>
                  )} */}
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

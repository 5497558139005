import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import { truckingRateRQ } from "Hooks/Queries";
import { formatNumber } from "Helpers/number";

export default function DetailRateTrucking(props) {
  const { id } = useParams();
  const { data, error, isFetching, status } = truckingRateRQ.useGetData(id)();
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Rate Trucking" />
        <Card>
          <CardHeader>
            <h1 className="fs-4">Detail Rate Trucking</h1>
          </CardHeader>
          <CardBody className="card-body">
            <div className="table-responsive">
              <Table className="table-hover table-bordered align-middle table-nowrap mb-0">
                <thead></thead>
                <tbody>
                  <tr>
                    <th
                      style={{ background: "rgb(243, 246, 249)" }}
                      colSpan={3}
                    >
                      General
                    </th>
                  </tr>
                  <tr>
                    <th style={{ maxWidth: "20%" }}>Waktu Tunggu</th>
                    <td colSpan={2}>{data?.data?.lead_time ?? "-"}</td>
                  </tr>
                  <tr>
                    <th style={{ maxWidth: "20%" }}>Asal</th>
                    <td colSpan={2}>{data?.data?.origin_point_name ?? "-"}</td>
                  </tr>
                  <tr>
                    <th style={{ maxWidth: "20%" }}>Tujuan</th>
                    <td colSpan={2}>
                      {data?.data?.destination_point_name ?? "-"}
                    </td>
                  </tr>
                  <tr>
                    <th style={{ maxWidth: "20%" }}>Catatan</th>
                    <td colSpan={2}>{data?.data?.notes ?? "-"}</td>
                  </tr>
                  <tr>
                    <th
                      style={{ background: "rgb(243, 246, 249)" }}
                      colSpan={3}
                    >
                      List Harga Truk
                    </th>
                  </tr>
                  <tr>
                    <th>Kategori</th>
                    <th>Tipe</th>
                    <th>Rate Harga</th>
                  </tr>
                  {(data?.data?.items?.length > 0 ? data?.data?.items : []).map(
                    (v, i) => (
                      <tr key={v?.id ?? i}>
                        <td>{v?.truck_category?.name ?? "-"}</td>
                        <td>{v?.truck_type?.name ?? "-"}</td>
                        <td>RP. {formatNumber(v.price)}</td>
                      </tr>
                    )
                  )}
                  <tr>
                    <th
                      style={{ background: "rgb(243, 246, 249)" }}
                      colSpan={3}
                    >
                      System
                    </th>
                  </tr>
                  <tr>
                    <th style={{ maxWidth: "20%" }}>Waktu dibuat</th>
                    <td colSpan={2}>{data?.data?.created_on ?? "-"}</td>
                  </tr>
                  <tr>
                    <th style={{ maxWidth: "20%" }}>Waktu diubah</th>
                    <td colSpan={2}>{data?.data?.action_date ?? "-"}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </CardBody>
          <CardFooter className="align-items-center d-flex justify-content-end gap-3">
            <Link to="/app/trucking-rate" className="btn btn-danger">
              Kembali
            </Link>
          </CardFooter>
        </Card>
      </Container>
    </div>
  );
}

import {useQuery} from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import {apiBearer} from 'Helpers/api'
import {formatNumber} from 'Helpers/number'
import {AGREEMENT_APPROVAL_RECEIVABLE_DETAIL} from 'Helpers/url_helper'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import {Card, CardBody, Col, Container, Row, Table} from 'reactstrap'

const AgreementReceivableDetail = () => {
  const {id, date} = useParams()
  const {state} = useLocation()

  const {data, isFetching, status} = useQuery(
    ['detail-receivable-agreement', id, date],
    async () => {
      const response = await apiBearer.get(
        AGREEMENT_APPROVAL_RECEIVABLE_DETAIL(id, date),
      )
      const data = response.data?.data
      return data
    },
    {
      enabled: !!id && !!date,
    },
  )

  const {amount, transaction_time, invoices} = data || {}

  useEffect(() => {
    if (status === 'success' && !isFetching) {
    }
  }, [isFetching, status])

  const render = () => {
    if (isFetching)
      return (
        <div id='preloader'>
          <div id='status'>
            <div
              className='spinner-border text-primary avatar-sm'
              role='status'
            >
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        </div>
      )

    if (!isFetching && status === 'success')
      return (
        <>
          <CardBody>
          <div className='d-flex justify-content-between flex-wrap my-4'>
            <h4>Riwayat Transaksi</h4>
            <h4>Consignee : {state?.consigneeName}</h4>
          </div>
            <div style={{border: '1px solid #D4D7DD'}}>
              <div style={{background: '#F3F4F4'}} className='p-3'>
                <h4>Detail Installment</h4>
              </div>
              {data?.invoices ? (
                <div className='p-3 d-flex' style={{color: '#677788'}}>
                  <div className='me-5'>
                    <h5>Total Pembayaran</h5>
                    <p className='lead'>Rp. {formatNumber(amount)}</p>
                  </div>
                  <div className=''>
                    <h5>Tgl Jatuh Tempo</h5>
                    <p className='lead'>
                      {moment(transaction_time).format(
                        'DD MMMM YYYY',
                      )}
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          </CardBody>
          <div className='px-3'>
            <h4 className='mb-2 mt-4'>List Transaksi</h4>
          </div>
          <div className='table-responsive'>
            <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
              <thead>
                <tr>
                  <th scope='col'>No Invoice</th>
                  <th scope='col'>Jangka Waktu</th>
                  <th scope='col'>total Pembayaran</th>
                  <th scope='col'>cicilan</th>
                  <th scope='col'>aksi</th>
                </tr>
              </thead>
              <tbody>
                {data?.invoices?.map((invoice) => (
                  <tr key={invoice.id}>
                    <td>{invoice.invoice_number}</td>
                    <td>{invoice.termin} Bulan</td>
                    <td>Rp. {formatNumber(invoice.amount)}</td>
                    <td>
                      {invoice.termin_phase} dari {invoice.termin} bulan
                    </td>
                    <Link 
                        to={`/app/trucking-order/detail/${invoice.booking_id}`}
                        className='link-dark'
                        >
                        <i className='ri-eye-fill'></i>
                    </Link>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className='d-flex p-3 justify-content-between align-items-center'>
              <div className='' style={{color: '#677788'}}>
                <div className='d-flex align-items-center'>
                  <h5 style={{minWidth: '12rem'}}>Total Pembayaran</h5>{' '}
                  <span style={{minWidth: 10, fontWeight: 800}}>:</span>
                  <p className='lead mb-0'>Rp. {formatNumber(amount)}</p>
                </div>
                <div className='d-flex align-items-center'>
                  <h5 style={{minWidth: '12rem'}}>Tgl Jatuh Tempo</h5>{' '}
                  <span style={{minWidth: 10, fontWeight: 800}}>:</span>
                  <p className='lead mb-0'>
                    {moment(transaction_time).format('DD MMMM YYYY')}
                  </p>
                </div>
              </div>

            <Link
              to={'/app/agreement-approval/detail/' + `${id}/credit-limit`}
              className='btn btn-outline-dark'
            >
              Kembali
            </Link>
          </div>
        </>
      )

    return <h4 className='text-danger'>Ada kesalahan, silahkan coba lagi nanti.</h4>
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Riwayat Transaksi' />
        <Row>
          <Col lg={12}>
            <Card>
              {render()}
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AgreementReceivableDetail

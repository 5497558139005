import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import * as Yup from "yup";
import { validPhone } from "Helpers/regex_validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { truckingRateRQ } from "Hooks/Queries";
import ErrorFieldWrapper from "Components/Common/ErrorFieldWrapper";
import FloatingInput from "Components/Common/FloatingInput";
import moment from "moment";
import AcyncSelect from "react-select/async";
import Select from "react-select";
import debounce from "Helpers/debounce";
import {
  LIST_TRUCKING_CAT_URL,
  TRUCKING_TYPE_BASE_API,
} from "Helpers/url_helper";
import { apiBearer } from "Helpers/api";
import { locationPointRQ } from "Hooks/Queries/locationPointRQ";

let idd = 0;
const initialDatas = [
  {
    id: idd++,
    truck_category: { label: "", value: "" },
    truck_type: { label: "", value: "" },
    price: "",
  },
];
export default function EditCreateTruckingRate(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [datas, setDatas] = useState(initialDatas);
  const [selectedOrigin, setSelectedOrigin] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState([]);
  const [originOptions, setOriginOptions] = useState([]);

  const {
    data: dataLocations,
    isFetching: isFetchingLocation,
    status: statusLocations,
  } = locationPointRQ.useGetDataList()(false, "", 0, 1000);

  useEffect(() => {
    if (!isFetchingLocation && statusLocations === "success") {
      if (dataLocations?.data) {
        setOriginOptions(
          dataLocations.data.map((v) => ({ label: v.name, value: v.id }))
        );
      }
    }
  }, [isFetchingLocation, statusLocations]);

  const [tructTypeOptions, setTruckTypeOptions] = useState([]);

  const fetchTruckCats = async (input) => {
    return new Promise(async (res, rej) => {
      try {
        const result = await apiBearer.get(
          `${LIST_TRUCKING_CAT_URL}?keyword=${input}&size=${10}`
        );
        const { data } = result;
        if (data.success) {
          res(data?.data?.data?.map((v) => ({ label: v.name, value: v.id })));
        }
        rej(data.message);
      } catch (error) {
        rej(error);
      }
    });
  };

  const debounceSearchTrucks = debounce((input, cb) => {
    fetchTruckCats(input).then((op) => cb(op));
  }, 1500);

  const onTruckCatSelected = async (id, e) => {
    setDatas((prevs) =>
      prevs.map((prev) => {
        if (prev.id === id) {
          return { ...prev, truck_category: e };
        }
        return prev;
      })
    );
    if (e?.value) {
      const truckTypeResponse = await apiBearer.get(
        `${TRUCKING_TYPE_BASE_API}/by-category/${e.value}`
      );
      const { data } = truckTypeResponse;
      if (data.success) {
        setTruckTypeOptions(
          data?.data?.map((v) => ({ label: v?.name, value: v?.id }))
        );
      }
    }
  };

  const onTruckTypeSelected = (id, e) => {
    setDatas((prevs) =>
      prevs.map((prev) => {
        if (prev.id === id) {
          return { ...prev, truck_type: e };
        }
        return prev;
      })
    );
  };

  const onPriceChange = (id, value) => {
    setDatas((prevs) =>
      prevs.map((prev) => {
        if (prev.id === id) {
          return { ...prev, price: value };
        }
        return prev;
      })
    );
  };

  const onAddInputs = () => {
    setDatas([
      ...datas,
      {
        truck_category: "",
        truck_type: "",
        price: "",
        id: idd++,
      },
    ]);
  };

  const onRemoveInputs = (id) => {
    setDatas((prevs) => prevs.filter((prev) => prev.id !== id));
  };

  const validation = Yup.object().shape({
    destination_point_id: Yup.string().required("Tujuan tidak boleh kosong"),
    origin_point_id: Yup.string().required("Asal tidak boleh kosong"),
    lead_time: Yup.number().required("Waktu tunggu tidak boleh kosong"),
    notes: Yup.string().required("Catatan tidak boleh kosong"),
    items: Yup.mixed(),
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      destination_point_id: "",
      origin_point_id: "",
      lead_time: 0,
      notes: "",
      items: [],
    },
  };
  const { handleSubmit, formState, control, reset, register } =
    useForm(formOptions);
  const { errors } = formState;

  const { mutate: updateCdo, isLoading: updateLoading } = truckingRateRQ.useUpdateData(navigate)();

  const getPayload = (values) => {
    const formData = values;
    formData.items = datas.map((data) => {
      return {
        ...data,
        truck_category_id: data.truck_category.value,
        truck_type_id: data.truck_type.value,
      };
    });
    formData.items.map((v) => {
      delete v.id;
      return v;
    });
    return formData;
  };
  const _updateCdo = (values) => {
    const merge = getPayload(values);
    merge.id = id;
    updateCdo(merge);
  };

  const { mutate: addCdo, isLoading: createLoading } = truckingRateRQ.useAddData(navigate)();

  const _createCdo = (values) => {
    addCdo(getPayload(values));
  };

  const { data, error, isFetching, status } = truckingRateRQ.useGetData(id)();

  useEffect(() => {
    if (!isFetching && status === "success") {
      reset({
        destination_point_id: data?.data?.destination_point_id ?? "",
        items: [],
        lead_time: data?.data?.lead_time ?? 0,
        notes: data?.data?.notes ?? "",
        origin_point_id: data?.data?.origin_point_id ?? "",
      });
      if (data?.data?.items) {
        setDatas(
          data.data.items.map((v) => {
            const { truck_category, truck_type } = v;
            return {
              ...v,
              truck_category: {
                label: truck_category.name,
                value: truck_category.id,
              },
              truck_type: { label: truck_type.name, value: truck_type.id },
              id: idd++,
            };
          })
        );
      }
      // "origin_point_id": "f17dd4b5-bde4-4bd9-855a-8271fe4a5629",
      // "origin_point_name": "Asal Dummy",
      // "destination_point_id": "ca589fef-9305-4575-b23e-0e24d2565e19",
      // "destination_point_name": "Tujuan Dummy",
      if (data?.data?.destination_point_name)
        setSelectedDestination({
          label: data.data.destination_point_name,
          value: data.data.destination_point_id,
        });
      if (data?.data?.origin_point_name)
        setSelectedOrigin({
          label: data.data.origin_point_name,
          value: data.data.origin_point_id,
        });
    }
    if (!isFetching && status === "error") {
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  const isLoading = createLoading || updateLoading

  const submitBtnText = ()=> {
    if(isLoading) return 'Loading...'
    return id ? "Update" : "Create"
  }


  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Rate Trucking" />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(id ? _updateCdo : _createCdo)}>
              <Card>
                <CardBody className="card-body">
                  <h4 className="">{id ? "Update" : "Create"} Rate Trucking</h4>
                  <Row>
                    <Col lg={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.origin_point_id}
                        message={errors.origin_point_id?.message}
                      >
                        <Label>
                          Asal <span className="mandatory">*</span>
                        </Label>
                        <Controller
                          id="origin_point_id"
                          name="origin_point_id"
                          type="text"
                          control={control}
                          render={({ field: { onChange, ...field } }) => (
                            <Select
                              {...field}
                              isClearable
                              value={selectedOrigin}
                              options={originOptions}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value);
                                setSelectedOrigin(e);
                              }}
                              placeholder="Search by name"
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors.destination_point_id}
                        message={errors.destination_point_id?.message}
                      >
                        <Label>
                          Tujuan <span className="mandatory">*</span>
                        </Label>
                        <Controller
                          id="destination_point_id"
                          name="destination_point_id"
                          type="text"
                          control={control}
                          render={({ field: { onChange, ...field } }) => (
                            <Select
                              {...field}
                              isClearable
                              value={selectedDestination}
                              options={originOptions}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value);
                                setSelectedDestination(e);
                              }}
                              placeholder="Search by name"
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                  <h4 className="mt-4">Type Truck & Rate</h4>
                  <Table className="table-hover table-bordered align-middle table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Kategori</th>
                        <th>Tipe</th>
                        <th>Rate Harga</th>
                        <th>Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {datas.map(
                        ({ id, truck_category, truck_type, price }, i) => (
                          <tr key={id}>
                            <td>{i + 1}</td>
                            <td style={{ minWidth: "30%" }}>
                              <AcyncSelect
                                cacheOptions
                                isClearable
                                defaultOptions
                                value={truck_category ?? ""}
                                loadOptions={debounceSearchTrucks}
                                onChange={(e) => {
                                  if (e?.value) onTruckCatSelected(id, e);
                                }}
                                placeholder="Search by name"
                              />
                            </td>
                            <td style={{ minWidth: "30%" }}>
                              <Select
                                cacheOptions
                                isClearable
                                defaultOptions
                                value={truck_type ?? ""}
                                options={tructTypeOptions}
                                onChange={(e) => {
                                  if (e?.value) onTruckTypeSelected(id, e);
                                }}
                                placeholder="Search by name"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder="Harga"
                                value={price ?? ""}
                                className="form-control"
                                onChange={(e) =>
                                  onPriceChange(id, e.target.value)
                                }
                              />
                            </td>
                            <td className="d-flex align-items-center justify-content-center gap-1">
                              {i >= 1 ? (
                                <button
                                  className="btn btn-small py-0 btn-danger"
                                  onClick={() => onRemoveInputs(id)}
                                  type="button"
                                >
                                  <i className="ri-subtract-fill ri-2x"></i>
                                </button>
                              ) : null}
                              <button
                                className="btn btn-small py-0 btn-success"
                                onClick={onAddInputs}
                                type="button"
                              >
                                <i className="ri-add-fill ri-2x"></i>
                              </button>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                  <Row className="mt-4">
                    <Col lg={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors?.lead_time}
                        message={errors?.lead_time?.message}
                      >
                        <Label>
                          Waktu Tunggu <span className="mandatory">*</span>
                        </Label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("lead_time")}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col lg={6} sm={12}>
                      <ErrorFieldWrapper
                        error={errors?.notes}
                        message={errors?.notes?.message}
                      >
                        <Label>
                          Catatan <span className="mandatory">*</span>
                        </Label>
                        <input
                          type="text"
                          className="form-control"
                          {...register("notes")}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to="/app/trucking-rate" className="btn btn-danger">
                    Cancel
                  </Link>
                  <Button color="success" type="submit" disabled={isLoading}>
                    {submitBtnText()}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

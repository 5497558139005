import { APP_NAME, BASE_API_URL } from "configs";
import { apiBasic } from "Helpers/api";
import { showError, showSuccess } from "Helpers/show_toast";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const verif = async (e) => {
    setLoading(true)
    if (e) e.preventDefault();
    try {
      await apiBasic.post(BASE_API_URL + "/auth/forgot-password", { email });
      showSuccess("Check your email to reset password!");
    } catch (error) {
      showError(
        error.response?.data?.message ??
          "Something wrong, please check the input"
      );
    }
    setLoading(false);
  };

  document.title = `${APP_NAME} | Forgot Password`;

  return (
    <div className="auth-page-content">
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <Card className="mt-4">
              <CardBody className="p-4">
                <div className="text-center mt-2 mb-0">
                  <Link to="/" className="d-inline-block auth-logo">
                    <img
                      src="/logo.svg"
                      alt="gkargo logo"
                      height={80}
                      width={80}
                    />
                  </Link>
                  <p className="mt-4 text-muted">Lupa Password</p>
                </div>
                <form onSubmit={verif}>
                  <label htmlFor="email" className="text-muted">
                    E-mail
                  </label>
                  <div className="position-relative auth-pass-inputgroup mb-3">
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                    >
                      <i className="ri-mail-line align-middle "></i>
                    </div>
                  </div>
                  <button className="btn btn-primary w-100" type="submit" disabled={loading}>
                    {loading ? 'Loading...' : 'Kirim'}
                  </button>
                  <button
                    className="btn btn-outline-dark w-100 mt-2"
                    type="button"
                    onClick={() => navigate("/login")}
                  >
                    Kembali
                  </button>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

import { CREATE_LOCATION_POINT_URL, DELETE_LOCATION_POINT_URL, LIST_LOCATION_POINT_URL, UPDATE_LOCATION_POINT_URL, VIEW_LOCATION_POINT_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'location-point';
const REDIRECT_URL = '/app/location-point';

export const locationPointRQ = new RQ(
  QUERY_KEY,
  LIST_LOCATION_POINT_URL,
  VIEW_LOCATION_POINT_URL,
  CREATE_LOCATION_POINT_URL,
  UPDATE_LOCATION_POINT_URL,
  DELETE_LOCATION_POINT_URL,
  REDIRECT_URL
);
import {yupResolver} from '@hookform/resolvers/yup'
import BreadCrumb from 'Components/Common/BreadCrumb'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {AdditionalCostTruckingRQ} from 'Hooks/Queries'
import {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {Link, useNavigate, useParams} from 'react-router-dom'
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from 'reactstrap'
import * as Yup from 'yup'

export default function EditCreateAdditionalCost(props) {
  const {id} = useParams()
  const navigate = useNavigate()
  const [type, setType] = useState(null)

  const validation = Yup.object().shape({
    name: Yup.string().required('Deskripsi tidak boleh kosong'),
    value_type: Yup.string().required('Tipe value tidak bolehkosong'),
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      name: '',
      value_type: '',
      value: '',
    },
  }
  const {handleSubmit, formState, register, reset, control} =
    useForm(formOptions)
  const {errors} = formState

  const {mutate: updateTrucking, isLoading: updateLoading} =
    AdditionalCostTruckingRQ.useUpdateData(navigate)()

  const _updateAdditionalCost = (values) => {
    const body = values
    body.id = id
    body.cost_type = 'BOOKING_TRUCK'
    updateTrucking(body)
  }

  const {mutate: addTrucking, isLoading: createLoading} = AdditionalCostTruckingRQ.useAddData(navigate)()

  const _createAdditionalCost = async (values) => {
    const body = values
    body.cost_type = 'BOOKING_TRUCK'
    addTrucking(body)
  }

  const {data, error, isFetching, status} =
    AdditionalCostTruckingRQ.useGetData(id)()

  useEffect(() => {
    if (!isFetching && status === 'success') {
      reset({
        name: data?.data?.name ?? '',
        value_type: data?.data?.value_type ?? '',
        value: data?.data?.value ?? '',
      })
      if (data?.data?.value_type === 'PERCENT')
        setType({label: 'Percent', value: 'PERCENT'})
      else setType({label: 'Amount', value: 'AMOUNT'})
    }
    if (!isFetching && status === 'error') {
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching])

  const getPlaceHolder = () => {
    if (!type?.value) return 'Pilih tipe value terlebih dahulu'
    if (type?.value === 'PERCENT') return 'Masukkan value (%)'
    else return 'Masukkan value'
  }

  const isLoading = createLoading || updateLoading

  const submitBtnText = () => {
    if(isLoading) return 'Loading...'
    return id ? 'Update' : 'Create'
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Biaya' pageTitle='Biaya' />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={handleSubmit(
                id ? _updateAdditionalCost : _createAdditionalCost,
              )}
            >
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {id ? 'Edit' : 'Create'} Biaya
                  </h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
                    <thead>
                      <tr>
                        <th>Deskripsi</th>
                        <th>Jumlah</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={id}>
                        <td>
                          <ErrorFieldWrapper
                            error={errors.name}
                            message={errors.name?.message}
                          >
                            <input
                              className='form-control'
                              {...register('name')}
                            />
                          </ErrorFieldWrapper>
                        </td>
                        <td>
                          <Row className='gy-4'>
                            <Col md={6}>
                              <ErrorFieldWrapper
                                error={errors.value_type}
                                message={errors.value_type?.message}
                              >
                                <Controller
                                  id='value_type'
                                  name='value_type'
                                  type='text'
                                  control={control}
                                  render={({field: {onChange, ...field}}) => (
                                    <Select
                                      {...field}
                                      cacheOptions
                                      isClearable
                                      defaultOptions
                                      value={type ?? ''}
                                      options={[
                                        {label: 'Percent', value: 'PERCENT'},
                                        {label: 'Amount', value: 'AMOUNT'},
                                      ]}
                                      onChange={(e) => {
                                        if (e?.value) onChange(e.value)
                                        setType(e)
                                      }}
                                      placeholder='Tipe Value'
                                    />
                                  )}
                                />
                              </ErrorFieldWrapper>
                            </Col>
                            <Col md={6}>
                              <ErrorFieldWrapper
                                error={errors.value}
                                message={errors.value?.message}
                              >
                                <input
                                  className='form-control'
                                  placeholder={getPlaceHolder()}
                                  {...register('value')}
                                />
                              </ErrorFieldWrapper>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link
                    to='/app/struktur-harga/trucking/biaya'
                    className='btn btn-danger'
                  >
                    Cancel
                  </Link>
                  <Button color='success' type='submit' disabled={isLoading}>
                    {submitBtnText()}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

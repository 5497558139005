import React, {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
  Table,
} from 'reactstrap'
import BreadCrumb from 'Components/Common/BreadCrumb'
import * as Yup from 'yup'
import {yupResolver} from '@hookform/resolvers/yup'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {rolesRQ} from 'Hooks/Queries'
import {useForm} from 'react-hook-form'
import {useQuery} from '@tanstack/react-query'
import {apiBearer} from 'Helpers/api'
import {uniq, chain, map} from 'lodash'
import SinglePermission from 'Components/Permission/SinglePermission'
import NestedPermission from 'Components/Permission/NestedPermission'


export default function DetailRoles(props) {
  const {id} = useParams()
  const [headers, setHeaders] = useState([])
  const [permissionValues, setPermissionValues] = useState([
    {id: '', value: []},
  ])

  const validation = Yup.object().shape({
    name: Yup.string().required('Kategori tidak boleh kosong'),
    description: Yup.string().required('Konten tidak boleh kosong'),
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      name: '',
      description: '',
    },
  }
  const {formState, register, reset} = useForm(formOptions)
  const {errors} = formState

  const {data, error, isFetching, status} = rolesRQ.useGetData(id)()

  const {
    data: dataPermissions,
    isFetching: isFetchingPermissions,
    status: statusPermission,
  } = useQuery(['role/permissions'], async () => {
    const {data} = await apiBearer.get('/roles/permission')
    return data.data
  })

  useEffect(() => {
    if (!isFetchingPermissions && statusPermission === 'success') {
      const tempOptions = chain(dataPermissions)
        .map((dataPermission) => {
          return map(dataPermission.permissions, (permission) => {
            return map(permission.base_option, (option) => option)
          })
        })
        .flatten()
        .flatten()
        .value()
      setHeaders(uniq(tempOptions).sort())
    }
  }, [dataPermissions, isFetchingPermissions, statusPermission])
  useEffect(() => {
    if (!isFetching && status === 'success') {
      reset({
        name: data.data?.name ?? '',
        description: data.data?.description ?? '',
      })
      const tempPemissions = []
      data.data?.permission_list_responses?.forEach((v) =>
        v.permissions.forEach((permission) => {
          tempPemissions.push({id: permission.id, value: permission.access})
        }),
      )

      setPermissionValues(tempPemissions)
    }
    if (!isFetching && status === 'error') {
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Role' pageTitle='Role' />
        <Row>
          <Col lg={12}>
            <form>
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>
                    {id ? 'Edit' : 'Create'} Role
                  </h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row className='gy-4'>
                    <Col sm={12}>
                      <ErrorFieldWrapper
                        error={errors.name}
                        message={errors.name?.message}
                      >
                        <Label className='text-uppercase'>
                          Permission Name <span className='mandatory'>*</span>
                        </Label>
                        <input
                          type='text'
                          className='form-control'
                          {...register('name')}
                          disabled
                        />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col sm={12}>
                      <ErrorFieldWrapper
                        error={errors.description}
                        message={errors.description?.message}
                      >
                        <Label className='text-uppercase'>
                          Description <span className='mandatory'>*</span>
                        </Label>
                        <input
                          type='text'
                          className='form-control'
                          {...register('description')}
                          disabled
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                  <div className='mt-4'>
                    <h4 className='card-title mb-0 flex-grow-1 mb-2'>
                      Assign Role
                    </h4>
                    <div className='table-responsive'>
                      <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
                        <thead>
                          <tr>
                            {['Menu', ...headers].map(
                              (v) => (
                                <th key={v} className='text-uppercase'>
                                  {v}
                                </th>
                              ),
                            )}
                          </tr>
                        </thead>

                        <tbody>
                        {dataPermissions?.map((dataPermission) =>
                            dataPermission.permissions.length > 1 ? (
                              <NestedPermission
                                headers={headers}
                                key={dataPermission.group}
                                dataPermission={dataPermission}
                                // onChange={onChangePermissions}
                                permissionValues={permissionValues}
                                disabled={true}
                              />
                            ) : (
                              <SinglePermission
                                headers={headers}
                                dataPermission={dataPermission}
                                // onChange={onChangePermissions}
                                permission={
                                  dataPermission?.permissions[0] ?? {}
                                }
                                key={dataPermission.group}
                                permissionValues={permissionValues}
                                disabled={true}
                              />
                            ),
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-description-end gap-3'>
                  <Link to='/app/permission'>
                    <Button color='light'>Kembali</Button>
                  </Link>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

const CREATE = '/save'
const LIST = '/lists'
const ALL = '/all'
const DETAIL = '/:id/detail'
const UPDATE = '/:id/save'
const DELETE = '/:id/delete'
const APPROVE = '/:id/approve'
const APPROVEV2 = '/:id/approve-v2'
const REJECT = '/:id/reject'

const USER_BASE_API = '/users'
const AGENT_BASE_API = '/forwarders'
const CDO_BASE_API = '/cdo'
const CDO_REQUEST_BASE_API = '/cdo-warehouse'
const TEMPLATE_CATEGORY_BASE_API = 'api/template/categories'
const WAREHOUSE_BASE_API = '/warehouses'
const LOCATION_BASE_API = '/location'
const TOPUP_BASE_API = '/top-up'
const TRUCK_BASE_API = '/truck'
const TRUCKING_BASE_API = '/trucking'
const CONSIGNEE_BASE_API = '/consignee'
const TRUCKING_CAT_BASE_API = '/truck-category'
const TRUCKING_ORDER_BASE_API = '/booking-truck-trucking'
export const TRUCKING_TYPE_BASE_API = '/truck-type'
const DRIVER_BASE_API = '/driver'
const TRUCK_RATE_BASE_API = '/truck-rate'
const BALANCE_BASE_API = '/balance-transaction'
const LOCATION_POINT_BASE_API = '/location-point'
export const TRUCK_BOOKING_BASE_API = '/booking-truck'
export const SELF_PICKUP_BASE_API = '/self-pickup'
const AGREEMENT_BASE_API = '/agreement'
const SHIPPING_MANAGEMENT_BASE_API = '/booking-truck-warehouse'
const AGREEMENT_APPROVAL_BASE_API = '/agreement-trucking'
const TERMINT_BASE_API = '/agreement-term'
const SPPB_DOC_TYPE_BASE = '/sppb-doc-type'
const STATIC_PAGE_BASE_API = '/static-page'
const FAQ_BASE_API = '/faq'
const COMMISSION_BASE_API = '/partner-commission'
const ADDITIONAL_COST_BASE_API = '/additional-cost'
const GENERAL_SETTING_BASE_API = '/admin/general-setting'
export const ADMIN_REPORT_TRUCK_COMMISSION = '/admin-report/trucking-commission'
export const ADMIN_REPORT_TRUCK_COMMISSION_EXPORT = ADMIN_REPORT_TRUCK_COMMISSION+'/export'
export const ADMIN_REPORT_CDO_COMMISSION = '/admin-report/cdo-commission'
export const ADMIN_REPORT_CDO_COMMISSION_EXPORT = ADMIN_REPORT_CDO_COMMISSION+'/export'
export const ADMIN_REPORT_SALDO = '/admin-report/saldo'
export const ADMIN_REPORT_SALDO_EXPORT = ADMIN_REPORT_SALDO+'/export'
export const ADMIN_REPORT_BOOKING_TRUCK = '/admin-report/booking-truck'
export const ADMIN_REPORT_BOOKING_TRUCK_EXPORT = ADMIN_REPORT_BOOKING_TRUCK + '/export'
export const ADMIN_REPORT_BOOKING_TRUCK_DETAIL = (id) => '/admin-report/booking-truck/' + id +'/detail'
const PARTNER_BASE_API = '/partner'
const BASE_NOTIFICATION = 'notification'
const BASE_ROLE = '/roles'


export const LOGIN = '/auth/login'
export const REFRESH_TOKEN = '/auth/refresh'
export const LOGIN_GOOGLE = '/auth/login/google'
export const LOGIN_NLE = '/auth/login/nle'
export const LOGIN_NLE_V2 = '/auth/nle/login'
export const REFRESH_TOKEN_NLE = '/auth/refresh/nle'
export const MY_PROFILE = '/profile'
export const BIND_FB = '/account-bind/facebook'
export const UNBIND_FB = '/account-unbind/facebook'
export const CHANGE_PASSWORD = '/profile/change-password'
export const CREATE_USER_URL = USER_BASE_API + CREATE
export const LIST_USER_URL = USER_BASE_API + LIST
export const VIEW_USER_URL = (id) => USER_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_USER_URL = (id) => USER_BASE_API + UPDATE.replace(':id', id)
export const DELETE_USER_URL = (id) => USER_BASE_API + DELETE.replace(':id', id)

export const CREATE_TEMPLATE_CATEGORY_URL = TEMPLATE_CATEGORY_BASE_API + CREATE
export const LIST_TEMPLATE_CATEGORY_URL = TEMPLATE_CATEGORY_BASE_API + LIST
export const VIEW_TEMPLATE_CATEGORY_URL = (id) =>
  TEMPLATE_CATEGORY_BASE_API + '/:id'.replace(':id', id)
export const UPDATE_TEMPLATE_CATEGORY_URL = (id) =>
  TEMPLATE_CATEGORY_BASE_API + UPDATE.replace(':id', id)
export const DELETE_TEMPLATE_CATEGORY_URL = (id) =>
  TEMPLATE_CATEGORY_BASE_API + DELETE.replace(':id', id)

export const CREATE_AGENT_URL = AGENT_BASE_API + CREATE
export const LIST_AGENT_URL = AGENT_BASE_API + LIST
export const LIST_ALL_FORWADER_URL = AGENT_BASE_API + ALL
export const VIEW_AGENT_URL = (id) => AGENT_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_AGENT_URL = (id) =>
  AGENT_BASE_API + UPDATE.replace(':id', id)
export const DELETE_AGENT_URL = (id) =>
  AGENT_BASE_API + DELETE.replace(':id', id)
export const APPROVE_AGENT_URL = (id) =>
  AGENT_BASE_API + APPROVE.replace(':id', id)

export const CREATE_WAREHOUSE_URL = WAREHOUSE_BASE_API + CREATE
export const LIST_WAREHOUSE_URL = WAREHOUSE_BASE_API + LIST
export const LIST_ALL_WAREHOUSE_URL = WAREHOUSE_BASE_API + ALL
export const VIEW_WAREHOUSE_URL = (id) =>
  WAREHOUSE_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_WAREHOUSE_URL = (id) =>
  WAREHOUSE_BASE_API + UPDATE.replace(':id', id)
export const DELETE_WAREHOUSE_URL = (id) =>
  WAREHOUSE_BASE_API + DELETE.replace(':id', id)
export const APPROVE_WAREHOUSE_URL = (id) =>
  WAREHOUSE_BASE_API + APPROVE.replace(':id', id)

export const LIST_PROVINCES_URL = LOCATION_BASE_API + '/provinces'
export const LIST_CITIES_URL = (id) =>
  LOCATION_BASE_API + '/:id/regencies'.replace(':id', id)
export const LIST_DISTRICTS_URL = (id) =>
  LOCATION_BASE_API + '/:id/districts'.replace(':id', id)
export const LIST_VILLAGES_URL = (id) =>
  LOCATION_BASE_API + '/:id/villages'.replace(':id', id)

export const CREATE_CDO_URL = CDO_BASE_API + CREATE
export const LIST_CDO_URL = CDO_BASE_API + LIST
export const VIEW_CDO_URL = (id) => CDO_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_CDO_URL = (id) => CDO_BASE_API + UPDATE.replace(':id', id)
export const DELETE_CDO_URL = (id) => CDO_BASE_API + DELETE.replace(':id', id)

export const LIST_CDO_REQUEST_URL = CDO_REQUEST_BASE_API + LIST
export const VIEW_CDO_REQUEST_URL = (id) =>
  CDO_REQUEST_BASE_API + DETAIL.replace(':id', id)
export const APPROVE_CDO_REQUEST_URL = (id) =>
  CDO_REQUEST_BASE_API + APPROVEV2.replace(':id', id)
export const REJECT_CDO_REQUEST_URL = (id) =>
  CDO_REQUEST_BASE_API + '/:id/reject'.replace(':id', id)

export const CREATE_TOPUP_URL = TOPUP_BASE_API + CREATE
export const LIST_TOPUP_URL = TOPUP_BASE_API + LIST
export const VIEW_TOPUP_URL = (id) => TOPUP_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_TOPUP_URL = (id) =>
  TOPUP_BASE_API + UPDATE.replace(':id', id)
export const DELETE_TOPUP_URL = (id) =>
  TOPUP_BASE_API + DELETE.replace(':id', id)

export const PROFILE_BALANCE_URL = '/profile/balance'
export const AVATAR_UPLOAD = '/profile/update-avatar'

export const LIST_BANKS_URL = '/public/banks'

export const CREATE_TRUCK_URL = TRUCK_BASE_API + CREATE
export const LIST_TRUCK_URL = TRUCK_BASE_API + LIST
export const VIEW_TRUCK_URL = (id) => TRUCK_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_TRUCK_URL = (id) =>
  TRUCK_BASE_API + UPDATE.replace(':id', id)
export const DELETE_TRUCK_URL = (id) =>
  TRUCK_BASE_API + DELETE.replace(':id', id)
export const APPROVE_TRUCK_URL = (id) =>
  TRUCK_BASE_API + APPROVE.replace(':id', id)

export const CREATE_TRUCKING_URL = TRUCKING_BASE_API + CREATE
export const LIST_TRUCKING_URL = TRUCKING_BASE_API + LIST
export const ALL_TRUCKING_URL = TRUCKING_BASE_API + ALL
export const VIEW_TRUCKING_URL = (id) =>
  TRUCKING_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_TRUCKING_URL = (id) =>
  TRUCKING_BASE_API + UPDATE.replace(':id', id)
export const DELETE_TRUCKING_URL = (id) =>
  TRUCKING_BASE_API + DELETE.replace(':id', id)
export const APPROVE_TRUCKING_URL = (id) =>
  TRUCKING_BASE_API + APPROVE.replace(':id', id)
  
export const CREATE_CONSIGNEE_URL = CONSIGNEE_BASE_API + CREATE
export const LIST_CONSIGNEE_URL = CONSIGNEE_BASE_API + LIST
export const ALL_CONSIGNEE_URL = CONSIGNEE_BASE_API + ALL
export const VIEW_CONSIGNEE_URL = (id) =>
  CONSIGNEE_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_CONSIGNEE_URL = (id) =>
  CONSIGNEE_BASE_API + UPDATE.replace(':id', id)
export const DELETE_CONSIGNEE_URL = (id) =>
  CONSIGNEE_BASE_API + DELETE.replace(':id', id)
export const APPROVE_CONSIGNEE_URL = (id) =>
  CONSIGNEE_BASE_API + APPROVE.replace(':id', id)

export const CREATE_TRUCKING_CAT_URL = TRUCKING_CAT_BASE_API + CREATE
export const LIST_TRUCKING_CAT_URL = TRUCKING_CAT_BASE_API + LIST
export const LIST_ALL_TRUCKING_CAT_URL = TRUCKING_CAT_BASE_API + ALL
export const VIEW_TRUCKING_CAT_URL = (id) =>
  TRUCKING_CAT_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_TRUCKING_CAT_URL = (id) =>
  TRUCKING_CAT_BASE_API + UPDATE.replace(':id', id)
export const DELETE_TRUCKING_CAT_URL = (id) =>
  TRUCKING_CAT_BASE_API + DELETE.replace(':id', id)

export const CREATE_TRUCKING_TYPE_URL = TRUCKING_TYPE_BASE_API + CREATE
export const LIST_TRUCKING_TYPE_URL = TRUCKING_TYPE_BASE_API + LIST
export const VIEW_TRUCKING_TYPE_URL = (id) =>
  TRUCKING_TYPE_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_TRUCKING_TYPE_URL = (id) =>
  TRUCKING_TYPE_BASE_API + UPDATE.replace(':id', id)
export const DELETE_TRUCKING_TYPE_URL = (id) =>
  TRUCKING_TYPE_BASE_API + DELETE.replace(':id', id)

export const CREATE_DRIVER_URL = DRIVER_BASE_API + CREATE
export const LIST_DRIVER_URL = DRIVER_BASE_API + LIST
export const VIEW_DRIVER_URL = (id) =>
  DRIVER_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_DRIVER_URL = (id) =>
  DRIVER_BASE_API + UPDATE.replace(':id', id)
export const DELETE_DRIVER_URL = (id) =>
  DRIVER_BASE_API + DELETE.replace(':id', id)
export const APPROVE_DRIVER_URL = (id) =>
  DRIVER_BASE_API + APPROVE.replace(':id', id)

export const CREATE_TRUCK_RATE_URL = TRUCK_RATE_BASE_API + CREATE
export const LIST_TRUCK_RATE_URL = TRUCK_RATE_BASE_API + LIST
export const VIEW_TRUCK_RATE_URL = (id) =>
  TRUCK_RATE_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_TRUCK_RATE_URL = (id) =>
  TRUCK_RATE_BASE_API + UPDATE.replace(':id', id)
export const DELETE_TRUCK_RATE_URL = (id) =>
  TRUCK_RATE_BASE_API + DELETE.replace(':id', id)

export const CREATE_LOCATION_POINT_URL = LOCATION_POINT_BASE_API + CREATE
export const LIST_LOCATION_POINT_URL = LOCATION_POINT_BASE_API + LIST
export const VIEW_LOCATION_POINT_URL = (id) =>
  LOCATION_POINT_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_LOCATION_POINT_URL = (id) =>
  LOCATION_POINT_BASE_API + UPDATE.replace(':id', id)
export const DELETE_LOCATION_POINT_URL = (id) =>
  LOCATION_POINT_BASE_API + DELETE.replace(':id', id)

export const CREATE_TRUCK_BOOKING_URL = TRUCK_BOOKING_BASE_API + CREATE
export const LIST_TRUCK_BOOKING_URL = TRUCK_BOOKING_BASE_API + LIST
export const VIEW_TRUCK_BOOKING_URL = (id) =>
  TRUCK_BOOKING_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_TRUCK_BOOKING_URL = (id) =>
  TRUCK_BOOKING_BASE_API + UPDATE.replace(':id', id)
export const DELETE_TRUCK_BOOKING_URL = (id) =>
  TRUCK_BOOKING_BASE_API + DELETE.replace(':id', id)
export const LIST_TRUCK_BOOKING_PAYMENT_METHOD_URL= (truck_rate_item_id) => 
  TRUCK_BOOKING_BASE_API + `/${truck_rate_item_id}/payment-method`

export const CREATE_SELF_PICKUP_URL = SELF_PICKUP_BASE_API + CREATE
export const LIST_SELF_PICKUP_URL = SELF_PICKUP_BASE_API + LIST
export const VIEW_SELF_PICKUP_URL = (id) =>
  SELF_PICKUP_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_SELF_PICKUP_URL = (id) =>
  SELF_PICKUP_BASE_API + UPDATE.replace(':id', id)
export const DELETE_SELF_PICKUP_URL = (id) =>
  SELF_PICKUP_BASE_API + DELETE.replace(':id', id)

export const SELF_PICK_CDO = SELF_PICKUP_BASE_API + CDO_BASE_API

export const LIST_TRUCKING_ORDER_URL = TRUCKING_ORDER_BASE_API + LIST
export const VIEW_TRUCKING_ORDER_URL = (id) =>
  TRUCKING_ORDER_BASE_API + DETAIL.replace(':id', id)
export const ASSIGN_TRUCKING_ORDER_DRIVER_URL = (id) =>
  TRUCKING_ORDER_BASE_API + APPROVE.replace(':id', id)
export const AVAILABLE_TRUCK_BY_TYPE_URL = TRUCK_BASE_API + '/available-by-type'
export const LIST_DRIVER_BY_TRUCK = DRIVER_BASE_API + '/by-truck'
export const ASSIGN_DRIVER_URL = (id) =>
  '/booking-truck-trucking/:id/assign'.replace(':id', id)

export const CREATE_AGREEMENT_URL = AGREEMENT_BASE_API + CREATE
export const LIST_AGREEMENT_URL = AGREEMENT_BASE_API + LIST
export const VIEW_AGREEMENT_URL = (id) =>
  AGREEMENT_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_AGREEMENT_URL = (id) =>
  AGREEMENT_BASE_API + UPDATE.replace(':id', id)
export const DELETE_AGREEMENT_URL = (id) =>
  AGREEMENT_BASE_API + DELETE.replace(':id', id)
export const LIST_AGREEMENT_UNPAID_URL = (agreementId) => AGREEMENT_BASE_API + `/${agreementId}/unpaid`
export const DETAIL_AGREEMENT_UNPAID_URL = (agreementId, date) => AGREEMENT_BASE_API + `/${agreementId}/unpaid/${date}/detail`
export const LIST_AGREEMENT_MUTATION_URL = (agreementId) => AGREEMENT_BASE_API + `/${agreementId}/mutation`
export const LIST_AGREEMENT_PAYMENT = (agreementId) => AGREEMENT_BASE_API + `/${agreementId}/payment`
export const DETAIL_AGREEMENT_PAYMENT = (agreementId, paymentId) => AGREEMENT_BASE_API + `/${agreementId}/payment/${paymentId}/detail`
export const PAY_AGREEMENT = (agreementId) => AGREEMENT_BASE_API + `/${agreementId}/paid`


export const LIST_AGREEMENT_APPROVAL_URL = AGREEMENT_APPROVAL_BASE_API + LIST
export const VIEW_AGREEMENT_APPROVAL_URL = (id) =>
  AGREEMENT_APPROVAL_BASE_API + DETAIL.replace(':id', id)
export const APPROVE_AGREEMENT_APPROVAL_URL = (id) =>
  AGREEMENT_APPROVAL_BASE_API + APPROVE.replace(':id', id)
export const REJECT_AGREEMENT_APPROVAL_URL = (id) =>
  AGREEMENT_APPROVAL_BASE_API + REJECT.replace(':id', id)
export const ADD_QUOTA_AGREEMENTE_APPROVAL_URL = (id) =>
  AGREEMENT_APPROVAL_BASE_API + '/:id/add-quota'.replace(':id', id)
export const AGREEMENT_APPROVAL_MUTATION = (id) => AGREEMENT_APPROVAL_BASE_API + `/${id}/mutation`
export const AGREEMENT_APPROVAL_RECEIVABLES = (id) => AGREEMENT_APPROVAL_BASE_API + `/${id}/receivable`
export const AGREEMENT_APPROVAL_RECEIVABLE_DETAIL = (id, date) => AGREEMENT_APPROVAL_BASE_API + `/${id}/receivable/${date}/detail`

export const CREATE_TERMINT_URL = TERMINT_BASE_API + CREATE
export const LIST_TERMINT_URL = TERMINT_BASE_API + LIST
export const VIEW_TERMINT_URL = (id) =>
  TERMINT_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_TERMINT_URL = (id) =>
  TERMINT_BASE_API + UPDATE.replace(':id', id)
export const DELETE_TERMINT_URL = (id) =>
  TERMINT_BASE_API + DELETE.replace(':id', id)

export const CREATE_SPPB_DOC_TYPE_URL = SPPB_DOC_TYPE_BASE + CREATE
export const LIST_SPPB_DOC_TYPE_URL = SPPB_DOC_TYPE_BASE + LIST
export const VIEW_SPPB_DOC_TYPE_URL = (id) => SPPB_DOC_TYPE_BASE + DETAIL.replace(':id', id)
export const UPDATE_SPPB_DOC_TYPE_URL = (id) => SPPB_DOC_TYPE_BASE + UPDATE.replace(':id', id)
export const DELETE_SPPB_DOC_TYPE_URL = (id) => SPPB_DOC_TYPE_BASE + DELETE.replace(':id', id)
export const LIST_SPPB_DOC_TYPE_ALL_URL = SPPB_DOC_TYPE_BASE + ALL

export const LIST_BALANCE_HISTORY_URL = BALANCE_BASE_API + '/history'
export const LIST_BALANCE_MUTATION_URL = BALANCE_BASE_API + '/mutation'

export const LIST_SHIPPING_MANAGEMENT_URL =
  SHIPPING_MANAGEMENT_BASE_API + '/lists'
export const VIEW_SHIPPING_MANAGEMENT_URL = (id) =>
  SHIPPING_MANAGEMENT_BASE_API + '/:id/detail'.replace(':id', id)
export const CHANGE_SHIPPING_MANAGEMENT_STATUS_API = (id, status) =>
  SHIPPING_MANAGEMENT_BASE_API +
  '/:id/:status'.replace(':id', id).replace(':status', status)

export const CREATE_STATIC_PAGE_URL = STATIC_PAGE_BASE_API + CREATE
export const LIST_STATIC_PAGE_URL = STATIC_PAGE_BASE_API + LIST
export const VIEW_STATIC_PAGE_URL = (id) =>
  STATIC_PAGE_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_STATIC_PAGE_URL = (id) =>
  STATIC_PAGE_BASE_API + UPDATE.replace(':id', id)
export const DELETE_STATIC_PAGE_URL = (id) =>
  STATIC_PAGE_BASE_API + DELETE.replace(':id', id)

export const CREATE_FAQ_URL = FAQ_BASE_API + CREATE
export const LIST_FAQ_URL = FAQ_BASE_API + LIST
export const VIEW_FAQ_URL = (id) => FAQ_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_FAQ_URL = (id) => FAQ_BASE_API + UPDATE.replace(':id', id)
export const DELETE_FAQ_URL = (id) => FAQ_BASE_API + DELETE.replace(':id', id)

export const CREATE_COMMISSION_URL = COMMISSION_BASE_API + CREATE
export const LIST_COMMISSION_URL = COMMISSION_BASE_API + LIST
export const VIEW_COMMISSION_URL = (id) =>
  COMMISSION_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_COMMISSION_URL = (id) =>
  COMMISSION_BASE_API + UPDATE.replace(':id', id)
export const DELETE_COMMISSION_URL = (id) =>
  COMMISSION_BASE_API + DELETE.replace(':id', id)

export const CREATE_ADDITIONAL_COST_URL = ADDITIONAL_COST_BASE_API + CREATE
export const LIST_ADDITIONAL_COST_URL = ADDITIONAL_COST_BASE_API + LIST
export const VIEW_ADDITIONAL_COST_URL = (id) =>
  ADDITIONAL_COST_BASE_API + DETAIL.replace(':id', id)
export const UPDATE_ADDITIONAL_COST_URL = (id) =>
  ADDITIONAL_COST_BASE_API + UPDATE.replace(':id', id)
export const DELETE_ADDITIONAL_COST_URL = (id) =>
  ADDITIONAL_COST_BASE_API + DELETE.replace(':id', id)

export const CREATE_GENERAL_SETTING_URL = GENERAL_SETTING_BASE_API + CREATE
export const LIST_GENERAL_SETTING_URL = GENERAL_SETTING_BASE_API + '/all'
export const VIEW_GENERAL_SETTING_URL = (type) =>
  GENERAL_SETTING_BASE_API + DETAIL.replace(':id', type)

export const ADMIN_REPORT_SALDO_DETAIL = (id) => 
  ADMIN_REPORT_SALDO + DETAIL.replace(':id', id)

export const PARTNER_LIST = PARTNER_BASE_API + LIST

export const NOTIFICATION_LIST = BASE_NOTIFICATION + LIST
export const NOTIFICATION_READ = (id) => `${BASE_NOTIFICATION}/${id}/read`
export const NOTIFICATION_READ_ALL = BASE_NOTIFICATION + '/read-all'
export const NOTIFICATION_DELETE = (id) => `${BASE_NOTIFICATION}/${id}/delete`
export const NOTIFICATION_DELETE_ALL = BASE_NOTIFICATION + '/delete-all'

export const CREATE_ROLE_URL = BASE_ROLE + CREATE
export const LIST_ROLE_URL = BASE_ROLE + LIST
export const LIST_ALL_ROLE_URL = BASE_ROLE + ALL
export const VIEW_ROLE_URL = (id) =>
  BASE_ROLE + DETAIL.replace(':id', id)
export const UPDATE_ROLE_URL = (id) =>
  BASE_ROLE + UPDATE.replace(':id', id)
export const DELETE_ROLE_URL = (id) =>
  SELF_PICKUP_BASE_API + DELETE.replace(':id', id)
import { useQuery } from "@tanstack/react-query";
import CdoPrint from "Components/Cdo/CdoPrint";
import BreadCrumb from "Components/Common/BreadCrumb";
import { apiBearer } from "Helpers/api";
import { VIEW_CDO_REQUEST_URL } from "Helpers/url_helper";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Card, CardBody, Container, Table } from "reactstrap";


export default function PrintCdoRequest() {
  const { id } = useParams();

  const { data, error, isFetching, status, refetch } = useQuery(
    ["cdo-request-detail", id],
    async () => {
      const res = await apiBearer.get(VIEW_CDO_REQUEST_URL(id));
      return res.data?.data;
    },
    {
      enabled: !!id,
    }
  );

  const [loadingPrint, setLoadingPrint] = useState(false);
  const componentRef = React.useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `cdo-doc-${data?.code??''}-${data?.created_on??''}`,
    onBeforePrint: () => {
      setLoadingPrint(true);
    },
    onAfterPrint: () => {
      setLoadingPrint(false);
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Cdo Request" />
        <Card>
          <CardBody>
           <CdoPrint data={data} componentRef={componentRef}/>
            <div className="modal-footer d-flex justify-content-end gap-1">
              <button
                className="btn btn-primary"
                type="button"
                onClick={handlePrint}
              >
                {loadingPrint ? "Loading..." : "Print"}
              </button>
              <Link
                className="btn btn-outline-dark"
                to={"/app/request-cdo/detail/" + id}
              >
                Cancel
              </Link>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
}

import { CREATE_USER_URL, DELETE_USER_URL, LIST_USER_URL, UPDATE_USER_URL, VIEW_USER_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'users';
const REDIRECT_URL = '/app/users';

export const UserRQ = new RQ(
  QUERY_KEY, 
  LIST_USER_URL, 
  VIEW_USER_URL, 
  CREATE_USER_URL, 
  UPDATE_USER_URL, 
  DELETE_USER_URL, 
  REDIRECT_URL
);
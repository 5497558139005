import { CREATE_AGREEMENT_APPROVAL_URL, DELETE_AGREEMENT_APPROVAL_URL, LIST_AGREEMENT_APPROVAL_URL, UPDATE_AGREEMENT_APPROVAL_URL, VIEW_AGREEMENT_APPROVAL_URL } from "../../Helpers/url_helper";
import RQ from "./baseRQ";

const QUERY_KEY = 'agreements-approval';
const REDIRECT_URL = '/app/agreement-approval';

export const AgreementApprovalRQ = new RQ(
  QUERY_KEY, 
  LIST_AGREEMENT_APPROVAL_URL, 
  VIEW_AGREEMENT_APPROVAL_URL, 
  REDIRECT_URL
);
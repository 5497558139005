import { DATA_LIMIT } from "../../configs";
import React from "react";
import { Link } from "react-router-dom";

export function TruckingRateITem({
  page,
  limit,
  index,
  id,
  destination_point_name,
  origin_point_name,
  lead_time,
  notes,
  onDeleteClick,
  table,
  additionalActions,
}) {
  const { toggleListRateModal } = additionalActions;
  return (
    <tr>
      <td className="fw-medium">{index + page + 1}</td>
      <td>
        <div className="hstack gap-3 fs-15">
          <Link to={`/app/${table}/detail/${id}`} className="link-success">
            <i className="ri-eye-fill"></i>
          </Link>
          <Link to={`/app/${table}/edit/${id}`} className="link-warning">
            <i className="ri-edit-2-line"></i>
          </Link>
          <Link
            to="#"
            className="link-danger"
            onClick={() => {
              onDeleteClick(id);
            }}
          >
            <i className="ri-delete-bin-5-line"></i>
          </Link>
          <div
            className="cursor-pointer"
            onClick={() => {
              toggleListRateModal(id);
            }}
          >
            <i className="ri-list-unordered"></i>
          </div>
        </div>
      </td>
      <td>{origin_point_name ?? "-"}</td>
      <td>{destination_point_name ?? "-"}</td>
      <td>{lead_time ?? "-"}</td>
      <td>{notes ?? "-"}</td>
    </tr>
  );
}

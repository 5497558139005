import {yupResolver} from '@hookform/resolvers/yup'
import {useMutation, useQuery} from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import ErrorFieldWrapper from 'Components/Common/ErrorFieldWrapper'
import {apiBearer} from 'Helpers/api'
import {showSuccess} from 'Helpers/show_toast'
import {
  CREATE_GENERAL_SETTING_URL,
  VIEW_GENERAL_SETTING_URL,
} from 'Helpers/url_helper'
import {useEffect} from 'react'
import {useForm} from 'react-hook-form'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from 'reactstrap'
import * as Yup from 'yup'

export default function EditCreatePpn(props) {
  const {id} = useParams()
  const navigate = useNavigate()

  const validation = Yup.object().shape({
    name: Yup.string().required('Deskripsi tidak boleh kosong'),
    value: Yup.string().required('Value tidak bolehkosong'),
  })
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      name: '',
      value: '',
    },
  }
  const {handleSubmit, formState, register, reset, control} =
    useForm(formOptions)
  const {errors} = formState

  const {mutate: addSetting, isLoading} = useMutation({
    mutationFn: (newData) => {
      return apiBearer.post(CREATE_GENERAL_SETTING_URL, newData)
    },
    onSuccess: async () => {
      showSuccess('PPN updated successfully')
      navigate('/app/struktur-harga/ppn')
    },
    onError: async (error) => {},
  })

  const _addSetting = async (values) => {
    const body = values
    body.setting_type = 'PPN'
    addSetting(body)
  }

  const {data, isFetching, status, error} = useQuery(['PPN'], async () => {
    const res = await apiBearer.get(VIEW_GENERAL_SETTING_URL('PPN'))
    return res.data
  })
  useEffect(() => {
    if (!isFetching && status === 'success') {
      reset({
        name: data?.data?.name ?? '',
        setting_type: data?.data?.setting_type ?? '',
        value: data?.data?.value ?? '',
      })
    }
    if (!isFetching && status === 'error') {
      console.log(error)
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching])

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='PPN' pageTitle='PPN' />
        <Row>
          <Col lg={12}>
            <form onSubmit={handleSubmit(_addSetting)}>
              <Card>
                <CardHeader className='align-items-center d-flex'>
                  <h4 className='card-title mb-0 flex-grow-1'>Update PPN</h4>
                </CardHeader>
                <CardBody className='card-body'>
                  <Row>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.name}
                        message={errors.name?.message}
                      >
                        <Label htmlFor='name'>
                          Deskripsi <span className='mandatory'>*</span>
                        </Label>
                        <input className='form-control' {...register('name')} />
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.value}
                        message={errors.value?.message}
                      >
                        <Label htmlFor='value'>
                          Amount <span className='mandatory'>*</span>
                        </Label>
                        <input
                          className='form-control'
                          {...register('value')}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className='align-items-center d-flex justify-content-end gap-3'>
                  <Link to='/app/struktur-harga/ppn' className='btn btn-danger'>
                    Cancel
                  </Link>
                  <Button color='success' type='submit' disabled={isLoading}>
                    {isLoading ? 'Loading...' : 'Update'}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

import { formatNumber } from "Helpers/number";
import React from "react";

export default function AgreementMutationItem({
  page,
  index,
//   id,
  mutation_time,
  notes,
  debit,
  credit,
//   mutation_type,
  balance,
}) {
  return (
    <tr>
      <td className="fw-medium">{index + page + 1}</td>
      <td>{mutation_time}</td>
      <td>{notes}</td>
      <td>Rp. {formatNumber(debit)}</td>
      <td>Rp. {formatNumber(credit)}</td>
      <td>Rp. {formatNumber(balance)}</td>
    </tr>
  );
}

import DetailAgent from 'Pages/Agent/Detail'
import EditCreateAgent from 'Pages/Agent/Edit'
import ListAgent from 'Pages/Agent/List'
import DetailAgreement from 'Pages/Agreement/Detail'
import EditCreateAgreement from 'Pages/Agreement/Edit'
import ListAgreement from 'Pages/Agreement/List'
import DetailAgreementApproval from 'Pages/AgreementApproval/Agreement/Detail'
import ListAgreementApproval from 'Pages/AgreementApproval/Agreement/List'
import EditCreateTermint from 'Pages/AgreementApproval/Termint/Edit'
import ListTermint from 'Pages/AgreementApproval/Termint/List'
import ForgotPassword from 'Pages/Auth/ForgotPassword'
import Register from 'Pages/Auth/Register'
import ResetPassword from 'Pages/Auth/ResetPassword'
import VerifEmail from 'Pages/Auth/VerifEmail'
import EditCreateCdo from 'Pages/Cdo/Edit'
import Cdo from 'Pages/Cdo/List'
import PrintCdo from 'Pages/Cdo/Print'
import DetailCDO from 'Pages/Cdo/detail'
import CdoRequest from 'Pages/CdoRequest/List'
import PrintCdoRequest from 'Pages/CdoRequest/Print'
import DetailCdoRequest from 'Pages/CdoRequest/detail'
import EditCreateDriver from 'Pages/Driver/Edit'
import ListDriver from 'Pages/Driver/List'
import DetailDriver from 'Pages/Driver/detail'
import ListExternalDriver from 'Pages/ExternalDriver/List'
import DetailExternalDriver from 'Pages/ExternalDriver/detail'
import EditCreateFaq from 'Pages/Master/Faq/Edit'
import ListFaq from 'Pages/Master/Faq/List'
import DetailLocationPoint from 'Pages/Master/LocationPoint/Detail'
import EditCreateLocationPoint from 'Pages/Master/LocationPoint/Edit'
import ListLocationPoint from 'Pages/Master/LocationPoint/List'
import EditCreateStaticPage from 'Pages/Master/StaticPage/Edit'
import ListStaticPage from 'Pages/Master/StaticPage/List'
import DetailAdditionalCdoCost from 'Pages/Master/StructurHarga/Cdo/AdditionalCost/Detail'
import EditCreateAdditionalCdoCost from 'Pages/Master/StructurHarga/Cdo/AdditionalCost/Edit'
import ListAdditionalCdoCost from 'Pages/Master/StructurHarga/Cdo/AdditionalCost/List'
import EditCdoRate from 'Pages/Master/StructurHarga/Cdo/Rate/Edit'
import ListCdoRate from 'Pages/Master/StructurHarga/Cdo/Rate/List'
import EditCreatePpn from 'Pages/Master/StructurHarga/Ppn/Edit'
import ListPpn from 'Pages/Master/StructurHarga/Ppn/List'
import DetailAdditionalTruckCost from 'Pages/Master/StructurHarga/Trucking/AdditionalCost/Detail'
import EditCreateAdditionalTruckCost from 'Pages/Master/StructurHarga/Trucking/AdditionalCost/Edit'
import ListAdditionalTruckCost from 'Pages/Master/StructurHarga/Trucking/AdditionalCost/List'
import DetailTruckCommission from 'Pages/Master/StructurHarga/Trucking/Commission/Detail'
import EditCreateTruckCommission from 'Pages/Master/StructurHarga/Trucking/Commission/Edit'
import ListTruckCommission from 'Pages/Master/StructurHarga/Trucking/Commission/List'
import DetailTrucking from 'Pages/Master/Trucking/Detail'
import EditCreateTrucking from 'Pages/Master/Trucking/Edit'
import ListTrucking from 'Pages/Master/Trucking/List'
import PaymentRedirect from 'Pages/PaymentRedirect'
import CommissionCdoReport from 'Pages/Report/CommissionCdo/CommissionCdoReport'
import CommissionTruckReport from 'Pages/Report/CommissionTrucking/CommissionTruckReport'
import DetailSaldoReport from 'Pages/Report/Saldo/DetailSaldoReport'
import SaldoReport from 'Pages/Report/Saldo/SaldoReport'
import ListHistoryBalance from 'Pages/Saldo/HistoryBalance/List'
import ListMutationBalance from 'Pages/Saldo/MutationBalance/List'
import CreateTopup from 'Pages/Saldo/Topup/Create'
import DetailTopup from 'Pages/Saldo/Topup/Detail'
import ListTopup from 'Pages/Saldo/Topup/List'
import DetailSelfPickup from 'Pages/SelfPickup/Detail'
import EditCreateSelfPickup from 'Pages/SelfPickup/Edit'
import ListselfPickup from 'Pages/SelfPickup/List'
import DetailShippingManagement from 'Pages/ShippingManagement/Detail'
import ShippingManagement from 'Pages/ShippingManagement/List'
import DetailTruckingCat from 'Pages/Truck/Category/Detail'
import EditCreateTruckingCat from 'Pages/Truck/Category/Edit'
import ListTruckingCat from 'Pages/Truck/Category/List'
import EditCreateTruck from 'Pages/Truck/Data/Edit'
import ListTruck from 'Pages/Truck/Data/List'
import DetailTruck from 'Pages/Truck/Data/detail'
import DetailTruckingType from 'Pages/Truck/Type/Detail'
import EditCreateTruckingType from 'Pages/Truck/Type/Edit'
import ListTruckingType from 'Pages/Truck/Type/List'
import CreateTruckBooking from 'Pages/TruckBooking/Create'
import DetailTruckBooking from 'Pages/TruckBooking/Detail'
import ListTruckBooking from 'Pages/TruckBooking/List'
import AssignTruckingOrder from 'Pages/TruckingOrder/Assign'
import DetailTruckingOrder from 'Pages/TruckingOrder/Detail'
import ListTruckingOrder from 'Pages/TruckingOrder/List'
import EditCreateTruckingRate from 'Pages/TruckingRate/Edit'
import ListTruckingRate from 'Pages/TruckingRate/List'
import DetailRateTrucking from 'Pages/TruckingRate/detail'
import DetailWarehouse from 'Pages/Warehouse/Detail'
import EditCreateWarehouse from 'Pages/Warehouse/Edit'
import ListWarehouse from 'Pages/Warehouse/List'
import { Navigate } from 'react-router-dom'
import AuthenticationLayout from '../Layout/AuthenticationLayout'
import Layout from '../Layout/index'
import SignIn from '../Pages/Auth/SignIn'
import Dashboard from '../Pages/Dashboard'
import UserProfile from '../Pages/Profile/Profile'
import EditCreateUser from '../Pages/User/Edit'
import User from '../Pages/User/List'
import BookingTruckReport from 'Pages/Report/BookingTruck/BookingTruckReport'
import AgreementPayment from 'Pages/Agreement/Payment'
import AgreementMutations from 'Pages/Agreement/Mutasi'
import AgreementUnpaidList from '../Pages/Agreement/Unpaid'
import PayAgreement from 'Pages/Agreement/Pay'
import AgreementApprovalReceivables from 'Pages/AgreementApproval/Agreement/receivables/List'
import AgreementReceivableDetail from 'Pages/AgreementApproval/Agreement/receivables/Detail'
import ListConsignee from 'Pages/Master/Consignee/List'
import EditCreateConsignee from 'Pages/Master/Consignee/Edit'
import DetailConsignee from 'Pages/Master/Consignee/Detail'
import BookingTruckReportDetail from 'Pages/Report/BookingTruck/BookingTruckReportDetail'
import ListSppbDocType from 'Pages/Master/SppbDocType/List'
import EditCreateSppbDocType from 'Pages/Master/SppbDocType/Edit'
import ListRole from 'Pages/Permission/List'
import EditCreateRoles from 'Pages/Permission/Edit'
import DetailRoles from 'Pages/Permission/Detail'
import MapDetail from 'Pages/TruckBooking/Map'

const publicRoutes = [
  {
    path: '/',
    element: <AuthenticationLayout />,
    children: [
      {path: 'login', element: <SignIn />},
      {path: 'register', element: <Register />},
      {path: 'verif-email/:token/:type', element: <VerifEmail />},
      {path: 'forgot-password', element: <ForgotPassword />},
      {path: 'password-reset/:token/:type', element: <ResetPassword />},
      {path: 'payment/:status', element: <PaymentRedirect />},
      {path: '/', element: <Navigate to='/app' />},
    ],
  },
]

const authProtectedRoutes = [
  {
    path: 'app',
    element: <Layout />,
    children: [
      {path: '', element: <Dashboard />},
      {path: 'dashboard', element: <Dashboard />},
      {path: 'profile', element: <UserProfile />},
      {path: 'users', element: <User />},
      {path: 'users/edit/:id', element: <EditCreateUser />},
      {path: 'users/create', element: <EditCreateUser />},
      {path: 'permission', element: <ListRole />},
      {path: 'permission/edit/:id', element: <EditCreateRoles />},
      {path: 'permission/detail/:id', element: <DetailRoles />},
      {path: 'permission/create', element: <EditCreateRoles />},
      {path: 'cdo', element: <Cdo />},
      {path: 'cdo/detail/:id', element: <DetailCDO />},
      {path: 'cdo/edit/:id', element: <EditCreateCdo />},
      {path: 'cdo/create', element: <EditCreateCdo />},
      {path: 'cdo/print/:id', element: <PrintCdo />},
      {path: 'request-cdo', element: <CdoRequest />},
      {path: 'request-cdo/detail/:id', element: <DetailCdoRequest />},
      {path: 'request-cdo/print/:id', element: <PrintCdoRequest />},
      {path: 'warehouses', element: <ListWarehouse />},
      {path: 'warehouses/create', element: <EditCreateWarehouse />},
      {path: 'warehouses/edit/:id', element: <EditCreateWarehouse />},
      {path: 'warehouses/detail/:id', element: <DetailWarehouse />},
      {path: 'agents', element: <ListAgent />},
      {path: 'agents/create', element: <EditCreateAgent />},
      {path: 'agents/edit/:id', element: <EditCreateAgent />},
      {path: 'agents/detail/:id', element: <DetailAgent />},
      {path: 'topups', element: <ListTopup />},
      {path: 'topups/create', element: <CreateTopup />},
      {path: 'topups/detail/:id', element: <DetailTopup />},
      {path: 'truckings', element: <ListTrucking />},
      {path: 'truckings/create', element: <EditCreateTrucking />},
      {path: 'truckings/edit/:id', element: <EditCreateTrucking />},
      {path: 'truckings/detail/:id', element: <DetailTrucking />},
      {path: 'consignee', element: <ListConsignee />},
      {path: 'consignee/create', element: <EditCreateConsignee />},
      {path: 'consignee/edit/:id', element: <EditCreateConsignee />},
      {path: 'consignee/detail/:id', element: <DetailConsignee />},

      {
        path: 'struktur-harga',
        children: [
          {path: 'ppn', element: <ListPpn />},
          {path: 'ppn/update', element: <EditCreatePpn />},
          {
            path: 'trucking',
            children: [
              {path: 'komisi', element: <ListTruckCommission />},
              {
                path: 'komisi/create',
                element: <EditCreateTruckCommission />,
              },
              {
                path: 'komisi/edit/:id',
                element: <EditCreateTruckCommission />,
              },
              {
                path: 'komisi/detail/:id',
                element: <DetailTruckCommission />,
              },
              {path: 'biaya', element: <ListAdditionalTruckCost />},
              {
                path: 'biaya/create',
                element: <EditCreateAdditionalTruckCost />,
              },
              {
                path: 'biaya/edit/:id',
                element: <EditCreateAdditionalTruckCost />,
              },
              {
                path: 'biaya/detail/:id',
                element: <DetailAdditionalTruckCost />,
              },
            ],
          },
          {
            path: 'cdo',
            children: [
              {path: 'biaya', element: <ListAdditionalCdoCost />},
              {
                path: 'biaya/create',
                element: <EditCreateAdditionalCdoCost />,
              },
              {
                path: 'biaya/edit/:id',
                element: <EditCreateAdditionalCdoCost />,
              },
              {
                path: 'biaya/detail/:id',
                element: <DetailAdditionalCdoCost />,
              },

              {path: 'rate', element: <ListCdoRate />},
              {
                path: 'rate/update',
                element: <EditCdoRate />,
              },
            ],
          },
        ],
      },

      {path: 'history-balance', element: <ListHistoryBalance />},
      {path: 'mutation-balance', element: <ListMutationBalance />},

      {
        path: 'truck',
        children: [
          {path: 'data', element: <ListTruck />},
          {path: 'data/create', element: <EditCreateTruck />},
          {path: 'data/edit/:id', element: <EditCreateTruck />},
          {path: 'data/detail/:id', element: <DetailTruck />},

          {path: 'category', element: <ListTruckingCat />},
          {path: 'category/create', element: <EditCreateTruckingCat />},
          {path: 'category/edit/:id', element: <EditCreateTruckingCat />},
          {path: 'category/detail/:id', element: <DetailTruckingCat />},

          {path: 'type', element: <ListTruckingType />},
          {path: 'type/create', element: <EditCreateTruckingType />},
          {path: 'type/edit/:id', element: <EditCreateTruckingType />},
          {path: 'type/detail/:id', element: <DetailTruckingType />},
        ],
      },
      {path: 'driver', element: <ListDriver />},
      {path: 'driver/create', element: <EditCreateDriver />},
      {path: 'driver/edit/:id', element: <EditCreateDriver />},
      {path: 'driver/detail/:id', element: <DetailDriver />},

      {path: 'external-driver', element: <ListExternalDriver />},
      {path: 'external-driver/detail/:id', element: <DetailExternalDriver />},

      {path: 'trucking', element: <ListTrucking />},
      {path: 'trucking/create', element: <EditCreateTrucking />},
      {path: 'trucking/edit/:id', element: <EditCreateTrucking />},
      {path: 'trucking/detail/:id', element: <DetailTrucking />},

      {path: 'trucking-rate', element: <ListTruckingRate />},
      {path: 'trucking-rate/create', element: <EditCreateTruckingRate />},
      {path: 'trucking-rate/edit/:id', element: <EditCreateTruckingRate />},
      {path: 'trucking-rate/detail/:id', element: <DetailRateTrucking />},

      {path: 'location-point', element: <ListLocationPoint />},
      {path: 'location-point/create', element: <EditCreateLocationPoint />},
      {path: 'location-point/edit/:id', element: <EditCreateLocationPoint />},
      {path: 'location-point/detail/:id', element: <DetailLocationPoint />},

      {
        path: 'truck-book',
        children: [
          {path: 'booking', element: <ListTruckBooking />},
          {path: 'booking/create', element: <CreateTruckBooking />},
          {path: 'booking/detail/:id', element: <DetailTruckBooking />},
          {path: 'booking/detail/:id/map', element: <MapDetail />},

          {path: 'self-pickup', element: <ListselfPickup />},
          {path: 'self-pickup/create', element: <EditCreateSelfPickup />},
          {path: 'self-pickup/detail/:id', element: <DetailSelfPickup />},
          // { path: "data/detail/:id", element: <DetailTruck /> },

          // { path: "category", element: <ListTruckingCat /> },
          // { path: "category/create", element: <EditCreateTruckingCat /> },
          // { path: "category/edit/:id", element: <EditCreateTruckingCat /> },
          // { path: "category/detail/:id", element: <DetailTruckingCat /> },

          // { path: "type", element: <ListTruckingType /> },
          // { path: "type/create", element: <EditCreateTruckingType /> },
          // { path: "type/edit/:id", element: <EditCreateTruckingType /> },
          // { path: "type/detail/:id", element: <DetailTruckingType /> },
        ],
      },

      {path: 'trucking-order', element: <ListTruckingOrder />},
      {path: 'trucking-order/detail/:id', element: <DetailTruckingOrder />},
      {path: 'trucking-order/assign/:id', element: <AssignTruckingOrder />},

      {
        path: 'agreement', children: [
          {path: '', element: <ListAgreement />},
          {path: 'create', element: <EditCreateAgreement />},
          {path: 'edit/:id', element: <EditCreateAgreement />},
          {path: 'detail/:id', children: [
              {
                path:'',
                element: <DetailAgreement/>
              },
              {
                path:'tagihan',
                children:[
                  {
                    path:'',
                    element: <AgreementUnpaidList/>

                  },
                  {
                    path:':date/bayar',
                    element: <PayAgreement/>
                  },
                ]
              },
              {
                path:'mutasi',
                element: <AgreementMutations/>
              },
              {
                path:'payment',
                children: [
                  {
                    path: ':paymentId',
                    element: <AgreementPayment/>
                  }
                ]
              },
            ]
          },
        ]
      },

      {path: 'shipping-management', element: <ShippingManagement />},
      {
        path: 'shipping-management/detail/:id',
        element: <DetailShippingManagement />,
      },

      {path: 'agreement-approval', element: <ListAgreementApproval />},
      {
        path: 'agreement-approval/detail/:id',
        children :[
          {
            path : '',
            element: <DetailAgreementApproval />
          },
          {
            path : 'credit-limit',
            element: <AgreementApprovalReceivables />
          },
          {
            path : 'credit-limit/:date',
            element: <AgreementReceivableDetail />
          }
        ]
      },

      {path: 'termint', element: <ListTermint />},
      {path: 'termint/create', element: <EditCreateTermint />},
      {path: 'termint/edit/:id', element: <EditCreateTermint />},

      {path: 'sppb-doc-type', element: <ListSppbDocType />},
      {path: 'sppb-doc-type/create', element: <EditCreateSppbDocType />},
      {path: 'sppb-doc-type/edit/:id', element: <EditCreateSppbDocType />},

      {path: 'static-page', element: <ListStaticPage />},
      {path: 'static-page/edit/:id', element: <EditCreateStaticPage />},
      {path: 'static-page/create', element: <EditCreateStaticPage />},

      {path: 'faq', element: <ListFaq />},
      {path: 'faq/edit/:id', element: <EditCreateFaq />},
      {path: 'faq/create', element: <EditCreateFaq />},
      {path: 'laporan', children: [
          {
            path: 'komisi-trucking',
            element: <CommissionTruckReport/>
          },
          {
            path: 'komisi-cdo',
            element: <CommissionCdoReport/>
          },
          {
            path: 'saldo',
            element:<SaldoReport/>
          },
          {
            path: 'saldo/detail/:id',
            element:<DetailSaldoReport/>
          }
        ]
      },
      {path: 'laporan-transaksi', children: [
        {
          path: 'booking-truck',
          children : [
            {
              path:'',
              element: <BookingTruckReport/>
            },
            {
              path: 'detail/:id',
              element: <BookingTruckReportDetail/>
            }
          ]
        },
      ]
    }
    ],
  },
]

export { authProtectedRoutes, publicRoutes }


import { useMutation, useQuery } from "@tanstack/react-query";
import { LIST_BALANCE_HISTORY_URL, LIST_BALANCE_MUTATION_URL } from "Helpers/url_helper";
import { apiBearer } from "../../Helpers/api";

class BalanceRQClass {
  QUERY_KEY = "";
  LIST_URL_HISTORY = "";
  LIST_URL_MUTATION = "";

  constructor(
    QUERY_KEY,
    LIST_URL_HISTORY,
    LIST_URL_MUTATION,
  ) {
    this.QUERY_KEY = QUERY_KEY;
    this.LIST_URL_HISTORY = LIST_URL_HISTORY;
    this.LIST_URL_MUTATION = LIST_URL_MUTATION;
  }

  useGetDataList() {
    return (isSearch = false, keyword = "", offset = 0, limit = 10) =>
      useQuery(
        [this.QUERY_KEY, isSearch, keyword, offset, limit],
        async () => {
          const params = {
            page: offset,
            size: limit,
            search: keyword
          };
          const res = await apiBearer.get(this.LIST_URL_HISTORY, {params});
          return res.data?.data;
        }
      );
  }

  useGetDataListMutation() {
    return (isSearch = false, keyword = "", offset = 0, limit = 10) =>
      useQuery(
        [this.QUERY_KEY, isSearch, keyword, offset, limit],
        async () => {
          const params = {
            page: offset,
            size: limit,
            search: keyword
          };
          const res = await apiBearer.get(this.LIST_URL_MUTATION, {params});
          return res.data?.data;
        }
      );
  }

}

const QUERY_KEY = 'balance';

export const BalanceRQ = new BalanceRQClass(
  QUERY_KEY,
  LIST_BALANCE_HISTORY_URL,
  LIST_BALANCE_MUTATION_URL,
)

import axios from "axios";
import configs from "../configs";
import AxiosWithToken from "./axiosWithBearer";

const {BASE_API_URL, BASIC_API_KEY} = configs

export const apiBasic = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    'api-key': `Basic ${BASIC_API_KEY}`,
  },
});

export const apiBearer  = AxiosWithToken
import React from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import BreadCrumb from "Components/Common/BreadCrumb";
import SimpleBar from "simplebar-react";
import { DriverRQ } from "Hooks/Queries";

export default function DetailDriver(props) {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, error, isFetching, status } = DriverRQ.useGetData(id)();

  useEffect(() => {
    if (!isFetching && status === "success") {
    }
    if (!isFetching && status === "error") {
      console.log(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  console.log(data);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Driver" />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={function (e) {
                e.preventDefault();
              }}
            >
              <Card>
                <CardHeader className="d-flex align-items-center">
                  <h6 className="card-title mb-0 flex-grow-1">Detail Driver</h6>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive table-card">
                    <SimpleBar style={{ maxHeight: "365px" }}>
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex items-center">
                            <div className="ms-3 col-6">Email :</div>
                            <div className="ms-3 col-3">
                              <h6>{data?.data?.email ?? "-"}</h6>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Username :</div>
                            <div className="ms-3 col-3">
                              <h6>{data?.data?.user_name ?? "-"}</h6>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Password :</div>
                            <div className="ms-3 col-3">
                              <h6>
                                {Array.from(new Array(8)).map((v) => "*").join("")
                                  }
                              </h6>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Name :</div>
                            <div className="ms-3 col-3">
                              <h6>{data?.data?.name ?? "-"}</h6>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">NIK :</div>
                            <div className="ms-3 col-3">
                              <h6>{data?.data?.nik ?? "-"}</h6>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Masa Aktif SIM :</div>
                            <div className="ms-3 col-3">
                              <h6>{data?.data?.sim_expired ?? "-"}</h6>
                            </div>
                          </div>
                        </li>
                        {/* <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Masa Aktif KIR :</div>
                            <div className="ms-3 col-3">
                              <h6>{data?.data?.kir_expired ?? "-"}</h6>
                            </div>
                          </div>
                        </li> */}
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Foto SIM :</div>
                            <div className="ms-3 col-3">
                              <a
                                className="text-decoration-underline"
                                target={data?.data?.sim_url ? "_blank" : ""}
                                rel="noreferrer"
                                href={data?.data?.sim_url ? data?.data?.sim_url : "#"}
                              >
                                Preview
                              </a>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Foto KTP :</div>
                            <div className="ms-3 col-3">
                              <a
                                className="text-decoration-underline"
                                target={data?.data?.ktp_url ? "_blank" : ""}
                                rel="noreferrer"
                                href={data?.data?.ktp_url ? data?.data?.ktp_url : "#"}
                              >
                                Preview
                              </a>
                            </div>
                          </div>
                        </li>
                        <li className="list-group-item list-group-item-action">
                          <div className="d-flex align-items-center">
                            <div className="ms-3 col-6">Foto Driver :</div>
                            <div className="ms-3 col-3">
                              <a
                                className="text-decoration-underline"
                                target={data?.data?.photo_url ? "_blank" : ""}
                                rel="noreferrer"
                                href={data?.data?.photo_url ? data?.data?.photo_url : "#"}
                              >
                                Preview
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </SimpleBar>
                  </div>
                </CardBody>
                <CardFooter className="text-end">
                  <Link className="btn btn-light" to="/app/driver">
                    Kembali
                  </Link>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

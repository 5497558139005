import {useQuery} from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import MyPagination from 'Components/Common/Table/Pagination'
import flatpickr from 'flatpickr'
import {apiBearer} from 'Helpers/api'
import {formatNumber} from 'Helpers/number'
import {LIST_AGREEMENT_MUTATION_URL} from 'Helpers/url_helper'
import moment from 'moment'
import {useEffect, useRef, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Row,
  Spinner,
  Table,
} from 'reactstrap'

const AgreementMutations = () => {
  const {id} = useParams()
  console.log(id)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)
  const [stateIndicator, setStateIndicator] = useState(false)

  const startDateRef = useRef()
  const endDateRef = useRef()
  const formRef = useRef()

  useEffect(() => {
    if (startDateRef.current) {
      flatpickr(startDateRef.current, {
        dateFormat: 'Y-m-d',
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setStartDate(sDate)
        },
      })
    }
  }, [])

  useEffect(() => {
    if (endDateRef.current) {
      flatpickr(endDateRef.current, {
        dateFormat: 'Y-m-d',
        closeOnSelect: true,
        onChange: (v, sDate) => {
          setEndDate(sDate)
        },
      })
    }
  }, [])

  const {data, isFetching, status, refetch} = useQuery(
    ['agreement-mutations', page, size],
    async () => {
      const params = {
        page,
        size,
        start_date: startDate,
        end_date: endDate,
      }
      const res = await apiBearer.get(LIST_AGREEMENT_MUTATION_URL(id), {params})
      return res.data?.data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      enabled: !!id,
      retryOnMount: false,
      retry: false,
    },
  )

  const onSubmitSearch = (e) => {
    e.preventDefault()
    if (page === 0) refetch()
    else setPage(0)
  }

  //handle refetch when form change and reset button clicked
  useEffect(() => {
    if (id) refetch()
  }, [refetch, stateIndicator])

  const theadStyles = {
    textAlign: 'center',
    verticalAlign: 'middle',
  }

  const colCounts =
    10 +
    (data?.sum_commissions?.length || 0) +
    (data?.sum_additional_costs?.length || 0)

  const renderData = () => {
    if (status === 'error')
      return (
        <tr>
          <td colSpan={colCounts} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    if (isFetching)
      return (
        <tr>
          <td colSpan={colCounts} className='text-center'>
            <Spinner color='success' />
          </td>
        </tr>
      )
    if (status === 'loading' && !isFetching) {
      return (
        <tr>
          <td colSpan={colCounts} className='text-center'>
            <span className='text-danger'>Search to show data</span>
          </td>
        </tr>
      )
    }
    if (!data?.data || data?.data.length < 1)
      return (
        <tr>
          <td colSpan={colCounts} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    return data?.data?.map((props, i) => {
      return (
        <tr key={i}>
          <td>{i + data?.offset + 1}</td>
          <td>{moment(props.mutation_time).format('DD MMMM YYYY HH:mm')}</td>
          <td>{props.notes ?? '-'}</td>
          <td>Rp. {formatNumber(props.debit)}</td>
          <td>Rp. {formatNumber(props.credit)}</td>
          <td>Rp. {formatNumber(props.balance)}</td>
        </tr>
      )
    })
  }

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Mutasi' />
        <Card>
          <CardBody>
            <h4>Detail Mutasi</h4>
            <form onSubmit={onSubmitSearch} ref={formRef}>
              <Row className='mt-3 mb-2 align-items-end'>
                <Col lg={2} md={6} sm={12}>
                  <label className='text-uppercase' htmlFor='start'>
                    Start Date
                  </label>
                  <input
                    type='text'
                    id='start'
                    ref={startDateRef}
                    className='form-control'
                    placeholder='Pilih tanggal'
                  />
                </Col>
                <Col lg={2} md={6} sm={12}>
                  <label className='text-uppercase' htmlFor='end'>
                    End Date
                  </label>
                  <input
                    type='text'
                    id='end'
                    ref={endDateRef}
                    className='form-control'
                    placeholder='Pilih tanggal'
                  />
                </Col>
                <Col lg={2} md={6} sm={12} className='mt-2 d-flex '>
                  <div className='flex align-items-end'>
                    <button
                      className='btn btn-primary bg-white text-primary'
                      type='button'
                      onClick={() => {
                        formRef.current?.reset()
                        setStartDate('')
                        setEndDate('')
                        setPage(0)
                        setStateIndicator((v) => !v)
                      }}
                    >
                      <i className='mdi mdi-restore' style={{fontSize: 15}}></i>
                    </button>
                    <button
                      className='ms-3 btn btn-primary'
                      style={{border: '1px solid #3F5F29'}}
                      type='submit'
                    >
                      Terapkan
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
          <div className='table-responsive'>
            <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
              <thead>
                <tr>
                  <th scope='col'>No</th>
                  <th scope='col'>Tanggal dan waktu</th>
                  <th scope='col'>Informasi</th>
                  <th scope='col'>DEbet</th>
                  <th scope='col'>Kredit</th>
                  <th scope='col'>Balance</th>
                </tr>
              </thead>
              <tbody>{renderData()}</tbody>
            </Table>
          </div>
          <div className='p-2'>
            <MyPagination
              total={data?.total || 0}
              page={data?.offset || 0}
              perPage={data?.size || 10}
              onPageChange={(e) => {
                setPage(e.selected)
              }}
            />
          </div>
        <CardFooter className='d-flex justify-content-end'>
          <Link
            to={'/app/agreement/detail/' + id}
            className='btn btn-outline-dark'
          >
            Kembali
          </Link>
        </CardFooter>
        </Card>
      </Container>
    </div>
  )
}

export default AgreementMutations

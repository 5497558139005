import { useQuery } from '@tanstack/react-query'
import BreadCrumb from 'Components/Common/BreadCrumb'
import MyPagination from 'Components/Common/Table/Pagination'
import { apiBearer } from 'Helpers/api'
import debounce from 'Helpers/debounce'
import { formatNumber } from 'Helpers/number'
import {
  ADMIN_REPORT_SALDO, ADMIN_REPORT_SALDO_EXPORT, PARTNER_LIST
} from 'Helpers/url_helper'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import {
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  Table,
  UncontrolledDropdown
} from 'reactstrap'

const SaldoReport = () => {
  const [partnerType, setPartnerType] = useState('')
  const [selectedPartnerType, setselectedPartnerType] = useState('')
  const [partnerId, setPartnerId] = useState('')
  const [selectedPartnerId, setselectedPartnerId] = useState('')
  const [isFetched, setIsFetched] = useState(false)
  const [stateIndicator, setStateIndicator] = useState(false)
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(10)

  const formRef = useRef()



  const {data, isFetching, status, refetch} = useQuery(
    ['report-saldo', page, size],
    async () => {
      const params = {
        page,
        size,
        partnerType,
        partnerId
      }
      setIsFetched(true)
      const res = await apiBearer.get(ADMIN_REPORT_SALDO, {params})
      return res.data?.data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      enabled: isFetched,
      retryOnMount: false,
      retry: false,
    },
  )

  //handle refetch when form change and reset button clicked
  useEffect(() => {
    if (isFetched) refetch()
  }, [isFetched, refetch, stateIndicator])

  const {
    isFetching: isFetchingExport,
    refetch: refetchExport,
  } = useQuery(
    ['report-saldo-export', page, size],
    async () => {
      const params = {
        page,
        size,
        partnerType,
        partnerId
      }
      const res = await apiBearer.get(ADMIN_REPORT_SALDO_EXPORT, {
        params,
        responseType: 'blob',
      })
      const href = URL.createObjectURL(res.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `Report Komisi Trucking.xlsx`) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      enabled: false,
    },
  )

  const onSubmitSearch = (e) => {
    e.preventDefault()
    if(page === 0) refetch()
    else setPage(0)
  }

  const theadStyles = {
    textAlign: 'center',
    verticalAlign: 'middle',
  }


  const renderData = () => {
    if (status === 'error')
      return (
        <tr>
          <td colSpan={7} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    if (isFetching)
      return (
        <tr>
          <td colSpan={7} className='text-center'>
            <Spinner color='success' />
          </td>
        </tr>
      )
    if (status === 'loading' && !isFetching) {
      return (
        <tr>
          <td colSpan={7} className='text-center'>
            <span className='text-danger'>Search to show data</span>
          </td>
        </tr>
      )
    }
    if (!data?.data || data?.data.length < 1)
      return (
        <tr>
          <td colSpan={7} className='text-center'>
            <span className='text-danger'>No Data</span>
          </td>
        </tr>
      )
    return data?.data?.map((props, i) => {
      return (
        <tr key={i}>
          <td>{i + data?.offset + 1}</td>
          <td>
            <div className='hstack gap-3 fs-15'>
              <Link to={`/app/laporan/saldo/detail/${props.id}`} className='link-success'>
                <i className='ri-eye-fill'></i>
              </Link>
            </div>
          </td>
          <td>{props.partner_type}</td>
          <td>{props.partner_name}</td>
          <td>{props.partner_email}</td>
          <td>{props.partner_address}</td>
          <td>Rp. {formatNumber(props.saldo)}</td>
        </tr>
      )
    })
  }

  const searchUser = async (input, isEmail = false) => {
    return new Promise(async (res, rej) => {
      try {
        const result = await apiBearer.get(
          `${PARTNER_LIST}?search=${input}&size=10`,
        )
        const {data} = result
        if (data.success) {
          res( 
            isEmail ? 
            data?.data?.data?.map((v) => ({label: v?.email, value: v?.email}))
            :
            data?.data?.data?.map((v) => ({label: v?.name, value: v?.id})))
        }
        rej(data.message)
      } catch (error) {
        rej(error)
      }
    })
  }

  const debounceSearchUser = debounce((input, cb) => {
    searchUser(input).then((op) => cb(op))
  }, 1500)

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb title='Laporan Saldo' />
        <Card>
          <CardBody>
            <h4>Laporan Saldo</h4>
            <div style={{border: '1px solid #3F5F29'}} className='rounded mt-2'>
              <div className='bg-primary px-3 py-3'>
                <h6 className='text-white '>Search</h6>
              </div>
              <form onSubmit={(onSubmitSearch)} ref={formRef}>
                <Row className='px-3 mt-3 mb-2 align-items-end'>
                  <Col md={6} sm={12}>
                    <label htmlFor='method'>Mitra</label>
                    <Select
                      isClearable
                      cacheOptions
                      value={selectedPartnerType ?? ''}
                      options={[
                        {label:'WAREHOUSE', value:'WAREHOUSE'},
                        {label:'CUSTOMER', value:'CUSTOMER'},
                        {label:'TRUCKING', value:'TRUCKING'},
                        {label:'FORWARDERS', value:'FORWARDERS'}
                      ]}
                      onChange={(v) => {
                        setPartnerType(v?.value ?? '')
                        setselectedPartnerType(v)
                      }}
                      placeholder='Pilih status'
                    />
                  </Col>
                  <Col md={6} sm={12}>
                    <label htmlFor='consignee'>Nama Mitra</label>
                      <AsyncSelect
                        key={partnerType}
                        defaultOptions
                        isClearable
                        // isDisabled={!partnerType}
                        value={selectedPartnerId ?? ''}
                        loadOptions={debounceSearchUser}
                        onChange={(e) => {
                          setPartnerId(e?.value ?? '')
                          setselectedPartnerId(e)
                        }}
                        placeholder={'Search '+partnerType}
                      />
                  </Col>
                  <Col sm={12} className='mt-2 d-flex justify-content-end'>
                    <div className='flex align-items-end'>
                      <button
                        className='btn btn-primary'
                        type='button'
                        onClick={() => {
                          formRef.current?.reset()
                          setselectedPartnerId(null)
                          setPartnerId('')
                          setPartnerType('')
                          setselectedPartnerType(null)
                          setPage(0)
                          setStateIndicator((v) => !v)
                        }}
                      >
                        <i
                          className='mdi mdi-restore'
                          style={{fontSize: 15}}
                        ></i>
                      </button>
                      <button
                        className='ms-3 btn btn-primary bg-white text-primary'
                        style={{border: '1px solid #3F5F29'}}
                        type='submit'
                      >
                        Search
                      </button>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
            <Row className='mt-3'>
              <Col lg={12} md={12} xs={12} className='mb-2'>
                <div className='d-flex'>
                  <div className='flex-grow-1'>
                    Show&nbsp;
                    <ButtonGroup>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag='button'
                          className='btn btn-sm btn-primary'
                        >
                          {data?.perPage}
                          <i className='mdi mdi-chevron-down'></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem onClick={() => setSize(10)}>
                            10
                          </DropdownItem>
                          <DropdownItem onClick={() => setSize(25)}>
                            25
                          </DropdownItem>
                          <DropdownItem onClick={() => setSize(50)}>
                            50
                          </DropdownItem>
                          <DropdownItem onClick={() => setSize(100)}>
                            100
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </ButtonGroup>
                    &nbsp;entries
                  </div>
                  <div className='flex-shrink-0'>
                    <button
                      type='button'
                      className='btn btn-sm btn-primary waves-effect waves-light fw-bold d-flex align-items-center'
                      onClick={refetchExport}
                    >
                      <i
                        className={
                          isFetchingExport
                            ? 'mdi mdi-loading mdi-spin mdi-18px me-2'
                            : 'mdi mdi-printer mdi-18px me-2'
                        }
                      ></i>{' '}
                      Export
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            <div className='table-responsive'>
              <Table className='table-hover table-bordered align-middle table-nowrap mb-0'>
                <thead>
                  <tr>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      No
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Aksi
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Mitra
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Nama Mitra
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Email
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Alamat
                    </th>
                    <th scope='col' rowSpan={2} style={theadStyles}>
                      Saldo
                    </th>
                  </tr>
                </thead>
                <tbody>{renderData()}</tbody>
              </Table>
            </div>
            <MyPagination
              total={data?.total || 0}
              page={data?.offset || 0}
              perPage={data?.size || 10}
              onPageChange={(e) => {
                setPage(e.selected)
              }}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default SaldoReport

import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorFieldWrapper from "../../../Components/Common/ErrorFieldWrapper";
import { TruckingTypeRQ } from "Hooks/Queries";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select/async";
import { apiBearer } from "Helpers/api";
import { LIST_TRUCKING_CAT_URL } from "Helpers/url_helper";
import debounce from "Helpers/debounce";
import { validName } from "Helpers/regex_validation";

export default function EditCreateTruckingCat(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [truckCat, setTruckCat] = useState(null);
  const [truckCatOptions, setTruckCatOptions] = useState([]);

  const fetchTruckCats = async (input) => {
    return new Promise(async (res, rej) => {
      try {
        const result = await apiBearer.get(
          `${LIST_TRUCKING_CAT_URL}?keyword=${input}&size=${10}`
        );
        const { data } = result;
        if (data.success) {
          setTruckCatOptions(
            data?.data?.data?.map((v) => ({ label: v?.name, value: v?.id }))
          );
          res(data?.data?.data?.map((v) => ({ label: v.name, value: v.id })));
        }
        rej(data.message);
      } catch (error) {
        rej(error);
      }
    });
  };

  const debounceSearchTrucks = debounce((input, cb) => {
    fetchTruckCats(input).then((op) => cb(op));
  }, 1500);

  // useEffect(() => {
  //   fetchTruckCats();
  // }, []);

  const validation = Yup.object().shape({
    name: Yup.string().required("Name harus diisi")
    .test('test-valid-name', 'Name hanya boleh berisi huruf', (v)=> validName.test(v)),
    truck_category_id: Yup.string().required("Truck category is required"),
  });
  const formOptions = {
    resolver: yupResolver(validation),
    defaultValues: {
      name: "",
      truck_category_id: "",
    },
  };
  const { handleSubmit, formState, register, reset, control } =
    useForm(formOptions);
  const { errors } = formState;

  const onTruckCatSelected = async (e) => {
    setTruckCat(e);
  };

  const { mutate: updateTrucking, isLoading: updateLoading } = TruckingTypeRQ.useUpdateData(navigate)();

  const _updateTruckingCat = (values) => {
    const body = values;
    body.id = id;
    updateTrucking(body);
  };

  const { mutate: addTrucking, isLoading: createLoading } = TruckingTypeRQ.useAddData(navigate)();

  const _createTruckingCat = async (values) => {
    addTrucking(values);
  };

  const { data, error, isFetching, status } = TruckingTypeRQ.useGetData(id)();

  useEffect(() => {
    if (!isFetching && status === "success") {
      reset({
        name: data?.data?.name ?? "",
        truck_category_id: data?.data?.truck_category?.id ?? "",
      });
      if(data?.data?.truck_category){
        setTruckCat({label:data.data.truck_category.name, value:data.data.truck_category.id})
      }
    }
    if (!isFetching && status === "error") {
      console.log(error);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [status, isFetching]);

  const isLoading = createLoading || updateLoading

  const submitBtnText = ()=> {
    if(isLoading) return 'Loading...'
    return id ? "Update" : "Create"
  }


  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Trucking Type" pageTitle="Trucking Type" />
        <Row>
          <Col lg={12}>
            <form
              onSubmit={handleSubmit(
                id ? _updateTruckingCat : _createTruckingCat
              )}
            >
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {id ? "Edit" : "Create"} Trucking Type
                  </h4>
                </CardHeader>
                <CardBody className="card-body">
                  <Row className="gy-4">
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.name}
                        message={errors.name?.message}
                      >
                        <Label htmlFor="name">
                          Name <span className="mandatory">*</span>
                        </Label>
                        <input className="form-control" {...register('name')}/>
                      </ErrorFieldWrapper>
                    </Col>
                    <Col md={6}>
                      <ErrorFieldWrapper
                        error={errors.truck_category_id}
                        message={errors.truck_category_id?.message}
                      >
                        <Label
                          htmlFor="truck_category_id"
                          className="form-label"
                        >
                          Trucking Category <span className="mandatory">*</span>
                        </Label>
                        <Controller
                          id="truck_category_id"
                          name="truck_category_id"
                          type="text"
                          control={control}
                          render={({ field: { onChange, ...field } }) => (
                            <Select
                              {...field}
                              cacheOptions
                              isClearable
                              defaultOptions
                              value={truckCat ?? ""}
                              loadOptions={debounceSearchTrucks}
                              onChange={(e) => {
                                if (e?.value) onChange(e.value);
                                onTruckCatSelected(e);
                              }}
                              placeholder="Search by name"
                            />
                          )}
                        />
                      </ErrorFieldWrapper>
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter className="align-items-center d-flex justify-content-end gap-3">
                  <Link to="/app/truck/type" className="btn btn-danger">
                    Cancel
                  </Link>
                  <Button color="success" type="submit" disabled={isLoading}>
                    {submitBtnText()}
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

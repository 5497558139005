import {apiBasic} from 'Helpers/api'
import {showError} from 'Helpers/show_toast'
import {LOGIN_NLE_V2} from 'Helpers/url_helper'
import {useState} from 'react'
import {Modal, ModalBody} from 'reactstrap'

const NleLoginModal = ({show, toggleModal, onSuccessLogin}) => {
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()
    try {
      const response = await apiBasic.post(LOGIN_NLE_V2, {
        username: e.target.username.value,
        password: e.target.password.value,
      })
      const {data} = response
      onSuccessLogin( {...data.data, username:e.target.username.value})
    } catch (error) {
      console.log(error)
      if (error.response && error.response.data)
        showError(error.response.data.message)
      else showError('Something wrong, pleas try again later')
    }
    setLoading(false)
  }

  return (
    <Modal
      isOpen={show}
      toggle={toggleModal}
      centered={true}
      id='exampleModal'
      style={{border: 'none', maxWidth: 400}}
    >
      <ModalBody style={{background: '#233552', borderRadius: 5}}>
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <img
            src='/assets/images/logo-nle-transparent.png'
            alt=''
            loading='eager'
            className='img-fluid'
            style={{borderRadius: 15, maxWidth: 300}}
          />
          <form className='px-5 mt-4' onSubmit={handleSubmit}>
            <input
              name='username'
              type='text'
              placeholder='username'
              className='form-control mb-3'
              required
            />
            <div className='input-group'>
              <input
                name='password'
                type={showPassword ? 'text' : 'password'}
                placeholder='Password'
                className='form-control'
                required
              />
              <button
                className='btn btn-warning'
                type='button'
                id='inputGroupFileAddon04'
                onClick={() => setShowPassword((v) => !v)}
              >
                <i
                  className={`ri ${
                    showPassword ? 'ri-eye-fill' : 'ri-eye-off-fill'
                  }`}
                ></i>
              </button>
            </div>
            <button
              className='btn w-100 my-3 fw-bold'
              type='submit'
              disabled={loading}
              style={{background: '#ffc107'}}
            >
              {loading ? 'Loading...' : 'Login'}
            </button>

            <div className='d-flex flex-column justify-content-center fs-13 fw-medium mb-3'>
              <a
                href='https://portal.beacukai.go.id/portal/lupa'
                target='_blank'
                rel='noreferrer'
                style={{color: 'white'}}
              >
                Lupa nama user/kata sandi
              </a>
              <a
                href='https://portal.beacukai.go.id/portal/daftar'
                target='_blank'
                rel='noreferrer'
                style={{color: 'white'}}
              >
                Daftar baru
              </a>
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  )
  //   <div>NleLoginModal</div>
}

export default NleLoginModal
